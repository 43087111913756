import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { Row, Col, Statistic, Progress, Spin } from 'antd';
import { CloudDownloadOutlined, CloudOutlined, CloudUploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { ROW_GUTTER } from 'constants/ThemeConstant';

const UserStorageCard = forwardRef(({ storageUpdated, userId, authToken, api }, ref) => {
    const [storage, setStorage] = useState({
        total: 0,
        used: 0,
        remaining: 0
    });
    const [loading, setLoading] = useState(false);

    const convertToMB = (value) => {
        if (typeof value === "string") {
            const [amount, unit] = value?.split(" ");
            const size = parseFloat(amount);

            switch (unit) {
                case "GB":
                    return size * 1024;
                case "KB":
                    return size / 1024;
                case "Bytes":
                    return size / (1024 * 1024);
                default:
                    return size; // Assume already in MB
            }
        }
        return value;
    };
    const fetchUserStorage = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${api}user/${userId}/storage`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            const totalMB = convertToMB(response.data.storage_limit);
            const usedMB = convertToMB(response.data.used_storage);
            const remainingMB = convertToMB(response.data.remaining_storage);

            setStorage({
                total: totalMB,
                used: usedMB,
                remaining: remainingMB
            });
        } catch (error) {
            console.error('Error fetching user storage:', error);
        } finally {
            setLoading(false);
        }
    }, [userId, authToken, api]); // Dependencies

    useEffect(() => {
        fetchUserStorage();
    }, [fetchUserStorage, storageUpdated]);

    const usedPercentage = storage.total > 0 ? (storage.used / storage.total) * 100 : 0;
    return (
        <>
            {loading ? (
                <Spin size="large" style={{ display: 'block', textAlign: 'center', margin: '20px 0' }} />
            ) : (
                <div className=''>
                    <Row justify="end" gutter={ROW_GUTTER}>
                        {/* Progress Bar */}
                        <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                            <Progress
                                percent={usedPercentage.toFixed(1)}
                                status={usedPercentage < 80 ? "active" : "exception"}
                                strokeColor={{
                                    "0%": "#108ee9",
                                    "100%": usedPercentage < 80 ? "#108ee9" : "#ff4d4f",
                                }}
                                size="small"
                            />
                        </Col>
                    </Row>
                    <Row justify="end" gutter={ROW_GUTTER}>
                        {/* Storage Stats */}
                        <Col xs={24} sm={12} md={10} lg={8} xl={6}>
                            <Row justify="space-between">
                                <Col span={8} className="text-center">
                                    <Statistic
                                        title="Total"
                                        value={storage.total.toFixed(1)}
                                        suffix="MB"
                                        prefix={<CloudOutlined style={{ color: "#108ee9" }} />}
                                        valueStyle={{ fontSize: "16px" }}
                                    />
                                </Col>
                                <Col span={8} className="text-center">
                                    <Statistic
                                        title="Used"
                                        value={storage.used.toFixed(1)}
                                        suffix="MB"
                                        prefix={<CloudUploadOutlined />}
                                        valueStyle={{
                                            fontSize: "16px",
                                            color: usedPercentage >= 80 ? "#ff4d4f" : undefined,
                                        }}
                                    />
                                </Col>
                                <Col span={8} className="text-center">
                                    <Statistic
                                        title="Free"
                                        value={storage.remaining.toFixed(1)}
                                        suffix="MB"
                                        prefix={<CloudDownloadOutlined />}
                                        valueStyle={{ fontSize: "16px" }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
});

export default UserStorageCard;
