import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Card, Table, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Flex from 'components/shared-components/Flex'
import utils from 'utils'
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';
const Groups = forwardRef(({ GetGroupNumber }, ref) => {
    const { api, authToken, UserData } = useMyContext();
    const [list, setList] = useState([])
    const [groupListData, setGroupData] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [loading, setLoading] = useState(false)


    const GroupData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}groups/${UserData.id}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            const data = (response.data.groups).reverse();
            setGroupData(data);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    useEffect(() => {
        GroupData()
    }, [])

    useImperativeHandle(ref, () => ({
        resetSelection: () => {
            setSelectedRowKeys([]); // Clear the selection
        }
    }));



    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'name')
        },
        {
            title: 'Description',
            dataIndex: 'description',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'description')
        },
        {
            title: 'Total Contacts',
            dataIndex: 'contacts_count',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'contacts_count')
        },
        // {
        //     title: 'Actions',
        //     dataIndex: 'actions',
        //     render: (_, elm) => (
        //         <Flex alignItems="center" style={{ gap: '1rem' }}>
        //             <Link to={`manage/${elm.id}`} className='d-flex h4'>
        //                 <EyeOutlined />
        //             </Link>
        //             <span className='d-flex h4 cursor-pointer'>
        //                 <DeleteOutlined />
        //             </span>
        //         </Flex>
        //     )
        // }
    ];

    const rowSelection = {
        onChange: (key, rows) => {
            setSelectedRows(rows)
            setSelectedRowKeys(key)
        }
    };

    const onSearch = e => {
        const value = e.currentTarget.value
        const searchArray = e.currentTarget.value ? list : groupListData
        const data = utils.wildCardSearch(searchArray, value)
        setList(data)
        setSelectedRowKeys([])
    }

    useEffect(() => {
        if (selectedRowKeys) {
            const selectedGroups = groupListData.filter(group => selectedRowKeys.includes(group.id));
            const contacts = selectedGroups.flatMap(group => group.contacts?.map(contact => contact.number));
            GetGroupNumber(contacts);
        }
    }, [selectedRowKeys]);

    return (
        <Card title="Contact Groups">
            <Flex alignItems="center" justifyContent="space-between" mobileFlex={false}>
                <Flex className="mb-1" mobileFlex={false}>
                    <div className="mr-md-3 mb-3">
                        <Input placeholder="Search" prefix={<SearchOutlined />} onChange={e => onSearch(e)} />
                    </div>
                </Flex>
            </Flex>
            <div className="table-responsive">
                <Table
                    loading={loading}
                    columns={tableColumns}
                    dataSource={groupListData}
                    rowKey='id'
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        type: 'checkbox',
                        preserveSelectedRowKeys: false,
                        ...rowSelection,
                    }}
                />
            </div>
        </Card>
    )
});

export default Groups
