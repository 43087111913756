import { Card, Col, Row } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import EditChatBotForm from './Compoenents/EditChatBotForm';
import ChatbotInfo from './ChatbotCodeComps/ChatbotInfo'
const EditChatBot = () => {
    const { id } = useParams();
    return (
        <>
            <Row gutter={ROW_GUTTER}>
                <Col xs={24} sm={24} md={24} lg={18}>
                    <Card title="Edit Response" style={{ maxHeight: 700, overflow: 'auto' }}>
                        <EditChatBotForm id={id} showParentChatbot={true} />
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} style={{ maxHeight: 720, overflow: 'auto' }}>
                    <ChatbotInfo id={id}/>
                </Col>
            </Row>
        </>
    )
}

export default EditChatBot
