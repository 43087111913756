import axios from 'axios';
import React, { createContext, useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { SmileOutlined } from '@ant-design/icons';
import { Grid, notification } from 'antd';
import Utils from 'utils';
const { useBreakpoint } = Grid;
const MyContext = createContext();

export const MyContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [timestamp, setTimestamp] = useState(0);
  const [maintenance, setMaintenance] = useState(false);
  const [midnightTimestamp, setMidnightTimestamp] = useState(0);
  const [userBalance, setUserBalance] = useState('00.00');
  const [userAlertBalance, setUserAlertBalance] = useState(0);
  const [templates, setTemplates] = useState([]);
  const [adminConfig, setAdminConfig] = useState([]);
  const [UserList, setUserList] = useState([]);
  const [supportAgents, setSupportAgents] = useState([]);
  const [qualityData, setQualityData] = useState();

  const api = process.env.REACT_APP_API_PATH;
  const UserData = useSelector((state) => state?.auth?.user);
  const authToken = useSelector((state) => state?.auth?.token);
  const themeData = useSelector((state) => state?.theme);
  const Permisson = useSelector((state) => state?.auth?.user?.permissions);
  const twoFactor = useSelector(state => state?.auth?.twoFactor);

  const userRole = UserData?.role;


  const apiKey = UserData?.api_key && UserData?.api_key[UserData?.api_key?.length - 1]?.key;
  const appId = UserData?.user_config?.app_id;
  const smsAlert = UserData?.text_alerts === 'true';
  const whatsappAlert = UserData?.whatsapp_alerts === 'true';
  const emailAlert = UserData?.email_alerts === 'true';
  const isSupportAgent = userRole === 'Support Agent';
  const waToken = isSupportAgent ? UserData?.reporting_user?.user_config?.meta_access_token : UserData?.user_config?.meta_access_token;
  const phone_id = isSupportAgent ? UserData?.reporting_user?.user_config?.whatsapp_phone_id : UserData?.user_config?.whatsapp_phone_id
  const messagesApi = useMemo(() => process.env.REACT_APP_WA_API_MESSAGES.replace(':whatsapp_phone_id:', phone_id), [phone_id]);
  const uploadMediaApi = useMemo(() => process.env.REACT_APP_WA_API_UPLOAD_MEDIA.replace(':whatsapp_phone_id:', UserData?.user_config?.whatsapp_phone_id), [UserData]);

  const analyticsApi = useMemo(() => {
    if (!UserData || !timestamp || !midnightTimestamp) return null;
    return process.env.REACT_APP_WA_API_ANALYTICS
      .replace(':wapId:', UserData?.user_config?.whatsapp_business_account_id)
      .replace(':realtime_unix:', timestamp)
      .replace(':waToken:', waToken)
      .replace(':today_unix:', midnightTimestamp);
  }, [UserData, waToken, timestamp, midnightTimestamp]);

  const templatesApi = useMemo(() => {
    if (!UserData || !waToken) return null;
    return process.env.REACT_APP_WA_API_TEMPLATES
      .replace(':whatsapp_business_account_id:', UserData?.user_config?.whatsapp_business_account_id)
      .replace(':waToken:', waToken);
  }, [UserData, waToken]);

  const temlateSubmitApi = useMemo(() => {
    if (!UserData) return null;
    return process.env.REACT_APP_WA_API_TEMPLATES_SUBMIT
      .replace(':whatsapp_business_account_id:', UserData?.user_config?.whatsapp_business_account_id);
  }, [UserData]);

  const templateDelete = useMemo(() => {
    if (!UserData) return null;
    return 'https://graph.facebook.com/v18.0/:whatsapp_business_account_id:/message_templates?hsm_id=:template_id:&name=:name:'
      .replace(':whatsapp_business_account_id:', UserData?.user_config?.whatsapp_business_account_id);
  }, [UserData]);

  const getMidnightTimestamp = useCallback(() => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return Math.floor(now.getTime() / 1000);
  }, []);

  const GetSettingData = useCallback(async () => {
    try {
      const response = await axios.get(`${api}setting`, {
        headers: { 'Authorization': 'Bearer ' + authToken }
      });
      setMaintenance(response.data.setting?.maintenance === 1);
      setAdminConfig(response.data.setting);
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  }, [api, authToken]);

  const UserCredits = useCallback(async () => {
    if (!UserData?.id) return;
    try {
      const response = await axios.get(`${api}chek-user/${UserData.id}`, {
        headers: { 'Authorization': 'Bearer ' + authToken }
      });
      setUserBalance(response.data.balance);
    } catch (error) {
      console.error('Error fetching user credits:', error);
    }
  }, [api, authToken, UserData]);

  const GetTemplate = useCallback(async () => {
    if (!templatesApi) return;
    try {
      const response = await axios.get(templatesApi, {
        headers: { 'Authorization': 'Bearer ' + waToken }
      });
      setTemplates(response.data.data);
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  }, [templatesApi, waToken]);

  const GetNumberQuality = useCallback(async (wabaID, number) => {
    if (!number && !wabaID) {
      setQualityData()
      return;
    }
    try {
      const response = await axios.get(`https://graph.facebook.com/v20.0/${wabaID}/phone_numbers?access_token=${waToken}`, {
        headers: { 'Authorization': 'Bearer ' + waToken }
      });
      setQualityData(response.data.data[0]);
    } catch (error) {
      setQualityData()
      console.error('Error fetching templates:', error);
    }
  }, [waToken, UserData]);

  const Userslist = useCallback(async () => {
    try {
      const response = await axios.get(`${api}users`, {
        headers: { 'Authorization': 'Bearer ' + authToken }
      });
      setUserList(response.data.user);
    } catch (error) {
      console.error('Error fetching users list:', error);
    }
  }, [api, authToken]);

  const SupportAgentslist = useCallback(async () => {
    try {
      const response = await axios.get(`${api}user/support-agents`, {
        headers: { 'Authorization': 'Bearer ' + authToken }
      });
      let data = response.data?.data
      setSupportAgents(data);
    } catch (error) {
      console.error('Error fetching users list:', error);
    }
  }, [api, authToken]);

  useEffect(() => {
    const updateTimestamp = () => {
      setTimestamp(Math.floor(Date.now() / 1000));
      setMidnightTimestamp(getMidnightTimestamp());
    };
    updateTimestamp();
    const intervalId = setInterval(updateTimestamp, 1000);

    return () => clearInterval(intervalId);
  }, [getMidnightTimestamp]);

  useEffect(() => {
    if (!UserData?.id) return;
    const initializeData = async () => {
      await GetSettingData();
      setUserAlertBalance(UserData?.pricing_model?.price_alert);
      await UserCredits();
      await GetTemplate();
      SupportAgentslist()
      if (userRole === 'Admin' || userRole === 'Reseller') {
        await Userslist();
      }
    };
    initializeData();
  }, [UserData, GetSettingData, UserCredits, GetTemplate, Userslist, userRole]);

  const encryptData = useCallback((data) => {
    if (userRole === 'Admin') {
      return data;
    } else {
      if (data?.length > 0) {
        if (data?.length <= 4) return data;
        const firstTwo = data?.substring(0, 2);
        const lastTwo = data?.substring(data?.length - 2);
        const middle = "*".repeat(data?.length - 4);
        return `${firstTwo}${middle}${lastTwo}`;
      }
    }
  }, [userRole]);

  const DownloadExcelFile = useCallback((data, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);
  }, []);

  const HandleExport = useCallback(async (path, fileName, id = null) => {
    try {
      const response = await axios.get(`${api}${path}/${id || UserData.id}`, {
        headers: {
          'Authorization': 'Bearer ' + authToken,
        },
      });
      const data = response.data?.data;
      DownloadExcelFile(data, fileName);
    } catch (error) {
      console.error('Error in HandleExport:', error);
    }
  }, [api, authToken, UserData, DownloadExcelFile]);

  const HandleSendEmail = useCallback(async (id, body) => {
    try {
      const response = await axios.post(`${api}send-email/${id}`, body, {
        headers: {
          'Authorization': 'Bearer ' + authToken,
        }
      });
      if (response.data.status) {
        openNotification('Email');
      }
    } catch (error) {
      console.error('Error in HandleSendEmail:', error);
    }
  }, [api, authToken]);

  const handleWhatsappAlert = useCallback(async (id, apikey, number, name, price_alert, latest_balance) => {
    const modifiedNumber = modifyNumber(number);
    try {
      const response = await axios.post(`https://event.smsforyou.biz/api/send-messages?apikey=${apiKey}&to=${modifiedNumber}&type=T&tname=lowbalance1&values=${name},${price_alert},${latest_balance}`);
      if (response.data.status) {
        openNotification('Whatsapp');
      }
    } catch (error) {
      console.error('Error in handleWhatsappAlert:', error);
    }
  }, [apiKey]);

  const HandleSendSMS = useCallback(async (number, message) => {
    const otpApi = `https://login.smsforyou.biz/V2/http-api.php?apikey=${adminConfig?.sms_apiKey}&senderid=${adminConfig?.sms_senderId}&number=${number}&message=${message}&format=json`;
    try {
      await axios.get(otpApi);
    } catch (error) {
      console.error('Error in HandleSendSMS:', error);
    }
  }, [adminConfig]);

  const handleMakeReport = useCallback(async (number, message_id) => {
    try {
      await axios.post(`${api}make-reports`, {
        message_id: message_id,
        waId: number,
        display_phone_number: UserData?.whatsapp_number
      }, {
        headers: {
          'Authorization': 'Bearer ' + authToken,
        }
      });
    } catch (error) {
      console.error('Error in handleMakeReport:', error);
    }
  }, [api, authToken, UserData]);

  const openNotification = useCallback((type) => {
    notification.open({
      message: 'Alert Sent',
      description: `Alert has been sent to this user via ${type}.`,
      duration: 2,
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    });
  }, []);

  const modifyNumber = useCallback((number) => {
    let mob_number = String(number);
    if (mob_number.length === 10) {
      return '91' + mob_number;
    } else if (mob_number.length === 12) {
      return mob_number;
    }
  }, []);

  useEffect(() => {
    GetNumberQuality(UserData?.user_config?.whatsapp_business_account_id, UserData?.whatsapp_number)
  }, [UserData]);

  const handleSearch = (value, setDataState, resetFunction, data, tableColumns) => {
    if (value) {
      const searchLower = value.toLowerCase();
      const filtered = data?.filter((item) =>
        tableColumns.some((column) =>
          item[column.dataIndex]?.toString().toLowerCase().includes(searchLower)
        )
      );
      setDataState(filtered);
    } else {
      resetFunction();
    }
  };

  const getMedia = useCallback(async (setState) => {
    try {
      const response = await axios.get(`${api}media/${UserData?.id}`, {
        headers: { 'Authorization': 'Bearer ' + authToken },
      });
      const data = response.data?.media || [];
      setState(data)
    } catch (error) {
    }
  }, [UserData, api, authToken]);

  const getUrlFromMediaId = useCallback((mediaId, media) => {

    if (mediaId && media) {
      const mediaItem = media?.find((item) => item?.media_id === mediaId);
      if (mediaItem) {
        return mediaItem?.path;
      } else {
        return '/img/others/dummy-header.jpg';
      }
    }
  }, []);


  //what is suitable for CheckExpiredMedia function useCallback or useMemo

  const CheckExpiredMedia = useCallback(async (mediaId) => {
    try {
      const response = await axios.get(`https://graph.facebook.com/v21.0/${mediaId}`, {
        headers: {
          'Authorization': `Bearer ${waToken}`
        }
      });
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }, [waToken]);

  const calculateRequredBalance = useCallback((totalNUmbers, category) => {
    if (totalNUmbers && category) {
      let categoryType = [category] + '_price'
      let total = totalNUmbers * UserData?.pricing_model?.[categoryType]
      return total < userBalance;
    }
  }, [UserData, userBalance]);

  //useMemo in buttonStyle
  const buttonStyle = useMemo(() => {
    return {
      background: 'linear-gradient(116deg,  #40e495, #30dd8a, #2bb673)',
      color: 'white',
      border: 'none'
    }
  }, []);

  const getNumberCount = useCallback((numbers, number) => {
    if (number || numbers?.length > 0) {
      const count = numbers?.length +
        number
          ?.split(',')
          ?.map(item => item.trim())
          ?.flatMap(item => item.split('\n').map(subItem => subItem.trim()))
          ?.filter(item => item !== '' && (item.length === 10 || item.length === 12)).length
      return count;
    } else {
      return 0;
    }
  }, []);

  const isMobile = !Utils.getBreakPoint(useBreakpoint()).includes('lg');

  const contextDependencies = {
    phone_id, api, waToken, authToken, UserData, themeData, navigate, messagesApi, analyticsApi, templatesApi, temlateSubmitApi, userBalance, UserCredits, appId, templates, Permisson, GetTemplate, timestamp, twoFactor, userRole, UserList, setTemplates, smsAlert, whatsappAlert, emailAlert, adminConfig, encryptData, DownloadExcelFile, HandleExport, HandleSendEmail, handleWhatsappAlert, HandleSendSMS, handleMakeReport, maintenance, templateDelete, apiKey, userAlertBalance, uploadMediaApi, setMaintenance, qualityData, calculateRequredBalance, modifyNumber, getMedia, getUrlFromMediaId, CheckExpiredMedia, buttonStyle, isSupportAgent, supportAgents, getNumberCount, isMobile
  }
  const contextValue = useMemo(() => ({ ...contextDependencies }),
    Object.keys(contextDependencies)?.map(key => contextDependencies[key])
  );
  // const contextValue = useMemo(() => ({
  //   api, waToken, authToken, UserData, themeData, navigate, messagesApi, analyticsApi, templatesApi, temlateSubmitApi, userBalance, UserCredits, appId, templates, Permisson, GetTemplate, timestamp, twoFactor, userRole, UserList, setTemplates, smsAlert, whatsappAlert, emailAlert, adminConfig, encryptData, DownloadExcelFile, HandleExport, HandleSendEmail, handleWhatsappAlert, HandleSendSMS, handleMakeReport, maintenance, templateDelete, apiKey, userAlertBalance, uploadMediaApi, setMaintenance, qualityData, calculateRequredBalance, modifyNumber, getMedia, getUrlFromMediaId, CheckExpiredMedia, buttonStyle, isSupportAgent, supportAgents, getNumberCount
  // }), [
  //   api, waToken, authToken, UserData, themeData, navigate, messagesApi, analyticsApi, templatesApi, temlateSubmitApi, userBalance, UserCredits, appId, templates, Permisson, GetTemplate, timestamp, twoFactor, userRole, UserList, smsAlert, whatsappAlert, emailAlert, adminConfig, maintenance, templateDelete, apiKey, userAlertBalance, uploadMediaApi, encryptData, DownloadExcelFile, HandleExport, HandleSendEmail, handleWhatsappAlert, HandleSendSMS, handleMakeReport, qualityData, calculateRequredBalance, modifyNumber, getMedia, getUrlFromMediaId, CheckExpiredMedia, buttonStyle, isSupportAgent, supportAgents, getNumberCount
  // ]);

  return <MyContext.Provider value={contextValue}>{children}</MyContext.Provider>;
};

export const useMyContext = () => useContext(MyContext);