import { Col, Form, Input, Radio, Select, Space, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { Chatbots } from '../ChatBotUtils/ChatbotListApi';
import { useMyContext } from 'Context/MyContextProvider';
import { motion } from "framer-motion"
import KeywordField from '../ChatBotUtils/KeywordField';
const { Option } = Select;
export const ChatbotSelect = ({ setChatBot, value }) => {
    const { authToken, api, UserData } = useMyContext()
    const [chatbots, setChatbots] = useState([]);
    const [loading, setLoading] = useState(false);
    const filterOption = (input, option) => {
        try {
            const keywords = JSON.parse(option?.children || "[]");
            const searchTerm = input.toLowerCase();
            return keywords.some(keyword =>
                keyword.toLowerCase().includes(searchTerm)
            );
        } catch (error) {
            console.error("Error parsing keywords:", error);
            return false;
        }
    };
    //analytics
    const ChatbotData = async () => {
        await Chatbots(setLoading, api, UserData?.id, authToken, setChatbots);
    }
    useEffect(() => {
        ChatbotData()
    }, [])
    return (
        <Select
            showSearch
            size={'default'}
            defaultValue={value}
            style={{ width: '100%' }}
            onChange={(value) => setChatBot(value)}
            optionFilterProp="keyword"
            filterOption={filterOption}
        >
            {chatbots?.length > 0 ?
                chatbots?.map((item, index) =>
                    <Option value={item.id} key={index}>{item.keyword}</Option>
                )
                :
                <Option value="">Select</Option>

            }
        </Select>
    )
}

export const ParentChatbot = ({ show, parentID, setParentChatBot }) => {
    return (
        <>
            {show && (
                parentID ?
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item label="Select Parent ChatBot" name={'parent chatBot'}>
                            <ChatbotSelect value={parentID} setChatBot={setParentChatBot} />
                            <p className='d-none'>{parentID}</p>
                        </Form.Item>
                    </Col>
                    :
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item label="Select Parent ChatBot" name={'parent chatBot'}>
                            <ChatbotSelect value={'Select ChatBot'} setChatBot={setParentChatBot} />
                        </Form.Item>
                    </Col>
            )}
        </>
    )

}

export const HeaderFields = ({ headerRequired, headerKey, headerValue, setHeaderKey, setHeaderRequired, setHeaderValue }) => {
    const options = [
        {
            value: 'token',
            label: 'Token',
        }
    ];
    return (
        <>
            <>
                <Col xs={24} sm={24} md={6}>
                    <Form.Item label="Header Required">
                        <Switch checked={headerRequired} onChange={(checked) => setHeaderRequired(checked)} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6}>
                    <Form.Item label="Header Key & Value">
                        <Space.Compact>
                            <Input value={headerKey} onChange={(e) => setHeaderKey(e.target.value)} />
                            <Select value={headerValue} options={options} onChange={(value) => setHeaderValue(value)} />
                        </Space.Compact>
                    </Form.Item>
                    <p>EX : {`{
                'Authorization' : Bearer Token
                }`}</p>
                </Col>
            </>
        </>
    )
}

export const MessageType = ({ value, setValue }) => (
    <Col xs={24} sm={24} md={24}>
        <MotionWrapper>
            <Form.Item label="Message Type">
                <Radio.Group onChange={(e) => setValue(e.target.value)} value={value}>
                    <Radio value='text'>Text</Radio>
                    <Radio value='image'>Image</Radio>
                    <Radio value='video'>Video</Radio>
                    <Radio value='document'>Document(PDF)</Radio>
                </Radio.Group>
            </Form.Item>
        </MotionWrapper>
    </Col>
)
export const ReplyType = ({ value, setValue }) => (
    <Col xs={24} sm={24} md={12}>
        <MotionWrapper>
            <Form.Item label="Reply Type">
                <Radio.Group onChange={(e) => setValue(e.target.value)} value={value}>
                    <Radio value='Custom'>Custom</Radio>
                    <Radio value='template'>Template</Radio>
                    <Radio value='preset'>Carousel Preset</Radio>
                    <Radio value='list_item'>List Item</Radio>
                </Radio.Group>
            </Form.Item>
        </MotionWrapper>
    </Col>
)
export const KeywordsComp = ({ badges, setBadges, inputValue, setInputValue }) => {
    const { themeData } = useMyContext()
    const removeBadge = (index) => {
        const newBadges = [...badges];
        newBadges.splice(index, 1);
        setBadges(newBadges);
    };

    const handleInputKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === ',') {
            event.preventDefault();
            if (inputValue.trim() !== '') {
                setBadges([...badges, inputValue.trim()]);
                setInputValue('');
            }
        }
    };
    return (
        <Col xs={24} sm={24} md={24}>
            <Form.Item label="Keywords">
                <KeywordField
                    badges={badges}
                    themeData={themeData}
                    removeBadge={removeBadge}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    handleInputKeyDown={handleInputKeyDown}
                />
            </Form.Item>
        </Col>
    )
}


export const MotionWrapper = ({ children }) => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            {children}
        </motion.div>
    );
};

export default MotionWrapper;

