import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Button, Card, Col, Form, Row, message } from 'antd'
import { useMyContext } from 'Context/MyContextProvider'
import ChatBotConfig from './ChatbotConfig/ChatBotConfig'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import { DeFautRes } from './Compoenents/DefaultRes'
import ChatBotIdealTimer from './ChatBotIdealTimer'
import ChatbotTransfer from './ChatbotCodeComps/ChatbotTransfer'





const DefaultResponse = () => {

    const { authToken, api, UserData, templates, userRole } = useMyContext()
    const id = UserData?.id;
    const [customMessage, setCustomMessage] = useState('');
    const [requestType, setRequestType] = useState('');
    const [templateName, setTemplateName] = useState('');

    //analytics
    const ChatbotData = async () => {
        try {
            const response = await axios.get(`${api}default-chatbot/${id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
            const data = response.data.default;
            setRequestType(data?.type)
            setCustomMessage(data?.text)
            setTemplateName(data?.template_name)
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        ChatbotData();
    }, [])

    const onChange = e => {
        setRequestType(e.target.value);
    };

    const HandleTemplate = (value) => {
        setTemplateName(value);
    }

    const HandleSubmit = async () => {
        await axios.post(`${api}update-default-chatbot`,
            {
                'type': requestType,
                'user_id': UserData?.id,
                'text': customMessage,
                'template': templateName
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            }).then((res) => {
                message.success('Response Updated Successfully')
            }).catch((error) => {
            })
    }


    return (
        <>
            <Row gutter={ROW_GUTTER}>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <Row gutter={ROW_GUTTER}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Card title="Default Response">
                                <Row>
                                    <Col span={24}>
                                        <Form
                                            labelCol={{ span: 6 }}
                                            wrapperCol={{ span: 24 }}
                                            layout="horizontal"
                                        >
                                            <DeFautRes
                                                requestType={requestType}
                                                onChange={onChange}
                                                setCustomMessage={setCustomMessage}
                                                templateName={templateName}
                                                HandleTemplate={HandleTemplate}
                                                templates={templates}
                                                HandleSubmit={HandleSubmit}
                                                customMessage={customMessage} />
                                            <Form.Item className='text-center'>
                                                <Button className='mt-3 px-3' type="primary" onClick={() => HandleSubmit()}>Submit</Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <ChatBotIdealTimer UserData={UserData} token={authToken} api={api} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <Row gutter={ROW_GUTTER}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <ChatBotConfig />
                        </Col>
                        {userRole === 'Admin' &&
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <ChatbotTransfer />
                            </Col>
                        }
                    </Row>
                </Col>
            </Row>

        </>
    )
}

export default DefaultResponse
