import { Button, DatePicker, Modal, Result } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const ScheduleModel = ({openScheduleModel,handleCancelScheduleModel,HandleScheduleTime,HandleSchedule}) => {
    return (
        <Modal
            title="Schedule Campaign"
            open={openScheduleModel}
            onCancel={handleCancelScheduleModel}
            footer={null}
        >
            <Result
                status="success"
                title="Select date and Time"
                extra={
                    <>
                        <DatePicker
                            showTime={{ format: 'HH:mm' }}
                            format="YYYY-MM-DD HH:mm"
                            onChange={(value) => HandleScheduleTime(value)} />
                        <Link to={``}>
                            <Button type="primary" key="console" onClick={() => HandleSchedule()}>
                                Schedule Campaign
                            </Button>
                        </Link>
                    </>
                }
            />
        </Modal>
    )
}

export default ScheduleModel
