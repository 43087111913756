import React, { useState, useEffect, memo } from 'react';
import { Tag } from 'antd';

export const ExportTimer = memo(({ messageSentTime }) => {
    const [remainingTime, setRemainingTime] = useState(0);

    useEffect(() => {
        const currentDate = new Date();
        const SentTime = new Date(messageSentTime).getTime();
        const expirationTime = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
        const diff = currentDate.getTime() - SentTime;
        const remaining = expirationTime - diff;

        if (remaining > 0) {
            setRemainingTime(remaining);
        }

        const interval = setInterval(() => {
            setRemainingTime(prevTime => {
                if (prevTime <= 1000) {
                    clearInterval(interval);
                    return 0;
                }
                return prevTime - 1000;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [messageSentTime]);

    const formatTime = (time) => {
        const totalSeconds = Math.floor(time / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        // Pad hours, minutes, and seconds with leading zeros
        const pad = (num) => (num < 10 ? `0${num}` : num);

        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    };

    const getColor = (time) => {
        const totalSeconds = Math.floor(time / 1000);
        if (totalSeconds > 18 * 60 * 60) return 'green';
        else if (totalSeconds > 12 * 60 * 60) return 'blue';
        else if (totalSeconds > 6 * 60 * 60) return 'orange';
        else return 'red';
    };

    return (
        <Tag color={remainingTime > 0 ? getColor(remainingTime) : 'red'}>
            {remainingTime > 0 ? (
                <span>
                    Chat Expires In: <strong>{formatTime(remainingTime)}</strong>
                </span>
            ) : (
                <span>Chat has expired.</span>
            )}
        </Tag>
    );
});