import { Button, Modal, Progress, Spin } from 'antd'
import React from 'react'

const ProgressModal = (props) => {
    const {progress,isModalVisible,loading,conicColors,resetCampaign,navigate} = props
    return (
        <Modal
            title={
                <div style={{ textAlign: 'center', width: '100%' }}>
                    {progress === 100 ? "Campaign Execution Completed" : "Campaign Execution Under Process"}
                </div>
            }
            open={isModalVisible}
            footer={null}
            closable={false}
        >
            <div className="d-flex justify-content-center align-items-center flex-column mt-3">
                {!loading && <Progress type="circle" percent={Math.round(progress)} strokeColor={conicColors} />}
                {loading && <div className="d-flex flex-column my-3 justify-content-center align-items-center gap-3">
                    <Spin size='medium' />
                    <p>Generating Reports...</p>
                </div>
                }
                {(progress === 100 && !loading) && (
                    <div className="d-flex justify-content-center mt-3">
                        <Button className="mr-2" onClick={() => resetCampaign()}>New Campaign</Button>
                        <Button onClick={() => navigate('/campaign-history')}>View Report</Button>
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default ProgressModal
