import React, { useState } from 'react';
import { Menu, message, Modal } from 'antd';
import { useMyContext } from 'Context/MyContextProvider';
import { HandleDeleteChatbot } from '../chatbotByUser';
import ActionModel from './ActionModel';

const MenuActions = ({ selectedNode, setDropdownVisible, ChatbotData }) => {
    const { UserData, authToken, api } = useMyContext();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [actionType, setActionType] = useState('');

    const handleMenuClick = (key) => {
        switch (key) {
            case 'child':
                setActionType('add'); // Set action type to 'add'
                setIsModalVisible(true);
                break;
            case 'edit':
                setActionType('edit'); // Set action type to 'edit'
                setIsModalVisible(true);
                break;
            case 'delete':
                showDeleteConfirm(selectedNode?.id);
                break;
            default:
                break;
        }
        setDropdownVisible(false); // Close dropdown
    };

    const handleModalOk = () => {
        setIsModalVisible(false);
    };


    const handleModalCancel = (type) => {
        if(type !== 'manual') {
            ChatbotData(UserData?.id)
        }
        setIsModalVisible(false);
        setIsModalVisible(false);
    };

    const showDeleteConfirm = (id) => {
        setDropdownVisible(false)
        Modal.confirm({
            title: 'Are you sure you want to delete this item?',
            content: 'This action cannot be undone.',
            onOk() {
                HandleDeleteChatbot(id, authToken, ChatbotData, api, UserData);
            },
            onCancel() {
                message.info('Delete action cancelled');
            },
        });
    };

    return (
        <>
            <ActionModel
                actionType={actionType}
                isModalVisible={isModalVisible}
                handleModalOk={handleModalOk}
                handleModalCancel={handleModalCancel}
                parentID={selectedNode?.id}
                userId={UserData?.id}
            />
            <Menu
                onClick={({ key }) => handleMenuClick(key)}
                items={[
                    { key: 'child', label: 'Add Child' },
                    { key: 'edit', label: 'Edit' },
                    { key: 'delete', label: 'Delete' },
                ]}
            />
        </>
    );
};

export default MenuActions;
