import { Input, Tag } from 'antd'
import React from 'react'
const KeywordField = ({ badges, themeData, removeBadge, inputValue, setInputValue, handleInputKeyDown }) => {
    return (
        <>
            <div className='d-flex flex-wrap p-3 rounded'
                style={{ border: `1px solid ${themeData === 'dark' ? '#4d5b75' : '#e6ebf1'}` }}
            >
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                    {badges && badges?.map((badge, index) => (
                        <Tag key={badge} className='d-flex align-items-center' color='cyan' closable onClose={() => removeBadge(index)}>
                            {badge}
                        </Tag>
                    ))}
                    <Input
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={handleInputKeyDown}
                        className='border-0'
                        style={{ width: 'auto', flexGrow: 1, outline: 'none' }}
                    //placeholder="Type and press ',' to add"
                    />
                </div>
            </div>
            <p className="text-muted">Type and press ',' or press <b>Enter Key </b>to add</p>
        </>
    )
}

export default KeywordField
