import React, { useEffect, useState } from 'react';
import { Card, Image, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faUpRightFromSquare , faReply } from '@fortawesome/free-solid-svg-icons';
import { UnorderedListOutlined } from '@ant-design/icons';

const { Text } = Typography;

const styles = {
    chatBackground: {
        backgroundImage: `url(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: '8px',
        padding: '16px',
    },
    chatBubble: {
        background: 'white',
        borderRadius: '8px',
        padding: '16px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        position: 'relative',
        marginLeft: '20px',
        maxWidth: '85%',
        wordWrap: 'break-word',
    },
    buttonBubble: {
        background: 'white',
        borderRadius: '8px',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        boxShadow: '0 8px 8px -4px rgba(0, 0, 0, 0.15)',
        position: 'relative',
        bottom: '0.5rem',
        marginLeft: '20px',
        maxWidth: '85%',
        wordWrap: 'break-word',
    },
    chatTime: {
        textAlign: 'right',
        fontSize: '12px',
        color: 'gray',
        marginTop: '8px',
    },
    footerMessageStyle: {
        fontSize: '12px',
        marginTop: '5px',
        color: '#918f8f',
    },
    buttonStyle: {
        wordWrap: 'break-word',
        fontFamily: 'system-ui',
        display: 'block',
        textAlign: 'justify',
    }
};

const ChatPreview = ({
    headerType, selected, header, body, footer, media, 
    subButtonType, buttonType, visitWebsiteCount, 
    visitWebsite1, visitWebsite2, quickReply, phoneNumber
}) => {
    const [currentTime, setCurrentTime] = useState('');
    const [preview, setPreview] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const [allButtons, setAllButtons] = useState([]);
    const [selectedQuickReply, setSelectedQuickReply] = useState('');
    
    useEffect(() => {
        const updateCurrentTime = () => {
            const now = new Date();
            setCurrentTime(now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
        };
        updateCurrentTime();
        const interval = setInterval(updateCurrentTime, 60000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (media && media[0]) {
            const reader = new FileReader();
            reader.onloadend = () => setPreview(reader.result);
            reader.readAsDataURL(media[0]);
        } else {
            setPreview(null);
        }
    }, [media]);

    useEffect(() => {
        const renderButton = (icon, text, key, onClick = null) => (
            <span key={key} style={styles.buttonStyle} className="border-top py-2">
                <button className="border-0 bg-transparent w-100 fw-bold d-flex align-items-center justify-content-center gap-2" style={{ color: '#00a5f4' }} onClick={onClick}>
                    <div className="d-flex align-items-center gap-2">
                        {icon && <FontAwesomeIcon icon={icon} />} {text}
                    </div>
                </button>
            </span>
        );

        const buttons = [];
        if (buttonType.includes('call_phone_number')) {
            buttons.push(renderButton(faPhone, phoneNumber?.button_text, 'phone'));
        }
        if (buttonType.includes('visit_website')) {
            if (visitWebsiteCount === 1) {
                buttons.push(renderButton(faUpRightFromSquare, visitWebsite1?.button_text, 'website1'));
            } else {
                buttons.push(
                    renderButton(faUpRightFromSquare, visitWebsite1?.button_text, 'website1'),
                    renderButton(faUpRightFromSquare, visitWebsite2?.button_text, 'website2')
                );
            }
        }
        if (buttonType.includes('quick_reply')) {
            quickReply?.forEach((reply, index) => {
                buttons.push(renderButton(faReply, reply, `quick_reply_${index}`));
            });
        }
        setAllButtons(buttons);
    }, [buttonType, phoneNumber, visitWebsite1, visitWebsite2, quickReply, visitWebsiteCount]);

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-200">
            <Card title="Template Preview" bordered={false}>
                <div style={styles.chatBackground}>
                    <div style={styles.chatBubble}>
                        {headerType === 'Media' && preview && <Image src={preview} preview={false} />}
                        {header && <h5>{header}</h5>}
                        {body && (
                            <Text>
                                {body.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line.split(/\*([^*]+)\*/g).map((part, i) =>
                                            i % 2 === 1 ? <strong key={i}>{part}</strong> : part
                                        )}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </Text>
                        )}
                        <span style={styles.footerMessageStyle}>{footer}</span>
                        <div style={styles.chatTime}>{currentTime}</div>
                        {selectedQuickReply && (
                            <div className="mt-2 p-2 bg-light border rounded">
                                <Text strong>Selected Quick Reply:</Text> {selectedQuickReply}
                            </div>
                        )}
                    </div>
                    <div style={styles.buttonBubble}>
                        {showAll ? allButtons : allButtons.slice(0, 2)}
                        {!showAll && allButtons.length > 2 && (
                            <span style={styles.buttonStyle} className="border-top py-2">
                                <button className="border-0 bg-transparent fw-bold w-100" style={{ color: '#34B7F1' }} onClick={() => setShowAll(true)}>
                                    <UnorderedListOutlined /> See all options
                                </button>
                            </span>
                        )}
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default ChatPreview;