import { Alert, Button, Card, Col, DatePicker, Form, Input, List, Modal, Progress, Radio, Result, Row, Select, Typography, Upload, message } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useMyContext } from 'Context/MyContextProvider';
import { CloudUploadOutlined, RightOutlined, UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion"
import MediModel from './MediModel';
import Flex from 'components/shared-components/Flex';
import { SPACER } from 'constants/ThemeConstant';
import { ReactTransliterate } from "react-transliterate";
import "react-transliterate/dist/index.css";
import * as XLSX from 'xlsx';
import NewMobilePreview from './newMobilePreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { toLower } from 'lodash';
import ModeLindex from './CampainComps/ModeLindex';
import MotionError from './CampainComps/MotionError';
import { fetchUserStatus, filterNumbers, formatNumber, scrollToTop, validateUserAndBalance } from './CampainComps/OtherUtils';
import { createCampaign, generateMessagePayloads } from '../CustomUtils.js/CampaignUtils';
import SelectTemplate from './CampainComps/SelectTemplate';

const { Option } = Select;
const { Text } = Typography;
const ExcelCampaign = () => {

  const { UserData, api, authToken, messagesApi, waToken, templates, Permisson, navigate, calculateRequredBalance, buttonStyle } = useMyContext()


  useEffect(() => {
    if (!Permisson?.includes('View Campaign')) {
      navigate('404')
    }
  }, [Permisson]);

  const [templateList, setTemplateList] = useState([]);
  const [componentSize, setComponentSize] = useState('small');
  const [number, setNumber] = useState('');
  const [customMessage, setCustomMessage] = useState('');
  const [numberError, setNumberError] = useState(false);
  const [requestType, setRequestType] = useState('template');
  const [dynamicValues, setDynamicValues] = useState([]);
  const [dynamicButtonUrl, setDynamicUrlButton] = useState([]);
  const [templateName, setTemplateName] = useState('');
  const [newMesaage, setNewMessage] = useState('');
  const [name, setName] = useState('');
  const [templatePreview, setTemplatePreview] = useState([])
  const [mediaLink, setMediaLink] = useState('')
  const [mediaName, setMediaName] = useState('')
  const [thumbnail, setThumbnail] = useState('')
  const [mediaType, setMediaType] = useState('Media')
  const [template, setTemplate] = useState({
    header: null,
    body: null,
    footer: null,
    buttons: null
  });
  const [templateKey, setTemplateKey] = useState(0);
  const [templateInputs, setTemplateInputs] = useState([]);
  const [templateUrlInputs, setTemplateUrlInputs] = useState([]);
  const [urlButtons, setUrlButtons] = useState([]);
  const [numbers, SetNumbers] = useState([]);

  const [singleLineNumber, setSingleLineNumber] = useState([])
  const [userStatus, setUerStatus] = useState(false)
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [scheduleDate, setScheduleDate] = useState('');
  const [scheduleTime, setScheduleTime] = useState('');
  const [templateCategory, setTemplateCategory] = useState('MARKETING');
  const [blockNumbers, setBlockNumbers] = useState([]);
  const [templateLanguage, setTemplateLanguage] = useState('');
  const [openMediaModel, setOpenMediaModel] = useState(false);
  const [openScheduleModel, setOpenSchedule] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customLanguage, setCustomLanguage] = useState('en');
  const [importedData, setImportedData] = useState([])
  const [disableUpload, setDisableUpload] = useState(true);
  const [fileList, setFileList] = useState([]);

  const GetBlockNumber = async () => {
    try {
      const response = await axios.get(`${api}get-block-numbers/${UserData.id}`, {
        headers: {
          Authorization: "Bearer " + authToken,
        },
      });
      const serverBlocked = response.data.serverBlocked
      const userBlocked = response.data.serverBlocked
      const serverNumbers = serverBlocked.map(item => formatNumber(item.numbers)).filter(Boolean);
      const userNumbers = userBlocked.map(item => formatNumber(item.numbers)).filter(Boolean);
      const total = [...serverNumbers, ...userNumbers];
      setBlockNumbers([...new Set(total)]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    GetBlockNumber()
  }, [])



  useEffect(() => {
    setTemplateList(templates)
  }, [templates])




  useEffect(() => {
    // console.log(number)
    if (number) {
      setNumberError(false)
    }
  }, [number])




  const HandleTemplate = (value) => {
    setMediaLink('');
    setTemplateName(value);
    setTemplateKey(prevKey => prevKey + 1);
    const template = templates?.find((item) => item.name === value);
    // console.log(template)
    setTemplateLanguage(template?.language)
    setTemplatePreview(template);
    const { header, body, footer, buttons } = extractTemplateSections(template);
    setTemplate({ header, body, footer, buttons });

    const templateBodyDynamicValue = body?.text?.match(/{{\d+}}/g);
    if (templateBodyDynamicValue) {
      const inputs = generateInputsForDynamicValues(templateBodyDynamicValue);
      setTemplateInputs(inputs);
    } else {
      setTemplateInputs([]);
    }

    if (buttons?.buttons) {
      const urlInputs = generateUrlInputs(buttons.buttons);
      setTemplateUrlInputs(urlInputs);
    } else {
      setTemplateUrlInputs([]);
    }
  }

  const extractTemplateSections = (template) => {
    let header = null, body = null, footer = null, buttons = null;
    template?.components?.forEach(section => {
      switch (section.type) {
        case "HEADER":
          header = section;
          break;
        case "BODY":
          body = section;
          break;
        case "FOOTER":
          footer = section;
          break;
        case "BUTTONS":
          buttons = section;
          break;
        default:
          // Handle any other section types if needed
          break;
      }
    });
    return { header, body, footer, buttons };
  }

  const generateInputsForDynamicValues = (dynamicValues) => {
    return dynamicValues?.map((placeholder, index) => (
      <Input
        key={index}
        type="text"
        placeholder={`Enter value for ${placeholder}`}
        onChange={(e) => handleInputChange(index, e.target.value)}
      />
    ));
  }

  const generateUrlInputs = (urlButtons) => {
    return urlButtons.map((item, index) => {
      // console.log(item)
      const isDisabled = !item.hasOwnProperty('example');
      return (
        <div key={index}>
          {!isDisabled ?
            <>
              <Link>{item?.url}</Link>
              <Input
                type="text"
                // value={isDisabled ? item.url : ''}
                placeholder={`Your "${item.text}" Button Value`}
                onChange={(e) => handleUrlButton(index, e.target.value)}
                disabled={isDisabled}
              />
            </>
            :
            <Input
              type="text"
              value={item.url}
              onChange={(e) => handleUrlButton(index, e.target.value)}
              disabled={isDisabled}
            />
          }
        </div>
      );
    });
  }



  const handleInputChange = (index, value) => {
    setDynamicValues(prevValues => {
      const updatedValues = [...prevValues];
      updatedValues[index] = value;
      return updatedValues;
    });

  };

  const handleUrlButton = (index, value) => {
    setDynamicUrlButton(prevValues => {
      const updatedValues = [...prevValues];
      updatedValues[index] = value;
      return updatedValues;
    });
  };

  const HandleDynamicValues = (templatePreview) => {
    if (importedData?.length > 0) {
      const text = templatePreview?.components[1]?.text;
      let updatedText = text;
      importedData[0]?.value?.forEach((item, index) => {
        updatedText = updatedText?.replace(`{{${index + 1}}}`, item);
      });

      setNewMessage(updatedText); // Log the updated text array
    }
  }
  useEffect(() => {
    HandleDynamicValues(templatePreview)
  }, [templatePreview, importedData])





  useEffect(() => {
    if (requestType === 'template') {
      setCustomMessage('')
    } else {
      setTemplatePreview([])
      setTemplateInputs([]);
      setDynamicValues([]);
      setTemplateUrlInputs([]);
      setDynamicUrlButton([]);
      setTemplate({
        header: null,
        body: null,
        footer: null,
        buttons: null
      });

    }
  }, [requestType])





  useEffect(() => {
    if (numbers?.length > 0) {
      const numbersWithNewlines = numbers?.join('\n');
      setSingleLineNumber(numbersWithNewlines)
    }
    // console.log(numbers)
  }, [numbers])




  const showModal = async () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };


  // for media model 

  const showMediaModel = async () => {
    setOpenMediaModel(true);
  };
  const handleCancelMediaModel = () => {
    setOpenMediaModel(false);
  };

  const handleCancelScheduleModel = () => {
    setOpenSchedule(false);
  };


  useEffect(() => {
    const filteredList = templateCategory
      ? templates.filter(item => item.category === templateCategory)
      : templates;

    setTemplateList(filteredList);
  }, [templateCategory, templates]);






  const HandleUploadImage = async (path, name) => {
    if (path) {
      setMediaLink(path)
      setMediaName(name)
      setOpenMediaModel(false)
    }
  };


  const HandleScheduleTime = (value) => {
    const { $d } = value;
    const year = $d.getFullYear();
    const month = $d.getMonth() + 1;
    const day = $d.getDate();
    const hours = $d.getHours();
    const minutes = $d.getMinutes();
    // const seconds = $d.getSeconds();
    setScheduleDate(`${year}-${month}-${day}`);
    setScheduleTime(`${hours}:${minutes}:00`);
  }



  // final submit 
  const HandleSchedule = async () => {
    setLoading(true)
    const filteredNumbers = filterNumbers(number, numbers, blockNumbers);
    // return
    const campaignResponse = await axios.post(`${api}schedule-campaign/${UserData?.id}`, {
      name,
      user_id: UserData.id,
      numbers: filteredNumbers,
      campaign_type: requestType,
      custom_text: customMessage,
      template_name: templateName,
      header_type: template.header?.format,
      header_media_url: mediaLink,
      body_values: dynamicValues,
      button_type: template?.buttons?.buttons[0].type,
      button_value: dynamicButtonUrl,
      schedule_date: scheduleDate,
      schedule_time: scheduleTime,
    }, {
      headers: {
        Authorization: "Bearer " + authToken,
      }
    });
    // console.log(campaignResponse)
    if (campaignResponse.data.status) {
      navigate('/campaign')
    }
    setLoading(false)
  }


  const styles = {
    textarea: {
      width: '100%',
      border: '1px solid #ced4da',
      borderRadius: '.5rem',
      outline: 'none',
    },
  };

  useEffect(() => {
    setImportedData([])
    setFileList([]);
    setDisableUpload(true)
  }, [template]);

  //import
  const props = {
    onRemove: file => {
      setImportedData([]);
      setFileList([]);
      setDisableUpload(true)
    },

    beforeUpload: file => {
      setImportedData([])
      const reader = new FileReader();
      const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isExcel) {
        message.error('You can only upload Excel files (.xlsx)!');
        return false;
      }
      reader.onload = (event) => {
        const binaryString = event.target.result;
        const workbook = XLSX.read(binaryString, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        let dataLength = jsonData[0].length;
        let expectedLength = templateInputs.length + 1;

        if (dataLength !== expectedLength) {
          setDisableUpload(true)
          message.error('Invalid file column!');
          return false;
        } else {
          setDisableUpload(false)
          //console.log(jsonData)
          setFileList([file]);
          setImportedData(jsonData);
        }
      };

      reader.readAsBinaryString(file);
      return false;
    },
  };

  const HandleImport = () => {
    const numbersArray = importedData?.map(item => item[0]);
    SetNumbers(numbersArray);
    const updatedMobileNumbers = importedData.map(item => ({
      number: item[0],
      value: item.length > 1 ? item.slice(1) : []
    }));
    console.log(updatedMobileNumbers)
    setImportedData(updatedMobileNumbers);
    setDisableUpload(true)
  };



  const [isSending, setIsSending] = useState(true);
  const [progress, setProgress] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);


  // Function to handle the rate-limited message sending
  const sendMessageWithRateLimit = async (messagePayloads, messagesApi, waToken, campaignData, api, authToken) => {
    const customRateLimit = 20;
    const interval = 1000 / customRateLimit;
    const reportData = [];
    const totalMessages = messagePayloads.length;
    let sentMessages = 0;
    const sendBatch = async (batch) => {
      await Promise.all(batch?.map(async (payload) => {
        const mobileNumber = payload.to;

        try {
          const res = await axios.post(messagesApi, payload, {
            headers: { Authorization: `Bearer ${waToken}` },
          });

          if (res.data.messages[0].message_status === 'accepted' || res.data.messages[0]?.id) {
            reportData.push({
              campaign_id: campaignData.id,
              message_id: res.data.messages[0].id,
              mobile_number: mobileNumber,
            });
          }
        } catch (error) {
          console.error('Error sending message:', error);
        }
        sentMessages++;
        const currentProgress = (sentMessages / totalMessages) * 100;
        setProgress(currentProgress);
      }));
    };

    const processMessages = async () => {
      for (let i = 0; i < messagePayloads.length; i += customRateLimit) {
        const batch = messagePayloads.slice(i, i + customRateLimit);
        await sendBatch(batch);

        // Wait for the next interval before sending the next batch
        await new Promise(resolve => setTimeout(resolve, interval));
      }

      // After all messages are processed, store the campaign reports
      setIsSending(false);
      await storeCampaignReports(reportData, campaignData, api, authToken);
    };

    setIsSending(true); // Start sending process
    processMessages();
  };



  const storeCampaignReports = async (reportData, campaignData, api, authToken) => {
    try {
      const response = await axios.post(`${api}create-campaign-report`, {
        campaign_id: campaignData.id,
        reports: reportData,
      }, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      if (response.data.status) {
        // successFunction(true);
        // console.log('Campaign reports stored successfully:', response.data.message);
      } else {
        console.error('Failed to store campaign reports:', response.data.message);
      }
    } catch (error) {
      console.error('Error storing campaign reports:', error);
    }
  };


  const [showProcessingModal, setShowProcessingModal] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isSending) {
        e.preventDefault();
        e.returnValue = ''; // For most browsers
        return ''; // For older browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isSending]);

  useEffect(() => {
    const handlePopstate = (e) => {
      if (isSending) {
        e.preventDefault();
        setShowProcessingModal(true);
      }
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [isSending]);



  const resetCampaign = () => {
    setProgress()
    setIsModalVisible(false)
    setSingleLineNumber([])
    setNumber('')
  }

  const conicColors = {
    '0%': '#16702F',
    '100%': '#34B55A',
  };

  const listData = [
    'Select your messaging category and template',
    'System will generate Alert for number of columns required in Excel',
    'The first column should always have mobile numbers',
    'Create Excel file with data only (i.e. without headers)',
    'The number of columns in excel other than mobile number should match the total number of variables in template',
    'Message on Blocked Mobile Numbers will not be sent',
    'Avoid using Special Characters in the variable contents',
    'Sample message preview for first row of excel will be available after submitting file'
  ];

  const HandleApiTest = async () => {
    // console.log(dynamicValues);return
    const filteredNumbers = filterNumbers('', numbers, blockNumbers);
    if (filteredNumbers?.length > 5000) {
      setError('You can only process up to 5000 numbers.');
      scrollToTop();
      return;
    }

    if (!await validateUserAndBalance(filteredNumbers.length, calculateRequredBalance, templateCategory, showModal)) {
      showModal()
      return;
    }
    try {
      setLoading(true);
      setIsModalVisible(true);

      const userResponse = await fetchUserStatus(UserData.id, api, authToken);
      setUerStatus(userResponse.data.status);

      if (userResponse.data.status === false) {
        showModal();
        return false;
      }
      const campaignData = await createCampaign(name, UserData.id, templateName, customMessage, authToken, api);
      const messagePayloads = generateMessagePayloads(filteredNumbers, 'template', templateName, templateLanguage, template, customMessage, dynamicValues, dynamicButtonUrl, null, mediaLink, true, importedData);
      await sendMessageWithRateLimit(messagePayloads, messagesApi, waToken,
        campaignData, api, authToken, setProgress, setIsSending, setLoading, templateCategory);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCustomMedia = (data) => { };

  return (
    <>
      <ModeLindex
        open={open}
        handleCancel={handleCancel}
        UserData={UserData}
        openScheduleModel={openScheduleModel}
        handleCancelScheduleModel={handleCancelScheduleModel}
        HandleScheduleTime={HandleScheduleTime}
        HandleSchedule={HandleSchedule}
        openMediaModel={openMediaModel}
        handleCancelMediaModel={handleCancelMediaModel}
        HandleUploadImage={HandleUploadImage}
        requestType={requestType}
        handleCustomMedia={handleCustomMedia}
        progress={progress}
        isModalVisible={isModalVisible}
        loading={loading}
        conicColors={conicColors}
        resetCampaign={resetCampaign}
        navigate={navigate}
      />



      <Row gutter={12}>
        {Permisson?.includes('View Campaign') &&
          <>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Card title="Excel Campaign">
                <Col span={24}>
                  <MotionError error={error} />
                  <Form
                    name="basicInformation"
                    layout="vertical"
                    initialValues={{ size: componentSize }}
                    size={componentSize}
                  >
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Campaign Name"
                          name="Campaign-Name"
                          initialValue={name}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter campaign name!',
                            },
                          ]}
                        >
                          <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                        </Form.Item>
                        {requestType === 'Custom' ?
                          <Form.Item
                            label="Custom Text"
                            name='Custom-Text'
                            rules={[
                              {
                                required: true,
                                message: 'This field is required!',
                              },
                            ]}>
                            <Select className='mb-2' defaultValue="en" style={{ width: 120 }} onChange={(value) => setCustomLanguage(value)}>
                              <Option value="en">English</Option>
                              <Option value="gu">Gujarati</Option>
                              <Option value="hi">Hindi</Option>
                            </Select>
                            <ReactTransliterate
                              renderComponent={(props) =>
                                <textarea
                                  {...props}
                                  rows={4}
                                  className='p-2'
                                  style={styles.textarea}
                                />
                              }
                              value={customMessage}
                              onChangeText={(text) => {
                                setCustomMessage(text);
                              }}
                              lang={customLanguage}
                            />
                            {/* <Input.TextArea value={customMessage} type="text" rows={4} onChange={(e) => setCustomMessage(e.target.value)} /> */}

                          </Form.Item>
                          :
                          requestType === 'template' ?
                            <>
                              <Form.Item
                                label="Template Category"
                                name='Template_category'
                                initialValue={templateCategory}
                              >
                                <Radio.Group onChange={(e) => setTemplateCategory(e.target.value)}>
                                  <Radio value={'MARKETING'}>Marketing</Radio>
                                  <Radio value={'UTILITY'}>Utility</Radio>
                                </Radio.Group>
                              </Form.Item>
                              <Form.Item
                                label="Templates"
                                name='Templates'
                              >
                                <SelectTemplate
                                  templateName={templateName}
                                  HandleTemplate={HandleTemplate}
                                  templateList={templateList}
                                />
                              </Form.Item>
                              {
                                template?.header && template?.header?.format !== 'TEXT' &&
                                <>

                                  {mediaType === 'Media' ?
                                    <Form.Item
                                      label="Select media"
                                      name='media-link'
                                    >
                                      {!mediaLink ?
                                        <Button type='' style={buttonStyle} onClick={showMediaModel}><CloudUploadOutlined />Choose File</Button>
                                        :
                                        <>
                                          <Input type="text" disabled value={mediaName} className='mb-3' />
                                          <Button type='' style={buttonStyle} onClick={showMediaModel}><CloudUploadOutlined />Choose Another File</Button>
                                          <p className='d-none'>{mediaName}</p>
                                        </>
                                      }
                                    </Form.Item>
                                    :
                                    <Form.Item
                                      label="Your media file link"
                                      name='media-link'
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please enter campaign name!',
                                        },
                                      ]}
                                    >
                                      <Input type="text" onChange={(e) => setMediaLink(e.target.value)} className='mb-3' />
                                    </Form.Item>
                                  }
                                </>
                              }
                              <div>
                                {templateInputs.length > 0 && (
                                  <Form.Item label={`${templateInputs.length} dynamic values detected`}>
                                    <Text code>
                                      Excel file Must have One Number and {templateInputs.length} variable values column
                                    </Text>
                                  </Form.Item>
                                )}
                              </div>
                            </>

                            : ''
                        }
                        {templateName &&
                          <Form.Item
                            label="Choose Excel File"
                            name=""
                            rules={[
                              {
                                required: true,
                                message: 'Please Choose File!',
                              },
                            ]}
                          >
                            <span className='d-flex mr-2'>
                              <Upload {...props}
                                fileList={fileList}
                                accept=".xlsx"
                              >
                                <Button className='mr-2' disabled={!disableUpload} icon={<UploadOutlined />}>Click to Select</Button>
                              </Upload>
                              <Button type="primary" onClick={HandleImport} disabled={disableUpload}>Upload</Button>
                            </span>
                          </Form.Item>
                        }
                        <div>
                          {templateUrlInputs.length > 0 && (
                            <Form.Item label={`${templateUrlInputs.length} Url Button detected`}>
                              {templateUrlInputs.map((input, index) => (
                                <div key={index} className='mb-2'>{input}</div>
                              ))}
                            </Form.Item>
                          )}
                          <Form.Item
                            label=" "
                            name=''
                          >
                            <Flex alignItems="center" gap={SPACER[2]}>
                              {Permisson?.includes('Create Campaign') &&
                                <Button type="primary" htmlType="submit" onClick={(e) => HandleApiTest(e)} disabled={!disableUpload} loading={loading}>Submit</Button>
                              }
                              {/* <Button type="primary" htmlType="submit" onClick={(e) => showScheduleModel(e)} loading={loading}>Schedule Campaign</Button> */}
                            </Flex>
                          </Form.Item>
                          {/* <Progress percent={progress} status="active" />
                       <p>{progress}</p> */}
                        </div>

                      </Col>
                    </Row>
                  </Form>
                </Col>

              </Card>
            </Col>
            {/* mobile preview  */}
            <Col xs={24} sm={24} md={24} lg={6}>
              <Card title="Campaign Preview">
                <Flex gap={5} alignItems='center' className='mb-3'>
                  <FontAwesomeIcon icon={faPhone} />
                  <span>: {importedData[0]?.number}</span>
                </Flex>

                <NewMobilePreview
                  requestType={requestType}
                  template={template}
                  mediaLink={mediaLink}
                  thumbnail={thumbnail}
                  newMesaage={newMesaage}
                  urlButtons={urlButtons}
                  customMessage={customMessage}
                />
              </Card>
            </Col>
          </>
        }
        {Permisson?.includes('View Group') &&
          <Col xs={24} sm={24} md={24} lg={6}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Card title={`Excel Campaign Guidelines`}>
                  <Row>
                    <Col span={24}>
                      <List
                        itemLayout="horizontal"
                        dataSource={listData}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              avatar={<RightOutlined style={{ color: '#00a5f4' }} />}
                              title={item}
                            />
                          </List.Item>
                        )}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>

            </Row>
          </Col>
        }
      </Row >
    </>
  )
}

export default ExcelCampaign
