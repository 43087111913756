import React from 'react'
import { CloudUploadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Tabs } from 'antd'

const CardData = (props) => {
    const { cardData, handleTabChange, buttonStyle, showMediaModel, handleMediaLinkChange } = props
    return (
        <>
            {cardData.length > 0 && (
                <Col span={24}>
                    <Card title={`Total Cards : ${cardData?.length}`} style={{ width: '100%' }}>
                        <Tabs
                            defaultActiveKey="0"
                            type="card"
                            onChange={handleTabChange}
                            items={
                                cardData.map((card, cardIndex) => ({
                                    key: String(cardIndex),
                                    label: (
                                        `Card ${cardIndex + 1}`
                                    ),
                                    children: (
                                        <Card bordered={false} style={{ height: '100%' }}>
                                            <p>Card {cardIndex + 1} Body  : {card?.body?.text}</p>
                                            {card.mediaType === 'Media' ? (
                                                <Form.Item
                                                    label={`Select Media File For Card ${cardIndex + 1}`}
                                                    name={`media-link-${cardIndex}`}
                                                >
                                                    {!card.mediaLink ? (
                                                        <Button
                                                            style={buttonStyle}
                                                            onClick={() => showMediaModel(cardIndex)}
                                                        >
                                                            <CloudUploadOutlined /> Choose File
                                                        </Button>
                                                    ) : (
                                                        <>
                                                            <Input
                                                                type="text"
                                                                disabled
                                                                value={card.mediaLink}
                                                                className="mb-3"
                                                            />
                                                            <Button
                                                                style={buttonStyle}
                                                                onClick={() => showMediaModel(cardIndex)}
                                                            >
                                                                <CloudUploadOutlined /> Choose Another File
                                                            </Button>
                                                        </>
                                                    )}
                                                </Form.Item>
                                            ) : (
                                                <Form.Item
                                                    label="Your media file link"
                                                    name={`media-link-${cardIndex}`}
                                                    rules={[
                                                        { required: true, message: 'Please enter a media link!' }
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        onChange={(e) =>
                                                            handleMediaLinkChange(e.target.value, cardIndex)
                                                        }
                                                        className="mb-3"
                                                    />
                                                </Form.Item>
                                            )}
                                        </Card>
                                    ),
                                }))}
                        />
                    </Card>
                </Col>
            )}
        </>
    )
}

export default CardData
