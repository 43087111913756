import { useEffect, useState } from "react";
import { useMyContext } from "Context/MyContextProvider";
import axios from "axios";
import { Card, Col, Form, message, Row, Select, Spin } from "antd";
import ChatbotFlowmap from "./FlowComps/FlowMap";
import Flex from "components/shared-components/Flex";
import ChatbotBuilder from "./FlowComps/ChatbotBuilder";
const { Option } = Select

const ChatBotFlow = () => {
    const { authToken, api, UserData, UserList, Permisson,userRole } = useMyContext();

    const [chatbot, setChatbot] = useState([]);
    const [loading, setLoading] = useState(false);
    const ChatbotData = async (id) => {
        setLoading(true);
        try {
            const response = await axios.get(`${api}chatbot-map/${id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
            const data = response.data;
            if (data.status) {
                setChatbot(data.data);
                setLoading(false);
            }
        } catch (error) {
            if(userRole === "Admin") {
                message.error('Chatbot does not exist for this user')
            }else{
                setChatbot([]);
            }
            setLoading(false);
        }
    }
    useEffect(() => {
        ChatbotData(UserData?.id)
    }, []);

    const HandleUserChange = (value) => {
        ChatbotData(value)
    }
  
    const hasPermissionChange = Permisson?.includes('Manage User Chat Flow')
    return (
        <>
            {loading ? (
                <Flex justifyContent="center" alignItems="center" style={{ height: '80vh' }}>
                    <Spin size="large" />
                </Flex>
            ) : Object.keys(chatbot).length > 0 ? (
                <>
                    <Card size="small" className='pb-0 bg-none border-0'>
                        <Row align="middle" className="w-100" gutter={[16, 16]}>
                            <Col xs={24} md={18} className="text-center">
                                <h5 className="p-0 m-0">Chatbot Flow</h5>
                            </Col>
                            {hasPermissionChange &&
                                <Col xs={24} md={6}>
                                    <Form.Item label="Username" className="m-0">
                                        <Select
                                            showSearch
                                            size="default"
                                            defaultValue={{ key: "choose..." }}
                                            style={{ width: '100%' }}
                                            onChange={(value) => HandleUserChange(value)}
                                        >
                                            {UserList?.length > 0
                                                ? UserList.map((item, index) => (
                                                    <Option value={item?.id} key={index}>
                                                        {item?.company_name}
                                                    </Option>
                                                ))
                                                : <Option>No Users Found</Option>}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            }
                        </Row>
                    </Card>
                    <ChatbotFlowmap data={chatbot} ChatbotData={ChatbotData} />
                </>
            ) :
                <ChatbotBuilder UserData={UserData} ChatbotData={ChatbotData}/>

            }

        </>
    );
};

export default ChatBotFlow;