import { Select } from 'antd'
import React from 'react'

export const SelectUser = ({users,setUser}) => {
    
    return (
        <Select
            showSearch
            size={'default'}
            defaultValue={{ key: "choose..." }}
            style={{ width: '100%' }}
            onChange={(value) => setUser(value)}
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            >
            {
                users?.length > 0 ?
                    users.map((item, index) =>
                        <Select.Option value={item.id} key={index}>{item?.company_name}</Select.Option>
                    )
                    :
                    <Select.Option value="">No Data Found</Select.Option>

            }
        </Select>
    )
}

