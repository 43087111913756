import { Button, Checkbox, Col, Form, Input, Radio, Row, message } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import TextArea from 'antd/es/input/TextArea'
import { useMyContext } from 'Context/MyContextProvider'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import { toLower } from 'lodash'
import SelectMediaModel from '../../Campaign/CampainComps/SelectMediaModel';
import CustomMediaMessage from '../ChatbotCodeComps/CustomMediaMessage';
import { extractTemplateSections } from '../../CustomUtils.js/TemplateUtils';
import ResponsePanel from '../ChatbotCodeComps/ResponsePanel';
import { HeaderFields, KeywordsComp, ParentChatbot, ReplyType } from '../ChatbotCodeComps/ChatbotFields';
import ChatbotDataStoreComps from '../ChatbotCodeComps/ChatbotDataStoreComps';
import InputWithEmoji from '../../CustomUtils.js/InputWithKeyword';
import DynamicForm from './DynamicForm';
import UrlActionInput from '../ChatbotCodeComps/UrlActionInput'
const NewChatBotForm = ({ id, showParentChatbot, parentID = null, isModal = false, CloseModal }) => {

    const { authToken, api, UserData, templates, navigate, buttonStyle, getUrlFromMediaId, getMedia } = useMyContext()
    const [isUrl, setIsUrl] = useState(false);
    const [timestamp, setTimestamp] = useState(0);
    const [serialNumber, setSerialNumber] = useState('');
    const [templateList, setTemplateList] = useState([]);
    const [media, setMedia] = useState([]);
    const [loading, setLoading] = useState(false);

    const [listItem, setListItem] = useState('');
    // new states 
    const [chatBotType, setChatBotType] = useState('');
    const [replyTemplate, setReplyTemplate] = useState('');
    const [customType, setCustomType] = useState('');
    const [replyText, setReplyText] = useState('');
    const [externalUrl, setExternalUrl] = useState('');
    const [urlActionType, setUrlActionType] = useState('');
    const [urlText, setUrlText] = useState('');
    const [urlXml, setUrlXml] = useState('');
    const [urlJsonKey, setUrlJsonKey] = useState('');

    const [customMessageType, setCustomMessageType] = useState('text')
    const [customMediaName, setMediaCustomName] = useState('')
    const [preset, setpreset] = useState();
    // json true states 
    const [jsonTrueKey, setJsonTrueKey] = useState('');
    const [jsonTrueValue, setJsonTrueValue] = useState('');
    const [jsonTrueOutgoingRes, setJsonTrueOutgoingRes] = useState('');
    const [jsonTrueChatbot, setJsonTrueChatbot] = useState('');
    const [jsonTrueTemplate, setJsonTrueTemplate] = useState('');
    const [jsonTrueJsonRes, setJsonTrueJsonRes] = useState('');
    const [jsonTrueCustomText, setJsonTrueCustomText] = useState('');
    const [jsonTrueListItemKey, setJsonTrueListItemKey] = useState('');
    const [jsonTrueLiHeader, setJsonTrueLiHeader] = useState('');
    const [jsonTrueLiBody, setJsonTrueLiBody] = useState('');

    // json false states
    const [jsonFalseKey, setJsonFalseKey] = useState('');
    const [jsonFalseValue, setJsonFalseValue] = useState('');
    const [jsonFalseOutgoingRes, setJsonFalseOutgoingRes] = useState('');
    const [jsonFalseChatbot, setJsonFalseChatbot] = useState('');
    const [jsonFalseTemplate, setJsonFalseTemplate] = useState('');
    const [jsonFalseJsonRes, setJsonFalseJsonRes] = useState('');
    const [jsonFalseCustomText, setJsonFalseCustomText] = useState('');
    const [jsonFalseListItemKey, setJsonFalseListItemKey] = useState('');
    const [jsonFalseLiHeader, setJsonFalseLiHeader] = useState('');
    const [jsonFalseLiBody, setJsonFalseLiBody] = useState('');

    // badges 
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState(false);
    const [templatesMediaState, setTemplatesMediaState] = useState({
        replyTemplate: false,
        jsonTrueTemplate: false,
        jsonFalseTemplate: false,
    });
    const [templatesMedia, setTemplatesMedia] = useState({
        replyTemplate: { url: '', type: '' },
        jsonTrueTemplate: { url: '', type: '' },
        jsonFalseTemplate: { url: '', type: '' },
    });
    const [templatesLanguage, setTemplatesLanguage] = useState({
        replyTemplate: '',
        jsonTrueTemplate: '',
        jsonFalseTemplate: '',
    });

    const [chatbotAction, setChatbotAction] = useState('');
    const [chatbotState, setChatbotState] = useState('');
    const [storeKeys, setStoreKeys] = useState([]);
    // header state 
    const [headerRequired, setHeaderRequired] = useState(false);
    const [headerKey, setHeaderKey] = useState();
    const [headerValue, setHeaderValue] = useState();

    useEffect(() => {
        if (parentID) {
            setParentChatBot(parentID);
        }
    }, [parentID]);

    useEffect(() => {
        if (templates.length > 0) {
            // filter the templates with no carousel in components
            const nonCarouselTemplates = templates.filter(template =>
                !template.components?.some(comp => comp.type === 'CAROUSEL')
            );

            const result = nonCarouselTemplates.filter(template => {
                const bodyComponent = template?.components.find(comp => comp.type === 'BODY');
                const hasDynamicVariables = bodyComponent && /{{\d+}}/.test(bodyComponent.text);
                return !hasDynamicVariables
            });
            setTemplateList(result);
        }
    }, [templates]);

    const [templateName, setTemplateName] = useState('');

    const handleTemplate = (value, stateKey, mediaState) => {
        stateKey(value)
        setTemplatesMedia(prevState => ({
            ...prevState,
            [mediaState]: null,
        }));
        const selectedTemplate = templates?.find(item => item.name === value);
        if (selectedTemplate) {
            setTemplateName(selectedTemplate?.name)
            const template = extractTemplateSections(selectedTemplate);
            let header = template?.header
            setTemplatesLanguage(prevState => ({
                ...prevState,
                [mediaState]: selectedTemplate?.language,
            }));
            setTemplatesMediaState(prevState => ({
                ...prevState,
                [mediaState]: header && header?.format !== "TEXT",
            }));
            setTemplatesMedia(prevState => ({
                ...prevState,
                [mediaState]: {
                    ...prevState[mediaState],
                    type: header?.format || ''
                }
            }));
        }
    }

    useEffect(() => {
        const shouldCallApi = templateName && (chatBotType === 'template' || jsonTrueOutgoingRes === 'Template' || jsonFalseOutgoingRes === 'Template');

        const getMediaId = () => {
            if (chatBotType === 'template') {
                return templatesMedia?.replyTemplate?.url;
            }
            if (jsonTrueOutgoingRes === 'Template') {
                return templatesMedia?.jsonTrueTemplate?.url;
            }
            if (jsonFalseOutgoingRes === 'Template') {
                return templatesMedia?.jsonFalseTemplate?.url;
            }
            return null;
        };
        if (shouldCallApi) {

            const media_id = getMediaId();
            MakeDynamicApi(templateName, media_id);
        }
    }, [templateName, templatesMedia, chatBotType, jsonTrueOutgoingRes, jsonFalseOutgoingRes,]);

    // make state waba
    const [waba, setWABA] = useState('');

    const MakeDynamicApi = (templateName, media_id) => {
        let waApi, template, media;
        if (templateName) {
            template = templateName
        }
        if (media_id) {
            media = media_id
        }
        if (media) {
            waApi = `https://waba.smsforyou.biz/api/send-messages?apikey=:API_KEY:&to=:NUMBER:&type=T&tname=${template}&media_id=${media}`
        } else {
            waApi = `https://waba.smsforyou.biz/api/send-messages?apikey=:API_KEY:&to=:NUMBER:&type=T&tname=${template}`
        }
        console.log(waApi)
        setWABA(waApi);
    }


    const [presetsList, setpresetList] = useState([]);
    const GetPresets = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}presets/${UserData?.id}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            if (response.data.status) {
                setpresetList(response.data.preset)
            }
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        GetPresets()
        getMedia(setMedia)
        setTimestamp(Math.floor(Date.now() / 1000));
    }, [])


    const [badges, setBadges] = useState([]);



    function handleUrl(e) {
        setIsUrl(e.target.checked);
        if (e.target.checked) {
            setCustomType('Url')
        } else {
            setCustomType('Text')
        }
    }


    const checkSerialNumber = () => {
        axios.post(`${api}exist-sr-no`,
            {
                'req_id': id,
                'rq': 'new',
                'user_id': UserData?.id,
                'sr_no': serialNumber,
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            }).then((res) => {
                if (res?.data?.exists) {
                    message.error('Already another response assigned to this serial number')
                    setError(res.data.exists)
                } else {
                    setError(false)
                }
                //console.log(res.data)
            }).catch((error) => {
            })
    }
    const [typingTimeout, setTypingTimeout] = useState(null);

    useEffect(() => {
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const timeout = setTimeout(() => {
            if (serialNumber) {
                checkSerialNumber();
            }
        }, 500);
        setTypingTimeout(timeout);
        return () => clearTimeout(timeout);
    }, [serialNumber]);

    useEffect(() => {
        if (chatBotType !== 'template') {
            setTemplatesMediaState({
                replyTemplate: false,
                jsonTrueTemplate: false,
                jsonFalseTemplate: false,
            })
            setTemplatesMedia({
                replyTemplate: { url: '', type: '' },
                jsonTrueTemplate: { url: '', type: '' },
                jsonFalseTemplate: { url: '', type: '' },
            })
        }
    }, [chatBotType]);

    // make sate for medisState
    const [openMediaModel, setOpenMediaModel] = useState(false);
    const [mediaID, setMediaID] = useState('')
    const [parentChatBot, setParentChatBot] = useState();
    const [mediaState, setMediaState] = useState('');

    const handleCancelMediaModel = () => {
        setOpenMediaModel(false);
    };
    const showMediaModel = async (MediaState) => {
        setMediaState(MediaState)
        setOpenMediaModel(true);
    };
    const HandleUploadImage = async (path, name) => {

        setTemplatesMedia(prevState => ({
            ...prevState,
            [mediaState]: {
                ...prevState[mediaState],
                url: path,
                name: name
            }
        }))
        setMediaID(path)
        setMediaCustomName(name)
        handleCancelMediaModel()
    };

    const HandleSubmit = async () => {
        if (error) {
            message.error('Already another response assigned to this serial number')
        }
        if (badges?.length === 0) {
            message.error('Please add at least one keyword')
            return
        }
        else {
            const payload = {
                'user_id': UserData?.id,
                'sr_no': serialNumber,
                'ref_no': timestamp,
                'keyword': badges,
                'chatbot_type': chatBotType,
                'reply_template': replyTemplate,
                'custom_message_type': customMessageType,
                'parent_id': parentChatBot,
                'preset': preset,
                'custom_type': customType,
                'reply_text': replyText,
                'external_url': externalUrl,
                'url_action_type': urlActionType,
                'url_text': urlText,
                'url_xml': urlXml,
                'url_json_key': urlJsonKey,

                'json_true_key': jsonTrueKey,
                'json_true_value': jsonTrueValue,
                'json_true_outgoing_res': jsonTrueOutgoingRes,
                'json_true_chatbot': jsonTrueChatbot,
                'json_true_json_res': jsonTrueJsonRes,
                'json_true_custom_text': jsonTrueCustomText,
                'json_true_list_key': jsonTrueListItemKey,

                'media_id': mediaID,
                'waba': waba,

                'json_false_key': jsonFalseKey,
                'json_false_value': jsonFalseValue,
                'json_false_outgoing_res': jsonFalseOutgoingRes,
                'json_false_chatbot': jsonFalseChatbot,
                'json_false_json_res': jsonFalseJsonRes,
                'json_false_custom_text': jsonFalseCustomText,
                'json_false_list_key': jsonFalseListItemKey,

                'reply_template_media': templatesMedia?.replyTemplate.url && templatesMedia?.replyTemplate,
                'json_true_template_media': templatesMedia?.jsonTrueTemplate.url && templatesMedia?.jsonTrueTemplate,
                'json_false_template_media': templatesMedia?.jsonFalseTemplate.url && templatesMedia?.jsonFalseTemplate,

                'reply_template_language': templatesLanguage?.replyTemplate && templatesLanguage?.replyTemplate,
                'json_true_template_language': templatesLanguage?.jsonTrueTemplate && templatesLanguage?.jsonTrueTemplate,
                'json_false_template_language': templatesLanguage?.jsonFalseTemplate && templatesLanguage?.jsonFalseTemplate,

                'json_true_li_body' :  jsonTrueLiBody,
                'json_true_li_header' :  jsonTrueLiHeader,
    
                'json_false_li_header' :  jsonFalseLiHeader,
                'json_false_li_body' :  jsonFalseLiBody,

                'header_required': headerRequired === true ? 1 : 0,
                'header_key': headerKey,
                'header_value': headerValue,
                'chatbot_action': chatbotAction,
                'chatbot_state': chatbotState,
                'store_keys': storeKeys?.map((key) => `"${key}"`).join(','),
                'list_item': listItem,

            }
            await axios.post(`${api}chatbot-create`, payload,
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                }).then((res) => {
                    message.success('Response Created Successfully')
                    if (isModal) {
                        CloseModal()
                    } else {
                        navigate('/manage-request')
                    }
                }).catch((error) => {
                })
        }
    }

    return (
        <>
            <SelectMediaModel
                handleCancelMediaModel={handleCancelMediaModel}
                HandleUploadImage={HandleUploadImage}
                openMediaModel={openMediaModel}
            />
            <Form layout="vertical">
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Row gutter={ROW_GUTTER}>
                            <ParentChatbot
                                show={showParentChatbot}
                                parentID={parentID}
                                setParentChatBot={setParentChatBot}
                            />
                            <KeywordsComp
                                badges={badges}
                                setBadges={setBadges}
                                inputValue={inputValue}
                                setInputValue={setInputValue}
                            />
                            <ReplyType setValue={setChatBotType} value={chatBotType} />
                            <ChatbotDataStoreComps
                                chatbotAction={chatbotAction}
                                setChatbotAction={setChatbotAction}
                                chatbotState={chatbotState}
                                setChatbotState={setChatbotState}
                                storeKeys={storeKeys}
                                setStoreKeys={setStoreKeys}
                            />
                            {chatBotType === 'Custom' &&
                                <Col xs={24} sm={24} md={24}>
                                    <Form.Item label="Message Type">
                                        <Radio.Group onChange={(e) => setCustomMessageType(e.target.value)} value={customMessageType}>
                                            <Radio value='text'>Text</Radio>
                                            <Radio value='image'>Image</Radio>
                                            <Radio value='video'>Video</Radio>
                                            <Radio value='document'>Document(PDF)</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            }
                            {chatBotType === 'Custom' &&
                                customMessageType !== 'text' &&
                                <CustomMediaMessage
                                    chatBotType={chatBotType}
                                    customMessageType={customMessageType}
                                    customMediaName={customMediaName}
                                    buttonStyle={buttonStyle}
                                    media={media}
                                    mediaID={mediaID}
                                    getUrlFromMediaId={getUrlFromMediaId}
                                    showMediaModel={showMediaModel}
                                />
                            }
                            {(chatBotType === 'Custom' && customMessageType === 'text') &&
                                <>
                                    <Col xs={24} sm={24} md={12}>
                                        <Form.Item className='mb-0' label="Is this callback url?">
                                            <Checkbox onChange={(e) => handleUrl(e)} checked={isUrl}>True</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    {isUrl &&
                                        <HeaderFields
                                            headerRequired={headerRequired}
                                            headerKey={headerKey}
                                            headerValue={headerValue}
                                            setHeaderKey={setHeaderKey}
                                            setHeaderRequired={setHeaderRequired}
                                            setHeaderValue={setHeaderValue}
                                        />
                                    }
                                    <Col xs={24} sm={24} md={24}>
                                        {isUrl ?
                                            <Form.Item label={`External Url`} className='mb-0'>
                                                <TextArea rows={4} type="text"
                                                    value={externalUrl}
                                                    onChange={(e) => setExternalUrl(e.target.value)}
                                                />
                                                <p style={{ fontSize: '12px' }} className='mt-2'>❕ Make Sure to replace <strong>#number</strong> with your dynamic mobile number variable</p>
                                            </Form.Item>
                                            :
                                            <InputWithEmoji
                                                value={replyText}
                                                hideVarBTN={true}
                                                language={'english'}
                                                setValue={setReplyText}
                                            />

                                        }
                                    </Col>

                                    {isUrl &&
                                        <>
                                            <Col xs={24} sm={24} md={24}>
                                                <Form.Item label="Action">
                                                    <Radio.Group onChange={(e) => setUrlActionType(e.target.value)} value={urlActionType}>
                                                        <Radio value='Json' name='res'>Json</Radio>
                                                        <Radio value='Text' name='res'>Custom text</Radio>
                                                        <Radio value='XML' name='res'>XML</Radio>
                                                        <Radio value='Url' name='res'>Url Response</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            {urlActionType === 'Json' ?
                                                <>
                                                    {/* for true  */}
                                                    <Col xs={24} sm={24} md={24}>
                                                        <ResponsePanel
                                                            panelTitle="Incoming Json Response 1(Example : true)"
                                                            jsonKey={jsonTrueKey}
                                                            setJsonKey={setJsonTrueKey}
                                                            jsonValue={jsonTrueValue}
                                                            setJsonValue={setJsonTrueValue}
                                                            outgoingRes={jsonTrueOutgoingRes}
                                                            setOutgoingRes={setJsonTrueOutgoingRes}
                                                            template={jsonTrueTemplate}
                                                            setTemplate={setJsonTrueTemplate}
                                                            chatbot={jsonTrueChatbot}
                                                            setChatbot={setJsonTrueChatbot}
                                                            customText={jsonTrueCustomText}
                                                            setCustomText={setJsonTrueCustomText}
                                                            jsonRes={jsonTrueJsonRes}
                                                            setJsonRes={setJsonTrueJsonRes}
                                                            templatesMediaState={templatesMediaState}
                                                            templatesMedia={templatesMedia}
                                                            templateList={templateList}
                                                            handleTemplate={handleTemplate}
                                                            showMediaModel={showMediaModel}
                                                            media={media}
                                                            getUrlFromMediaId={getUrlFromMediaId}
                                                            setListItemKey={setJsonTrueListItemKey}
                                                            listItemKey={jsonTrueListItemKey}
                                                            jsonLiHeader={jsonTrueLiHeader}
                                                            jsonLiBody={jsonTrueLiBody}
                                                            setJsonLiHeader={setJsonTrueLiHeader}
                                                            setJsonLiBody={setJsonTrueLiBody}
                                                            responseType="jsonTrue"
                                                        />
                                                    </Col>
                                                    {/* for false */}
                                                    <Col xs={24} sm={24} md={24} className='mt-2'>
                                                        <ResponsePanel
                                                            panelTitle="Incoming Json Response 2(Example : false)"
                                                            jsonKey={jsonFalseKey}
                                                            setJsonKey={setJsonFalseKey}
                                                            jsonValue={jsonFalseValue}
                                                            setJsonValue={setJsonFalseValue}
                                                            outgoingRes={jsonFalseOutgoingRes}
                                                            setOutgoingRes={setJsonFalseOutgoingRes}
                                                            template={jsonFalseTemplate}
                                                            setTemplate={setJsonFalseTemplate}
                                                            chatbot={jsonFalseChatbot}
                                                            setChatbot={setJsonFalseChatbot}
                                                            customText={jsonFalseCustomText}
                                                            setCustomText={setJsonFalseCustomText}
                                                            jsonRes={jsonFalseJsonRes}
                                                            setJsonRes={setJsonFalseJsonRes}
                                                            templatesMediaState={templatesMediaState}
                                                            templatesMedia={templatesMedia}
                                                            templateList={templateList}
                                                            handleTemplate={handleTemplate}
                                                            showMediaModel={showMediaModel}
                                                            media={media}
                                                            getUrlFromMediaId={getUrlFromMediaId}
                                                            setListItemKey={setJsonFalseListItemKey}
                                                            listItemKey={jsonFalseListItemKey}
                                                            jsonLiHeader={jsonFalseLiHeader}
                                                            jsonLiBody={jsonFalseLiBody}
                                                            setJsonLiHeader={setJsonFalseLiHeader}
                                                            setJsonLiBody={setJsonFalseLiBody}
                                                            responseType="jsonFalse"
                                                        />
                                                    </Col>
                                                </>
                                                :
                                                <UrlActionInput
                                                    urlActionType={urlActionType}
                                                    urlText={urlText}
                                                    urlXml={urlXml}
                                                    urlJsonKey={urlJsonKey}
                                                    setUrlText={setUrlText}
                                                    setUrlXml={setUrlXml}
                                                    setUrlJsonKey={setUrlJsonKey}
                                                />
                                            }
                                        </>
                                    }
                                </>
                            }
                            <Col xs={24} sm={24} md={24}>
                                <DynamicForm
                                    chatBotType={chatBotType}
                                    templateList={templateList}
                                    replyTemplate={replyTemplate}
                                    handleTemplate={handleTemplate}
                                    templatesMediaState={templatesMediaState}
                                    templatesMedia={templatesMedia}
                                    showMediaModel={showMediaModel}
                                    buttonStyle={buttonStyle}
                                    toLower={toLower}
                                    getUrlFromMediaId={getUrlFromMediaId}
                                    media={media}
                                    preset={preset}
                                    presetsList={presetsList}
                                    setpreset={setpreset}
                                    setReplyTemplate={setReplyTemplate}
                                    setListItem={setListItem}
                                    listItem={listItem}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24}>
                                <Form.Item className='text-center'>
                                    <Button className='mt-3 px-3' type="primary" onClick={() => HandleSubmit()}>Submit</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default NewChatBotForm
