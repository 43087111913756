import React, { useEffect, useState } from 'react';
import { Col, Row, Collapse, Radio, Input, Select, Button, Form, Image } from 'antd';
import { CaretRightOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { toLower } from 'lodash';
import { useMyContext } from 'Context/MyContextProvider';
import { Chatbots } from '../ChatBotUtils/ChatbotListApi';

const { Panel } = Collapse;
const { TextArea } = Input;
const { Option } = Select;

const ROW_GUTTER = [16, 16];
const buttonStyle = { /* add your button styles here */ };

const ResponsePanel = ({
  panelTitle,
  jsonKey,
  setJsonKey,
  jsonValue,
  setJsonValue,
  outgoingRes,
  setOutgoingRes,
  template,
  setTemplate,
  chatbot,
  setChatbot,
  customText,
  setCustomText,
  jsonRes,
  setJsonRes,
  templatesMediaState,
  templatesMedia,
  templateList,
  handleTemplate,
  showMediaModel,
  media,
  getUrlFromMediaId,
  responseType,
  chatbotName,
  setListItemKey,
  listItemKey,
  setJsonLiBody,
  setJsonLiHeader,
  jsonLiBody,
  jsonLiHeader
}) => {

  const { authToken, api, UserData } = useMyContext()
  const [chatbots, setChatbots] = useState([]);
  const [loading, setLoading] = useState(false);
  const filterOption = (input, option) => {
    try {
      const keywords = JSON.parse(option?.children || "[]");
      const searchTerm = input.toLowerCase();
      return keywords.some(keyword =>
        keyword.toLowerCase().includes(searchTerm)
      );
    } catch (error) {
      console.error("Error parsing keywords:", error);
      return false;
    }
  };
  //analytics
  const ChatbotData = async () => {
    await Chatbots(setLoading, api, UserData?.id, authToken, setChatbots);
  }
  useEffect(() => {
    ChatbotData()
  }, [])

  const renderTemplateContent = () => (
    <Row gutter={ROW_GUTTER} className='align-items-end'>
      <Col xs={24} sm={24} md={24}>
        <Form.Item label="Templates" name={`${responseType}templates`}>
          <Select
            showSearch
            size="default"
            defaultValue={template}
            style={{ width: '100%' }}
            onChange={(value) => handleTemplate(value, setTemplate, `${responseType}Template`)}
          >
            {templateList?.length > 0 ? (
              templateList.map((item, index) => (
                <Option value={item.name} key={index}>{item.name}</Option>
              ))
            ) : (
              <Option value="">You Don't Have Any Approved Templates</Option>
            )}
          </Select>
        </Form.Item>
      </Col>
      {templatesMediaState?.[`${responseType}Template`] && (
        <>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Select media"
              className='mb-0'
              name={`media-link-${responseType}-template`}
            >
              {!templatesMedia?.[`${responseType}Template`]?.url ? (
                <Button type='' style={buttonStyle} onClick={() => showMediaModel(`${responseType}Template`)}>
                  <CloudUploadOutlined />Choose File
                </Button>
              ) : (
                <>
                  <Input
                    type="text"
                    disabled
                    value={templatesMedia[`${responseType}Template`].url}
                    className='mb-3'
                  />
                  <Button type='' style={buttonStyle} onClick={() => showMediaModel(`${responseType}Template`)}>
                    <CloudUploadOutlined />Choose Another File
                  </Button>
                </>
              )}
            </Form.Item>
            <span className='text-warning'>
              This Template Contains {toLower(templatesMedia?.[`${responseType}Template`]?.type)} in header
            </span>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Media Preview"
              className='mb-0'
            >
              <Image
                src={getUrlFromMediaId(templatesMedia?.[`${responseType}Template`]?.url, media) || '/img/others/dummy-header.jpg'}
                className="rounded cursor-pointer"
                preview={false}
                style={{
                  width: '200px',
                  height: '150px',
                  objectFit: 'contain',
                }}
              />
            </Form.Item>
            <span className='text-warning'>Preview available for images</span>
          </Col>
        </>
      )}
    </Row>
  );

  const renderResponseContent = () => {
    switch (outgoingRes) {
      case 'Template':
        return renderTemplateContent();
      case 'Chatbot':
        return (
          <Col xs={24} sm={24} md={24}>
            <Form.Item label="Select ChatBot" name={`chatbots ${responseType}`}>
              <Select
                showSearch
                size={'default'}
                defaultValue={chatbotName || ''}
                style={{ width: '100%' }}
                onChange={(value) => setChatbot(value)}
                optionFilterProp="keyword"
                filterOption={filterOption}
              >
                {chatbots?.length > 0 ?
                  chatbots?.map((item, index) =>
                    <Option value={item.id} key={index}>{item.keyword}</Option>
                  )
                  :
                  <Option value="">Select</Option>

                }
              </Select>
            </Form.Item>
          </Col>
        );
      case 'Text':
        return (
          <Col xs={24} sm={24} md={24}>
            <Form.Item label="custom message">
              <TextArea
                rows={2}
                value={customText}
                onChange={(e) => setCustomText(e.target.value)}
              />
            </Form.Item>
          </Col>
        );
      case 'json_res':
        return (
          <>
            <Col xs={24} sm={24} md={24}>
              <Form.Item label="Json Key">
                <Input
                  value={jsonRes}
                  onChange={(e) => setJsonRes(e.target.value)}
                />
              </Form.Item>
            </Col>
          </>
        );
      case 'list_item':
        return (
          <Row gutter={10}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item label="List Item Key" className='mb-0'>
                <Input
                  value={listItemKey}
                  onChange={(e) => setListItemKey(e.target.value)}
                />
              </Form.Item>
              <p className="text-muted m-0 p-0">Enter the key that you want to extract from the response and send to the user.</p>
            </Col>
            <Col xs={24} sm={24} md={12} >
              <Form.Item label="List Item Header">
                <Input
                  value={jsonLiHeader}
                  onChange={(e) => setJsonLiHeader(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} >
              <Form.Item label="List Item Body" className='mb-0'>
                <TextArea
                  rows={2}
                  value={jsonLiBody}
                  onChange={(e) => setJsonLiBody(e.target.value)}
                />
              </Form.Item>
            </Col>
            <p className='text-muted'>Required only when responding with a list item.</p>
          </Row>
        );
      default:
        return null;
    }
  };

  return (
    <Col xs={24} sm={24} md={24}>
      <Collapse
        bordered={false}
        defaultActiveKey={['0']}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        className="site-collapse-custom-collapse"
      >
        <Panel header={panelTitle} key="1">
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item label="Key">
                <Input value={jsonKey} onChange={(e) => setJsonKey(e.target.value)} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item label="Value">
                <Input value={jsonValue} onChange={(e) => setJsonValue(e.target.value)} />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={24} md={24}>
            <Form.Item label="Set outgoing response">
              <Radio.Group onChange={(e) => setOutgoingRes(e.target.value)} value={outgoingRes}>
                <Radio value='Chatbot' name={`resType${responseType}`}>Chatbot</Radio>
                <Radio value='Template' name={`resType${responseType}`}>Template</Radio>
                <Radio value='json_res' name={`resType${responseType}`}>Json Response</Radio>
                <Radio value='Text' name={`resType${responseType}`}>Custom Text</Radio>
                <Radio value='list_item' name={`resType${responseType}`}>List Item</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          {renderResponseContent()}
        </Panel>
      </Collapse>
    </Col>
  );
};

export default ResponsePanel;