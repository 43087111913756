import React from 'react'
import { Alert, Col } from 'antd'
import { motion } from "framer-motion"
const MotionError = ({error}) => {
    
  const isJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };
    const getErrorMessage = () => {
        if (typeof error === 'string') {
          return error;
        } else if (isJSON(JSON.stringify(error))) {
          return JSON.stringify(error);
        }
        return 'An unexpected error occurred';
      };
    return (
        <div>
            {error &&
                <Col span={24}>
                    <motion.div
                        initial={{ opacity: 0, marginBottom: 0 }}
                        animate={{
                            opacity: error ? 1 : 0,
                            marginBottom: error ? 20 : 0
                        }}>
                        <Alert type="error" showIcon message={getErrorMessage()}></Alert>
                    </motion.div>
                </Col>
            }
        </div>
    )
}

export default MotionError
