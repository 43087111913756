import { Button, Modal, Result } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const LowCreditModal = ({open,handleCancel,UserData}) => {
    return (
        <Modal
            title="Insufficient Credits"
            open={open}
            onCancel={handleCancel}
            footer={null}
        >
            <Result
                title="Insufficient credits to run this campaign"
                extra={
                    <Link to={`/users/manage/${UserData.id}/billing`}>
                        <Button type="primary" key="console">
                            Add Credits
                        </Button>
                    </Link>
                }
            />
        </Modal>
    )
}

export default LowCreditModal
