import React, { useEffect, useState } from 'react'

import { Row, Col, Image, Modal, message, Button, Result, Upload, Menu, Progress, Empty } from 'antd';
import { UploadOutlined, LoadingOutlined, DeleteOutlined } from '@ant-design/icons';
import ProjectListData from '../../apps/project/project-list/ProjectListData';
import ReactPlayer from 'react-player'
import {
    PlayCircleOutlined,
    FileExcelOutlined
} from '@ant-design/icons';
import Flex from 'components/shared-components/Flex';


import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';




const ItemHeader = ({ name, category }) => (
    <div>
        <h5 className="mb-0">{name.length > 15 ? name.substring(0, 16) + '...' : name}</h5>
        <span className="text-muted">{category}</span>
    </div>
)


const mediaBoxStyle = {
    height: '250px',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const imageStyle = {
    width: '100%',
    height: '250px',  // Fixed height for consistent grid look
    objectFit: 'cover',  // 'cover' ensures the image fills the container while maintaining aspect ratio
    objectPosition: 'center', // Centers the image within the container
    borderRadius: '8px', // Optional: adds rounded corners
};

const ItemAction = ({ id, getMedia }) => {
    const { api, authToken } = useMyContext();

    return (
        <EllipsisDropdown menu={
            <Menu>
                <Menu.Item key="2" onClick={() => handleDelete(id, api, authToken, getMedia)}>
                    <DeleteOutlined />
                    <span className="ml-2">Delete</span>
                </Menu.Item>
            </Menu>
        } />
    );
}

const handleDelete = async (id, api, authToken, getMedia) => {
    try {
        const response = await axios.delete(`${api}delete-media/${id}`, {
            headers: {
                Authorization: "Bearer " + authToken,
            },
        });
        if (response.data.status) {
            getMedia();
        }
    } catch (error) {
        message.error('Failed to delete media');
        console.error(error);
    }
}
const GridItem = ({ data, removeId, HandleUploadImage, handleCustomMedia, requestType }) => {
    // video 
    const [openVideo, setOpenVideo] = useState(false);
    const [video, setVideo] = useState({
        path: '',
        title: ''
    });
    const HandleVideoPlay = (path, title) => {
        setOpenVideo(true);
        setVideo({ path: path, title: title })
    };
    const handleCancelVideo = () => {
        setOpenVideo(false);
    };
    const PassMediaData = (media_id, name, url) => {
        if (requestType === 'Custom') {
            handleCustomMedia(media_id, name, url)
        } else {
            HandleUploadImage(media_id, name, url)
        }
    }
    const mediaPath = data?.path;
    return (
        <>
            {/* video model  */}
            <Modal
                destroyOnClose
                maskClosable={false}
                open={openVideo}
                onCancel={handleCancelVideo}
                footer={null}
            >
                <Flex justifyContent='center' flexDirection='column'>
                    <h4 style={{ width: '90%' }}>{video?.title}</h4>
                    <div className='player'>
                        <ReactPlayer autoPlay={true} url={video?.path} controls={true} width={'100%'} height={'auto'} className='rounded' />
                    </div>
                </Flex>
            </Modal>
            {/* video model end  */}
            <div className='mb-5 border rounded p-2' onClick={() => PassMediaData(data?.media_id, data?.name, data?.path)}>
                <Flex alignItems="center" justifyContent="">
                    <ItemHeader name={data?.name} category={data?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'excel' : data?.type} />
                    {/* <ItemAction path={mediaPath} id={data?.id} /> */}
                </Flex>
                <div className="mt-2">
                    <div className='image-box d-flex justify-content-center align-items-center rounded' style={mediaBoxStyle}>
                        {
                            (data.type === 'image/png') || (data.type === 'image/jpg') || (data.type === 'image/jpeg') ?
                                <Image src={data?.path} className='border rounded cursor-pointer' style={imageStyle} preview={false} />
                                :
                                data?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ?
                                    <span style={{ fontSize: '5rem' }}>
                                        <FileExcelOutlined />
                                    </span>
                                    :
                                    data?.type === 'video/mp4' ?
                                        <span style={{ fontSize: '5rem' }}>
                                            <PlayCircleOutlined
                                                onClick={() => HandleVideoPlay(data?.path, data?.name)}
                                            />

                                        </span>
                                        :
                                        ''
                        }
                    </div>
                </div>
            </div>
        </>
    )
}





const MediModel = ({ HandleUploadImage, handleCustomMedia, requestType }) => {
    const { UserData, api, authToken, uploadMediaApi, waToken } = useMyContext()
    const [list, setList] = useState(ProjectListData);
    const [selectedMedia, setSelectedMedia] = useState([]);
    const [media, setMedia] = useState([]);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [open, setOpen] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);


    const getMedia = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}media/${UserData?.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                },
            });

            // if (response.data?.status) {
            const data = response.data.media;

            setMedia(data
                .filter(item => !item.deleted_at)
                .map(item => ({ ...item, isDeleted: false }))
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            );
            setLoading(false)
            // }
        } catch (error) {
            setLoading(false)
            message.error(error);
        }
    };

    useEffect(() => {
        getMedia()
    }, [])



    const deleteItem = id => {
        const data = list.filter(elm => elm.id !== id)
        setList(data)
    }

    const handleCancel = () => {
        setOpen(false);
        setSelectedMedia([]);
    };

    const props = {
        beforeUpload: file => {
            setSelectedMedia(prev => [...prev, file]);
            return false;
        },
        fileList: selectedMedia,
        onRemove: file => {
            setSelectedMedia(prev => prev.filter(item => item.uid !== file.uid));
        }
    };

    const handleUploadOnMeta = async () => {
        const MAX_FILE_SIZE_MB = 15;
        if (selectedMedia && selectedMedia.length > 0) {
            try {
                setLoading(true);
                setUploadProgress(0);
                // Process each file sequentially
                for (const file of selectedMedia) {
                    const type = file.type;
                    const name = file.name;
                    const fileSizeMB = file.size / (1024 * 1024);

                    // File size validation
                    if (fileSizeMB > MAX_FILE_SIZE_MB) {
                        message.error(`File "${name}" exceeds the maximum size of ${MAX_FILE_SIZE_MB} MB.`);
                        continue; // Skip this file and move to next
                    }

                    // Upload to Meta to get media ID
                    const formDataMeta = new FormData();
                    formDataMeta.append('file', file);
                    formDataMeta.append('type', type);
                    formDataMeta.append('filename', name);
                    formDataMeta.append('messaging_product', 'whatsapp');

                    try {
                        // Upload to Meta
                        const responseMeta = await axios.post(`${uploadMediaApi}`, formDataMeta, {
                            headers: { 'Authorization': 'Bearer ' + waToken },
                            // onUploadProgress: (progressEvent) => {
                            //     const percentCompleted = Math.round(
                            //         (progressEvent.loaded * 100) / progressEvent.total
                            //     );
                            //     setUploadProgress(percentCompleted);
                            // }
                        });

                        // Get media ID from Meta response
                        const mediaId = responseMeta.data.id;

                        // Now upload to your server using the mediaId
                        await handleUpload(file, mediaId);

                    } catch (metaUploadError) {
                        message.error(`Failed to upload ${name} to Meta`);
                    }
                }

                // After processing all files
                message.success('File uploads completed');
                handleCancel();
                getMedia();

            } catch (error) {
                message.error('Upload process failed');
            } finally {
                setLoading(false);
                setSelectedMedia([]);
                setUploadProgress(0);
            }
        } else {
            message.warning('No media selected for upload');
        }
    };

    const handleUpload = async (file, mediaId) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('media_id', mediaId);
        formData.append('user_id', UserData.id);
        setUploadProgress(0);
        try {
            const response = await axios.post(`${api}upload-media`, formData, {
                headers: { 'Authorization': 'Bearer ' + authToken },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setUploadProgress(percentCompleted);
                }
            });

            if (response.data?.status) {
                message.success('File uploaded successfully');
                return true;
            }
        } catch (error) {
            message.error(`Failed to upload file to your server: ${error.message}`);
            return false;
        }
    };

    useEffect(() => {
        if (selectedMedia.length > 0) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [selectedMedia]);


    return (
        <>
            <Modal
                destroyOnClose
                maskClosable={false}
                title="Upload Media"
                open={open}
                onCancel={handleCancel}
                footer={null}
            >
                <Result
                    icon={<PlayCircleOutlined />}
                    title="Upload your media files here!"
                    extra={
                        <Upload {...props} accept=".jpg,.jpeg,.png,.pdf,.xls,.xlsx" multiple>
                            <Button icon={<UploadOutlined />}>Click to select file</Button>
                        </Upload>
                    }
                />
                <Flex justifyContent='center' flexDirection='column' alignItems='center'>
                    {loading ? (
                        <>
                            <Progress
                                percent={uploadProgress}
                                className='rounded-3'
                                status={uploadProgress === 100 ? 'success' : 'active'}
                                strokeColor={{
                                    '0%': '#108ee9',
                                    '100%': '#87d068',
                                }}
                            />
                            <Button
                                type='primary'
                                className='text-center mt-2'
                                icon={<LoadingOutlined />}
                                disabled
                            >
                                Uploading... {uploadProgress}%
                            </Button>
                        </>
                    ) : (
                        <Button
                            type='primary'
                            className='text-center'
                            disabled={disabled}
                            onClick={handleUploadOnMeta}
                        >
                            <UploadOutlined />Upload
                        </Button>
                    )}
                </Flex>
            </Modal>
            <div className={`my-4 container-fluid `}>

                <Row gutter={16}>
                    <div className='d-flex justify-content-between align-items-center mr-3 mb-2 w-100'>
                        <h5>{''}</h5>
                        <Button type="primary" onClick={() => setOpen(true)}>Upload New</Button>
                    </div>
                    {media.length > 0 ? media.map(item => (
                        <Col xs={24} sm={24} lg={8} xl={8} xxl={4} key={item.id}>
                            <GridItem
                                data={item}
                                removeId={id => deleteItem(id)}
                                HandleUploadImage={HandleUploadImage}
                                handleCustomMedia={handleCustomMedia}
                                requestType={requestType}
                            />
                        </Col>
                    )) :
                        <Col xs={24} sm={24} lg={8} xl={24} xxl={24} className='d-flex justify-content-center'>
                            <Empty />
                        </Col>
                    }
                </Row>
            </div>
        </>
    )
}

export default MediModel

