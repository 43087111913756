import { Button, Checkbox, Col, Form, Input, Radio, Row, message } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import TextArea from 'antd/es/input/TextArea'
import { useMyContext } from 'Context/MyContextProvider'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import { toLower } from 'lodash'
import SelectMediaModel from '../../Campaign/CampainComps/SelectMediaModel';
import CustomMediaMessage from '../ChatbotCodeComps/CustomMediaMessage';
import { extractTemplateSections } from '../../CustomUtils.js/TemplateUtils';
import { HeaderFields, KeywordsComp, MessageType, ParentChatbot, ReplyType } from '../ChatbotCodeComps/ChatbotFields';
import ResponsePanel from '../ChatbotCodeComps/ResponsePanel';
import ChatbotDataStoreComps from '../ChatbotCodeComps/ChatbotDataStoreComps';
import InputWithEmoji from '../../CustomUtils.js/InputWithKeyword';
import DynamicForm from './DynamicForm';
import UrlActionInput from '../ChatbotCodeComps/UrlActionInput'


const EditChatBotForm = ({ id, showParentChatbot, parentID = null, isModal = false, CloseModal }) => {
    const { authToken, api, UserData, templates, navigate, buttonStyle, getUrlFromMediaId, getMedia } = useMyContext()
    const [componentSize, setComponentSize] = useState('small');
    const [isUrl, setIsUrl] = useState(false);
    const [timestamp, setTimestamp] = useState(0);
    const [serialNumber, setSerialNumber] = useState('');
    const [chatbots, setChatbots] = useState([]);
    const [loading, setLoading] = useState(false);


    const [media, setMedia] = useState([]);
    const [listItem, setListItem] = useState('');

    // new states 
    const [chatBotType, setChatBotType] = useState('');
    const [replyTemplate, setReplyTemplate] = useState('');
    const [customType, setCustomType] = useState('');
    const [replyText, setReplyText] = useState('');
    const [externalUrl, setExternalUrl] = useState('');
    const [urlActionType, setUrlActionType] = useState('');
    const [urlText, setUrlText] = useState('');
    const [urlXml, setUrlXml] = useState('');
    const [urlJsonKey, setUrlJsonKey] = useState('');

    //custom type states 
    const [customMessageType, setCustomMessageType] = useState('text')
    const [mediaID, setMediaID] = useState('')
    const [customMediaName, setMediaCustomName] = useState('')
    // json true states 
    const [jsonTrueKey, setJsonTrueKey] = useState('');
    const [jsonTrueValue, setJsonTrueValue] = useState('');
    const [jsonTrueOutgoingRes, setJsonTrueOutgoingRes] = useState('');
    const [jsonTrueChatbot, setJsonTrueChatbot] = useState('');
    const [jsonTrueChatbotName, setJsonTrueChatbotName] = useState('');
    const [jsonTrueTemplate, setJsonTrueTemplate] = useState('');
    const [jsonTrueJsonRes, setJsonTrueJsonRes] = useState('');
    const [jsonTrueCustomText, setJsonTrueCustomText] = useState('');
    const [jsonTrueLiHeader, setJsonTrueLiHeader] = useState('');
    const [jsonTrueLiBody, setJsonTrueLiBody] = useState('');


    // json false states
    const [jsonFalseKey, setJsonFalseKey] = useState('');
    const [jsonFalseValue, setJsonFalseValue] = useState('');
    const [jsonFalseOutgoingRes, setJsonFalseOutgoingRes] = useState('');
    const [jsonFalseChatbot, setJsonFalseChatbot] = useState('');
    const [jsonFalseChatbotName, setJsonFalseChatbotName] = useState('');
    const [jsonFalseTemplate, setJsonFalseTemplate] = useState('');
    const [jsonFalseJsonRes, setJsonFalseJsonRes] = useState('');
    const [jsonFalseCustomText, setJsonFalseCustomText] = useState('');
    const [parentChatBot, setParentChatBot] = useState();
    const [jsonFalseLiHeader, setJsonFalseLiHeader] = useState('');
    const [jsonFalseLiBody, setJsonFalseLiBody] = useState('');

    //badges
    const [badges, setBadges] = useState([]);
    const [error, setError] = useState(false);
    const [templateList, setTemplateList] = useState([]);
    const [templatesMediaState, setTemplatesMediaState] = useState({
        replyTemplate: false,
        jsonTrueTemplate: false,
        jsonFalseTemplate: false,
    });
    const [templatesMedia, setTemplatesMedia] = useState({
        replyTemplate: { url: '', type: '' },
        jsonTrueTemplate: { url: '', type: '' },
        jsonFalseTemplate: { url: '', type: '' },
    });
    const [templatesLanguage, setTemplatesLanguage] = useState({
        replyTemplate: '',
        jsonTrueTemplate: '',
        jsonFalseTemplate: '',
    });

    const [jsonFalseListItemKey, setJsonFalseListItemKey] = useState('');
    const [jsonTrueListItemKey, setJsonTrueListItemKey] = useState('');

    const [presetsList, setpresetList] = useState([]);
    const [preset, setpreset] = useState();

    const [chatbotAction, setChatbotAction] = useState('');
    const [chatbotState, setChatbotState] = useState('');
    const [storeKeys, setStoreKeys] = useState([]);
    // header state 
    const [headerRequired, setHeaderRequired] = useState(false);
    const [headerKey, setHeaderKey] = useState();
    const [headerValue, setHeaderValue] = useState();


    useEffect(() => {
        if (parentID) {
            setParentChatBot(parentID);
        }
    }, [parentID]);

    useEffect(() => {
        if (templates.length > 0) {
            const nonCarouselTemplates = templates.filter(template =>
                !template.components?.some(comp => comp.type === 'CAROUSEL')
            );

            const result = nonCarouselTemplates.filter(template => {
                const bodyComponent = template?.components.find(comp => comp.type === 'BODY');
                const hasDynamicVariables = bodyComponent && /{{\d+}}/.test(bodyComponent.text);
                return !hasDynamicVariables
            });
            setTemplateList(result);
        }
    }, [templates]);


    const GetPresets = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}presets/${UserData?.id}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            if (response.data.status) {
                setpresetList(response.data.preset)
            }
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    // fetch old data 
    const ChatbotData = async () => {
        try {
            const response = await axios.get(`${api}edit-chatbot/${id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
            const data = response.data?.chatbot;

            setTimestamp(data?.ref_no)
            setSerialNumber(data?.sr_no)
            setParentChatBot(data?.parent_id)
            setBadges(JSON.parse(data?.keyword))
            setChatBotType(data?.chatbot_type)
            setCustomType(data?.custom_type)
            setpreset(data?.preset)
            setCustomMessageType(data?.custom_message_type)
            setMediaID(data?.media_id)
            setMediaID(data?.list_item)
            setpreset(data?.preset)
            setParentChatBot(data?.parent_id)
            if (data?.custom_type === 'Url') {
                setIsUrl(true)
                setExternalUrl(data?.external_url)
            } else {
                setReplyText(data?.reply_text)
            }
            setUrlActionType(data?.url_action_type)
            setReplyTemplate(data?.reply_template)
            setUrlText(data?.url_text)
            setUrlXml(data?.url_xml)
            setUrlJsonKey(data?.url_json_key)

            setJsonTrueKey(data?.json_true_key)
            setJsonTrueValue(data?.json_true_value)
            setJsonTrueOutgoingRes(data?.json_true_outgoing_res)
            setJsonTrueChatbot(data?.json_true_chatbot)

            setJsonTrueTemplate(data?.json_true_template)
            setJsonTrueJsonRes(data?.json_true_json_res)
            setJsonTrueCustomText(data?.json_true_custom_text)

            setJsonFalseKey(data?.json_false_key)
            setJsonFalseValue(data?.json_false_value)
            setJsonFalseOutgoingRes(data?.json_false_outgoing_res)
            setJsonFalseChatbot(data?.json_false_chatbot)
            setJsonFalseTemplate(data?.json_false_template)
            setJsonFalseJsonRes(data?.json_false_json_res)
            setJsonFalseCustomText(data?.json_false_custom_text)

            setJsonTrueListItemKey(data?.json_true_list_key)
            setJsonFalseListItemKey(data?.json_false_list_key)

            setJsonTrueLiBody(data?.json_true_li_header)
            setJsonTrueLiHeader(data?.json_true_li_body)

            setJsonFalseLiBody(data?.json_false_li_header)
            setJsonFalseLiHeader(data?.json_false_li_body)

            const formattedReplyTemplate = parseTemplateMedia(data?.reply_template_media);
            const formattedJsonTrueTemplate = parseTemplateMedia(data?.json_true_template_media);
            const formattedJsonFalseTemplate = parseTemplateMedia(data?.json_false_template_media);
            setTemplatesMediaState({
                replyTemplate: formattedReplyTemplate?.url ? true : '',
                jsonTrueTemplate: formattedJsonTrueTemplate?.url ? true : '',
                jsonFalseTemplate: formattedJsonFalseTemplate?.url ? true : '',
            })
            setTemplatesMedia({
                replyTemplate: formattedReplyTemplate ? formattedReplyTemplate : '',
                jsonTrueTemplate: formattedJsonTrueTemplate ? formattedJsonTrueTemplate : '',
                jsonFalseTemplate: formattedJsonFalseTemplate ? formattedJsonFalseTemplate : '',
            });
            setTemplatesLanguage({
                replyTemplate: data?.reply_template_language ? data?.reply_template_language : '',
                jsonTrueTemplate: data?.json_true_template_language ? data?.json_true_template_language : '',
                jsonFalseTemplate: data?.json_false_template_language ? data?.json_false_template_language : '',
            });

            if (data?.chatbot_type) {
                setMediaState('replyTemplate')
            } else if (data?.jsonTrueOutgoingRes === 'Template') {
                setMediaState('jsonTrueTemplate')
            } else if (data?.jsonFalseOutgoingRes === 'Template') {
                setMediaState('jsonFalseTemplate')
            }
            setTemplateApis({
                replyTemplate: data?.reply_template_api ? data?.reply_template_api : '',
                jsonTrueTemplate: data?.json_true_template_api ? data?.json_true_template_api : '',
                jsonFalseTemplate: data?.json_false_template_api ? data?.json_false_template_api : '',
            });

            setHeaderRequired(data?.header_required === 1);
            setHeaderKey(data?.header_key !== 'null' ? data?.header_key : '');
            setHeaderValue(data?.header_value !== 'null' ? data?.header_value : '');


            setChatbotAction(data?.chatbot_action !== 'null' ? data?.chatbot_action : '');
            setChatbotState(data?.chatbot_state !== 'null' ? data?.chatbot_state : '');
            if (data?.store_keys) {
                const storeKeysArray = data.store_keys.split(',').map(key => key.replace(/"/g, '').trim());
                setStoreKeys(storeKeysArray);
            } else {
                setStoreKeys([]);
            }


        } catch (error) {
            //  console.log(error);
        }
    }

    const parseTemplateMedia = (media) => {
        if (!media) return { url: '', type: '' }; // Default object
        try {
            let parsed = JSON.parse(media);
            if (typeof parsed === 'string') {
                parsed = JSON.parse(parsed); // Handle double-encoded JSON
            }
            return parsed;
        } catch (error) {
            console.error('Error parsing media:', media, error);
            return { url: '', type: '' }; // Fallback to default if parsing fails
        }
    };


    // badges 
    const [inputValue, setInputValue] = useState('');
    const Chatbots = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}chatbot/${UserData?.id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
            const data = response.data.chatbot;
            setChatbots(data);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }


    useEffect(() => {
        Chatbots();
        ChatbotData();
        GetPresets()
        getMedia(setMedia)
    }, [])

    const [templateName, setTemplateName] = useState('');
    const handleTemplate = (value, stateKey, mediaState) => {
        stateKey(value)
        setTemplatesMedia(prevState => ({
            ...prevState,
            [mediaState]: null,
        }));
        const selectedTemplate = templates?.find(item => item.name === value);
        if (selectedTemplate) {
            setTemplateName(selectedTemplate?.name)
            const template = extractTemplateSections(selectedTemplate);
            let header = template?.header
            setTemplatesLanguage(prevState => ({
                ...prevState,
                [mediaState]: selectedTemplate?.language,
            }));
            setTemplatesMediaState(prevState => ({
                ...prevState,
                [mediaState]: header && header?.format !== "TEXT",
            }));
            setTemplatesMedia(prevState => ({
                ...prevState,
                [mediaState]: {
                    ...prevState[mediaState],
                    type: header?.format || ''
                }
            }));
        }
    }
    const [templateApis, setTemplateApis] = useState({});
    useEffect(() => {
        if (jsonTrueTemplate !== 'null' || jsonFalseTemplate !== 'null' || replyTemplate !== 'null') {
            const generatedApis = {
                chatBotType: MakeDynamicApi(replyTemplate, "replyTemplate"),
                jsonTrueOutgoingRes: MakeDynamicApi(jsonTrueTemplate, "jsonTrueTemplate"),
                jsonFalseOutgoingRes: MakeDynamicApi(jsonFalseTemplate, "jsonFalseTemplate"),
            };
            setTemplateApis(generatedApis);
        }
        else {
            setTemplateApis({
                chatBotType: null,
                jsonTrueOutgoingRes: null,
                jsonFalseOutgoingRes: null,
            });
        }
    }, [replyTemplate, jsonTrueTemplate, jsonFalseTemplate, templatesMedia]);

    const MakeDynamicApi = (templateName, mediaKey) => {
        if (templateName) {
            const mediaId = templatesMedia?.[mediaKey]?.url || null;
            if (mediaId) {
                return `https://waba.smsforyou.biz/api/send-messages?apikey=:API_KEY:&to=:NUMBER:&type=T&tname=${templateName}&media_id=${mediaId}`;
            }
            return `https://waba.smsforyou.biz/api/send-messages?apikey=:API_KEY:&to=:NUMBER:&type=T&tname=${templateName}`;
        } else {
            return null;
        }
    };





    const onFormLayoutChange = ({ size }) => {
        setComponentSize(size);
    };


    function handleUrl(e) {
        setIsUrl(e.target.checked);
        if (e.target.checked) {
            setCustomType('Url')
        } else {
            setCustomType('Text')
        }
    }

    useEffect(() => {
        if (jsonTrueChatbot && chatbots.length > 0) {
            const chatbotName = chatbots.find(item => item.id === parseInt(jsonTrueChatbot))?.keyword
            setJsonTrueChatbotName(chatbotName)
        }
    }, [jsonTrueChatbot, chatbots])

    useEffect(() => {
        if (jsonFalseChatbot && chatbots.length > 0) {
            const chatbotName = chatbots?.find(item => item.id === parseInt(jsonFalseChatbot))?.keyword
            setJsonFalseChatbotName(chatbotName)
        }
    }, [jsonFalseChatbot, chatbots])


    const checkSerialNumber = async () => {
        await axios.post(`${api}exist-sr-no`,
            {
                'req_id': id,
                'rq': 'edit',
                'user_id': UserData?.id,
                'sr_no': serialNumber,
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            }).then((res) => {
                if (res?.data?.exists) {
                    message.error('Already another response assigned to this serial number')
                    setError(res.data.exists)
                } else {
                    setError(false)
                }
                //console.log(res.data)
            }).catch((error) => {
            })
    }

    const [typingTimeout, setTypingTimeout] = useState(null);

    useEffect(() => {
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const timeout = setTimeout(() => {
            if (serialNumber) {
                checkSerialNumber();
            }
        }, 500);
        setTypingTimeout(timeout);
        return () => clearTimeout(timeout);
    }, [serialNumber]);


    useEffect(() => {
        if (chatBotType !== 'template') {
            setTemplatesMediaState({
                replyTemplate: false,
                jsonTrueTemplate: false,
                jsonFalseTemplate: false,
            })
            setTemplatesMedia({
                replyTemplate: { url: '', type: '' },
                jsonTrueTemplate: { url: '', type: '' },
                jsonFalseTemplate: { url: '', type: '' },
            })
        }
    }, [chatBotType]);

    const [mediaState, setMediaState] = useState('');
    const showMediaModel = async (MediaState) => {
        setMediaState(MediaState)
        setOpenMediaModel(true);
    };
    const [openMediaModel, setOpenMediaModel] = useState(false);
    const handleCancelMediaModel = () => {
        setOpenMediaModel(false);
    };
    const HandleUploadImage = async (path, name) => {
        setTemplatesMedia(prevState => ({
            ...prevState,
            [mediaState]: {
                ...prevState[mediaState],
                url: path,
                name: name
            }
        }))
        setMediaID(path)
        setMediaCustomName(name)
        handleCancelMediaModel()
    };

    const HandleSubmit = async () => {
        if (error) {
            message.error('Already another response assigned to this serial number')
        }
        if (badges?.length === 0) {
            message.error('Please add at least one keyword')
            return
        }
        else {
            const formData = new FormData();
            // Append all the fields to the FormData object
            formData.append('id', id);
            formData.append('user_id', UserData?.id);
            formData.append('sr_no', serialNumber);
            formData.append('ref_no', timestamp);
            if (parentChatBot) {
                formData.append('parent_id', parentChatBot);
            }
            if (Array.isArray(badges)) {
                badges?.forEach((badge) => {
                    formData.append('keyword[]', badge);
                });
            }
            formData.append('chatbot_type', chatBotType);

            formData.append('header_required', headerRequired === true ? 1 : 0);
            formData.append('header_key', headerKey);
            formData.append('header_value', headerValue);
            formData.append('chatbot_action', chatbotAction);
            formData.append('chatbot_state', chatbotState);
            if (storeKeys?.length > 0) {
                formData.append('store_keys', storeKeys?.map((key) => `"${key}"`).join(','));
            }

            formData.append('reply_template', replyTemplate);
            if (preset) {
                formData.append('preset', preset);
            }
            formData.append('custom_type', customType);
            formData.append('reply_text', replyText);
            formData.append('external_url', externalUrl);
            formData.append('url_action_type', urlActionType);
            formData.append('url_text', urlText);
            formData.append('url_xml', urlXml);
            formData.append('url_json_key', urlJsonKey);

            formData.append('custom_message_type', customMessageType);
            if (mediaID) {
                formData.append('media_id', mediaID);
            }

            formData.append('json_true_key', jsonTrueKey);
            formData.append('json_true_value', jsonTrueValue);
            formData.append('json_true_outgoing_res', jsonTrueOutgoingRes);
            formData.append('json_true_chatbot', jsonTrueChatbot);
            formData.append('json_true_template', jsonTrueTemplate);
            formData.append('json_true_list_key', jsonTrueListItemKey);
            formData.append('json_false_list_key', jsonFalseListItemKey);

            formData.append('json_true_json_res', jsonTrueJsonRes);
            formData.append('json_true_custom_text', jsonTrueCustomText);

            formData.append('json_false_key', jsonFalseKey);
            formData.append('json_false_value', jsonFalseValue);
            formData.append('json_false_outgoing_res', jsonFalseOutgoingRes);
            formData.append('json_false_chatbot', jsonFalseChatbot);
            formData.append('json_false_template', jsonFalseTemplate);

            formData.append('json_false_json_res', jsonFalseJsonRes);
            formData.append('json_false_custom_text', jsonFalseCustomText);

            formData.append('json_true_li_body', jsonTrueLiBody);
            formData.append('json_true_li_header', jsonTrueLiHeader);

            formData.append('json_false_li_header', jsonFalseLiHeader);
            formData.append('json_false_li_body', jsonFalseLiBody);

            if (templatesMedia?.replyTemplate.type !== 'TEXT') {
                formData.append(
                    'reply_template_media',
                    JSON.stringify(templatesMedia.replyTemplate)
                );
            }
            if (templatesMedia?.jsonTrueTemplate.type !== 'TEXT') {
                formData.append(
                    'json_true_template_media',
                    JSON.stringify(templatesMedia.jsonTrueTemplate)
                );
            }
            if (templatesMedia?.jsonFalseTemplate.type !== 'TEXT') {
                formData.append(
                    'json_false_template_media',
                    JSON.stringify(templatesMedia.jsonFalseTemplate)
                );
            }

            if (templatesLanguage?.replyTemplate) {
                formData.append('reply_template_language', templatesLanguage?.replyTemplate);
            }
            if (templatesLanguage?.jsonTrueTemplate) {
                formData.append('json_true_template_language', templatesLanguage?.jsonTrueTemplate);
            }
            if (templatesLanguage?.jsonFalseTemplate) {
                formData.append('json_false_template_language', templatesLanguage?.jsonFalseTemplate);
            }

            formData.append('reply_template_api', templateApis?.chatBotType);
            formData.append('json_true_template_api', templateApis?.jsonTrueOutgoingRes);
            formData.append('json_false_template_api', templateApis?.jsonFalseOutgoingRes);
            formData.append('list_item', listItem);

            await axios.post(`${api}update-chatbot`, formData,
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                }).then((res) => {
                    if (isModal) {
                        CloseModal()
                    } else {
                        message.success('Response Updated Successfully')
                        navigate('/manage-request')
                    }
                }).catch((error) => {
                })
        }
    }


    return (
        <>
            <SelectMediaModel
                handleCancelMediaModel={handleCancelMediaModel}
                HandleUploadImage={HandleUploadImage}
                openMediaModel={openMediaModel}
            />
            <Form
                layout="vertical"
                initialValues={{ size: componentSize }}
                onValuesChange={onFormLayoutChange}
                size={componentSize}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Row gutter={ROW_GUTTER}>
                            <ParentChatbot
                                show={showParentChatbot}
                                parentID={parentChatBot}
                                setParentChatBot={setParentChatBot}
                            />
                            <KeywordsComp
                                badges={badges}
                                setBadges={setBadges}
                                inputValue={inputValue}
                                setInputValue={setInputValue}
                            />
                            <ReplyType setValue={setChatBotType} value={chatBotType} />
                            <ChatbotDataStoreComps
                                chatbotAction={chatbotAction}
                                setChatbotAction={setChatbotAction}
                                chatbotState={chatbotState}
                                setChatbotState={setChatbotState}
                                storeKeys={storeKeys}
                                setStoreKeys={setStoreKeys}
                            />
                            {chatBotType === 'Custom' &&
                                <MessageType value={customMessageType} setValue={setCustomMessageType} />
                            }
                            {chatBotType === 'Custom' &&
                                customMessageType !== 'text' &&
                                <CustomMediaMessage
                                    chatBotType={chatBotType}
                                    customMessageType={customMessageType}
                                    customMediaName={customMediaName}
                                    buttonStyle={buttonStyle}
                                    media={media}
                                    mediaID={mediaID}
                                    getUrlFromMediaId={getUrlFromMediaId}
                                    showMediaModel={showMediaModel}
                                />
                            }
                            {(chatBotType === 'Custom' && customMessageType === 'text') &&
                                <>
                                    <Col xs={24} sm={24} md={12}>
                                        <Form.Item label="Is this callback url?">
                                            <Checkbox onChange={(e) => handleUrl(e)} checked={isUrl}>True</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    {isUrl &&
                                        <HeaderFields
                                            headerRequired={headerRequired}
                                            headerKey={headerKey}
                                            headerValue={headerValue}
                                            setHeaderKey={setHeaderKey}
                                            setHeaderRequired={setHeaderRequired}
                                            setHeaderValue={setHeaderValue}
                                        />
                                    }
                                    <Col xs={24} sm={24} md={24}>
                                        {isUrl ?
                                            <Form.Item label={`External Url`}>
                                                <TextArea rows={4} type="text"
                                                    value={externalUrl}
                                                    onChange={(e) => setExternalUrl(e.target.value)}
                                                />
                                                {
                                                    isUrl &&
                                                    <p style={{ fontSize: '12px' }} className='mt-2'>❕ Make Sure to replace <strong>#number</strong> with your dynamic mobile number variable</p>
                                                }
                                            </Form.Item>
                                            :
                                            <InputWithEmoji
                                                value={replyText}
                                                hideVarBTN={true}
                                                language={'english'}
                                                setValue={setReplyText}
                                            />

                                        }
                                    </Col>

                                    {isUrl &&
                                        <>
                                            <Col xs={24} sm={24} md={24}>
                                                <Form.Item label="Action">
                                                    <Radio.Group onChange={(e) => setUrlActionType(e.target.value)} value={urlActionType}>
                                                        <Radio value='Json' name='res'>Json</Radio>
                                                        <Radio value='Text' name='res'>Custom text</Radio>
                                                        <Radio value='XML' name='res'>XML</Radio>
                                                        <Radio value='Url' name='res'>Url Response</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            {
                                                urlActionType === 'Json' ?
                                                    <>
                                                        {/* for true  */}
                                                        <Col xs={24} sm={24} md={24}>
                                                            <ResponsePanel
                                                                panelTitle="Incoming Json Response 1(Example : true)"
                                                                jsonKey={jsonTrueKey}
                                                                setJsonKey={setJsonTrueKey}
                                                                jsonValue={jsonTrueValue}
                                                                setJsonValue={setJsonTrueValue}
                                                                outgoingRes={jsonTrueOutgoingRes}
                                                                setOutgoingRes={setJsonTrueOutgoingRes}
                                                                template={jsonTrueTemplate}
                                                                setTemplate={setJsonTrueTemplate}
                                                                chatbot={jsonTrueChatbot}
                                                                chatbotName={jsonTrueChatbotName}
                                                                setChatbot={setJsonTrueChatbot}
                                                                customText={jsonTrueCustomText}
                                                                setCustomText={setJsonTrueCustomText}
                                                                jsonRes={jsonTrueJsonRes}
                                                                setJsonRes={setJsonTrueJsonRes}
                                                                templatesMediaState={templatesMediaState}
                                                                templatesMedia={templatesMedia}
                                                                templateList={templateList}
                                                                handleTemplate={handleTemplate}
                                                                showMediaModel={showMediaModel}
                                                                media={media}
                                                                getUrlFromMediaId={getUrlFromMediaId}
                                                                setListItemKey={setJsonTrueListItemKey}
                                                                listItemKey={jsonTrueListItemKey}
                                                                jsonLiHeader={jsonTrueLiHeader}
                                                                jsonLiBody={jsonTrueLiBody}
                                                                setJsonLiHeader={setJsonTrueLiHeader}
                                                                setJsonLiBody={setJsonTrueLiBody}
                                                                responseType="jsonTrue"
                                                            />
                                                        </Col>
                                                        {/* for false */}
                                                        <Col xs={24} sm={24} md={24} className='mt-2'>
                                                            <ResponsePanel
                                                                panelTitle="Incoming Json Response 2(Example : false)"
                                                                jsonKey={jsonFalseKey}
                                                                setJsonKey={setJsonFalseKey}
                                                                jsonValue={jsonFalseValue}
                                                                setJsonValue={setJsonFalseValue}
                                                                outgoingRes={jsonFalseOutgoingRes}
                                                                setOutgoingRes={setJsonFalseOutgoingRes}
                                                                template={jsonFalseTemplate}
                                                                setTemplate={setJsonFalseTemplate}
                                                                chatbot={jsonFalseChatbot}
                                                                chatbotName={jsonFalseChatbotName}
                                                                setChatbot={setJsonFalseChatbot}
                                                                customText={jsonFalseCustomText}
                                                                setCustomText={setJsonFalseCustomText}
                                                                jsonRes={jsonFalseJsonRes}
                                                                setJsonRes={setJsonFalseJsonRes}
                                                                templatesMediaState={templatesMediaState}
                                                                templatesMedia={templatesMedia}
                                                                templateList={templateList}
                                                                handleTemplate={handleTemplate}
                                                                showMediaModel={showMediaModel}
                                                                media={media}
                                                                getUrlFromMediaId={getUrlFromMediaId}
                                                                setListItemKey={setJsonFalseListItemKey}
                                                                listItemKey={jsonFalseListItemKey}
                                                                jsonLiHeader={jsonFalseLiHeader}
                                                                jsonLiBody={jsonFalseLiBody}
                                                                setJsonLiHeader={setJsonFalseLiHeader}
                                                                setJsonLiBody={setJsonFalseLiBody}
                                                                responseType="jsonFalse"
                                                            />
                                                        </Col>
                                                    </>
                                                    :
                                                    <UrlActionInput
                                                        urlActionType={urlActionType}
                                                        urlText={urlText}
                                                        urlXml={urlXml}
                                                        urlJsonKey={urlJsonKey}
                                                        setUrlText={setUrlText}
                                                        setUrlXml={setUrlXml}
                                                        setUrlJsonKey={setUrlJsonKey}
                                                    />
                                            }
                                        </>
                                    }
                                </>
                            }
                            <Col xs={24} sm={24} md={24}>
                                <DynamicForm
                                    chatBotType={chatBotType}
                                    templateList={templateList}
                                    replyTemplate={replyTemplate}
                                    handleTemplate={handleTemplate}
                                    templatesMediaState={templatesMediaState}
                                    templatesMedia={templatesMedia}
                                    showMediaModel={showMediaModel}
                                    buttonStyle={buttonStyle}
                                    toLower={toLower}
                                    getUrlFromMediaId={getUrlFromMediaId}
                                    media={media}
                                    preset={preset}
                                    presetsList={presetsList}
                                    setpreset={setpreset}
                                    setReplyTemplate={setReplyTemplate}
                                    setListItem={setListItem}
                                    listItem={listItem}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24}>
                                <Form.Item className='text-center'>
                                    <Button className='mt-3 px-3' type="primary" onClick={() => HandleSubmit()}>Submit</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default EditChatBotForm
