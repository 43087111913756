import { Button, Card, Col } from 'antd'
import { CloudDownloadOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react'
import { useMyContext } from 'Context/MyContextProvider';
import Flex from 'components/shared-components/Flex';
import ScheduleCampainTable from './ScheduleCampainTable';

const ScheduleCampaign = () => {

    const { Permisson, navigate,HandleExport } = useMyContext();
    useEffect(() => {
        if (!Permisson?.includes('View Campaign History')) {
            navigate('404')
        }
    }, [Permisson]);


    return (
        <Card bodyStyle={{ padding: '0px' }}>
            <div className="container-fluid">
                <Flex justifyContent="space-between" alignItems="center" className="py-4">

                    <h2>Schedule Campaign</h2>
                    <div>
                        <Col span={4}>
                            <div className="mb-3 text-right">
                                <Button icon={<CloudDownloadOutlined />} onClick={() => HandleExport('export-campaign', 'campaigns.xlsx')}>Export Report</Button>
                            </div>
                        </Col>
                    </div>
                </Flex>
            </div>

            <div className="table-responsive">
               
            </div>
            {/* <UserView data={selectedUser} visible={userProfileVisible} close={() => closeUserProfile()} /> */}
            <ScheduleCampainTable />
        </Card >
    )
}

export default ScheduleCampaign
