import React, { useEffect, useState } from 'react';
import { Form, Avatar, Button, Input, Row, Col, Upload, Card, Select, Radio, Alert } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import Flex from 'components/shared-components/Flex'
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';
import { SelectUser } from './CommmonFields';
const { Option } = Select
export const AddUser = () => {
    const { userRole, UserData } = useMyContext();
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const { api, authToken, navigate } = useMyContext();
    const [reportingUser, setReportingUser] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [number, setNumber] = useState('');
    const [password, setPassword] = useState('');
    const [roleId, setRoleId] = useState('');
    const [status, setStatus] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        axios.get(`${api}users`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then(response => {
                setUsers(response.data.user);
                if (userRole === 'Reseller') {
                    setReportingUser(UserData?.id)
                    const roleList = response.data.roles
                    const resellerRoles = roleList?.filter((item) => item?.name !== 'Admin' && item?.name !== userRole);
                    setRoles(resellerRoles)
                } else {
                    setRoles(response.data.roles)
                }
            })
            .catch(error => {
                console.log(error);
            });
    }, [])

    const HandleUserStatus = (e) => {
        setStatus(e.target.value)
    }

    const CreateUser = async (e) => {
        e.preventDefault();

        await axios.post(`${api}create-user`, {
            reportingUser,
            name,
            email,
            number,
            password,
            status,
            companyName,
            role_id: roleId
        }, {
            headers: {
                'Authorization': 'Bearer ' + authToken
            }
        })
            .then((response) => {
                if (response.data.status) {
                    navigate('/users')
                }
            })
            .catch((error) => {
                console.log(error);
                setError(error.response?.data?.message || 'An error occurred while creating the user.');
            });

    }
    return (
        <Row>
            <Col xs={24} sm={24} md={24} lg={16}>
                <Card title="New User">
                {error && (
                        <Alert
                            description={error}
                            type="error"
                            showIcon
                            closable
                            onClose={()=>setError()}
                            className="mb-4"
                        />
                    )}
                    <Flex alignItems="center" mobileFlex={false} className="text-center text-md-left">
                        <Avatar size={90}
                            // src={avatarUrl} 
                            icon={<UserOutlined />} />
                        <div className="ml-3 mt-md-0 mt-3">
                            <Upload
                                // onChange={onUploadAavater} 
                                showUploadList={false}
                            // action={this.avatarEndpoint}
                            >
                                <Button type="primary">Change Avatar</Button>
                            </Upload>
                            <Button className="ml-2"
                            // onClick={onRemoveAvater}
                            >Remove</Button>
                        </div>
                    </Flex>
                    <div className="mt-4">
                        <Form
                            name="basicInformation"
                            layout="vertical"
                        >

                            <Row gutter={ROW_GUTTER}>
                                <Col xs={24} sm={24} md={12}>
                                    <Form.Item
                                        label="Company Name"
                                        name="company_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your username!'
                                            },
                                        ]}
                                    >
                                        <Input onChange={(e) => setCompanyName(e.target.value)} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your name!',
                                            },
                                        ]}
                                    >
                                        <Input onChange={(e) => setName(e.target.value)} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[{
                                            required: true,
                                            type: 'email',
                                            message: 'Please enter a valid email!'
                                        }]}
                                    >
                                        <Input onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Item>
                                </Col>
                                {userRole === 'Admin' &&
                                    <Col xs={24} sm={24} md={12}>
                                        <Form.Item
                                            label="Account Manager"
                                            name="account_manager"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select your Account Manager!',
                                                },
                                            ]}
                                        >
                                            <SelectUser users={users} setUser={setReportingUser} />
                                        </Form.Item>
                                    </Col>
                                }
                                <Col xs={24} sm={24} md={12}>
                                    <Form.Item
                                        label="Phone Number"
                                        name="phoneNumber"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Phone Number!',
                                            },
                                        ]}
                                    >
                                        <Input onChange={(e) => setNumber(e.target.value)} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your password!',
                                            },
                                            {
                                                pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/,
                                                message: 'Password must be at least 8 characters long and contain at least one digit, one lowercase letter, one uppercase letter, and one special character.',
                                            },
                                        ]}
                                    >
                                        <Input onChange={(e) => setPassword(e.target.value)} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <Form.Item
                                        label="User Role"
                                        name="user_role"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select user role!',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            size={'default'}
                                            defaultValue={{ key: "choose..." }}
                                            style={{ width: '100%' }}
                                            onChange={(value) => setRoleId(value)}>
                                            {
                                                roles.length > 0 ?
                                                    roles.map((item, index) =>
                                                        <Option value={item.id} key={index}>{item.name}</Option>
                                                    )
                                                    :
                                                    <Option value="">No Data Found</Option>

                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <Form.Item
                                        label="User Status"
                                        name="user_status"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select user status!',
                                            },
                                        ]}
                                    >
                                        <Radio.Group onChange={HandleUserStatus} value={status}>
                                            <Radio value={'active'}>Active</Radio>
                                            <Radio value={'inactive'}>Inactive</Radio>
                                            {/* <Radio value={'verified'}>Verified</Radio> */}
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className='d-flex justify-content-end'>
                                <Button type="primary" className='mr-2' danger onClick={(e) => navigate('/users')}>
                                    Cancel
                                </Button>
                                <Button type="primary" htmlType="submit" onClick={(e) => CreateUser(e)}>
                                    Save Change
                                </Button>
                            </div>

                        </Form>
                    </div>
                </Card>
            </Col>
        </Row>
    )
}


export default AddUser
