import axios from "axios";
import { toLower } from "lodash";

export const HandleNumberWithCountryCode = (NormalNumber) => {
    const num = parseInt(NormalNumber);
    if (!isNaN(num)) {
        return num?.toString().length === 10 ? '91' + num : num.toString();
    }
    return ""; // Return an empty string or handle errors
};
export const generateMessagePayloads = (
    filteredNumbers, requestType, templateName, templateLanguage,
    template, customMessage, dynamicValues, dynamicButtonUrl,
    customType, mediaLink, isExcelCampaign, importedData
) => {
    const getDynamicValues = (number) => {
        if (isExcelCampaign && Array.isArray(importedData)) {
            const data = importedData.find(elm => 
                elm?.number && HandleNumberWithCountryCode(parseInt(elm?.number)) === HandleNumberWithCountryCode(parseInt(number))
            );
            return data?.value ?? []; // Ensure an empty array is returned if undefined
        }
        return dynamicValues ?? []; // Default to an empty array
    };
    
    return filteredNumbers?.map(item =>
        generatePayload(
            item, requestType, templateName, templateLanguage,
            template, customMessage, getDynamicValues(item),
            dynamicButtonUrl, customType, mediaLink
        )
    ) ?? [];
};


// Create campaign function
export const createCampaign = async (name, userId, templateName, customMessage, authToken, api) => {
    const response = await axios.post(`${api}create-campaign`, {
        name,
        user_id: userId,
        templateName: templateName ? templateName : `Custom Message : ${customMessage}`,
    }, {
        headers: { Authorization: `Bearer ${authToken}` },
    });
    return response.data.campaign;
};

const generatePayload = (item, requestType, templateName, templateLanguage, template, customMessage, dynamicValues, dynamicButtonUrl, customType, mediaLink) => {
    return {
        messaging_product: "whatsapp",
        to: item,
        type: requestType === "template" ? "template" : customType,
        ...(requestType === "template" ? {
            template: {
                name: templateName,
                language: { code: templateLanguage },
                components: [
                    ...(template.header && template.header?.format !== "TEXT" ? [
                        {
                            type: "header",
                            parameters: [
                                {
                                    type: (() => {
                                        switch (template.header?.format) {
                                            case "IMAGE":
                                                return "image";
                                            case "VIDEO":
                                                return "video";
                                            case "DOCUMENT":
                                                return "document";
                                            default:
                                                return "text";
                                        }
                                    })(),
                                    ...generateContent(template.header?.format, mediaLink),
                                },
                            ],
                        },
                    ] : []),
                    {
                        type: "body",
                        parameters: dynamicValues && dynamicValues?.map((item) => ({ type: "text", text: item })),
                    },
                    ...(template?.buttons?.buttons.length < 3 && template?.buttons && template?.buttons?.buttons[0] && dynamicButtonUrl?.length ? [
                        {
                            type: "button",
                            sub_type: (() => {
                                switch (template?.buttons?.buttons[0].type) {
                                    case "QUICK_REPLY":
                                        return "quick_reply";
                                    case "URL":
                                        return "url";
                                    case "PHONE_NUMBER":
                                        return "url";
                                    default:
                                        return "";
                                }
                            })(),
                            ...(template?.buttons?.buttons[0].type === 'URL' && template?.buttons?.buttons[0]?.example ? {
                                index: 0
                            } : {
                                index: 1
                            }),
                            ...(template?.buttons?.buttons[0]?.example && {
                                parameters: (() => {
                                    switch (template?.buttons?.buttons[0].type) {
                                        case "QUICK_REPLY":
                                            return [{ type: "payload", payload: "btntwo" }];
                                        case "URL":
                                            return dynamicButtonUrl.map((item) => ({ type: "text", text: item }));
                                        default:
                                            return [];
                                    }
                                })()
                            }),
                        },
                    ] : []),
                ]
            },
        } :
            {
                ...(customType === 'text'
                    ? { [customType]: { preview_url: false, body: customMessage } }
                    : { [customType]: { id: customMessage } })
            }
        )
    }
};

// send message code start from here 
const generateContent = (type, mediaLink) => {
    switch (type) {
        case 'IMAGE':
            return { image: { id: mediaLink } };
        case 'VIDEO':
            return { video: { id: mediaLink } };
        case 'DOCUMENT':
            return { document: { id: mediaLink } };
        default:
            return {};
    }
};

// Function to handle the rate-limited message sending
export const sendMessageWithRateLimit = async (messagePayloads, messagesApi, waToken,
    campaignData, api, authToken, setProgress, setIsSending, setLoading, templateCategory) => {
    const customRateLimit = 20;
    const interval = 1000 / customRateLimit;
    const reportData = [];
    const totalMessages = messagePayloads.length;
    let sentMessages = 0;
    const sendBatch = async (batch) => {
        await Promise.all(batch.map(async (payload) => {
            const mobileNumber = payload.to;

            try {
                const res = await axios.post(messagesApi, payload, {
                    headers: { Authorization: `Bearer ${waToken}` },
                });

                if (res.data.messages[0].message_status === 'accepted' || res.data.messages[0]?.id) {
                    reportData.push({
                        campaign_id: campaignData.id,
                        message_id: res.data.messages[0].id,
                        mobile_number: mobileNumber,
                    });
                }
            } catch (error) {
                console.error('Error sending message:', error);
            }
            sentMessages++;
            const currentProgress = (sentMessages / totalMessages) * 100;
            setProgress(currentProgress);
        }));
    };

    const processMessages = async () => {
        for (let i = 0; i < messagePayloads.length; i += customRateLimit) {
            const batch = messagePayloads.slice(i, i + customRateLimit);
            await sendBatch(batch);
            await new Promise(resolve => setTimeout(resolve, interval));
        }
        setIsSending(false);
        await storeCampaignReports(reportData, campaignData, api, authToken, setLoading, templateCategory);
    };
    setIsSending(true);
    processMessages();
};

const storeCampaignReports = async (reportData, campaignData, api, authToken, setLoading, templateCategory) => {
    try {
        setLoading(true)
        const response = await axios.post(`${api}create-campaign-report`, {
            campaign_id: campaignData.id,
            reports: reportData,
            template_category: toLower(templateCategory)
        }, {
            headers: { Authorization: `Bearer ${authToken}` },
        });

        if (response.data.status) {
            //console.log('Campaign reports stored successfully:', response.data.message);
        } else {
            //console.error('Failed to store campaign reports:', response.data.message);
        }
    } catch (error) {
        //console.error('Error storing campaign reports:', error);
    } finally {
        setLoading(false)
    }
};