import React, { useEffect, useState } from 'react'
import { Typography, Divider, Card, Button } from "antd";
import { useMyContext } from 'Context/MyContextProvider';
import axios from 'axios';
import jsonFormat from 'json-format';
import Flex from 'components/shared-components/Flex';

const { Title, Paragraph, Text } = Typography;
const Logs = () => {
    const { api, userRole, authToken,navigate } = useMyContext();

    useEffect(() => {
        if (userRole !== 'Admin') {
            navigate('404')
        }
    }, [userRole]);

    const [loading, setLoading] = useState(false)
    const [logs, setLogs] = useState([])
    const GetSettingData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}logs`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
            const data = response.data;
            setLogs((data?.logs).reverse());
            setLoading(false)
        } catch (error) {
            // console.log(error);
        }
    }
    const handleClearLogs = async () => {
        try {
            const response = await axios.delete(`${api}clear-logs`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
            GetSettingData()
        } catch (error) {
            // console.log(error);
        }
    }
    useEffect(() => {
        GetSettingData()
    }, [])

    useEffect(() => {
        console.log(logs[0]?.logs)
    }, [logs])

    return (
        <>
            <Card>
                <Typography>
                    <Flex justifyContent='space-between'>
                        <h4>System Webhook Logs</h4>
                        <Button type="primary" danger onClick={() => handleClearLogs()}>Clear Logs</Button>
                    </Flex>
                </Typography>

                {logs?.map((item) => (
                    <Typography>
                        <Paragraph>
                            <pre className='log-object' style={{ maxHeight: '20rem' }}>
                                {Object?.entries(item?.logs)?.map(([key, value]) => (
                                    <div key={key}>
                                        <span>{key}: </span>
                                        <span>{JSON.stringify(value, null, 2)}</span>
                                    </div>
                                ))}
                            </pre>
                        </Paragraph>

                    </Typography>
                ))}
            </Card>
        </>
    )
}

export default Logs
