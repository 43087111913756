import { message } from 'antd';
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';
import { useState } from 'react';

const useAgentStatus = () => {
    const { userRole, authToken, UserData, api } = useMyContext();
    const [isAlreadyChecked, setIsAlreadyChecked] = useState();
    const updateAgentStatus = async (status) => {
        if (userRole !== 'Support Agent') {
            return;
        }
        if(status === 'online'){
            setIsAlreadyChecked(status === 'online')
        }
        if (status && !isAlreadyChecked) {
            try {
                await axios.post(
                    `${api}support-agent/${UserData?.id}/status`,
                    { status },
                    {
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                        },
                    }
                );
               // message.success(`Status updated to ${status}`);
            } catch (error) {
               // message.error('Failed to change status');
            }
        }
    };

    return { updateAgentStatus };
};

export default useAgentStatus;
