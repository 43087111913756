import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Checkbox, Col, Form, Input, Row, Select, Spin } from 'antd';
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';
import { useLocation } from 'react-router-dom';
import "react-transliterate/dist/index.css";
import { debounce } from 'lodash';
import Flex from 'components/shared-components/Flex';
import TemplateComponent from '../Campaign/TemplateComponent';
import NewMobilePreview from '../Campaign/newMobilePreview';
import CommonNumberField from '../Campaign/CampainComps/CommonNumberField';
import ModeLindex from '../Campaign/CampainComps/ModeLindex';
import { createCampaign, generateMessagePayloads, sendMessageWithRateLimit } from '../CustomUtils.js/CampaignUtils';
import MotionError from '../Campaign/CampainComps/MotionError';
import { HandleDynamicValues, HandleTemplateChange } from '../CustomUtils.js/TemplateUtils';
import { fetchUserStatus, formatNumber, scrollToTop, validateUserAndBalance } from '../Campaign/CampainComps/OtherUtils';
const { Option } = Select;

const ReTagret = () => {

    const { UserData, api, authToken, messagesApi, waToken, templates, Permisson, navigate, userBalance, calculateRequredBalance, buttonStyle } = useMyContext()


    useEffect(() => {
        if (!Permisson?.includes('View Campaign')) {
            navigate('404')
        }
    }, [Permisson]);

    const [templateList, setTemplateList] = useState([]);
    const [componentSize, setComponentSize] = useState('small');
    const [number, setNumber] = useState('');
    const [customMessage, setCustomMessage] = useState('');
    const [numberError, setNumberError] = useState(false);
    const [requestType, setRequestType] = useState('template');
    const [dynamicValues, setDynamicValues] = useState([]);
    const [dynamicButtonUrl, setDynamicUrlButton] = useState([]);
    const [templateName, setTemplateName] = useState('');
    const [newMesaage, setNewMessage] = useState('');
    const [templatePreview, setTemplatePreview] = useState([])
    const [mediaLink, setMediaLink] = useState('')
    const [mediaName, setMediaName] = useState('')
    const [thumbnail, setThumbnail] = useState('')
    const [mediaType, setMediaType] = useState('Media')
    const [template, setTemplate] = useState({
        header: null,
        body: null,
        footer: null,
        buttons: null
    });
    const [templateKey, setTemplateKey] = useState(0);
    const [templateInputs, setTemplateInputs] = useState([]);
    const [templateUrlInputs, setTemplateUrlInputs] = useState([]);
    const [urlButtons, setUrlButtons] = useState([]);
    const [numbers, SetNumbers] = useState([]);
    const [userStatus, setUerStatus] = useState(false)
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [scheduleDate, setScheduleDate] = useState('');
    const [scheduleTime, setScheduleTime] = useState('');
    const [templateCategory, setTemplateCategory] = useState('MARKETING');
    const [blockNumbers, setBlockNumbers] = useState([]);
    const [templateLanguage, setTemplateLanguage] = useState('');
    const [openMediaModel, setOpenMediaModel] = useState(false);
    const [openScheduleModel, setOpenSchedule] = useState(false);
    const [loading, setLoading] = useState(false);

    const GetBlockNumber = async () => {
        try {
            const response = await axios.get(`${api}get-block-numbers/${UserData.id}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            const serverBlocked = response.data.serverBlocked
            const userBlocked = response.data.serverBlocked
            const serverNumbers = serverBlocked.map(item => formatNumber(item.numbers)).filter(Boolean);
            const userNumbers = userBlocked.map(item => formatNumber(item.numbers)).filter(Boolean);
            const total = [...serverNumbers, ...userNumbers];
            setBlockNumbers([...new Set(total)]);
        } catch (error) {
            console.log(error);
        }
    }
    const location = useLocation();
    const { id } = location.state || {};
    const [campaignID, setCampaignID] = useState();

    useEffect(() => {
        GetBlockNumber();
        fetchCampaigns();
        if (id) {
            setCampaignID(id);
        }
    }, []);


    useEffect(() => {
        if (templates.length > 0) {
            const nonCarouselTemplates = templates.filter(template =>
                !template.components?.some(comp => comp.type === 'CAROUSEL')
            );
            setTemplateList(nonCarouselTemplates);
        }
    }, [templates]);

    const onFormLayoutChange = ({ size }) => {
        setComponentSize(size);
    };

    useEffect(() => {
        if (number) {
            setNumberError(false)
        }
    }, [number])

    const HandleTemplate = (value) => {
        HandleTemplateChange(value, setMediaLink, setTemplateName, setTemplateKey, templates, setTemplateCategory, setTemplateLanguage,
            setTemplatePreview, setTemplate, setTemplateInputs, setTemplateUrlInputs, setDynamicValues, setDynamicUrlButton)
    }

    useEffect(() => {
        HandleDynamicValues(templatePreview, dynamicValues, setNewMessage)
    }, [templatePreview, dynamicValues])

    useEffect(() => {
        if (requestType === 'template') {
            setCustomMessage('')
        } else {
            setTemplatePreview([])
            setTemplateInputs([]);
            setDynamicValues([]);
            setTemplateUrlInputs([]);
            setDynamicUrlButton([]);
            setTemplate({
                header: null,
                body: null,
                footer: null,
                buttons: null
            });

        }
    }, [requestType])

    const showModal = async () => {
        setOpen(true);
    };
    const handleCancel = () => {
        setOpen(false);
    };

    // for media model 
    const showMediaModel = async () => {
        setOpenMediaModel(true);
    };
    const handleCancelMediaModel = () => {
        setOpenMediaModel(false);
    };

    // for media model 
    const CreditCalculation = async (numberCount) => {
        try {
            const MP = UserData?.pricing_model?.marketing_price;
            return numberCount * MP >= userBalance
        } catch (error) {
            console.error(error);
        }
    }

    const showScheduleModel = async () => {
        const filteredNumbers = filterNumbers(numbers, blockNumbers);
        let CreditNotAvailable = await CreditCalculation(filteredNumbers?.length)
        if (CreditNotAvailable) {
            showModal()
        } else {
            setOpenSchedule(true);
        }
    };
    const handleCancelScheduleModel = () => {
        setOpenSchedule(false);
    };

    useEffect(() => {
        const filteredList = templates
            ?.filter(item => (!templateCategory || item.category === templateCategory))
            ?.filter(item => !item.components
                ?.some(comp => comp.type === 'CAROUSEL')
            );

        setTemplateList(filteredList);
    }, [templateCategory, templates]);

    const HandleUploadImage = async (path, name) => {
        if (path) {
            setMediaLink(path)
            setMediaName(name)
            setOpenMediaModel(false)
        }
    };

    const HandleScheduleTime = (value) => {
        const { $d } = value;
        const year = $d.getFullYear();
        const month = $d.getMonth() + 1;
        const day = $d.getDate();
        const hours = $d.getHours();
        const minutes = $d.getMinutes();
        // const seconds = $d.getSeconds();
        setScheduleDate(`${year}-${month}-${day}`);
        setScheduleTime(`${hours}:${minutes}:00`);
    }

    const filterNumbers = (numbers, blockNumbers) => {
        const uniqueNumbersSet = [...new Set(numbers)];
        const numbersArray = number.split(',').map(item => item.trim()).flatMap(item => item.split('\n')).map(number => number.trim());
        const combinedNumbers = [...uniqueNumbersSet, ...numbersArray];
        const finalFilteredNumbers = combinedNumbers.filter(number => {
            const num = parseInt(number);
            return !isNaN(num) && (num.toString().length === 10 || num.toString().length === 12);
        }).map(number => {
            const num = parseInt(number);
            return num.toString().length === 10 ? '91' + num : number;
        });
        const filteredNumbers = finalFilteredNumbers.filter(finalNumber => !blockNumbers.includes(finalNumber));
        return filteredNumbers;
    };

    // final submit 
    const HandleSchedule = async () => {
        setLoading(true)
        const filteredNumbers = filterNumbers(numbers, blockNumbers);
        const campaignResponse = await axios.post(`${api}schedule-campaign/${UserData?.id}`, {
            campaignName,
            user_id: UserData.id,
            numbers: filteredNumbers,
            campaign_type: requestType,
            custom_text: customMessage,
            template_name: templateName,
            header_type: template.header?.format,
            header_media_url: mediaLink,
            body_values: dynamicValues,
            button_type: template?.buttons?.buttons[0].type,
            button_value: dynamicButtonUrl,
            schedule_date: scheduleDate,
            schedule_time: scheduleTime,
        }, {
            headers: {
                Authorization: "Bearer " + authToken,
            }
        });
        if (campaignResponse.data.status) {
            navigate('/campaign')
        }
        setLoading(false)
    }

    const [isSending, setIsSending] = useState(true);
    const [progress, setProgress] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);


    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (isSending) {
                e.preventDefault();
                e.returnValue = ''; // For most browsers
                return ''; // For older browsers
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isSending]);

    useEffect(() => {
        const handlePopstate = (e) => {
            if (isSending) {
                e.preventDefault();
            }
        };

        window.addEventListener('popstate', handlePopstate);

        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, [isSending]);

    const resetCampaign = () => {
        setProgress()
        setIsModalVisible(false)
        setNumber('')
    }
    const conicColors = {
        '0%': '#16702F',
        '100%': '#34B55A',
    };

    const handleCustomMedia = (id, name) => {
        setCustomMessage(id)
        setOpenMediaModel(false)
    }

    const validateNumbers = (number, numbers, setError) => {
        if (number || numbers) {
            const inputNumbers = number
                ? number
                    .split(',')
                    .map(item => item.trim())
                    .flatMap(item => item.split('\n').map(subItem => subItem.trim()))
                    .filter(item => item !== '' && (item.length === 10 || item.length === 12))
                : [];

            const total = (numbers?.length || 0) + inputNumbers.length;

            if (total > 5000) {
                setError('You can only process up to 5000 numbers.');
            } else {
                setError('');
            }
        }
    };

    const debouncedValidateNumbers = debounce(validateNumbers, 500);

    useEffect(() => {
        debouncedValidateNumbers(number, numbers, setError);
        return () => {
            debouncedValidateNumbers.cancel();
        };
    }, [number, numbers]);

    const [campaigns, setCampaigns] = useState([])
    const [campaignName, setCampaignName] = useState()
    const fetchCampaigns = async () => {
        setLoading(true);
        const params = {};

        try {
            const response = await axios.get(`${api}campaigns/${UserData?.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                },
                params
            });
            const data = (response.data.campaign).reverse();
            setCampaigns(data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };


    const [statusNumbers, setStatusNumbers] = useState({
        read: 0,
        delivered: 0,
        sent: 0,
        failed: 0,
    });
    const statuses = [
        { label: 'Read', value: 'read', count: statusNumbers?.read?.length || 0 },
        { label: 'Delivered', value: 'delivered', count: statusNumbers?.delivered?.length || 0 },
        { label: 'Sent', value: 'sent', count: statusNumbers?.sent?.length || 0 },
        { label: 'Failed', value: 'failed', count: statusNumbers?.failed?.length || 0 }
    ];

    const plainOptions = statuses.map((status) => ({
        label: (
            <span className="d-flex align-items-center">
                {status.label}
                <Badge
                    count={status.count}
                    style={{
                        backgroundColor: status.value === 'failed' ? '#ff4d4f' : '#52c41a',
                        marginLeft: '8px'
                    }}
                />
            </span>
        ),
        value: status.value,
    }));


    const getCampaignName = (id) => {
        let data = campaigns.find((item) => item?.id === id)
        HandleTemplate(data?.template_name)
        setCampaignName(data?.name + ' - Retarget')
    }

    const fetchCampaignReports = async (id) => {
        getCampaignName(id)
        try {
            setLoading(true)
            const response = await axios.get(`${api}campaign-reports/${id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
            const data = response.data.campaign
            const readNumbers = data.filter(item => item.status === 'read').map(item => item.mobile_number);
            const deliveredNumbers = data.filter(item => item.status === 'delivered').map(item => item.mobile_number);
            const sentNumbers = data.filter(item => item.status === 'sent').map(item => item.mobile_number);
            const failedNumbers = data.filter(item => item.status === 'failed').map(item => item.mobile_number);
            setStatusNumbers({
                read: readNumbers,
                delivered: deliveredNumbers,
                sent: sentNumbers,
                failed: failedNumbers,
            });
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }

    const HandleSetNumber = (id) => {
        fetchCampaignReports(id)
    }


    const onChange = (checkedValues) => {
        let updatedNumbers = [];

        if (checkedValues.includes('read')) {
            updatedNumbers = [...updatedNumbers, ...statusNumbers.read];
        }
        if (checkedValues.includes('delivered')) {
            updatedNumbers = [...updatedNumbers, ...statusNumbers.delivered];
        }
        if (checkedValues.includes('sent')) {
            updatedNumbers = [...updatedNumbers, ...statusNumbers.sent];
        }
        if (checkedValues.includes('failed')) {
            updatedNumbers = [...updatedNumbers, ...statusNumbers.failed];
        }

        SetNumbers(updatedNumbers);
    };

    useEffect(() => {
        if (campaigns.length && campaignID) {
            getCampaignName(campaignID);
            fetchCampaignReports(campaignID);
        }
    }, [campaignID, campaigns]);

    // Input validation function
    const validateInputs = () => {
        if (!requestType || (requestType === 'template' && templateName === '')) {
            return 'You have to select a template first';
        } else if (template.header && template.header.format !== 'TEXT' && !mediaLink) {
            return 'Please Select Media Before Submitting';
        }
        return null;
    };



    const HandleApiTest = async () => {
        const errorMessage = validateInputs();
        if (errorMessage) {
            setError(errorMessage);
            scrollToTop();
            return;
        }

        const filteredNumbers = filterNumbers(numbers, blockNumbers);

        if (filteredNumbers.length > 5000) {
            setError('You can only process up to 5000 numbers.');
            scrollToTop();
            return;
        }
        await validateUserAndBalance(filteredNumbers.length, calculateRequredBalance, templateCategory, showModal)
        try {
            setLoading(true);
            setIsModalVisible(true);

            const userResponse = await fetchUserStatus(UserData.id, api, authToken);
            setUerStatus(userResponse.data.status);

            if (userResponse.data.status === false) {
                showModal();
                return false;
            }
            const campaignData = await createCampaign(campaignName, UserData.id, templateName, customMessage, authToken, api);
            const messagePayloads = generateMessagePayloads(filteredNumbers, 'template', templateName, templateLanguage, template, customMessage, dynamicValues, dynamicButtonUrl, null, mediaLink);
            await sendMessageWithRateLimit(messagePayloads, messagesApi, waToken,
                campaignData, api, authToken, setProgress, setIsSending, setLoading, templateCategory);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            {/* for belance alert  */}
            <ModeLindex
                open={open}
                handleCancel={handleCancel}
                UserData={UserData}
                openScheduleModel={openScheduleModel}
                handleCancelScheduleModel={handleCancelScheduleModel}
                HandleScheduleTime={HandleScheduleTime}
                HandleSchedule={HandleSchedule}
                openMediaModel={openMediaModel}
                handleCancelMediaModel={handleCancelMediaModel}
                HandleUploadImage={HandleUploadImage}
                requestType={requestType}
                handleCustomMedia={handleCustomMedia}
                progress={progress}
                isModalVisible={isModalVisible}
                loading={loading}
                conicColors={conicColors}
                resetCampaign={resetCampaign}
                navigate={navigate}
            />


            <Row gutter={12}>
                {Permisson?.includes('View Campaign') &&
                    <>
                        <Col xs={24} sm={24} md={24} lg={18}>
                            <Card title="Re Target Campaign">
                                <MotionError error={error} />
                                <Col span={24}>
                                    <Form
                                        name="basicInformation"
                                        layout="vertical"
                                        initialValues={{ size: componentSize }}
                                        onValuesChange={onFormLayoutChange}
                                        size={componentSize}
                                    >
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item
                                                    label="Select Campaign"
                                                    name="Campaign"
                                                >
                                                    <Select
                                                        size="default"
                                                        style={{ width: '100%' }}
                                                        onChange={(value) => HandleSetNumber(value)}
                                                        showSearch
                                                        value={campaignID}
                                                    >
                                                        <Option value="">select</Option>
                                                        {campaigns?.length > 0 ? (
                                                            campaigns?.map((item, index) => (
                                                                <Option value={item?.id} key={index}>
                                                                    {item.name}
                                                                </Option>
                                                            ))
                                                        ) : (
                                                            <Option value="">
                                                                You Don't Have Any Approved Templates
                                                            </Option>
                                                        )}
                                                    </Select>
                                                    <span className='d-none'>{campaignID}</span>
                                                </Form.Item>
                                                {loading ?
                                                    <div className="d-flex justify-content-center">
                                                        <Spin />
                                                    </div>
                                                    :
                                                    campaignName &&
                                                    <>
                                                        <Row>
                                                            <Col span={12}>
                                                                <Form.Item label="Select Category">
                                                                    <Checkbox.Group
                                                                        options={plainOptions}
                                                                        onChange={onChange}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    className='m-0'
                                                                    label="Campaign Name"
                                                                    name="Campaign-Name"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please enter campaign name!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input value={campaignName} type="text" onChange={(e) => setCampaignName(e.target.value)} />
                                                                    <p className='d-none'>{campaignName}</p>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <CommonNumberField
                                                            numbers={numbers}
                                                            number={number}
                                                            setNumber={setNumber}
                                                        // groupsKey={groupsKey}
                                                        />
                                                        <TemplateComponent
                                                            templateName={templateName}
                                                            templateCategory={templateCategory}
                                                            setTemplateCategory={setTemplateCategory}
                                                            templateList={templateList}
                                                            HandleTemplate={HandleTemplate}
                                                            template={template}
                                                            mediaType={mediaType}
                                                            showMediaModel={showMediaModel}
                                                            mediaLink={mediaLink}
                                                            buttonStyle={buttonStyle}
                                                            mediaName={mediaName}
                                                            setMediaLink={setMediaLink}
                                                            templateInputs={templateInputs}
                                                            templateUrlInputs={templateUrlInputs}
                                                            HandleApiTest={HandleApiTest}
                                                            showScheduleModel={showScheduleModel}
                                                            loading={loading}
                                                            Permisson={Permisson}
                                                        />
                                                    </>
                                                }

                                                {/* Handling form buttons */}
                                                <Flex alignItems="center" gap={5}>
                                                    {Permisson?.includes('Create Campaign') && (
                                                        <Button
                                                            type="primary"
                                                            htmlType="submit"
                                                            onClick={(e) => HandleApiTest(e)}
                                                            loading={loading}
                                                        >
                                                            Submit
                                                        </Button>
                                                    )}
                                                    {Permisson?.includes('Schedule Campaign') && (
                                                        <Button
                                                            type="primary"
                                                            htmlType="submit"
                                                            onClick={(e) => showScheduleModel(e)}
                                                            loading={loading}
                                                        >
                                                            Schedule Campaign
                                                        </Button>
                                                    )}
                                                </Flex>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>

                            </Card>
                        </Col>
                        {/* mobile preview  */}
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Card title="Campaign Preview">
                                <NewMobilePreview
                                    requestType={requestType}
                                    template={template}
                                    mediaLink={mediaLink}
                                    thumbnail={thumbnail}
                                    newMesaage={newMesaage}
                                    urlButtons={urlButtons}
                                    customMessage={customMessage}
                                />
                            </Card>
                        </Col>
                    </>
                }
            </Row >
        </>
    )
}

export default ReTagret
