import { Input, Row, Col, Card, Form, Upload, Select, Button, message, Divider } from 'antd';
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Flex from 'components/shared-components/Flex';
import { PlusOutlined, CloseOutlined, FileImageOutlined, PlaySquareOutlined, FileTextOutlined, UploadOutlined } from '@ant-design/icons';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useMyContext } from 'Context/MyContextProvider';
import ChatPreview from './newPreview';
import InputWithEmoji from '../CustomUtils.js/InputWithKeyword';

const { Option } = Select;
const AddTemplate = () => {
    const { waToken, temlateSubmitApi, navigate, appId, api } = useMyContext()
    const [templateName, setTemplateName] = useState('')
    const [header, setHeader] = useState('')
    const [body, setBody] = useState('')
    const [footer, setFooter] = useState('')
    const [buttonType, setButtonType] = useState([])
    const [subButtonType, setSubButtonType] = useState('')
    const [media, setMedia] = useState('')
    const [language, setLanguage] = useState('')
    const [phoneNumber, setPhoneNumber] = useState({ button_text: '', number: '' })

    const [offerCode, setOfferCode] = useState({ button_text: '', offer: '' });
    const [visitWebsite1, setVisitWebsite1] = useState({ button_text: '', button_url: '' });
    const [visitWebsite2, setVisitWebsite2] = useState({ button_text: '', button_url: '' });

    const [visitWebsiteCount, setVisitWebsiteCount] = useState(1);
    const [quickReply, setQuickReply] = useState([]);


    const [headerType, setHeaderType] = useState('');
    const [templateType, setTamplateType] = useState('Text');
    const [preview, setPreview] = useState(false);
    const [selected, setSelected] = useState(null);
    const handleClick = (index) => setSelected(index);

    useEffect(() => {
        if (templateName) {
            const newValue = templateName.replace(/\s/g, '_').toLowerCase();
            setTemplateName(newValue);
        }
    }, [templateName])

    const props = {
        beforeUpload: file => {
            uploadFile([file]);
            return false;
        },
    };

    useEffect(() => {
        setBody(body);
    }, [body]);

    const handleAddQuickReply = () => {
        if (quickReply.length < 9) {
            setQuickReply([...quickReply, '']);
        }
    };

    const handleQuickReplyChange = (index, value) => {
        const updatedReplies = quickReply.map((reply, idx) =>
            idx === index ? value : reply
        );
        setQuickReply(updatedReplies);
    };

    const handleRemoveQuickReply = (index) => {
        const updatedReplies = quickReply.filter((_, idx) => idx !== index);
        setQuickReply(updatedReplies);
    };



    const resetButtonFields = (type) => {
        switch (type) {
            case 'visit_website':
                setPhoneNumber({ button_text: '', number: '' });
                setOfferCode({ button_text: '', offer: '' });
                break;
            case 'call_phone_number':
                setVisitWebsite1({ button_text: '', button_url: '' });
                setVisitWebsite2({ button_text: '', button_url: '' });
                setOfferCode({ button_text: '', offer: '' });
                break;
            case 'copy_offer_code':
                setVisitWebsite1({ button_text: '', button_url: '' });
                setVisitWebsite2({ button_text: '', button_url: '' });
                setPhoneNumber({ button_text: '', number: '' });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        resetButtonFields(subButtonType);
    }, [subButtonType]);

    useEffect(() => {
        setHeader('');
    }, [headerType]);


    const shouldShowPreview = () => {
        return header || body || footer || quickReply.length > 0 || visitWebsite1.button_text || phoneNumber.button_text;
    };

    useEffect(() => {
        setPreview(shouldShowPreview());
    }, [header, body, footer, quickReply, visitWebsite1, phoneNumber]);


    const [headerHandle, setHeaderHandle] = useState();
    const [disabled, setDisabled] = useState(false);

    const validateMedia = (file) => {
        const maxSize = {
            'image/jpeg': 5 * 1024 * 1024, // 5MB for images
            'image/png': 5 * 1024 * 1024,  // 5MB for PNG
            'video/3gpp': 16 * 1024 * 1024, // 16MB for 3gp videos
            'video/mp4': 16 * 1024 * 1024,  // 16MB for mp4 videos
            'application/pdf': 100 * 1024 * 1024 // 100MB for PDFs
        };

        const supportedFormats = [
            'image/jpeg',
            'image/png',
            'video/3gpp',
            'video/mp4',
            'application/pdf'
        ];

        // Check if the format is supported
        if (!supportedFormats.includes(file.type)) {
            return { valid: false, message: 'Unsupported file format. Allowed formats: jpg, jpeg, png, 3gp, mp4, pdf.' };
        }

        // Check file size based on type
        if (file.size > maxSize[file.type]) {
            return { valid: false, message: `File too large. Max size for ${file.type} is ${maxSize[file.type] / (1024 * 1024)}MB.` };
        }

        return { valid: true }; // Return true if validation passes
    };

    const uploadFile = async (media) => {
        if (media) {
            setLoading(true)
            const validation = validateMedia(media[0]);
            if (!validation.valid) {
                message.error(validation.message); // Display error message if validation fails
                return;
            }
            const formData = new FormData();
            formData.append('media', media[0]);
            formData.append('waToken', waToken); // Append waToken to form data
            formData.append('appId', appId); // Append appId to form data

            try {
                const response = await axios.post(`${api}upload-session`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                message.success('Header Set Successfully')
                setDisabled(true)
                setHeaderHandle(response.data);
                // Handle the response as needed
            } catch (error) {
                //  console.error('Error uploading file:', error);
            }
            finally {
                setLoading(false)
            }
        } else {
            // console.log('No file selected');
        }
    };

    const [loading, setLoading] = useState(false);

    const handleRemove = () => {
        setDisabled(false)
        setHeaderHandle();
    };
    const [form] = Form.useForm();
    const handleTypeChange = (value) => {
        setButtonType(value); // Update the selected button types

        // Check for removed button types and reset corresponding state and fields
        if (!value.includes('visit_website')) {
            form?.resetFields(['button_1_text', 'button_1_url', 'button_2_text', 'button_2_url']);
            setVisitWebsite1({ button_text: '', button_url: '' });
            setVisitWebsite2({ button_text: '', button_url: '' });
            setVisitWebsiteCount(1); // Reset to 1 button by default
        }

        if (!value.includes('call_phone_number')) {
            form.resetFields(['button_text', 'number']);
            setPhoneNumber({ button_text: '', number: '' });
        }

        if (!value.includes('quick_reply')) {
            setQuickReply([]); // Clear the quick replies
            // Reset any related fields (if needed)
        }
    };

    const HandleVisitwebsiteCount = () => {
        if (visitWebsiteCount === 1) {
            setVisitWebsiteCount(2)
        } else {
            setVisitWebsiteCount(1)
        }
    }

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        if (value.length === 10) {
            setPhoneNumber(prevState => ({ ...prevState, number: `91${value}` })); // Prefix with '91'
        } else {
            setPhoneNumber(prevState => ({ ...prevState, number: value })); // Set as it is
        }
    };

    // submit template 
    const HandleAddTemplate = async () => {
        setLoading(true)
        const templateBodyDynamicValue = body.match(/{{\d+}}/g);
        try {
            const createHeader = () => {
                let headerComponent;

                // If headerType is 'text', use the provided format; otherwise, check `selected`
                if (headerType === 'text') {
                    headerComponent = {
                        "type": "HEADER",
                        "format": "TEXT",
                        "text": header,
                    };
                } else {
                    // Define media type based on the value of `selected`
                    let headerMediaType;
                    switch (selected) {
                        case 1:
                            headerMediaType = "IMAGE";
                            break;
                        case 2:
                            headerMediaType = "VIDEO";
                            break;
                        case 3:
                            headerMediaType = "DOCUMENT";
                            break;
                        default:
                    }

                    headerComponent = {
                        "type": "HEADER",
                        "format": headerMediaType, // Set format based on headerMediaType
                        "example": {
                            "header_handle": [headerHandle.h]
                        }
                    };
                }

                return headerComponent;
            };

            const createBody = () => ({
                type: 'BODY',
                text: body,
                ...(templateBodyDynamicValue?.length > 0 && {
                    example: {
                        body_text: [templateBodyDynamicValue.flatMap((item, index) => 'item' + index)]
                    }
                })
            });

            const createFooter = () => ({ type: 'FOOTER', text: footer });

            const createButtons = () => {
                const buttons = [];

                // Add quick reply buttons if they exist
                if (buttonType.includes('quick_reply') && quickReply?.length > 0) {
                    quickReply.forEach(item => {
                        buttons.push({
                            type: 'QUICK_REPLY',
                            text: item
                        });
                    });
                }

                // Add visit website buttons if they exist
                if (buttonType.includes('visit_website')) {
                    buttons.push({
                        type: 'URL',
                        text: visitWebsite1.button_text,
                        url: visitWebsite1.button_url
                    });

                    // Add second website button if applicable
                    if (visitWebsiteCount === 2) {
                        buttons.push({
                            type: 'URL',
                            text: visitWebsite2.button_text,
                            url: visitWebsite2.button_url
                        });
                    }
                }

                // Add call phone number button if it exists
                if (buttonType.includes('call_phone_number')) {
                    buttons.push({
                        type: 'PHONE_NUMBER',
                        text: phoneNumber.button_text,
                        phone_number: phoneNumber.number
                    });
                }

                return {
                    type: 'BUTTONS',
                    buttons
                };
            };

            const createComponents = () => {
                const components = [];
                // console.log(header);return
                // Add header if header value exists
                if (headerType) {
                    components.push(createHeader());
                }

                // Add body
                if (body) {
                    components.push(createBody());
                }

                // Add footer if footer value exists
                if (footer) {
                    components.push(createFooter());
                }

                // Add buttons if any button type exists
                const buttonsComponent = createButtons();
                if (buttonsComponent.buttons.length > 0) {
                    components.push(buttonsComponent);
                }

                return components;
            };
            console.log(createComponents())
            //return
            await axios.post(temlateSubmitApi, {
                name: templateName,
                language: 'en_US',
                category: templateType,
                components: createComponents()
            }, { headers: { Authorization: 'Bearer ' + waToken } });

            navigate('template')

        } catch (error) {
        } finally {
            setLoading(false)
        }
    }
    return (
        <>
            <Row gutter={ROW_GUTTER}>
                <Col xs={24} sm={24} md={24} lg={18}>
                    <Card title="New Template" style={{ maxHeight: 700, overflow: 'auto' }}>
                        <Form
                            form={form}
                            name="basicInformation"
                            layout="vertical"
                            onFinish={HandleAddTemplate}
                        >
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <Row gutter={ROW_GUTTER}>
                                        <Col xs={24} sm={24} md={6}>
                                            <Form.Item
                                                label="Template type"
                                                name="template_type"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select template type!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    size={'default'}
                                                    // defaultValue={{ key: "choose..." }}
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => setTamplateType(value)}>
                                                    <Option value="MARKETING">MARKETING</Option>
                                                    <Option value="UTILITY">UTILITY</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={6}>
                                            <Form.Item
                                                label="Template name"
                                                name="template_name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your template name!',
                                                    },
                                                ]}
                                            >
                                                <Input onChange={(e) => setTemplateName(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={6}>
                                            <Form.Item
                                                label="Language"
                                                name="language"
                                            >
                                                <Select className='mb-2'
                                                    defaultValue="en"
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => setLanguage(value)}
                                                >
                                                    <Option value="en_US">English</Option>
                                                    <Option value="gu">Gujarati</Option>
                                                    <Option value="hi">Hindi</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        {/* Header type  */}
                                        <Col xs={24} sm={24} md={6}>
                                            <Form.Item
                                                label="Header Type"
                                                name="Header_Type"
                                            >
                                                <Select
                                                    showSearch
                                                    size={'default'}
                                                    defaultValue={{ key: "choose..." }}
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => setHeaderType(value)}>
                                                    <Option value="">None</Option>
                                                    <Option value="text">Text</Option>
                                                    <Option value="Media">Media</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        {/* Header  */}
                                        {
                                            headerType === 'text' ?
                                                <Col xs={24} sm={24} md={24}>
                                                    <Form.Item
                                                        name="Header"
                                                        label="Header"
                                                        rules={[
                                                            { required: true, message: 'Please input header text!' },
                                                            { max: 60, message: 'Maximum 60 characters allowed!' }
                                                        ]}
                                                    >
                                                        <Input onChange={(e) => setHeader(e.target.value)} maxLength={60} />
                                                    </Form.Item>
                                                    <div style={{ textAlign: 'right' }}>{header.length}/60</div>
                                                </Col>
                                                :
                                                headerType === 'Media' &&
                                                <Col xs={24} sm={24} md={24}>
                                                    <Flex gap="25px" className='border rounded p-2'>
                                                        {Array.from({ length: 4 }).map((_, index) => (
                                                            <Flex
                                                                key={index}
                                                                className={`flex-column rounded p-4 ${selected === index + 1 ? 'border' : ''}`}
                                                                style={{ cursor: "pointer", color: selected === index + 1 && '#22af4b', background: selected === index + 1 && '#22AF4B1C' }}
                                                                onClick={() => handleClick(index + 1)}
                                                            >
                                                                {index === 0 && <FileImageOutlined style={{ fontSize: '5rem' }} />}
                                                                {index === 1 && <PlaySquareOutlined style={{ fontSize: '5rem' }} />}
                                                                {index === 2 && <FileTextOutlined style={{ fontSize: '5rem' }} />}
                                                                {/* {index === 3 && <SendOutlined style={{ fontSize: '5rem', rotate: '90deg' }} />} */}
                                                                <h4 className='text-center'>{['Image', 'Video', 'Document'][index]}</h4>
                                                            </Flex>
                                                        ))}
                                                    </Flex>
                                                </Col>
                                        }
                                        {headerType === 'Media' &&
                                            selected === 1 ?
                                            <Col xs={24} sm={24} md={24}>
                                                <div className='mt-2 rounded p-3' style={{ background: '#eee' }}>
                                                    <Flex className='flex-column mb-2'>
                                                        <h3>Samples for header content</h3>
                                                        <span>To help us review your content, provide examples of the variables or media in the header. Do not include any customer information. Cloud API hosted by Meta reviews templates and variable parameters to protect the security and integrity of our services.</span>
                                                    </Flex>
                                                    <Upload
                                                        accept=".jpg,.jpeg,.png,.pdf,.xls,.xlsx"
                                                        {...props} onRemove={handleRemove}>
                                                        <Button icon={<UploadOutlined />} disabled={disabled} loading={loading}>Click to Upload</Button>
                                                    </Upload>
                                                </div>
                                            </Col>
                                            : selected === 2 ?
                                                <Col xs={24} sm={24} md={24}>
                                                    <div className='mt-2 rounded p-3' style={{ background: '#eee' }}>
                                                        <Flex className='flex-column mb-2'>
                                                            <h3>Samples for header content</h3>
                                                            <span>To help us review your content, provide examples of the variables or media in the header. Do not include any customer information. Cloud API hosted by Meta reviews templates and variable parameters to protect the security and integrity of our services.</span>
                                                        </Flex>
                                                        <Upload {...props} onRemove={handleRemove}>
                                                            <Button icon={<UploadOutlined />} disabled={disabled} loading={loading}>Click to Upload</Button>
                                                        </Upload>
                                                    </div>
                                                </Col>
                                                : selected === 3 &&
                                                <Col xs={24} sm={24} md={24}>
                                                    <div className='mt-2 rounded p-3' style={{ background: '#eee' }}>
                                                        <Flex className='flex-column mb-2'>
                                                            <h3>Samples for header content</h3>
                                                            <span>To help us review your content, provide examples of the variables or media in the header. Do not include any customer information. Cloud API hosted by Meta reviews templates and variable parameters to protect the security and integrity of our services.</span>
                                                        </Flex>
                                                        <Upload {...props} onRemove={handleRemove}>
                                                            <Button icon={<UploadOutlined />} disabled={disabled} loading={loading}>Click to Upload</Button>
                                                        </Upload>
                                                    </div>
                                                </Col>
                                        }
                                        <Col xs={24} sm={24} md={24}>
                                            <InputWithEmoji
                                                value={body}
                                                language={language}
                                                setValue={setBody}
                                            />
                                        </Col>
                                        <Col xs={24} sm={24} md={24}>
                                            <Form.Item
                                                label="Footer"
                                                name="Footer"
                                            >
                                                <Input onChange={(e) => setFooter(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        {/* <Col xs={24} sm={24} md={8}>
                                            <Form.Item label="Button Type" name="Button_Type">
                                                <Select
                                                    mode="multiple"
                                                    placeholder="Outlined"
                                                    onChange={(value) => handleButtonType(value)}
                                                    style={{
                                                        flex: 1,
                                                    }}
                                                    options={[
                                                        {
                                                            value: 'quick_reply',
                                                            label: 'Quick Reply',
                                                        },
                                                        {
                                                            value: 'call_to_action',
                                                            label: 'Call To Action',
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Col> */}
                                        <Col xs={24} sm={24} md={24}>
                                            <Form.Item label="Button Type" name="Button_Type">
                                                <Select
                                                    mode="multiple"
                                                    placeholder="Select Button Type"
                                                    onChange={(value) => handleTypeChange(value)}
                                                    style={{ flex: 1 }}
                                                    options={[
                                                        {
                                                            value: 'visit_website',
                                                            label: 'Visit Website',
                                                        },
                                                        {
                                                            value: 'call_phone_number',
                                                            label: 'Call Phone Number',
                                                        },
                                                        {
                                                            value: 'quick_reply',
                                                            label: 'Quick Reply',
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Col>

                                        {buttonType?.includes('quick_reply') && (
                                            <>
                                                <Divider variant="dotted" className='my-1'>Quick Reply</Divider>
                                                <Card className="w-100 bg-gray-lightest">
                                                    <Row>
                                                        {quickReply?.length > 0 &&
                                                            quickReply?.map((reply, index) => (
                                                                <Col xs={24} sm={24} md={10} key={index}>
                                                                    <Form.Item
                                                                        label={`Button Text ${index + 1}`}
                                                                        name={`quick_reply_${index}`}
                                                                        rules={[{ required: true, message: 'Please enter a valid button text!' }]}
                                                                    >
                                                                        <Flex>
                                                                            <Input
                                                                                placeholder={`Button Text ${index + 1}`}
                                                                                maxLength={25}
                                                                                onChange={(e) => handleQuickReplyChange(index, e.target.value)}
                                                                            />
                                                                            <Button
                                                                                type="link"
                                                                                danger
                                                                                onClick={() => handleRemoveQuickReply(index)}
                                                                            >
                                                                                X
                                                                            </Button>
                                                                        </Flex>
                                                                    </Form.Item>
                                                                </Col>
                                                            ))
                                                        }
                                                        <Col xs={24} sm={24} md={2}>
                                                            <Form.Item
                                                                label="Add More"
                                                                name="Button_Type"
                                                                className="cursor-pointer"
                                                            >
                                                                <span className="border rounded p-2" onClick={handleAddQuickReply}>
                                                                    <PlusOutlined />
                                                                </span>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </>
                                        )}

                                        {buttonType?.includes('visit_website') && (
                                            <>
                                                <Divider variant="dotted" className='my-1'>Visit Website</Divider>
                                                <Card className="w-100 bg-gray-lightest">
                                                    <Row gutter={ROW_GUTTER}>
                                                        <Col xs={24} sm={24} md={10}>
                                                            <Form.Item
                                                                label="Button 1 Text"
                                                                name="button_1_text"
                                                                rules={[{ required: true, message: 'Please enter a valid button text!' }]}
                                                            >
                                                                <Input
                                                                    maxLength={25}
                                                                    value={visitWebsite1.button_text}
                                                                    onChange={(e) => setVisitWebsite1(prevState => ({ ...prevState, button_text: e.target.value }))}
                                                                />

                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={10}>
                                                            <Form.Item
                                                                label="Button 1 Url"
                                                                name="button_1_url"
                                                                rules={[{ required: true, message: 'Please enter a valid URL!' }]}
                                                            >
                                                                <Input
                                                                    value={visitWebsite1.button_url}
                                                                    onChange={(e) => setVisitWebsite1(prevState => ({ ...prevState, button_url: e.target.value }))}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        {visitWebsiteCount === 2 && (
                                                            <>
                                                                <Col xs={24} sm={24} md={10}>
                                                                    <Form.Item
                                                                        label="Button 2 Text"
                                                                        name="button_2_text"
                                                                        rules={[{ required: true, message: 'Please enter a valid button text!' }]}
                                                                    >
                                                                        <Input
                                                                            value={visitWebsite2.button_text}
                                                                            maxLength={25}
                                                                            onChange={(e) => setVisitWebsite2(prevState => ({ ...prevState, button_text: e.target.value }))} />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} sm={24} md={10}>
                                                                    <Form.Item
                                                                        label="Button 2 Url"
                                                                        name="button_2_url"
                                                                        rules={[{ required: true, message: 'Please enter a valid URL!' }]}
                                                                    >
                                                                        <Input
                                                                            value={visitWebsite2.button_url}
                                                                            onChange={(e) => setVisitWebsite2(prevState => ({ ...prevState, button_url: e.target.value }))} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </>
                                                        )}
                                                        <Col xs={24} sm={24} md={2}>
                                                            <Form.Item
                                                                label={visitWebsiteCount === 1 ? "Add" : 'Remove'}
                                                                name="Button_Type"
                                                                className='cursor-pointer'
                                                            >
                                                                <span className='border rounded-circle p-2' onClick={() => HandleVisitwebsiteCount()}>
                                                                    {visitWebsiteCount === 1 ? <PlusOutlined /> : <CloseOutlined />}
                                                                </span>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </>
                                        )}
                                        {buttonType?.includes('call_phone_number') && (
                                            <>
                                                <Divider variant="dotted" className='my-1'>Call Phone Number</Divider>
                                                <Card className="w-100 bg-gray-lightest">
                                                    <Row gutter={ROW_GUTTER}>
                                                        <Col xs={24} sm={24} md={12}>
                                                            <Form.Item
                                                                label="Button Text"
                                                                name="button_text"
                                                                rules={[{ required: true, message: 'Please enter a valid button text!' }]}
                                                            >
                                                                <Input
                                                                    maxLength={25}
                                                                    value={phoneNumber.button_text}
                                                                    onChange={(e) => setPhoneNumber(prevState => ({ ...prevState, button_text: e.target.value }))} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={12}>
                                                            <Form.Item
                                                                label="Number (Country code is mandatory)"
                                                                name="number"
                                                                rules={[{ required: true, message: 'Please enter a valid number!' }]}
                                                            >
                                                                <Input
                                                                    type='number'
                                                                    maxLength={12}
                                                                    value={phoneNumber.number}
                                                                    onChange={handlePhoneNumberChange}
                                                                // onChange={(e) => setPhoneNumber(prevState => ({ ...prevState, number: e.target.value }))}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </>
                                        )}

                                    </Row>
                                    <div className='d-flex justify-content-end'>
                                        <Button type="primary" className='mr-2' danger
                                        >
                                            Cancel
                                        </Button>
                                        <Button type="primary" htmlType="submit"
                                            disabled={loading}
                                            loading={loading}
                                        //onClick={() => HandleAddTemplate()} 
                                        >
                                            Submit
                                        </Button>
                                        <span className='d-none'>{visitWebsite1 + visitWebsite2 + phoneNumber}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6}>
                    <ChatPreview
                        headerType={headerType}
                        selected={selected}
                        header={header}
                        body={body}
                        footer={footer}
                        media={media}

                        buttonType={buttonType}
                        quickReply={quickReply}

                        subButtonType={subButtonType}
                        visitWebsiteCount={visitWebsiteCount}

                        phoneNumber={phoneNumber}
                        visitWebsite1={visitWebsite1}
                        visitWebsite2={visitWebsite2}
                        preview={preview}
                    />
                </Col>
            </Row >

        </>
    )



}

export default AddTemplate
