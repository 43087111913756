import { message } from "antd";
import axios from "axios";
import { toLower } from "lodash";

export const fetchUserStatus = async (userId, api, authToken) => {
    return await axios.get(`${api}chek-user/${userId}`, {
        headers: { 'Authorization': `Bearer ${authToken}` },
    });
};
export const formatNumber = (number) => {
    const digits = number.toString();
    if (digits.length === 12) {
        return digits;
    } else if (digits.length === 10) {
        return '91' + digits;
    } else {
        return null;
    }
};
export const filterNumbers = (number, numbers, blockNumbers) => {
    const uniqueNumbersSet = [...new Set(numbers)];
    const numbersArray = number?.split(',').map(item => item.trim()).flatMap(item => item.split('\n')).map(number => number.trim());
    const combinedNumbers = [...uniqueNumbersSet, ...numbersArray];
    const finalFilteredNumbers = combinedNumbers?.filter(number => {
        const num = parseInt(number);
        return !isNaN(num) && (num.toString().length === 10 || num.toString().length === 12);
    }).map(number => {
        const num = parseInt(number);
        return num.toString().length === 10 ? '91' + num : number;
    });
    const filteredNumbers = finalFilteredNumbers?.filter(finalNumber => !blockNumbers.includes(finalNumber));
    return filteredNumbers;
};

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scrolling
    });
};
export const validateUserAndBalance = async (numbersLength, calculateRequredBalance, templateCategory, showModal) => {
    const isEnoughBalance = calculateRequredBalance(numbersLength, toLower(templateCategory));
    if (isEnoughBalance === false) {
        showModal()
        message.error('Not enough balance in your account');
        return false;
    }
    return true;
};