import React, { useEffect, useState } from 'react'
import { Collapse, Row, Table, Tooltip } from 'antd'
import InsideChatbots from '../Compoenents/InsideChatbots'
import { Chatbots } from '../ChatBotUtils/ChatbotListApi'
import { useMyContext } from 'Context/MyContextProvider'
const { Panel } = Collapse;

const Variables = () => {
    const tableData = [
        {
            variable: '#number',
            description: 'Mobile Number',
            action: 'Edit',
            value: '', // dynamic mobile number
        },
        {
            variable: ':acn',
            description: 'Account Number',
            action: 'Edit',
            value: '', // dynamic account number
        },
        {
            variable: ':otp',
            description: 'One-Time Password',
            action: 'Edit',
            value: '', // dynamic OTP
        },
        {
            variable: ':ClientID:',
            description: 'Client Id',
            action: 'Edit',
            value: '', // dynamic option
        },
        {
            variable: ':option',
            description: 'Option',
            action: 'Edit',
            value: '', // dynamic option
        },
        {
            variable: ':chq_no',
            description: 'Cheque Number',
            action: 'Edit',
            value: '', // dynamic cheque number
        },
        {
            variable: ':from',
            description: 'Start Date',
            action: 'Edit',
            value: '', // dynamic start date
        },
        {
            variable: ':to',
            description: 'End Date',
            action: 'Edit',
            value: '', // dynamic end date
        },
    ];

    const tableColumns = [
        {
            title: 'Variable',
            dataIndex: 'variable',
            key: 'variable',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        // {
        //     title: 'Action',
        //     dataIndex: 'action',
        //     key: 'action',
        //     render: (text, record) => (
        //         <Tooltip title="View Detail">
        //             <InfoCircleOutlined style={{ fontSize: 16, cursor: 'pointer' }} />
        //         </Tooltip>
        //     ),
        // },
    ];
    return (
        <Table columns={tableColumns} dataSource={tableData} />
    );

}

const ChatbotInfo = ({id}) => {
    const { authToken, api } = useMyContext()
    const [chatbots, setChatbots] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        Chatbots(setLoading, api, id, authToken, setChatbots);
    }, [])

    return (
        <>
            <Collapse defaultActiveKey={['1']} className='mb-2 bg-white'>
                <Panel header={'Variables'} key="1">
                        <Variables />
                </Panel>
            </Collapse>
            <Collapse defaultActiveKey={[]} className='mb-2 bg-white'>
                <Panel header={'Existing Requests'} key="1">
                        <InsideChatbots chatbots={chatbots} loading={loading} />
                </Panel>
            </Collapse>
        </>
    )
}

export default ChatbotInfo
