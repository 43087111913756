import { Card, Form, Button, Row, Col, Modal, Typography, Spin, Image, Progress, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { LoadingOutlined, DoubleRightOutlined } from '@ant-design/icons';
import Flex from 'components/shared-components/Flex';
import { UserSelectField } from '../../CustomUtils.js/CommonFieldUtils';
import { useMyContext } from 'Context/MyContextProvider';
import axios from 'axios';
const { Text } = Typography;

const ChatbotTransfer = () => {
    const { api, UserList, authToken, UserData } = useMyContext();
    const [form] = Form.useForm();
    const [users, setUsers] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
    const [isTransferring, setIsTransferring] = useState(false);
    const [selectedFromUser, setSelectedFromUser] = useState(null);
    const [selectedToUser, setSelectedToUser] = useState(null);
    const [progress, setProgress] = useState(0);
    const [isTransferComplete, setIsTransferComplete] = useState(false); // Track if the transfer is complete
    const [password, setPassword] = useState('');

    const onFinish = (values) => {
        setIsPasswordModalVisible(true);
    };
    useEffect(() => {
        setUsers(UserList);
    }, [UserList]);

    const handleTransfer = async () => {
        setPassword('')
        setIsTransferring(true);
        setProgress(0);

        try {
            const response = await axios.post(`${api}chatbot/copy-requests`, {
                from_user_id: selectedFromUser,
                to_user_id: selectedToUser,
            }, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                }
            });

            // Simulating real-time progress updates
            const totalSteps = response.data.totalSteps || 10; // Assume 10 steps by default
            let currentStep = 0;

            const interval = setInterval(() => {
                currentStep++;

                const calculatedProgress = Math.floor((currentStep / totalSteps) * 100);
                setProgress(calculatedProgress);

                if (calculatedProgress >= 100) {
                    clearInterval(interval);
                    setTimeout(() => {
                        setIsTransferring(false);
                        setIsTransferComplete(true);
                    }, 500);
                }
            }, 500); // Update interval (500ms)
        } catch (error) {
            console.error("Transfer failed", error);
            setIsTransferring(false);
        }
    };

    const handleCancel = () => {
        setPassword('')
        setIsModalVisible(false); // Close the modal
    };

    const handleFromUserChange = (value) => {
        setSelectedFromUser(value);
    };

    const handleToUserChange = (value) => {
        setSelectedToUser(value);
    };

    const handlePasswordSubmit = async () => {
        if (password) {
            await axios.post(`${api}chek-password`, {
                id: UserData?.id,
                password
            }, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            })
                .then((response) => {
                    console.log(response)
                    setIsPasswordModalVisible(false);
                    setIsModalVisible(true);
                })
                .catch((error) => {
                    Modal.error({
                        title: 'Invalid Password',
                        content: 'The password you entered is incorrect. Please try again.',
                    });
                });
        }
    }
    const getUserName = (id) => {
        return UserList?.find((user) => user?.id === id)?.company_name || "Unknown";
    };
    return (
        <>
            {/* Modal for password input */}
            <Modal
                title="Confirm Admin Password"
                open={isPasswordModalVisible}
                destroyOnClose
                onCancel={() => { setIsPasswordModalVisible(false); setPassword('') }}
                footer={[
                    <Button key="cancel" onClick={() => { setIsPasswordModalVisible(false); setPassword('') }}>
                        Cancel
                    </Button>,
                    <Button key="confirm" type="primary" onClick={handlePasswordSubmit}>
                        Confirm Password
                    </Button>,
                ]}
            >
                <Input.Password
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                />
            </Modal>
            {/* Modal for confirming the transfer */}
            <Modal
                title="Confirm Chatbot Transfer"
                open={isModalVisible}
                onCancel={handleCancel}
                width={600}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button
                        key="transfer"
                        type="primary"
                        onClick={isTransferComplete ? () => setIsModalVisible(false) : handleTransfer}
                        disabled={isTransferring}
                    >
                        {isTransferring ? (
                            <Spin indicator={<LoadingOutlined />} />
                        ) : isTransferComplete ? (
                            "Transfer Completed"
                        ) : (
                            "Confirm Transfer"
                        )}
                    </Button>

                ]}
            >
                <Image src="img/others/chatbot-transfer.gif" preview={false} />
                <div style={{ textAlign: 'center' }}>
                    {isTransferring ? (
                        <>
                            <Progress percent={progress} />
                            <Text type="secondary">Transferring chatbot...</Text>
                            <Text> Please wait, transfer chatbot under process.</Text>
                        </>
                    ) : isTransferComplete ? (
                        <Flex flexDirection="column">
                            <Text type="success">Chatbot transfer completed successfully!</Text>
                            <Text>Your chatbot has been successfully transferred.</Text>
                        </Flex>
                    ) : (
                        <>
                            <p>
                                <Text strong>Transfer Chatbot</Text> from{' '}
                                <Text type="danger">{getUserName(selectedFromUser)}</Text> to{' '}
                                <Text type="success">{getUserName(selectedToUser)}</Text>.
                            </p>
                            <Text>Please confirm to proceed with the transfer.</Text>
                        </>
                    )}
                </div>
            </Modal>
            <Card title="Chatbot Transfer">
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Row gutter={16} align="middle" justify="center">
                        <Col xs={24} sm={10} lg={9}>
                            <Form.Item
                                name="fromUser"
                                label="From"
                            >
                                <UserSelectField
                                    users={users?.filter((user) => user?.id !== selectedToUser)}
                                    HandleUserChange={handleFromUserChange} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={4} lg={2} style={{ textAlign: 'center' }}>
                            <DoubleRightOutlined style={{ fontSize: '24px', marginTop: '30px' }} />
                        </Col>

                        <Col xs={24} sm={10} lg={9}>
                            <Form.Item
                                name="toUser"
                                label="To"
                            >
                                <UserSelectField
                                    users={users?.filter((user) => user?.id !== selectedFromUser)}
                                    HandleUserChange={handleToUserChange} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify="center">
                        <Col>
                            <Button type="primary" htmlType="submit">
                                Transfer
                            </Button>
                        </Col>
                    </Row>
                </Form>


            </Card>
        </>
    );
};

export default ChatbotTransfer;
