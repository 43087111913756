import React, { useState, memo, useEffect } from 'react';
import { Avatar, Col, Dropdown, Menu, message, Modal, Radio, Row, Tag, Tooltip } from 'antd';
import { ReloadOutlined, UserOutlined, SwapOutlined, MoreOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getColorForName, getInitials } from '../Conversation';
import Flex from 'components/shared-components/Flex';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useMyContext } from 'Context/MyContextProvider';
import ProfileModel from '../OtherChatComps/ProfileModel';
import { Link } from 'react-router-dom';
import { ExportTimer } from '../OtherChatComps/ChatExpireTimer';
// import { ExportTimer } from '../OtherChatComps/ChatExpireTimer';

const ChatMenu = (props) => {
    const { supportAgents, activeAgent, visible, toggleModal, wa_id, displayPhoneNumber, authToken, api, getChats } = props;
    const [selectedAgent, setSelectedAgent] = useState();
    const [sortedAgents, setSortedAgents] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const activeAgentId = activeAgent?.agent_id;
        const filteredAgents = supportAgents?.filter(agent => agent?.id !== activeAgentId);
        const findActive = supportAgents?.find(agent => agent?.id === activeAgentId);
        const sortedAgents = activeAgentId ? [findActive, ...filteredAgents] : filteredAgents;
        setSortedAgents(sortedAgents)
        setSelectedAgent(activeAgent?.agent_id || null)
    }, [activeAgent]);


    const onAssignChat = async () => {
        try {
            const response = await axios.post(
                api + 'support-agent/report/' + activeAgent?.id,
                {
                    agent_id: selectedAgent,
                    display_phone_number: displayPhoneNumber,
                    wa_id: wa_id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.data?.status) {
                getChats(wa_id);
                toggleModal()
            }
            message.success('Assign Chat To This Agent Successfully');
        } catch (error) {
            message.error(error.response.data.message || 'Error updating report');
        } finally {
            // message.error(false);
        }
    };

    const activeDays = (data) => {
        return data?.split(',').map(day => day.trim()) || [];
    }

    return (
        <>
            <Modal
                width={600}
                title="Assign Chat To Support Agent"
                open={visible}
                onCancel={toggleModal}
                onOk={onAssignChat}
                okText="Assign"
                okButtonProps={{ loading }}
            >
                <div className="h-50 overflow-auto">
                    <Radio.Group
                        value={selectedAgent}
                        onChange={(e) => setSelectedAgent(e.target.value)}
                        style={{ width: '100%' }}
                    >
                        {sortedAgents?.map((agent) => (
                            <Row
                                key={agent?.id}
                                style={{
                                    borderRadius: '15px',
                                    background: selectedAgent === agent?.id && 'rgba(227, 227, 227,1)',
                                    padding: '12px 10px',
                                    borderBottom: '1px solid #f0f0f0',
                                    alignItems: 'center'
                                }}
                            >
                                <Col flex="auto">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar
                                            style={{
                                                backgroundColor: getColorForName(agent?.name),
                                                marginRight: 12
                                            }}
                                        >
                                            {getInitials(agent?.name)}
                                        </Avatar>
                                        <Flex flexDirection="column" style={{ flexWrap: 'wrap', gap: '4px' }}>
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <span
                                                    style={{
                                                        width: '10px',
                                                        height: '10px',
                                                        borderRadius: '50%',
                                                        backgroundColor: agent?.support_agent?.online === 1 ? 'green' : 'red',
                                                        marginRight: '5px'
                                                    }}
                                                />
                                                {agent?.name}
                                            </span>
                                            <Flex style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', maxWidth: '100%' }}>
                                                {activeDays(agent?.support_agent?.working_days)?.map((day) => (
                                                    <Tag
                                                        key={day}
                                                        color="blue"
                                                        style={{
                                                            borderRadius: '12px',
                                                            padding: '0 8px',
                                                            margin: 0,
                                                            fontSize: '11px',
                                                            lineHeight: '16px',
                                                        }}
                                                    >
                                                        {day}
                                                    </Tag>
                                                ))}
                                            </Flex>
                                        </Flex>
                                    </div>
                                </Col>
                                <Col>
                                    <Radio value={agent?.id} />
                                </Col>
                            </Row>
                        ))}
                    </Radio.Group>
                </div>
            </Modal>
        </>
    );
};

const ChatContentHeader = memo((props) => {
    const { isMobile, UserData } = useMyContext()
    const { messageSentTime, name, number, onRefresh, supportAgents, activeAgent, isSupportAgent, wa_id, displayPhoneNumber, authToken, api, getChats } = props
    const [visible, setVisible] = useState(false);
    const agentData = supportAgents?.find((active) => active?.id === activeAgent?.agent_id)
    const toggleModal = () => {
        setVisible(!visible)
    }

    const menu = (
        <Menu>
            <Menu.Item key="refresh" onClick={onRefresh}>
                <ReloadOutlined /> Refresh Chat
            </Menu.Item>
            <Menu.Item key="assign" onClick={toggleModal}>
                <UserOutlined /> Assign Chat
            </Menu.Item>
        </Menu>
    );

    const [profileModalVisible, setProfileModalVisible] = useState(false);
    const toggleProfileModal = () => {
        setProfileModalVisible(!profileModalVisible);
    };
    return (
        <>
            <ProfileModel
                name={name}
                api={api}
                token={authToken}
                parent={UserData?.whatsapp_number}
                number={number}
                show={profileModalVisible}
                toggleProfileModal={toggleProfileModal}
            />
            <ChatMenu
                onRefresh={onRefresh}
                supportAgents={supportAgents}
                activeAgent={activeAgent}
                visible={visible}
                toggleModal={toggleModal}
                wa_id={wa_id}
                api={api}
                getChats={getChats}
                authToken={authToken}
                displayPhoneNumber={displayPhoneNumber}
            />
            <div className="chat-content-header">
                <Row align="middle" gutter={ROW_GUTTER} className='w-100'>
                    <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                        <div className="d-flex align-items-center">
                            <Link className="profile-name d-flex align-items-center" onClick={toggleProfileModal}>
                                <Avatar style={{ backgroundColor: getColorForName(name), marginRight: 12 }}>
                                    {getInitials(name)}
                                </Avatar>
                                {!isMobile &&
                                    <div>
                                        <h4 className="mb-0">{name}</h4>
                                        <p style={{ fontSize: '0.8rem' }} className="mb-0">{number}</p>
                                    </div>
                                }
                            </Link>
                            {!isSupportAgent && agentData && (
                                <div className="d-flex align-items-center gap-2">
                                    {/* {!isMobile && <SwapOutlined />} */}
                                    <SwapOutlined />
                                    <div className="cursor-pointer" onClick={toggleModal}>
                                        <strong>{agentData?.name}</strong>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span
                                                style={{
                                                    width: '10px',
                                                    height: '10px',
                                                    borderRadius: '50%',
                                                    backgroundColor: agentData?.support_agent?.online === 1 ? 'green' : 'red',
                                                    marginRight: '5px'
                                                }}
                                            />
                                            {agentData?.support_agent?.online === 1 ? 'Online' : 'Offline'}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                        <div className="d-flex gap-3 justify-content-end">
                            <ExportTimer messageSentTime={messageSentTime} />
                            {isMobile ?
                                <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                                    <MoreOutlined className="cursor-pointer" />
                                </Dropdown>
                                :
                                <>
                                    <Tooltip title="Refresh Chat">
                                        <ReloadOutlined className="cursor-pointer" onClick={onRefresh} />
                                    </Tooltip>
                                    <Tooltip title="Assign Chat">
                                        <UserOutlined className="cursor-pointer" onClick={toggleModal} />
                                    </Tooltip>
                                </>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}
);

export default ChatContentHeader
