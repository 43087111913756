import React, { useEffect, useState } from 'react';
import { Image, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/shared-components/Flex';
import { faPhone, faReply, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { UnorderedListOutlined } from '@ant-design/icons';
import { useMyContext } from 'Context/MyContextProvider';
const { Text } = Typography;
const NewMobilePreview = ({ requestType, template, newMesaage, customMessage, mediaLink }) => {
  const { getMedia, getUrlFromMediaId } = useMyContext()
  // mobile preview style 
  const styles = {

    chatBackground: {
      backgroundImage: `url(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderRadius: '8px',
      padding: '16px',

      // height:'100%'
    },
    chatBubble: {
      background: 'white',
      borderRadius: '8px',
      padding: '8px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
      position: 'relative',
      marginLeft: '20px',
      maxWidth: '85%',
      wordWrap: 'break-word',
    },
    buttoneBubble: {
      background: 'white',
      borderRadius: '8px',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,

      // padding: '16px',
      boxShadow: '0 8px 8px -4px rgba(0, 0, 0, 0.15)',
      position: 'relative',
      bottom: '0.5rem',
      marginLeft: '20px',
      maxWidth: '85%',
      wordWrap: 'break-word',
    },
    chatBubbleBefore: {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '-5px',
      width: '0',
      height: '0',
      borderStyle: 'solid',
      borderWidth: '10px 10px 10px 0',
      borderColor: 'transparent white transparent transparent',
      background: '#ffff',
      backgroundColor: '#ffff',
      clipPath: 'polygon(0 0, 0 100%, 100% 100%)',
      transform: 'rotate(180deg)',
    },
    chatTime: {
      textAlign: 'right',
      fontSize: '12px',
      color: 'gray',
      marginTop: '8px',
    },
    footerMessageStyle: {
      fontSize: '12px',
      marginTop: '5px',
      color: '#918f8f'
    },
    buttonStyle: {
      // fontSize: '11px',
      wordWrap: 'break-word',
      fontFamily: 'system-ui',
      display: 'block',
      textAlign: 'justify',
    }
  };

  const [displayText, setDisplayText] = useState('');
  const [currentTime, setCurrentTime] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [media, setMedia] = useState([]);

  useEffect(() => {
    setDisplayText(newMesaage ? newMesaage : template?.body?.text);
  }, [newMesaage, template]);


  useEffect(() => {
    getMedia(setMedia)
    const updateCurrentTime = () => {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      setCurrentTime(`${hours}:${minutes}`);
    };

    updateCurrentTime();
    const interval = setInterval(updateCurrentTime, 60000);

    return () => clearInterval(interval);
  }, []);




  const renderTextWithLinks = (text) => {
    // Regex for URLs
    const urlRegex = /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?(?:\/\S*)?)/gi;
  
    // Regex for dynamic variables
    const variableRegex = /\{\{(\d+)\}\}/g;
  
  
    return (
      <div 
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          backgroundColor: isHovered ? 'rgba(232, 247, 237, 1)' : 'transparent',
          borderRadius: '5px',
          padding: '2px 4px',
          transition: 'background-color 0.3s ease'
        }}
      >
        {text.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line?.split(/({{[^}]+}}|\b(https?:\/\/|www\.)[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi)?.map((part, subIndex) => {
              // Check for URL
              const urlMatch = part?.match(urlRegex);
              if (urlMatch) {
                const href = part.startsWith('http') ? part : 
                             part.startsWith('www.') ? `https://${part}` : 
                             `https://www.${part}`;
                
                return (
                  <a
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ 
                      // color: '#e8f7ed', 
                      textDecoration: 'underline',
                      opacity: isHovered ? 0.8 : 1
                    }}
                    key={subIndex}
                  >
                    {part}
                  </a>
                );
              }
  
              // Check for dynamic variables
              const variableMatch = part?.match(variableRegex);
              if (variableMatch) {
                return (
                  <span
                    key={subIndex}
                    style={{
                      backgroundColor: isHovered 
                        ? '#22af4b82' 
                        : 'transparent',
                      padding: '2px 4px',
                      borderRadius: '3px',
                      transition: 'background-color 0.3s ease'
                    }}
                  >
                    {part}
                  </span>
                );
              }
  
              // Handle bold text
              return part?.split(/\*([^*]+)\*/g)?.map((boldPart, boldIndex) => {
                if (boldIndex % 2 === 1) {
                  return (
                    <span 
                      key={boldIndex} 
                      style={{ 
                        fontWeight: 'bold',
                        opacity: isHovered ? 0.8 : 1
                      }}
                    >
                      {boldPart}
                    </span>
                  );
                }
                return <span key={boldIndex}>{boldPart}</span>;
              });
            })}
            <br />
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-200">
      <div style={styles.chatBackground}>
        <div style={styles.chatBubble}>
          <div style={styles.chatBubbleBefore}></div>
          {requestType === 'template' ? (
            <>
              {
                template?.header?.format === 'DOCUMENT' ?
                  <div className='border rounded d-flex justify-content-center align-items-center mb-3' style={{ background: '#ccd0d5' }}>
                    <img
                      src={'/img/pdfBackground.png'}
                      className='my-3'
                      width={70}
                      alt='pdf'
                    />
                  </div>
                  :
                  template?.header?.format === 'IMAGE' ?
                    <Image
                      src={mediaLink ? getUrlFromMediaId(mediaLink, media) : '/img/others/dummy-header.jpg'}
                      className='mt-1' />
                    :
                    template?.header && template?.header?.format === 'VIDEO' ?
                      <div className=' d-flex justify-content-center align-items-center mb-3' style={{ background: '' }}>
                        <img src={'/img/dummyvideo.png'} className='mt-1 border rounded' alt='video' width={'100%'} />
                      </div>
                      :
                      <div className='header'>
                        <h5>{template?.header?.text}</h5>
                      </div>
              }
              {displayText && (
                <div className='mt-1'>
                  {renderTextWithLinks(displayText)}
                </div>
              )}
              <span style={styles.footerMessageStyle}>
                {template?.footer?.text ? template.footer.text : null}
              </span>
            </>
          ) : (
            customMessage?.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line.split(/\*([^*]+)\*/g).map((part, subIndex) => (
                  subIndex % 2 === 1 ? (
                    <Text key={subIndex} style={{ fontWeight: 'bold' }}>{part}</Text>
                  ) : (
                    <Text key={subIndex}>{part}</Text>
                  )
                ))}
                <br />
              </React.Fragment>
            ))
          )}
          <div style={styles.chatTime}>{currentTime}</div>
        </div>
        {/* <div style={styles.buttoneBubble}>
          {template?.buttons?.buttons?.length > 0 && template?.buttons?.buttons?.map((item, index) => {
            return (
              index < 2 &&
              <span style={styles.buttonStyle} className="border-top py-2">
                <button className="border-0 bg-transparent w-100" style={{ fontWeight: 'bold', color: '#00a5f4' }}>
                  <Flex justifyContent='center' alignItems='center' gap='5px'>
                    {item?.type === "QUICK_REPLY" && <FontAwesomeIcon icon={faReply} />}
                    {item?.type === "URL" && <FontAwesomeIcon icon={faUpRightFromSquare} />}
                    {item?.type === "PHONE_NUMBER" && <FontAwesomeIcon icon={faPhone} />}
                    <span>{item?.text}</span>
                  </Flex>
                </button>
              </span>
            )
          })
          }
          {template?.buttons?.buttons?.length > 3 &&
            <span style={styles.buttonStyle} className="border-top py-2">
              <button className="border-0 bg-transparent w-100" style={{ fontWeight: 'bold', color: '#00a5f4' }}>
                <Flex justifyContent="center" alignItems="center" gap="5px">
                  <UnorderedListOutlined />
                  See all options
                </Flex>
              </button>
            </span>
          }
        </div> */}
        <div style={styles.buttoneBubble}>
          {template?.buttons?.buttons?.length > 0 && template?.buttons?.buttons?.map((item, index) => {
            // Display the first 2 QUICK_REPLY buttons and all other buttons
            if (item?.type === "QUICK_REPLY" && index < 2) {
              return (
                <span style={styles.buttonStyle} className="border-top py-2" key={index}>
                  <button className="border-0 bg-transparent w-100" style={{ fontWeight: 'bold', color: '#00a5f4' }}>
                    <Flex justifyContent='center' alignItems='center' gap='5px'>
                      <FontAwesomeIcon icon={faReply} />
                      <span>{item?.text}</span>
                    </Flex>
                  </button>
                </span>
              );
            } else if (item?.type !== "QUICK_REPLY") {
              return (
                <span style={styles.buttonStyle} className="border-top py-2" key={index}>
                  {item?.type === "URL" ? (
                    <a
                      href={item?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="border-0 bg-transparent w-100 d-block text-center"
                      style={{
                        fontWeight: 'bold',
                        color: '#00a5f4',
                        textDecoration: 'none',
                      }}
                    >
                      <Flex justifyContent='center' alignItems='center' gap='5px'>
                        <FontAwesomeIcon icon={faUpRightFromSquare} />
                        <span>{item?.text}</span>
                      </Flex>
                    </a>
                  ) : item?.type === "PHONE_NUMBER" ? (
                    <a
                      href={`tel:${item?.phone_number}`}
                      className="border-0 bg-transparent w-100 d-block text-center"
                      style={{
                        fontWeight: 'bold',
                        color: '#00a5f4',
                        textDecoration: 'none',
                      }}
                    >
                      <Flex justifyContent='center' alignItems='center' gap='5px'>
                        <FontAwesomeIcon icon={faPhone} />
                        <span>{item?.text}</span>
                      </Flex>
                    </a>
                  ) : null}
                </span>
              );
            }
            return null;
          })}

          {template?.buttons?.buttons?.filter(item => item?.type === "QUICK_REPLY").length > 3 &&
            <span style={styles.buttonStyle} className="border-top py-2">
              <button className="border-0 bg-transparent w-100" style={{ fontWeight: 'bold', color: '#00a5f4' }}>
                <Flex justifyContent="center" alignItems="center" gap="5px">
                  <UnorderedListOutlined />
                  See all options
                </Flex>
              </button>
            </span>
          }
        </div>

      </div>
    </div>
  )
}

export default NewMobilePreview
