import React, { useEffect, useRef, useState } from 'react'
import PageHeaderAlt from 'components/layout-components/PageHeaderAlt'
import { Button, Menu, Card, Image, Result, Modal, Upload, message, Progress, Typography, Badge, Tabs, Checkbox, Row, Col } from 'antd';
import { LinkOutlined, PlusOutlined, FilePdfOutlined, DeleteOutlined, PlayCircleOutlined, UploadOutlined, LoadingOutlined, FileExcelOutlined, WhatsAppOutlined, CloudUploadOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player'
import { AnimatePresence, motion } from "framer-motion";
import Flex from 'components/shared-components/Flex';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown'
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';
import { MediaTabs } from './MediaTabs';
import UserStorageCard from './UserStorageCard';
import FloatingButton from './FloatingButton';

const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
        .then(() => {
            message.success('Link copied to clipboard');
        })
        .catch((error) => {
            message.error('Failed to copy link');
            console.error('Failed to copy: ', error);
        });
};
const MediaUploadModal = ({ open, onCancel, onUpload, loading, uploadProgress, selectedMedia, setSelectedMedia, currentFile, uploadedCount }) => {
    const props = {
        beforeUpload: file => {
            setSelectedMedia(prev => [...prev, file]);
            return false;
        },
        fileList: selectedMedia,
        onRemove: file => {
            setSelectedMedia(prev => prev.filter(item => item.uid !== file.uid));
        }
    };

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            title="Upload Media"
            open={open}
            centered
            onCancel={onCancel}
            footer={null}
        >
            <Result
                icon={<PlayCircleOutlined />}
                title="Upload your media files here!"
                extra={
                    <Upload {...props} accept=".jpg,.jpeg,.png,.pdf,.xls,.xlsx" multiple>
                        <Button icon={<UploadOutlined />}>Click to select file</Button>
                    </Upload>
                }
            />
            <Flex justifyContent='center' flexDirection='column' alignItems='center' className="mt-4">
                {loading ? (
                    <>
                        <motion.div
                            className="text-center"
                            initial={{ opacity: 0, y: -5 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            <AnimatePresence mode="popLayout">
                                {currentFile && (
                                    <motion.p
                                        key={currentFile.name} // Ensures animation triggers on change
                                        className="mb-2 text-primary"
                                        initial={{ opacity: 0, y: 20 }} // Start below
                                        animate={{ opacity: 1, y: 0 }}  // Move up to normal
                                        exit={{ opacity: 0, y: -20 }}  // Move up & fade out
                                        transition={{ duration: 0.3 }}
                                    >
                                        Uploading: <strong>{currentFile.name}</strong>
                                    </motion.p>
                                )}
                            </AnimatePresence>



                            <motion.p
                                className="mb-2 text-muted"
                                initial={{ scale: 0.95 }}
                                animate={{ scale: 1 }}
                                transition={{ duration: 0.2, delay: 0.1 }}
                            >
                                {uploadedCount} / {selectedMedia.length} files uploaded
                            </motion.p>
                        </motion.div>

                        <Progress
                            percent={uploadProgress}
                            className="rounded-3"
                            status={uploadProgress === 100 ? 'success' : 'active'}
                            strokeColor={{
                                '0%': '#108ee9',
                                '100%': '#87d068',
                            }}
                        />

                        <motion.div
                            animate={{ y: [0, -3, 0] }}
                            transition={{ repeat: Infinity, duration: 1.2, ease: "easeInOut" }}
                        >
                            <Button
                                type="primary"
                                className="mt-2"
                                icon={<LoadingOutlined />}
                                disabled
                            >
                                Uploading... {uploadProgress}%
                            </Button>
                        </motion.div>
                    </>
                ) : (
                    <Button
                        type='primary'
                        disabled={!selectedMedia.length}
                        onClick={onUpload}
                    >
                        <UploadOutlined />Upload
                    </Button>
                )}
            </Flex>
        </Modal>
    );
};
const ItemHeader = ({ name, category }) => (
    <div>
        <h5 className="mb-0">{name.length > 15 ? `${name.substring(0, 16)}...` : name}</h5>
        <span className="text-muted">{category}</span>
    </div>
);
const HandlePermDelete = async (UpdateStorageData, id, api, authToken, setExpiredMedia, preventMessage) => {
    //console.log(id, api, authToken, setExpiredMedia, preventMessage)
    try {
        const response = await axios.delete(`${api}media/permanent-delete/${id}`, {
            headers: { Authorization: "Bearer " + authToken },
        });

        if (response.data.status) {
            setExpiredMedia(prev => prev.filter(media => media.id !== id));
            if (preventMessage) {
                message.success('Media permanently deleted');
                UpdateStorageData()
            }
        } else {
            message.error(response.data.message || 'Failed to delete media');
        }
    } catch (error) {
        message.error('Failed to delete media');
        console.error(error);
    }
}
const handleReupload = async (UpdateStorageData, setIsReUploading, api, id, authToken, uploadMediaApi, waToken, setUploadProgress, setExpiredMedia, expiredMedia, setActiveMedia, getMedia, preventMessage) => {
    // if (!preventMessage) { 
    // }
    setIsReUploading(true);
    try {
        // Get the file
        const response = await axios.get(`${api}media/get-file/${id}`, {
            headers: { Authorization: "Bearer " + authToken },
            responseType: 'blob'
        });

        const file = new File([response.data], 'media_file', {
            type: response.data.type || 'application/octet-stream'
        });
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', file.type);
        formData.append('messaging_product', 'whatsapp');

        // Upload to Meta
        const metaUploadResponse = await axios.post(uploadMediaApi, formData, {
            headers: {
                'Authorization': `Bearer ${waToken}`,
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                // if (!preventMessage) {
                // }
                setUploadProgress(percentCompleted);

            },
        });

        if (metaUploadResponse?.data.id) {
            const updatedMedia = expiredMedia.find(media => media.id === id);
            if (updatedMedia) {
                updatedMedia.isDeleted = false;
                setExpiredMedia(prev => prev.filter(media => media.id !== id));
                setActiveMedia(prev => [...prev, updatedMedia]);
            }
            await getMedia();
            if (preventMessage) {
                message.success('Media ReUpload Success');
                UpdateStorageData()
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    } catch (error) {
        message.error('Failed to reupload media');
        console.error(error);
    } finally {
        setIsReUploading(false);
    }
};
const handleDelete = async (UpdateStorageData, id, api, authToken, getMedia, activeMedia, setActiveMedia, setExpiredMedia, preventMessage) => {
    try {
        const response = await axios.delete(`${api}delete-media/${id}`, {
            headers: { Authorization: "Bearer " + authToken },
        });
        if (response.data.status) {
            const deletedMedia = activeMedia.find(media => media.id === id);
            if (deletedMedia) {
                deletedMedia.isDeleted = true;
                setActiveMedia(prev => prev.filter(media => media.id !== id));
                setExpiredMedia(prev => [...prev, deletedMedia]);
            }
            await getMedia();
            if (preventMessage) {
                message.success('Media deleted successfully');
                UpdateStorageData()
            }
        }
    } catch (error) {
        message.error('Failed to delete media');
        console.error(error);
    }
};
const ItemAction = ({ UpdateStorageData, path, id, getMedia, mediaId, isDeleted, setActiveMedia, setExpiredMedia, expiredMedia, activeMedia }) => {
    const { api, authToken, uploadMediaApi, waToken } = useMyContext();
    const [isReUploading, setIsReUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handlePermanentDelete = async () => {
        Modal.confirm({
            title: 'Permanent Delete',
            content: 'Are you sure you want to permanently delete this media? This action cannot be undone.',
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: async () => {
                HandlePermDelete(UpdateStorageData, id, api, authToken, setExpiredMedia, true)
            }
        });
    };
    return (
        <>
            <Modal
                title="Re-Uploading Media"
                open={isReUploading}
                footer={null}
                closable={false}
            >
                <div className="text-center mb-4">
                    <CloudUploadOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
                    <h3 className="mt-2">Uploading to Meta</h3>
                </div>

                <Progress
                    percent={uploadProgress}
                    status={uploadProgress === 100 ? 'success' : 'active'}
                    className='rounded-3'
                    strokeColor={{
                        '0%': '#108ee9',
                        '100%': '#87d068',
                    }}
                />

                <div className="text-center mt-3">
                    <Typography.Text type={uploadProgress < 100 ? "secondary" : "success"}>
                        {uploadProgress < 100 ? `Uploading... ${uploadProgress}%` : 'Upload Complete!'}
                    </Typography.Text>
                </div>
            </Modal>

            <EllipsisDropdown
                menu={
                    isDeleted ? (
                        <Menu>
                            <Menu.Item key="reupload" onClick={() => handleReupload(UpdateStorageData, setIsReUploading, api, id, authToken, uploadMediaApi, waToken, setUploadProgress, setExpiredMedia, expiredMedia, setActiveMedia, getMedia)}>
                                <UploadOutlined />
                                <span className="ml-2">Re Upload to Meta</span>
                            </Menu.Item>
                            <Menu.Item key="permanent-delete" onClick={handlePermanentDelete}>
                                <DeleteOutlined />
                                <span className="ml-2">Permanent Delete</span>
                            </Menu.Item>
                        </Menu>
                    ) : (
                        <Menu>
                            <Menu.Item key="copy-media" onClick={() => copyToClipboard(mediaId)}>
                                <WhatsAppOutlined />
                                <span className="ml-2">Copy Media ID</span>
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item key="copy-link" onClick={() => copyToClipboard(path)}>
                                <LinkOutlined />
                                <span className="ml-2">Copy Link</span>
                            </Menu.Item>
                            <Menu.Item key="delete" onClick={() => handleDelete(UpdateStorageData, id, api, authToken, getMedia, activeMedia, setActiveMedia, setExpiredMedia, true)}>
                                <DeleteOutlined />
                                <span className="ml-2">Delete</span>
                            </Menu.Item>
                        </Menu>
                    )
                }
            />
        </>
    );
};
const GridItem = ({ UpdateStorageData, data, getMedia, setActiveMedia, setExpiredMedia, expiredMedia, activeMedia, selectedItems, handleSelectItem, bulkSelectMode }) => {
    const [openVideo, setOpenVideo] = useState(false);
    const [video, setVideo] = useState({ path: '', title: '' });

    const handleVideoPlay = (path, title) => {
        setOpenVideo(true);
        setVideo({ path, title });
    };

    const mediaPath = data?.path;

    const expiredOverlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
        color: 'white',
        fontSize: '24px',
        fontWeight: 'bold',
        borderRadius: '10px',
        textTransform: 'uppercase',
        pointerEvents: 'none',
    };

    const renderMediaContent = () => {
        if (data.type.startsWith('image/')) {
            return (
                <Image
                    src={mediaPath}
                    className='border rounded'
                    loading='lazy'
                    preview={bulkSelectMode ? false : true}
                    style={{
                        maxWidth: '100%',
                        maxHeight: '200px',
                        objectFit: 'contain',
                        filter: data.isDeleted ? 'grayscale(100%)' : 'none'
                    }}
                />
            );
        }

        const IconMap = {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': FileExcelOutlined,
            'video/mp4': PlayCircleOutlined,
            'application/pdf': FilePdfOutlined
        };

        const Icon = IconMap[data.type];

        if (!Icon) return null;

        return (
            <span style={{
                fontSize: '5rem',
                opacity: data.isDeleted ? 0.5 : 1,
                cursor: data.type === 'video/mp4' ? 'pointer' : 'default'
            }}>
                <Icon onClick={
                    data.type === 'video/mp4' && !data.isDeleted
                        ? () => handleVideoPlay(mediaPath, data?.name)
                        : undefined
                } />
            </span>
        );
    };

    return (
        <>
            <Modal
                destroyOnClose
                maskClosable={false}
                open={openVideo}
                onCancel={() => setOpenVideo(false)}
                footer={null}
            >
                <Flex justifyContent='center' flexDirection='column'>
                    <h4 style={{ width: '90%' }}>{video?.title}</h4>
                    <div className='player'>
                        <ReactPlayer
                            playing
                            url={video?.path}
                            controls
                            width='100%'
                            height='auto'
                            className='rounded'
                        />
                    </div>
                </Flex>
            </Modal>
            {bulkSelectMode && (
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.8, x: -10 }}
                        animate={{ opacity: 1, scale: 1, x: 0 }}
                        exit={{ opacity: 0, scale: 0.8, x: -10 }}
                        transition={{ duration: 0.2, ease: "easeInOut" }}
                        style={{ position: "absolute", top: 10, left: 20, zIndex: 10 }}
                    >
                        <Checkbox
                            checked={selectedItems.includes(data?.id)}
                            onChange={() => handleSelectItem(data?.id)}
                        />
                    </motion.div>
                </AnimatePresence>
            )}
            <Card
                onClick={() => bulkSelectMode && handleSelectItem(data?.id)}
                style={{
                    opacity: data.isDeleted ? 0.6 : 1,
                    position: 'relative',
                    //pointerEvents: bulkSelectMode && 'none',
                    border: data.isDeleted ? '2px dashed #ff4d4f' : '1px solid #d9d9d9'
                }}
                cover={
                    <div className='image-box d-flex justify-content-center align-items-center rounded'
                        style={{
                            height: '200px',
                            position: 'relative'
                        }}
                    >
                        {renderMediaContent()}
                        {data.isDeleted && (
                            <div style={expiredOverlayStyle}>
                                <div style={{
                                    transform: 'rotate(-45deg)',
                                    textAlign: 'center'
                                }}>
                                    Expired
                                </div>
                            </div>
                        )}
                    </div>
                }
            >


                {/* <div className="d-flex justify-content-center align-items-center" style={{ height: "200px", position: "relative" }}>
                    {renderMediaContent()}
                </div> */}

                <Card.Meta
                    title={
                        <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            style={{ color: data.isDeleted ? '#999' : 'inherit' }}
                        >
                            <ItemHeader
                                name={data?.name}
                                category={data?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                    ? 'excel'
                                    : data?.type}
                            />
                            <ItemAction
                                path={mediaPath}
                                id={data?.id}
                                getMedia={getMedia}
                                mediaId={data?.media_id}
                                isDeleted={data?.isDeleted}
                                activeMedia={activeMedia}
                                expiredMedia={expiredMedia}
                                UpdateStorageData={UpdateStorageData}
                                setExpiredMedia={setExpiredMedia}
                                setActiveMedia={setActiveMedia}
                            />
                        </Flex>
                    }
                />
            </Card>
        </>
    );
};
const Media = () => {
    const { UserData, api, authToken, Permisson, uploadMediaApi, waToken, buttonStyle } = useMyContext();
    const [selectedMedia, setSelectedMedia] = useState([]);
    const [activeMedia, setActiveMedia] = useState([]);
    const [expiredMedia, setExpiredMedia] = useState([]);
    const [loading, setLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('active');
    const [currentFile, setCurrentFile] = useState(null);
    const [uploadedCount, setUploadedCount] = useState(0);
    const [storageUpdated, setStorageUpdated] = useState(false);

    const Dummy = (value) => { }
    const UpdateStorageData = () => {
        setStorageUpdated(!storageUpdated);
        storageRef.current?.fetchUserStorage()
    }
    const getMedia = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${api}media/${UserData?.id}`, {
                headers: { 'Authorization': 'Bearer ' + authToken },
            });
            const data = response.data?.media || [];

            setActiveMedia(data
                .filter(item => !item.deleted_at)
                .map(item => ({ ...item, isDeleted: false }))
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            );

            setExpiredMedia(data
                .filter(item => item.deleted_at)
                .map(item => ({ ...item, isDeleted: true }))
                .sort((a, b) => new Date(b.deleted_at) - new Date(a.deleted_at))
            );
        } catch (error) {
            message.error('Failed to fetch media');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getMedia();
    }, []);

    const handleUploadOnMeta = async () => {
        const MAX_FILE_SIZE_MB = 15;

        if (!selectedMedia.length) {
            message.warning('No media selected for upload');
            return;
        }

        setLoading(true);
        setUploadProgress(0);
        setUploadedCount(0);

        try {
            // Process each file sequentially
            for (const [index, file] of selectedMedia.entries()) {
                setCurrentFile(file);
                const fileSizeMB = file.size / (1024 * 1024);

                // File size validation
                if (fileSizeMB > MAX_FILE_SIZE_MB) {
                    message.error(`File "${file.name}" exceeds the maximum size of ${MAX_FILE_SIZE_MB} MB`);
                    continue;
                }
                setUploadProgress(0);
                // Prepare form data for Meta upload
                const formDataMeta = new FormData();
                formDataMeta.append('file', file);
                formDataMeta.append('type', file.type);
                formDataMeta.append('filename', file.name);
                formDataMeta.append('messaging_product', 'whatsapp');

                try {
                    // Upload to Meta
                    const responseMeta = await axios.post(uploadMediaApi, formDataMeta, {
                        headers: { 'Authorization': `Bearer ${waToken}` },
                    });

                    const mediaId = responseMeta.data.id;
                    setUploadProgress(50);
                    // Upload to server
                    const formDataServer = new FormData();
                    formDataServer.append('file', file);
                    formDataServer.append('media_id', mediaId);
                    formDataServer.append('user_id', UserData.id);

                    const responseServer = await axios.post(`${api}upload-media`, formDataServer, {
                        headers: { 'Authorization': `Bearer ${authToken}` },
                        onUploadProgress: (progressEvent) => {
                            const percentCompleted = Math.round(
                                (progressEvent.loaded * 50) / progressEvent.total
                            );
                            setUploadProgress(50 + percentCompleted); // Add to previous 50%
                        }
                    });

                    if (responseServer.data?.status) {
                        setUploadedCount(index + 1);
                        message.success(`${file?.name} uploaded successfully`);
                    } else {
                        throw new Error('Server upload failed');
                    }
                } catch (error) {
                    message.error(`Failed to upload ${file.name}: ${error.message}`);
                }
            }
            // Clean up and refresh
            setUploadModalOpen(false);
            setSelectedMedia([]);
            await getMedia();

        } catch (error) {
            message.error('Upload process failed');
            console.error(error);
        } finally {
            UpdateStorageData()
            setLoading(false);
            setUploadProgress(0);
        }
    };


    const [bulkSelectMode, setBulkSelectMode] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [progress, setProgress] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false); // State for confirmation modal
    const [actionType, setActionType] = useState(""); // "delete" or "reupload"
    const [currentMediaId, setCurrentMediaId] = useState(null); // Store the media ID for the current action
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentMediaIds, setCurrentMediaIds] = useState([]);
    // Toggle Bulk Select Mode
    const handleBulkSelect = () => {
        setBulkSelectMode(!bulkSelectMode);
        setSelectedItems([]); // Clear selection when exiting
    };

    // Bulk Delete Function
    const handleBulkDelete = async () => {
        setProgress(0);
        setIsModalVisible(true);
        setCurrentIndex(0);
        for (let i = 0; i < selectedItems.length; i++) {
            const id = selectedItems[i];
            if (activeTab === 'expired') {
                await HandlePermDelete(Dummy, id, api, authToken, setExpiredMedia, false);
            } else {
                await handleDelete(Dummy, id, api, authToken, getMedia, activeMedia, setActiveMedia, setExpiredMedia, false);
            }
            setCurrentIndex(i + 1);
            setProgress(Math.round(((i + 1) / selectedItems.length) * 100));
        }
        UpdateStorageData()
        setSelectedItems([]);
        setBulkSelectMode(false);
        setIsModalVisible(false);
        message.success("All selected media permanently deleted.");
    };

    const showConfirmationModal = (action) => {
        setActionType(action);
        setCurrentMediaIds(selectedItems);
        setCurrentIndex(0);
        setProgress(0);
        setIsConfirmationModalVisible(true);
    };

    const handleBulkReupload = async () => {
        setProgress(0);
        setIsModalVisible(true);
        for (let i = 0; i < currentMediaIds?.length; i++) {
            await handleReupload(Dummy, Dummy, api, currentMediaIds[i], authToken, uploadMediaApi, waToken, Dummy, setExpiredMedia, expiredMedia, setActiveMedia, getMedia, false);
            setCurrentIndex(i + 1);
            setProgress(Math.round(((i + 1) / currentMediaIds?.length) * 100)); // Update progress
        }
        await getMedia();
        UpdateStorageData()
        message.success("All media reuploaded successfully");
        window.scrollTo({ top: 0, behavior: "smooth" });
        //setIsModalVisible(false);
    }
    // Action Menu for Dropdown
    const bulkActionMenu = (isActiveMedia) => (
        <Menu>
            <Menu.Item key="delete" onClick={() => showConfirmationModal('delete')} icon={<DeleteOutlined style={{ color: "red" }} />}>
                Delete Selected
            </Menu.Item>
            {!isActiveMedia && (
                <Menu.Item key="reupload" onClick={() => showConfirmationModal('reupload')} icon={<UploadOutlined style={{ color: "#1890ff" }} />}>
                    Reupload Selected (Coming Soon)
                </Menu.Item>
            )}
        </Menu>
    );
    const [prevCount, setPrevCount] = useState(selectedItems.length);

    useEffect(() => {
        setPrevCount(selectedItems.length);
    }, [selectedItems.length]);


    const handleTabChange = (key) => {
        setActiveTab(key);
        setSelectedItems([]);
        setBulkSelectMode(false);
    };
    const handleCancelConfirmation = () => {
        setIsConfirmationModalVisible(false);
    };
    const handleConfirmAction = () => {
        setIsConfirmationModalVisible(false);
        setProgress(0)
        setBulkSelectMode(false)
        if (actionType === "delete") {
            handleBulkDelete(currentMediaId);
        } else if (actionType === "reupload") {
            handleBulkReupload(currentMediaId);
        }
    };

    //tabs code
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
    const [shiftKeyPressed, setShiftKeyPressed] = useState(false);
    const handleSelectItem = (itemId) => {
        let media = activeTab === 'active' ? activeMedia : expiredMedia
        const currentIndex = media.findIndex((item) => item.id === itemId);

        if (shiftKeyPressed && lastSelectedIndex !== null) {
            const start = Math.min(lastSelectedIndex, currentIndex);
            const end = Math.max(lastSelectedIndex, currentIndex);
            const range = media.slice(start, end + 1).map(item => item.id);

            if (selectedItems.includes(itemId)) {
                // Unselect the items in the range
                setSelectedItems((prevItems) =>
                    prevItems.filter((id) => !range.includes(id))
                );
            } else {
                // Select the items in the range
                setSelectedItems((prevItems) => [...new Set([...prevItems, ...range])]);
            }
        } else {
            // Normal toggle: add or remove the item from selectedItems
            setSelectedItems((prevItems) =>
                prevItems.includes(itemId)
                    ? prevItems.filter((id) => id !== itemId)
                    : [...prevItems, itemId]
            );
        }

        setLastSelectedIndex(currentIndex); // Update the last selected index for range selection
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Shift') {
            setShiftKeyPressed(true);
        }
    };

    const handleKeyUp = (e) => {
        if (e.key === 'Shift') {
            setShiftKeyPressed(false);
        }
    };
    useEffect(() => {
        const handleScroll = () => {
            if (scrollRef.current) {
                setIsScrolled(scrollRef.current.scrollTop > 0);
            }
        };

        const scrollContainer = scrollRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", handleScroll);
        }

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            if (scrollContainer) {
                scrollContainer.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    const HandleCloseModal = () => {
        setIsModalVisible(false);
        setIsConfirmationModalVisible(false);
        setSelectedMedia([]);
        setCurrentMediaId()
        setCurrentMediaIds([])
        setActionType()
    }

    const [isScrolled, setIsScrolled] = useState(false);
    const scrollRef = useRef(null);
    const storageRef = useRef();

    const mediaTabsValues = [
        { key: 'active', label: 'Active Media', icon: <PlayCircleOutlined />, media: activeMedia, bgColor: '#52c41a', isActive: true },
        { key: 'expired', label: 'Expired Media', icon: <DeleteOutlined />, media: expiredMedia, bgColor: '#ff4d4f', isActive: false }
    ];

    const tabs = mediaTabsValues.map(({ key, label, icon, media, bgColor, isActive }) => ({
        key,
        label: (
            <span>
                {icon} {label}
                <Badge count={media.length} style={{ backgroundColor: bgColor, marginLeft: '8px' }} />
            </span>
        ),
        children: (
            <MediaTabs
                media={media}
                bulkSelectMode={bulkSelectMode}
                handleBulkSelect={handleBulkSelect}
                selectedItems={selectedItems}
                prevCount={prevCount}
                bulkActionMenu={bulkActionMenu}
                isScrolled={isScrolled}
                scrollRef={scrollRef}
                GridItem={GridItem}
                UpdateStorageData={UpdateStorageData}
                activeMedia={activeMedia}
                expiredMedia={expiredMedia}
                setActiveMedia={setActiveMedia}
                setExpiredMedia={setExpiredMedia}
                getMedia={getMedia}
                handleSelectItem={handleSelectItem}
                isActive={isActive}
                Permisson={Permisson}
                setUploadModalOpen={setUploadModalOpen}
                uploadModalOpen={uploadModalOpen}
                loading={loading}
            />
        )
    }));


    return (
        <>
            <Modal
                title={`Are you sure you want to ${actionType === "delete" ? "delete" : "reupload"} this media?`}
                visible={isConfirmationModalVisible}
                onOk={handleConfirmAction}
                onCancel={handleCancelConfirmation}
                okText="Yes"
                cancelText="No"
            >
                <p>{`This action will ${actionType === "delete" ? "permanently delete" : "reupload"} the selected media.`}</p>
            </Modal>
            <Modal
                title={actionType === "delete" ? "Deleting Items" : "Re Uploading Items"}
                open={isModalVisible}
                footer={progress === 100 ? <Button onClick={HandleCloseModal}>Close</Button> : null}
                closable={progress === 100}
                onCancel={progress === 100 ? HandleCloseModal : null}

                className='rounded-3'
                strokeColor={{
                    '0%': '#108ee9',
                    '100%': '#87d068',
                }}
            >
                <div className="text-center mb-4">
                    {actionType === "delete" ?
                        <DeleteOutlined style={{ fontSize: '48px', color: 'red' }} />
                        :
                        <CloudUploadOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
                    }
                </div>
                <Progress percent={progress} status={progress < 100 ? "active" : "success"} />
                <p>{progress < 100 ? "Processing, please wait..." : "Completed!"}</p>
            </Modal>

            <MediaUploadModal
                open={uploadModalOpen}
                onCancel={() => {
                    setUploadModalOpen(false);
                    setSelectedMedia([]);
                }}
                onUpload={handleUploadOnMeta}
                loading={loading}
                uploadedCount={uploadedCount}
                currentFile={currentFile}
                uploadProgress={uploadProgress}
                selectedMedia={selectedMedia}
                setSelectedMedia={setSelectedMedia}
            />

            <PageHeaderAlt className="border-bottom py-3">
                <div className="container-fluid">
                    <Row align="middle">
                        {/* Title on the left */}
                        <Col xs={24} sm={4}>
                            <h2 className="m-0">Media</h2>
                        </Col>

                        {/* UserStorageCard on the right */}
                        <Col xs={24} sm={18}>
                            <UserStorageCard
                                storageUpdated={storageUpdated}
                                ref={storageRef}
                                api={api}
                                userId={UserData?.id}
                                authToken={authToken}
                            />
                        </Col>
                    </Row>
                </div>
            </PageHeaderAlt>

            <FloatingButton
                uploadModalOpen={uploadModalOpen}
                setUploadModalOpen={setUploadModalOpen}
                loading={loading}
                buttonStyle={buttonStyle}
            />




            <div className="container-fluid mt-4">
                <Tabs
                    activeKey={activeTab}
                    // onChange={setActiveTab}
                    onChange={handleTabChange}
                    items={tabs}
                    animated
                />
            </div>
        </>
    );
};

export default Media;
