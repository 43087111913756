import { Form, Radio, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React from 'react'
const { Option } = Select;
export const DeFautRes = (props) => {
    const { requestType, onChange, setCustomMessage, templateName, HandleTemplate, templates, customMessage } = props
    return (
        <>
            <Form.Item label="Response Type">
                <Radio.Group value={requestType} onChange={(e) => onChange(e)}>
                    <Radio value='Custom' >Custom</Radio>
                    <Radio value='template'>Template</Radio>
                </Radio.Group>
            </Form.Item>
            {requestType === 'Custom' ?
                <>
                    <Form.Item label={`Custom Text`}>
                        <TextArea rows={4} type="text" onChange={(e) => setCustomMessage(e.target.value)} value={customMessage} />
                    </Form.Item>
                </>
                :
                requestType === 'template' ?
                    <Form.Item label="Templates">
                        <Select
                            showSearch
                            size={'default'}
                            defaultValue={{ key: templateName }}
                            style={{ width: '100%' }}
                            onChange={(value) => HandleTemplate(value)}
                        >
                            {templates?.length > 0 ?
                                    templates?.map((item, index) =>
                                        <Option value={item.name} key={index}>{item.name}</Option>
                                    ) : <Option value="">You Don't Have Any Approved Templates</Option>
                            }
                        </Select>
                    </Form.Item>
                    : ''
            }
        </>
    )
}
