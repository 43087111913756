import React, { useEffect, useState } from 'react'
import { Card, Table, Tooltip, Button, Modal, message, Drawer, Input, Tag } from 'antd';
import { SendOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import Flex from 'components/shared-components/Flex';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useMyContext } from 'Context/MyContextProvider';
import NewMobilePreview from '../../Campaign/newMobilePreview';
const { confirm } = Modal;

const PresetList = () => {
    const { Permisson, navigate, waToken, authToken, api, messagesApi, UserData,CheckExpiredMedia } = useMyContext();
    const [loading, setLoading] = useState(false);
    const [presets, setpresets] = useState([]);

    const GetPresets = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}presets/${UserData?.id}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            if (response.data.status) {
                setpresets(response.data.preset)
            }
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        GetPresets()
    }, []);

    const HandleCount = async (data) => {
        let object = JSON.parse(data);
        let cards = object.template.components[0].cards;
        const imageIds = cards?.map(card => card.components[0]?.parameters[0]?.image?.id)?.filter(id => id !== undefined);
        const expiryResults = await Promise.all(
            imageIds.map(async (id) => {
                try {
                    const isValid = await CheckExpiredMedia(id);
                    return !isValid; // Return true if expired
                } catch (error) {
                    console.error(`Error checking media ${id}:`, error);
                    return false; // Assume not expired in case of error
                }
            })
        );
        return expiryResults.filter(Boolean).length; // Count expired items
    };

    const [expiredCounts, setExpiredCounts] = useState({});
    useEffect(() => {
        const fetchExpiredCounts = async () => {
            const counts = await Promise.all(presets?.map(async (item) => {
                const count = await HandleCount(item?.object);
                return { id: item?.id, count };
            }));
            const countsMap = counts?.reduce((acc, { id, count }) => ({ ...acc, [id]: count }), {});
            setExpiredCounts(countsMap);
        };
        fetchExpiredCounts();
    }, [presets]);

    const tableColumns = [
        {
            title: 'Preset Name',
            dataIndex: 'name',
            sorter: {
                compare: (a, b) => a.name.length - b.name.length,
            },
        },
        {
            title: 'Status',
            dataIndex: 'mediaCount',
            render: (_, item) => {
                const expiredMediaCount = expiredCounts[item.id] || 0;
                return (
                    <Tag color={expiredMediaCount > 0 ? 'red' : 'green'}>
                        {
                            expiredMediaCount > 0 ? expiredMediaCount + ' Expired Media In This Preset' : 'Active'
                        }
                    </Tag>
                );
            }
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            render: (_, item) => (
                <div className="text-right d-flex">
                    <Tooltip title="Edit">
                        <Button
                            // type="primary"
                            className="mr-2"
                            icon={<EditOutlined />}
                            size="small"
                            onClick={() => navigate('carousel/Preset/add', {
                                state: {
                                    presetData: item,
                                    isEditing: true
                                }
                            })}
                        />
                    </Tooltip>
                    <Tooltip title="Quick Send">
                        <Button
                            type="primary"
                            className="mr-2"
                            icon={<SendOutlined />}
                            onClick={() => { HandleQuickSend(item?.id) }}
                            size="small"
                        />
                    </Tooltip>
                    {Permisson?.includes('Delete Carousel Preset') &&
                        <Tooltip title="Delete">
                            <Button
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() => { showConfirm(item.id, item.name) }}
                                size="small"
                            />
                        </Tooltip>
                    }
                </div>
            ),
        },
    ];


    function showConfirm(id, name) {
        confirm({
            title: 'Do you want to delete this preset?',
            content: "By deleting the preset, it will be permanently removed from Meta and can't be restored.",
            onOk() {
                HandleDelete(id, name)
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    }

    const HandleDelete = async (id) => {
        try {
            setLoading(true)
            const response = await axios.delete(`${api}crousel-preset/${id}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            if (response.data.status) {
                GetPresets()
                message.success(response.data.message);
            }
        } catch (error) {
            if (error.response) {
                message.error(error.response.data.message);
            }
        } finally {
            setLoading(false)
        }
    }


    //drawer states 
    const [open, setOpen] = useState(false);
    const [template, setTemplate] = useState([]);
    const [object, setObject] = useState();


    const HandleQuickSend = (id) => {
        let object = presets.find(item => item?.id === id);
        setObject(JSON.parse(object?.object))
        setIsModalVisible(true)
    }




    const onClose = () => {
        setOpen(false);
    };

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [number, setNumber] = useState();
    const handleOk = async () => {
        setLoading(true)
        let objectString = JSON.stringify(object);
        let replacedObjectString = objectString.replace(':number:', number);
        let updatedObject = JSON.parse(replacedObjectString);
        try {
            await axios.post(messagesApi, updatedObject, {
                headers: { Authorization: `Bearer ${waToken}` },
            });
            setNumber('')
            setIsModalVisible(false)
            message.success('Message sent successfully')
        } catch (error) {
        } finally {
            setLoading(false)
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setNumber('')
    };

    return (
        <>
            <Modal
                title="Enter Your WhatsApp Number"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Submit"
                cancelText="Cancel"
                okButtonProps={{ disabled: loading }}
                cancelButtonProps={{ disabled: loading }}
            >
                <Input value={number} onChange={(e) => setNumber(e.target.value)} />
            </Modal>
            <Card bodyStyle={{ padding: '0px' }}>
                <div className="container-fluid">
                    <Flex justifyContent="space-between" alignItems="center" className="py-4">
                        <h2>Carousel Presets</h2>
                        <div className='d-flex'>
                            {Permisson?.includes('Create Template') &&
                                <div>
                                    <Link to='add'>
                                        <Button type="primary" className="ml-2" >
                                            <PlusOutlined />
                                            <span>New</span>
                                        </Button>
                                    </Link>
                                </div>
                            }
                        </div>
                    </Flex>
                </div>

                <div className="table-responsive">
                    <Table loading={loading} columns={tableColumns} dataSource={presets} rowKey="id" />
                </div>
                <div className="">
                    <Drawer
                        title="Template Preview"
                        placement="right"
                        closable={false}
                        onClose={onClose}
                        open={open}
                        getContainer={false}
                        style={{
                            position: 'absolute',
                        }}
                    >

                        <NewMobilePreview
                            requestType={'template'}
                            template={template}
                        />
                    </Drawer>
                </div>
            </Card>
        </>
    );
};

export default PresetList
