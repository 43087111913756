import React, { useEffect, useState } from 'react'
import { Card, Table, Tooltip, Button, Row, Form, Col, Modal, message, Input } from 'antd';
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useMyContext } from 'Context/MyContextProvider';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { Chatbots } from './ChatBotUtils/ChatbotListApi';
import { UserSelectField } from '../CustomUtils.js/CommonFieldUtils';;
const { confirm } = Modal;

export const HandleDeleteChatbot = async (deleteid, authToken, ChatbotData, api, UserData) => {
    try {
        const response = await axios.delete(`${api}chatbot-delete/${deleteid}`, {
            headers: {
                Authorization: "Bearer " + authToken,
            },
        });
        console.log(response);
        if (response.data?.status) {
            ChatbotData(UserData?.id);
            // message.success(response.data?.message)
            message.success('Response deleted successfully')
        }
    } catch (error) {
        console.log(error);
    }
}
const ChatbotByUser = () => {
    const { api, authToken, UserData, Permisson } = useMyContext();
    const [loading, setLoading] = useState(false);
    const [chatbot, setChatbot] = useState([]);

    const [filterData, setFilterData] = useState([]);
    const [users, setUsers] = useState([]);


    const Userlist = () => {
        setLoading(true);
        axios.get(`${api}users`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then(response => {
                const data = (response.data.user).reverse();
                setUsers(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.log(error);
            });
    }
    //analytics
    const ChatbotData = (id) => {
        Chatbots(setLoading, api, id, authToken, setChatbot);
    }
    
    useEffect(() => {
        if (Permisson?.includes('View User')) {
            Userlist()
        }
        ChatbotData(UserData.id)
    }, [])

    function showConfirm(id) {
        confirm({
            title: 'Do you Want to delete this request?',
            content: "By deleting the request, it will be permanently removed from the system and can't be restored.",
            onOk() {
                HandleDeleteChatbot(id, authToken, ChatbotData, api, UserData)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const tableColumns = [
        {
            title: 'No',
            dataIndex: 'sr_no',
            render: (sr_no) => {
                return <span>{sr_no}</span>;
            },
            sorter: {
                compare: (a, b) => a.type?.sr_no - b.type?.sr_no,
            },
        },
        {
            title: 'Keywords',
            dataIndex: 'keyword',
            sorter: {
                compare: (a, b) => a.keyword.length - b.keyword.length,
            },
        },
        {
            title: 'Chatbot Action Type',
            dataIndex: 'chatbot_type',
            render: (text) => text.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()),
            sorter: {
                compare: (a, b) => a.chatbot_type - b.chatbot_type,
            },
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            render: (created_at) => {
                const date = new Date(created_at);
                const day = ("0" + date.getDate()).slice(-2);
                const month = ("0" + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear();
                let hours = date.getHours();
                const minutes = ("0" + date.getMinutes()).slice(-2);
                const seconds = ("0" + date.getSeconds()).slice(-2);
                let ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12;
                hours = hours ? hours : 12; // Handle midnight
                const formattedTime = `${hours}:${minutes}:${seconds} ${ampm}`;
                return `${day}/${month}/${year} | ${formattedTime}`;
            },
            sorter: {
                compare: (a, b) => a.phone_number.length - b.phone_number.length,
            },
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            render: (_, item) => (
                <div className="text-right d-flex">
                    {Permisson?.includes('Edit Chatbot') &&
                        <Tooltip title="View">
                            <Link to={`edit/${UserData?.id + '/'}${item.id}`}>
                                <Button
                                    type="primary"
                                    className="mr-2"
                                    icon={<EyeOutlined />}
                                    size="small"
                                />
                            </Link>
                        </Tooltip>
                    }
                    {Permisson?.includes('Delete Chatbot') &&
                        <Tooltip title="Delete">
                            <Button
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    showConfirm(item.id);
                                }}
                                size="small"
                            />
                        </Tooltip>
                    }
                </div>
            ),
        }

    ];

    const HandleUserChange = (id) => {
        if (id) {
            ChatbotData(id);
        } else {
            ChatbotData(UserData?.id);
        }
    }
    const [searchValue, setSearchValue] = useState("");
    // Filter the data based on the search keyword
    const OnSearch = (searchTerm) => {
        const filtered = chatbot?.filter((item) => {
            try {
                const keywords = JSON.parse(item?.keyword);
                return keywords.some((k) =>
                    k.toLowerCase().includes(searchTerm.toLowerCase())
                );
            } catch (e) {
                console.error("Error parsing keyword:", e);
                return false;
            }
        });
        setFilterData(filtered);
    }

    const handleSearch = (e) => {
        setSearchValue(e.target.value);
        OnSearch(e.target.value);
    };

    return (
        <Card bodyStyle={{ padding: '0px' }}>
            <div className="container-fluid">
                <Row justify="space-between" align="middle" className="py-4">
                    <Col xs={24} md={12} lg={8}>
                        <h2>Chatbot</h2>
                    </Col>
                    <Col xs={24} md={12} lg={16}>
                        <Row gutter={ROW_GUTTER} justify="end" align="middle">
                            <Col xs={24} sm={12} md={8} lg={4}>
                                <Form.Item>
                                    <UserSelectField users={users} HandleUserChange={HandleUserChange} />
                                </Form.Item>
                            </Col>
                            <Col xs={18} sm={12} md={8} lg={4}>
                                <Form.Item>
                                    <Input placeholder="Search" prefix={<SearchOutlined />}
                                        value={searchValue}
                                        onChange={handleSearch}
                                    // onKeyDown={handleKeyDown}
                                    />
                                </Form.Item>
                            </Col>
                            {Permisson?.includes('View Campaign') && (
                                <Col xs={6} sm={12} md={8} lg={4}>
                                    <Form.Item>
                                        <Link to={`add/${UserData?.id}`}>
                                            <Button type="primary">
                                                <PlusOutlined />
                                                <span>New</span>
                                            </Button>
                                        </Link>
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </div>

            <div className="table-responsive">
                <Table
                    expandable={{
                        expandedRowRender: (record) => <p className='ml-5'>{record?.external_url}</p>,
                    }}
                    loading={loading}
                    columns={tableColumns}
                    dataSource={filterData?.length > 0 ? filterData : chatbot}
                    rowKey="id" />
            </div>
        </Card >
    );
};

export default ChatbotByUser
