import React, { useState } from 'react';
import { Form, Input, InputNumber, Button, Card, Switch, Row, Col, message } from 'antd';
import axios from 'axios';
import { useEffect } from 'react';
const { TextArea } = Input;
const ChatBotIdealTimer = ({ UserData, token, api }) => {
    const [form] = Form.useForm();
    const [minutes, setMinutes] = useState(0);
    const [customMessage, setCustomMessage] = useState('');
    const [showFields, setShowFields] = useState(false);
    const [resetOnNewMesage, setResetOnNewMesage] = useState(false);
    const [loading, setLoading] = useState(false);
    const fetchIdealTimer = async () => {
        try {
            const response = await axios.get(`${api}get-ideal-timer-by-user/${UserData?.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.data) {
                const { minutes, message, status, reset_on_message } = response.data?.data;
                setMinutes(minutes);
                setCustomMessage(message);
                setShowFields(status === 1);
                setResetOnNewMesage(reset_on_message === 1);
                form.setFieldsValue({ minutes, message });
            }
        } catch (error) {
            message.error('Failed to fetch Idle timer data.');
        } finally {
        }
    };

    useEffect(() => {
        fetchIdealTimer()
    }, []);

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const payload = {
                user_id: UserData?.id,
                minutes: minutes,
                message: customMessage,
                status: showFields === true ? 1 : 0,
                reset_on_message: resetOnNewMesage === true ? 1 : 0,
            }
            const response = await axios.post(`${api}store-ideal-timer`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.data?.status) {
                message.success('Idle timer saved successfully!');
            }
        } catch (error) {
            message.error('Failed to save Idle timer.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card title="Chatbot Idle Timer Response">
            {/* Switch and Timer in one line */}
            <Row gutter={16} align="middle">
                <Col span={24}>
                    <Form.Item label="Set Idle Timer Response">
                        <Switch
                            checked={showFields}
                            onChange={(checked) => setShowFields(checked)}
                        />
                    </Form.Item>
                </Col>
                {showFields && (
                    <>
                        <Col>
                            <Form.Item
                                label="Timer (in minutes)"
                                name="minutes"
                                rules={[{ required: true, message: 'Please select the timer duration!' }]}
                            >
                                <InputNumber
                                    min={0}
                                    max={999}
                                    className='w-75'
                                    value={minutes}
                                    onChange={(value) => {
                                        if (!isNaN(value) && value.toString().length <= 3) {
                                            setMinutes(value);
                                        }
                                    }}
                                    onKeyDown={(event) => {
                                        if (!/[0-9]/.test(event.key) && event.key !== 'Backspace') {
                                            event.preventDefault();
                                        }
                                    }}
                                    style={{ width: '100%' }}
                                />
                                <p className='d-none'>{minutes}</p>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label="Activate Session On New Message">
                                <Switch
                                    checked={resetOnNewMesage}
                                    onChange={(checked) => setResetOnNewMesage(checked)}
                                />
                            </Form.Item>
                        </Col>
                    </>
                )}
            </Row>

            {/* Conditionally render message field based on switch state */}
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
            >
                {showFields && (
                    <Form.Item
                        label="Enter Your Message"
                        name="message"
                        rules={[{ required: true, message: 'Please enter your message!' }]}
                    >
                        <TextArea
                            rows={4}
                            value={message}
                            onChange={(e) => setCustomMessage(e.target.value)}
                        />
                    </Form.Item>

                )}
                <Form.Item>
                    <Button type="primary" htmlType='submit' loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default ChatBotIdealTimer;