import { motion } from "framer-motion";
import { Button, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const FloatingButton = ({ uploadModalOpen, setUploadModalOpen, loading, buttonStyle }) => {
    return (
        <Tooltip title={'Add New'}>
            <motion.div
                initial={{ scale: 1, y: 0 }} // Initial state
                whileHover={{ scale: 1.1, y: -3 }} // Button lifts slightly on hover
                whileTap={{ scale: 0.9 }} // Button shrinks slightly on click
                animate={{ rotate: uploadModalOpen ? 45 : 0 }} // Rotates when modal is open
                transition={{ type: "spring", stiffness: 300 }}
                style={{
                    position: "fixed",
                    bottom: "6rem",
                    right: "5rem",
                    zIndex: 1000,
                }}
            >
                <Button
                    shape="circle"
                    size="large"
                    type="primary"
                    onClick={() => setUploadModalOpen(!uploadModalOpen)}
                    disabled={loading}
                    icon={
                        <PlusOutlined />
                    }
                    style={{
                        ...buttonStyle,
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    }}
                />
            </motion.div>
        </Tooltip>
    );
};

export default FloatingButton;
