import React from 'react'
import LowCreditModal from './LowCreditModal'
import ScheduleModel from './ScheduleModel'
import SelectMediaModel from './SelectMediaModel'
import ProgressModal from './ProgressModal'

const ModeLindex = (props) => {
    const { open, handleCancel, UserData, openScheduleModel, handleCancelScheduleModel, HandleScheduleTime, HandleSchedule, openMediaModel,
        handleCancelMediaModel, HandleUploadImage, requestType, handleCustomMedia, progress, isModalVisible, loading, conicColors, resetCampaign, navigate
    } = props
    return (
        <>
            {/* for belance alert  */}
            <LowCreditModal open={open} handleCancel={handleCancel} UserData={UserData} />
            {/* schedule model  */}
            <ScheduleModel
                openScheduleModel={openScheduleModel}
                handleCancelScheduleModel={handleCancelScheduleModel}
                HandleScheduleTime={HandleScheduleTime}
                HandleSchedule={HandleSchedule}
            />
            {/* model for media file  */}
            <SelectMediaModel
                openMediaModel={openMediaModel}
                handleCancelMediaModel={handleCancelMediaModel}
                HandleUploadImage={HandleUploadImage}
                requestType={requestType}
                handleCustomMedia={handleCustomMedia}
            />
            {/* progress model */}
            <ProgressModal
                progress={progress}
                isModalVisible={isModalVisible}
                loading={loading}
                conicColors={conicColors}
                resetCampaign={resetCampaign}
                navigate={navigate}
            />
        </>
    )
}

export default ModeLindex
