import { Card, Form, TimePicker, Button, Switch, Col, Row, Tag, message, Alert, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { DeFautRes } from '../Compoenents/DefaultRes';
import { useMyContext } from 'Context/MyContextProvider';
import axios from 'axios';

const ChatBotConfig = () => {
    const { authToken, api, UserData, templates } = useMyContext()
    const id = UserData?.id;
    const [customMessage, setCustomMessage] = useState('');
    const [requestType, setRequestType] = useState('');
    const [templateName, setTemplateName] = useState('');
    const [alertNumber, setAlertNumber] = useState('');
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [isTimingEnabled, setIsTimingEnabled] = useState(false);
    const [isTimeIncluded, setIsTimeIncluded] = useState(false);

    const fetchChatbotSettings = async () => {
        try {
            const response = await axios.get(`${api}chatbot-setting/${id}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            const data = response.data?.data;
            setIsTimingEnabled(data?.chatbot_timer === 1);
            setCustomMessage(data?.default_timing_res);
            setRequestType(data?.default_timing_res_type);
            setTemplateName(data?.default_timing_template);
            setAlertNumber(data?.alert_number);
            setIsTimeIncluded(data?.including);
            setStartTime(moment(data?.timer_start_time, "hh:mm A"));
            setEndTime(moment(data?.timer_end_time, "hh:mm A"));
        } catch (error) {
            const err = error.response?.data;
            const ErrorMessage = err?.error;
            const Message = err?.message;
            message.error(ErrorMessage || Message);
        }
    };

    useEffect(() => {
        if (id) {
            fetchChatbotSettings();
        }
    }, []);

    const handleSubmit = async (values) => {
        //console.log(values)
        const requestData = {
            chatbot_timer: isTimingEnabled,
            timer_start_time: isTimingEnabled && values.startTime ? values.startTime.format("hh:mm A") : null,
            timer_end_time: isTimingEnabled && values.endTime ? values.endTime.format("hh:mm A") : null,
            including: isTimeIncluded,
            default_timing_res_type: requestType,
            default_timing_template: templateName,
            default_timing_res: customMessage,
        };

        try {
            const response = await axios.post(`${api}update-chatbot-settings/${id}`, requestData, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                }
            });
            if (response.data.status) {
                message.success('Chatbot settings updated successfully');
            } else {
                message.error('Failed to update chatbot settings');
            }
        } catch (error) {
            const err = error.response?.data
            const ErrorMessage = err?.error
            const Message = err?.message
            message.error(ErrorMessage || Message);
        }
    };

    const onChange = e => {
        setRequestType(e.target.value);
    };

    const HandleTemplate = (value) => {
        setTemplateName(value);
    }

    return (
        <Card title="Chatbot Hours">
            <Form layout='horizontal' onFinish={handleSubmit}>
                <Row gutter={ROW_GUTTER}>
                    <Col span={24}>
                        <Alert
                            className='mb-3'
                            message={
                                <>
                                    <strong>Important Note:</strong>
                                    <ul>
                                        <li>Please be aware that after configuring these settings, your chatbot will only be active during the specified time period.</li>
                                        <li>Ensure you select a template that does not contain dynamic values for optimal performance.</li>
                                    </ul>
                                    <p>If you have any questions or need assistance, feel free to reach out to our support team.</p>
                                </>
                            }
                            banner
                            closable
                        />
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Enable ChatBot Hours">
                            <Switch
                                checked={isTimingEnabled}
                                onChange={setIsTimingEnabled}
                            />
                        </Form.Item>
                    </Col>

                    {isTimingEnabled && (
                        <Col xs={24} sm={12} md={24}>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Form.Item
                                        label="Start Time"
                                        name="startTime"
                                        rules={[{ required: true, message: 'Please select start time!' }]}
                                    >
                                        <TimePicker
                                            format="hh:mm A"
                                            value={startTime}
                                            onChange={(time) => setStartTime(time)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Form.Item
                                        label="End Time"
                                        name="endTime"
                                        rules={[
                                            { required: true, message: 'Please select end time!' },
                                            {
                                                validator: (_, value) => {
                                                    if (value && startTime && value.isBefore(startTime)) {
                                                        return Promise.reject(new Error('End time must be after start time.'));
                                                    }
                                                    return Promise.resolve();
                                                }
                                            }
                                        ]}
                                    >
                                        <TimePicker
                                            format="hh:mm A"
                                            defaultValue={endTime}
                                            onChange={(time) => setEndTime(time)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={8}>
                                    <Form.Item label={`${isTimeIncluded ? 'OutSide' : 'Within'} Hours`} name="isTimeIncluded">
                                        <Switch checked={isTimeIncluded} onChange={setIsTimeIncluded} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={24} className='text-center'>
                                    <Tag color='red' className='my-2'>
                                        <strong>
                                            Chatbot will be functional {isTimeIncluded ? 'outside' : 'during'} the mentioned hours window
                                        </strong>
                                    </Tag>
                                </Col>
                                {/* <Col xs={24} sm={12} md={24}>
                                    <Form.Item
                                        label="Alert Number"
                                        name="alertNumber"
                                        rules={[{ required: true, message: 'Please enter a valid phone number!' }]}
                                    >
                                        <Input
                                            value={alertNumber}
                                            onChange={(e) => setAlertNumber(e.target.value)}
                                            placeholder="Enter phone number for alerts"
                                        />
                                    </Form.Item>
                                </Col> */}
                                <Col xs={24} sm={12} md={24}>
                                    <h4>Default Out of Hours Message </h4>
                                    <DeFautRes
                                        requestType={requestType}
                                        onChange={onChange}
                                        setCustomMessage={setCustomMessage}
                                        templateName={templateName}
                                        HandleTemplate={HandleTemplate}
                                        templates={templates}
                                        customMessage={customMessage} />
                                </Col>
                            </Row>
                        </Col>
                    )}
                    <Col xs={24} sm={12} md={24}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">Save</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
}

export default ChatBotConfig;