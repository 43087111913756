import React from 'react'
import { Select } from 'antd'
const { Option } = Select;
const SelectTemplate = ({ templateName, HandleTemplate, templateList }) => {
    return (
        <Select
            size="default"
            value={templateName}
            style={{ width: '100%' }}
            onChange={(value) => HandleTemplate(value)}
            showSearch
        >
            <Option value="choose...">select</Option>
            {templateList?.filter(item => item.status === "APPROVED").length > 0 ? (
                
                templateList
                    ?.filter(item => item.status === "APPROVED")
                    ?.map((item, index) => (
                        <Option value={item.name} key={index}>
                            {item.name}
                        </Option>
                    ))
            ) : (
                <Option value="">
                    You Don't Have Any Approved Templates
                </Option>
            )}
        </Select>
    )
}

export default SelectTemplate
