import React, { useCallback, useEffect, useState } from 'react'
import { Card, Table, Button, Form, DatePicker, Input, message, Row, Col } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import axios from 'axios';
import { SearchOutlined } from '@ant-design/icons';
import { useMyContext } from 'Context/MyContextProvider';
import { debounce } from 'lodash';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { UserSelectField } from '../CustomUtils.js/CommonFieldUtils';
const { RangePicker } = DatePicker;

const Reports = () => {
    const { api, authToken, UserData, Permisson, navigate, DownloadExcelFile, encryptData, UserList, userRole, isMobile } = useMyContext();
    useEffect(() => {
        if (!Permisson?.includes('View Incoming Reports')) {
            navigate('404')
        }
    }, [Permisson]);

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [users, setUsers] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [userId, setUserId] = useState(UserData?.id);



    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'Name',
            sorter: {
                compare: (a, b) => a.Name.length - b.Name.length,
            },
        },
        {
            title: 'Message',
            dataIndex: 'Message',
            render: Message => {
                const containsNumbers = Message?.match(/\d+/g);
                if (containsNumbers && Permisson?.includes('Encrypt content')) {
                    let encryptedText = Message;
                    containsNumbers.forEach(numericValue => {
                        encryptedText = encryptedText?.replace(numericValue, encryptData(numericValue));
                    });
                    return encryptedText;
                }
                else { return Message }
            },
            sorter: {
                compare: (a, b) => a.Message.length - b.Message.length,
            },
        },
        {
            title: 'From',
            dataIndex: 'From',
            render: From => {
                const data = Permisson?.includes('Encrypt Number') ? encryptData(From) : From;
                return (
                    <span>
                        {data}
                    </span>
                );
            },
            sorter: {
                compare: (a, b) => a.From.length - b.From.length,
            },
        },
        {
            title: 'To',
            dataIndex: 'To',
            //   render: date => <span>{dayjs.unix(date).format('MM/DD/YYYY')} </span>,
            sorter: {
                compare: (a, b) => a.To.length - b.To.length,
            },
        },
        {
            title: 'Date',
            dataIndex: 'Date',
            render: Date => {
                const date = dayjs.unix(Date);
                const formattedDate = date.format('MM/DD/YYYY');
                const formattedTime = date.format('hh:mm:ss A');
                return (
                    <span>
                        {formattedDate} | {formattedTime}
                    </span>
                );
            },
            sorter: {
                compare: (a, b) => a.timestamp.length - b.timestamp.length,
            },
        },
    ];

    const fetchReportList = useCallback(async (id) => {
        if (!id) return;
        setLoading(true);
        const params = {};
        if (startDate) {
            params.startDate = dayjs(startDate, 'DD/MM/YY').format('DD/MM/YY');
        }
        if (endDate) {
            params.endDate = dayjs(endDate, 'DD/MM/YY').format('DD/MM/YY');
        }
        if (searchValue) {
            params.search = searchValue.toLowerCase();
        }

        try {
            const response = await axios.get(`${api}reports/${id}`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                },
                params
            });
            const data = response.data.reverse();
            setReports(data);
        } catch (error) {
            message.error('Failed to fetch reports');
        } finally {
            setLoading(false);
        }
    }, [api, authToken, startDate, endDate, searchValue]);

    const debouncedFetchReports = useCallback(
        debounce((id) => fetchReportList(id), 300),
        [fetchReportList]
    );

    useEffect(() => {
        if (userId) {
            debouncedFetchReports(userId);
        }
    }, [userId, startDate, endDate, searchValue, debouncedFetchReports]);

    useEffect(() => {
        setUsers(UserList);
    }, [UserList]);

    const HandleUserChange = (value) => {
        setUserId(value);
    };

    const HandleDateRangeChange = (dates) => {
        if (dates && dates.length > 0) {
            const sortedDates = dates.slice().sort((a, b) => new Date(a) - new Date(b));
            const formattedDates = sortedDates.map(date => dayjs(date).format('DD/MM/YY'));
            setStartDate(formattedDates[0]);
            setEndDate(formattedDates[formattedDates.length - 1]);
        } else {
            setStartDate(null);
            setEndDate(null);
        }
    };



    const onSearch = (e) => {
        if (e.key === 'Enter') {
            debouncedFetchReports(userId);
        }
    };

    const handleExportWithEncryption = useCallback(() => {
        const encryptedReports = reports.map(item => ({
            ...item,
            Message: item.Message
                ? (Permisson?.includes('Encrypt content')
                    ? item.Message.replace(/\d+/g, match => encryptData(match))
                    : item.Message)
                : '',
            From: item.From
                ? (Permisson?.includes('Encrypt Number') ? encryptData(item.From) : item.From)
                : '',
        }));
        DownloadExcelFile(encryptedReports, 'incoming-report.xlsx');
    }, [reports, Permisson, encryptData, DownloadExcelFile]);
    
    return (
        <Card bodyStyle={{ padding: '0px' }}>
            <div className="container-fluid">
                <Form
                    name="basicInformation"
                    layout={`${isMobile ? 'vertical' : 'horizontal'} `}
                >
                    <Row gutter={ROW_GUTTER} justify="space-between" align="middle" className="py-4">
                        <Col>
                            <h2>Incoming Reports</h2>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={16}>
                            <Row justify="end" align="middle">
                                {(userRole === 'Admin' || userRole === 'Reseller') &&
                                    <Col span={24} xxl={6}>
                                        <div className="mr-md-3 mb-3">
                                            <Form.Item>
                                                <UserSelectField users={users} HandleUserChange={HandleUserChange} />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                }
                                <Col span={24} xxl={6}>
                                    <div className="mr-md-3 mb-3">
                                        <Form.Item label="Dates" className='mb-0'>
                                            <RangePicker
                                                style={{ width: '100%' }}
                                                allowClear onChange={(dates) => HandleDateRangeChange(dates)}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={14} xxl={6}>
                                    <div className="md-3 mb-3">
                                        <Form.Item label="" className='mb-0'>
                                            <Input placeholder="Search" prefix={<SearchOutlined />}
                                                onChange={e => setSearchValue(e.currentTarget.value.toLowerCase())}
                                                onKeyDown={(e) => onSearch(e)}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={10} xxl={4}>
                                    <div className="mb-3 text-center">
                                        <Button
                                            icon={<CloudDownloadOutlined />}
                                            onClick={handleExportWithEncryption}>
                                            Export Report</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div className="table-responsive">
                <Table
                    loading={loading}
                    columns={tableColumns}
                    dataSource={reports}
                    rowKey="id" />
            </div>
        </Card >
    );
};

export default Reports
