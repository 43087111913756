import { Col, Form, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React from 'react'

const UrlActionInput = (props) => {
    const { urlActionType, urlText, urlXml, urlJsonKey, setUrlText, setUrlXml, setUrlJsonKey } = props
    return (
        <>
            {
                urlActionType === 'Text' ?
                    <Col xs={24} sm={24} md={24}>
                        <Form.Item label={`Enter custom text message`}>
                            <TextArea value={urlText} onChange={(e) => setUrlText(e.target.value)} />
                        </Form.Item>
                    </Col>
                    :
                    urlActionType === 'XML' ?
                        <Col xs={24} sm={24} md={24}>
                            <Form.Item label={`Response`}>
                                <Input value={urlXml} onChange={(e) => setUrlXml(e.target.value)} />
                            </Form.Item>
                        </Col>
                        :
                        urlActionType === 'Url' &&
                        <Col xs={24} sm={24} md={24}>
                            <Form.Item label={`Json Key`}>
                                <Input value={urlJsonKey} onChange={(e) => setUrlJsonKey(e.target.value)} />
                            </Form.Item>
                        </Col>
            }
        </>
    )
}

export default UrlActionInput
