import { Form, Input } from 'antd'
import { useMyContext } from 'Context/MyContextProvider'
import React, { useEffect, useState } from 'react'

const CommonNumberField = ({ numbers, number, setNumber, groupsKey }) => {
    const { getNumberCount } = useMyContext()
    const [singleLineNumber, setSingleLineNumber] = useState([])

    useEffect(() => {
        if (numbers?.length > 0) {
            const numbersWithNewlines = numbers?.join('\n');
            setSingleLineNumber(numbersWithNewlines)
        }
    }, [numbers])
    useEffect(() => {
        setSingleLineNumber([])
    }, [groupsKey]);
    return (
        <>
            {numbers?.length > 0 &&
                <Form.Item className='m-0' label="Mobile Number from Groups">
                    <Input.TextArea
                        rows={numbers?.length >= 10 ? 10 : 5}
                        type="text"
                        value={singleLineNumber}
                        className='cursor-not-allowed'
                    >
                    </Input.TextArea>
                </Form.Item>
            }
            <Form.Item className='m-0' label={`${numbers?.length > 0 ? 'Additional ' : ''}Mobile Numbers`} >
                <Input.TextArea
                    rows={5}
                    type="text"
                    value={number}
                    onChange={(e) => {
                        const newValue = e.target.value.replace(/[^0-9\n]/g, '');
                        setNumber(newValue);
                    }}
                />
                <p className='float-right'>Max 5000 Numbers | Total numbers: {getNumberCount(numbers, number)}</p>
            </Form.Item>
        </>
    )
}

export default CommonNumberField
