import { Alert, Button, Card, Col, Form, Input, message, Modal, Radio, Row, Select, Switch, Tabs } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

import { useMyContext } from 'Context/MyContextProvider';
import { CloudUploadOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { motion } from "framer-motion"
import Flex from 'components/shared-components/Flex';
import "react-transliterate/dist/index.css";

import CarouselPreview from '../carouselPreview';
import MediModel from '../../Campaign/MediModel';
import SelectMediaModel from '../../Campaign/CampainComps/SelectMediaModel';

const CarouselCampaign = () => {
    const { UserData, api, authToken, templates, Permisson, navigate, buttonStyle } = useMyContext()
    useEffect(() => {
        if (!Permisson?.includes('View Campaign')) {
            navigate('404')
        }
    }, [Permisson]);
    const location = useLocation();

    const [templateList, setTemplateList] = useState([]);
    const [componentSize, setComponentSize] = useState('small');
    const [number, setNumber] = useState('');
    const [customMessage, setCustomMessage] = useState('');
    const [numberError, setNumberError] = useState(false);
    const [requestType, setRequestType] = useState('');
    const [dynamicValues, setDynamicValues] = useState([]);
    const [dynamicButtonUrl, setDynamicUrlButton] = useState([]);
    const [templateName, setTemplateName] = useState('');
    const [newMesaage, setNewMessage] = useState('');
    const [name, setName] = useState('');
    const [templatePreview, setTemplatePreview] = useState([])
    const [mediaLink, setMediaLink] = useState('')
    const [thumbnail, setThumbnail] = useState('')
    const [template, setTemplate] = useState({ body: null });
    const [templateKey, setTemplateKey] = useState(0);
    const [templateInputs, setTemplateInputs] = useState([]);
    const [templateUrlInputs, setTemplateUrlInputs] = useState([]);
    const [urlButtons, setUrlButtons] = useState([]);
    const [numbers, SetNumbers] = useState([]);

    const [singleLineNumber, setSingleLineNumber] = useState([])

    const [error, setError] = useState('');
    const [templateCategory, setTemplateCategory] = useState();
    const [blockNumbers, setBlockNumbers] = useState([]);
    const [templateLanguage, setTemplateLanguage] = useState('');
    const [openMediaModel, setOpenMediaModel] = useState(false);
    const [openScheduleModel, setOpenSchedule] = useState(false);
    const [loading, setLoading] = useState(false);

    const [initialTemplates, setInitialTemplates] = useState([]);
    const [mainBody, setMainBody] = useState(null);

    const [mainBodyInputs, setMainBodyInputs] = useState([]);

    const [cardData, setCardData] = useState([]);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [cards, setCards] = useState();


    //edit states
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(false);
    const [exitingMediaIDs, setExistingMediaIDs] = useState([]);

    //edit code
    useEffect(() => {
        // Check if we're in edit mode
        if (location.state?.isEditing && location.state?.presetData) {
            const presetData = location.state.presetData;
            setIsEditing(location.state?.isEditing)
            setEditId(presetData?.id)
            let Object = JSON.parse(presetData?.object)
            let cards = Object.template.components[0].cards
            const imageIds = cards?.map(card => card.components[0]?.parameters[0]?.image?.id)?.filter(id => id !== undefined);
            setExistingMediaIDs(imageIds)

            let TemplateName = Object?.template?.name;
            setTemplateCategory(presetData?.category)
            HandleTemplate(TemplateName)
            setName(presetData?.name)
            // console.log(location.state?.isEditing)
        }
    }, [location.state]);


    const GetBlockNumber = async () => {
        try {
            const response = await axios.get(`${api}get-block-numbers/${UserData.id}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            const serverBlocked = response.data.serverBlocked
            const userBlocked = response.data.serverBlocked

            const formatNumber = (number) => {
                const digits = number.toString();
                if (digits.length === 12) {
                    return digits;
                } else if (digits.length === 10) {
                    return '91' + digits;
                } else {
                    // Handle other cases if needed
                    return null; // Or throw an error
                }
            };
            // Extract and format numbers from serverBlocked
            const serverNumbers = serverBlocked.map(item => formatNumber(item.numbers)).filter(Boolean);

            // Extract and format numbers from userBlocked
            const userNumbers = userBlocked.map(item => formatNumber(item.numbers)).filter(Boolean);


            // Combine both arrays into one
            const total = [...serverNumbers, ...userNumbers];
            setBlockNumbers([...new Set(total)]);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        GetBlockNumber()
    }, [])


    useEffect(() => {
        if (templates.length > 0) {
            const carouselTemplates = templates?.filter(template =>
                template.components?.some(comp => comp.type === 'CAROUSEL')
            );
            setInitialTemplates(carouselTemplates)
            setTemplateList(carouselTemplates)
        }
    }, [templates])

    useEffect(() => {
        if (templateCategory) {
            const filteredList = initialTemplates.filter(item => item.category === templateCategory);
            setTemplateList(filteredList);
        } else {
            setTemplateList(initialTemplates);
        }
    }, [templateCategory, initialTemplates]);

    const onFormLayoutChange = ({ size }) => {
        setComponentSize(size);
    };




    useEffect(() => {
        if (number) {
            setNumberError(false)
        }
    }, [number])



    const HandleTemplate = (value) => {
        setMediaLink('');
        setTemplateName(value);
        setTemplateKey((prevKey) => prevKey + 1);

        // Find the selected template
        const template = templates?.find((item) => item.name === value);

        // Set template-related state
        setTemplateLanguage(template?.language);
        setTemplatePreview(template);

        // Extract main body and dynamic values
        const { templateCards, mainBodyTemp } = extractTemplateSections(template);
        setMainBody(mainBodyTemp);
        setCards(templateCards)

        const mainBodyDynamicValue = mainBodyTemp?.text?.match(/{{\d+}}/g);
        if (mainBodyDynamicValue) {
            const inputs = generateInputsForDynamicValues(mainBodyDynamicValue, 'mainBody');
            setMainBodyInputs(inputs);
        } else {
            setMainBodyInputs([]);
        }

        // Handle templateCards
        if (templateCards && templateCards.length > 0) {
            const cardData = templateCards.map((card, index) => {
                const cardBodyDynamicValue = card.body?.text?.match(/{{\d+}}/g);
                const medias = exitingMediaIDs.length > 0 ? exitingMediaIDs[index] : ''
                // Return the card with dynamic inputs and media-related fields
                return {
                    ...card,
                    body: card?.body,
                    inputs: cardBodyDynamicValue
                        ? generateInputsForDynamicValues(cardBodyDynamicValue, 'card', index)
                        : [],
                    mediaType: 'Media', // Initialize mediaType
                    mediaLink: medias, // Initialize mediaLink
                    mediaName: '', // Initialize mediaName
                    useExternalUrl: false, // Initialize useExternalUrl
                };
            });
            // console.log('HandleTemplate',cardData)
            if (!isEditing) {
                setCardData(cardData); // Set cardData with media-related fields
            }
        } else {
            setCardData([]);
        }
    };




    const extractTemplateSections = (template) => {
        if (template) {
            let mainBodyTemp = null;
            let templateCards = [];

            // Process components to extract main body and carousel cards
            template.components.forEach(section => {
                if (section.type === "BODY") {
                    mainBodyTemp = section;
                } else if (section.type === "CAROUSEL") {
                    templateCards = section.cards.map(card => {
                        let cardHeader = null;
                        let cardBody = null;
                        let cardButtons = null;

                        // Extract header, body, and buttons for each card
                        card.components.forEach(cardSection => {
                            switch (cardSection.type) {
                                case "HEADER":
                                    cardHeader = cardSection;
                                    break;
                                case "BODY":
                                    cardBody = cardSection;
                                    break;
                                case "BUTTONS":
                                    cardButtons = cardSection;
                                    break;
                                default:
                                    break;
                            }
                        });

                        return { header: cardHeader, body: cardBody, buttons: cardButtons };
                    });
                }
            });
            return { mainBodyTemp, templateCards }
            // setMainBody(mainBodyTemp);
            // setCarouselCards(cards);
        }
    }

    const generateInputsForDynamicValues = (dynamicValues, type, cardIndex) => {
        return dynamicValues.map((placeholder, index) => (
            <Input
                key={index}
                type="text"
                placeholder={`Enter value for ${placeholder}`}
                onChange={(e) => handleInputChange(index, e.target.value, type, cardIndex)}
            />
        ));
    }

    const handleInputChange = (index, value, type, cardIndex) => {
        if (type === 'mainBody') {
            setDynamicValues(prevValues => {
                const updatedValues = [...prevValues];
                updatedValues[index] = value;
                return updatedValues;
            });
        } else if (type === 'card') {
            setCardData(prevCards => {
                const updatedCards = [...prevCards];
                updatedCards[cardIndex].inputs[index] = value;
                return updatedCards;
            });
        }
    };


    const HandleDynamicValues = (templatePreview) => {
        if (dynamicValues.length > 0) {
            const text = templatePreview?.components[1]?.text;
            let updatedText = text;
            dynamicValues.forEach((item, index) => {
                updatedText = updatedText?.replace(`{{${index + 1}}}`, item);
            });
            setNewMessage(updatedText); // Log the updated text array
        }
    }

    useEffect(() => {
        HandleDynamicValues(templatePreview)
    }, [templatePreview, dynamicValues])





    useEffect(() => {
        if (requestType === 'template') {
            setCustomMessage('')
        } else {
            setTemplatePreview([])
            setTemplateInputs([]);
            setDynamicValues([]);
            setTemplateUrlInputs([]);
            setDynamicUrlButton([]);
            setTemplate({
                body: null,
            });

        }
    }, [requestType])




    useEffect(() => {
        if (numbers?.length > 0) {
            const numbersWithNewlines = numbers?.join('\n');
            setSingleLineNumber(numbersWithNewlines)
        }
        // console.log(numbers)
    }, [numbers])

    // for media model 
    const showMediaModel = (index) => {
        setCurrentCardIndex(index);  // Set the current card index
        setOpenMediaModel(true);
    };
    const handleCancelMediaModel = () => {
        setOpenMediaModel(false);
    };



    const HandleUploadImage = (path, name) => {
        if (path) {
            setExistingMediaIDs(prevMediaIDs => {
                const updatedMediaIDs = [...prevMediaIDs];
                updatedMediaIDs[currentCardIndex] = path;
                return updatedMediaIDs;
            });
            setCardData((prevData) =>
                prevData.map((card, index) =>
                    index === currentCardIndex
                        ? { ...card, mediaLink: path, mediaName: name }
                        : card
                )
            );
            setOpenMediaModel(false);
        }
    };

    // send message code start from here 
    const generateContent = (type, id) => {
        switch (type) {
            case 'IMAGE':
                return { image: { id: id } };
            case 'VIDEO':
                return { video: { id: id } };
            case 'DOCUMENT':
                return { document: { id: id } };
            default:
                return {};
        }
    };


    // Function to generate the payload for each message
    const generatePayload = (item, templateName, templateLanguage, dynamicValues) => {
        return {
            messaging_product: "whatsapp",
            to: item,
            type: "template",
            template: {
                name: templateName,
                language: { code: templateLanguage },
                components: [
                    //  ...(bodySection ? [bodySection] : []), // Include body section only if it exists
                    {
                        type: "CAROUSEL",
                        cards: cardData.map((card, cardIndex) => ({
                            card_index: cardIndex,
                            components: [
                                ...(card.header ? [
                                    {
                                        type: "HEADER",
                                        parameters: [
                                            {
                                                type: (() => {
                                                    switch (card.header?.format) {
                                                        case "IMAGE":
                                                            return "IMAGE";
                                                        case "VIDEO":
                                                            return "VIDEO";
                                                        case "DOCUMENT":
                                                            return "DOCUMENT";
                                                        default:
                                                            return "TEXT";
                                                    }
                                                })(),
                                                ...generateContent(card.header?.format, card.mediaLink),
                                            },
                                        ],
                                    },
                                ] : []),
                                ...(card.buttons && card.buttons.length > 0 ? card.buttons.map((button, buttonIndex) => ({
                                    type: "BUTTON",
                                    sub_type: button.type === "QUICK_REPLY" ? "QUICK_REPLY" : "URL",
                                    index: buttonIndex,
                                    ...(button.type === 'URL' && dynamicButtonUrl?.length ? {
                                        parameters: dynamicButtonUrl.map(url => ({ type: "text", text: url }))
                                    } : {}),
                                    ...(button.type === 'QUICK_REPLY' ? {
                                        parameters: [{ type: "payload", payload: "btntwo" }]
                                    } : {}),
                                })) : []),
                            ],
                        })),
                    }
                ]
            },
        }
    };


    const handleMediaLinkChange = (value, index) => {
        const newMediaLink = value;
        setCardData((prevData) =>
            prevData.map((card, i) =>
                i === index ? { ...card, mediaLink: newMediaLink } : card
            )
        );
    };

    const [showAlert, setShowAlert] = useState(false);
    const [isLoop, setIsLoop] = useState(true);
    useEffect(() => {
        if (showAlert) {
            message.error('Please provide the required media link and media type.');
        }
    }, [showAlert]);

    // Main handler function
    const ParepareRequest = async (e) => {
        e.preventDefault();
        const missingCardIndices = cardData
            .map((card, index) => card.mediaType === 'Media' && !card.mediaLink ? index + 1 : null)
            .filter(index => index !== null);

        if (missingCardIndices.length > 0) {
            // Create a message listing all problematic card numbers
            const cardNumbers = missingCardIndices.join(', ');
            message.error(`Cards ${cardNumbers} are missing media files. Please select and upload files for these cards.`);
            return; // Prevent form submission if there are errors
        }
        else if (templateName === '') {
            setError('You have to select a template first');
            return;
        }
        try {
            // Generate message payloads
            const messagePayloads = generatePayload(
                ':number:', templateName, templateLanguage, dynamicValues
            );
            HandleSubmit(messagePayloads)
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const HandleSubmit = async (payload) => {
        const data = {
            name: name, category: templateCategory, user_id: UserData?.id, object: payload
        }
        const url = !isEditing ? `${api}save-preset` : `${api}update-preset`
        if (isEditing) {
            data.id = editId
        }
        // console.log(data)
        // return
        try {
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            if (response.data.status) {
                navigate('/carousel/Preset')
            }
        } catch (error) {

        } finally {

        }
    }
    const handleSwitchChange = (checked) => {
        setIsLoop(checked)
    }

    useEffect(() => {
        if (exitingMediaIDs.length > 0) {
            const updatedCardData = cardData.map((card, index) => ({
                ...card,
                mediaLink: exitingMediaIDs[index]
            }));
            setCardData(updatedCardData)
        }
    }, [exitingMediaIDs]);

    const handleTabChange = (key) => {
        const newIndex = parseInt(key, 10);
        setCurrentCardIndex(newIndex);
    };
    return (
        <>
            <SelectMediaModel
            openMediaModel={openMediaModel}
            handleCancelMediaModel={handleCancelMediaModel}
            HandleUploadImage={HandleUploadImage}
            />
            <Row gutter={12}>
                {Permisson?.includes('View Campaign') &&
                    <>
                        <Col xs={24} sm={24} md={24} lg={16}>
                            <Card title="Carousel Preset">
                                {/* Error Alert */}
                                {error && (
                                    <Col span={24}>
                                        <motion.div
                                            initial={{ opacity: 0, marginBottom: 0 }}
                                            animate={{ opacity: error ? 1 : 0, marginBottom: error ? 20 : 0 }}
                                        >
                                            <Alert type="error" showIcon message={JSON.stringify(error)} />
                                        </motion.div>
                                    </Col>
                                )}
                                <Alert
                                    className='mb-3'
                                    message="Please select a template without dynamic values"
                                    banner
                                    closable
                                />
                                {isEditing &&
                                    <Alert
                                        className='mb-3'
                                        message="Only Update The Required Media"
                                        banner
                                        closable
                                    />
                                }
                                {/* Form */}
                                <Form
                                    name="basicInformation"
                                    labelCol={{ span: 8 }}
                                    // wrapperCol={{ span: 16 }}
                                    layout="vertical"
                                    initialValues={{ size: componentSize }}
                                    onValuesChange={onFormLayoutChange}
                                    size={componentSize}
                                >
                                    <Row gutter={[16, 16]}>
                                        {/* Campaign Name */}
                                        <Col span={isEditing ? 8 : 8}>
                                            <Form.Item
                                                label="Preset Name"
                                                name="Preset-Name"
                                                rules={[{ required: true, message: 'Please enter Preset name!' }]}
                                            >
                                                <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                                <p className="d-none">{name}</p>
                                            </Form.Item>
                                        </Col>
                                        {
                                            !isEditing &&
                                            <>
                                                {/* Template Category */}
                                                <Col span={6}>
                                                    <Form.Item label="Category" name="Template_category">
                                                        <Radio.Group
                                                            onChange={(e) => setTemplateCategory(e.target.value)}
                                                            value={templateCategory}
                                                        >
                                                            <Radio value="MARKETING">Marketing</Radio>
                                                            <Radio value="UTILITY">Utility</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                    <p className="d-none">{templateCategory}</p>
                                                </Col>
                                                {/* Templates Selection */}
                                                {templateCategory && (
                                                    <Col span={8}>
                                                        <Form.Item label="Templates" name="Templates">
                                                            <Select
                                                                size="default"
                                                                style={{ width: '100%' }}
                                                                onChange={(value) => HandleTemplate(value)}
                                                                showSearch
                                                            >
                                                                <Select.Option value="choose...">Select</Select.Option>
                                                                {templateList?.length > 0 ? (
                                                                    templateList.map((item, index) => (
                                                                        <Select.Option value={item.name} key={index}>
                                                                            {item.name}
                                                                        </Select.Option>
                                                                    ))
                                                                ) : (
                                                                    <Select.Option value="">
                                                                        You Don't Have Any Approved Templates
                                                                    </Select.Option>
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                )}
                                            </>
                                        }
                                        {/* Carousel Cards */}
                                        {cardData.length > 0 && (
                                            <Col span={isEditing ? 16 : 24}>
                                                <Card title={`Total Cards : ${cardData?.length}`} style={{ width: '100%' }}>
                                                    <Tabs
                                                        defaultActiveKey="0"
                                                        type="card"
                                                        onChange={handleTabChange}
                                                        items={
                                                            cardData.map((card, cardIndex) => ({
                                                                key: String(cardIndex),
                                                                label: (
                                                                    `Card ${cardIndex + 1}`
                                                                ),
                                                                children: (
                                                                    <Card bordered={false} style={{ height: '100%' }}>
                                                                        {card.mediaType === 'Media' ? (
                                                                            <>
                                                                                <Form.Item
                                                                                    label={`Select Media File For Card ${cardIndex + 1}`}
                                                                                    name={`media-link-${cardIndex}`}
                                                                                >
                                                                                    <p>Card {cardIndex + 1} Body  : {card?.body.text}</p>
                                                                                    {!card.mediaLink ? (
                                                                                        <Button
                                                                                            style={buttonStyle}
                                                                                            onClick={() => showMediaModel(cardIndex)}
                                                                                        >
                                                                                            <CloudUploadOutlined /> Choose File
                                                                                        </Button>
                                                                                    ) : (
                                                                                        <>
                                                                                            <Input
                                                                                                type="text"
                                                                                                disabled
                                                                                                value={card.mediaLink}
                                                                                                className="mb-3"
                                                                                            />
                                                                                            {/* <span>{card.mediaLink}</span> */}
                                                                                            <Button
                                                                                                style={buttonStyle}
                                                                                                onClick={() => showMediaModel(cardIndex)}
                                                                                            >
                                                                                                <CloudUploadOutlined /> Choose Another File
                                                                                            </Button>
                                                                                        </>
                                                                                    )}
                                                                                </Form.Item>
                                                                                <Col span={24}>
                                                                                    {card.mediaLink && (
                                                                                        <CheckMediaValidity mediaLink={card.mediaLink} cardIndex={cardIndex} />
                                                                                    )}
                                                                                </Col>
                                                                                {/* {error && (
                                                                                )} */}
                                                                            </>
                                                                        ) : (
                                                                            <Form.Item
                                                                                label="Your media file link"
                                                                                name={`media-link-${cardIndex}`}
                                                                                rules={[
                                                                                    { required: true, message: 'Please enter a media link!' }
                                                                                ]}
                                                                            >
                                                                                <Input
                                                                                    type="text"
                                                                                    onChange={(e) =>
                                                                                        handleMediaLinkChange(e.target.value, cardIndex)
                                                                                    }
                                                                                    className="mb-3"
                                                                                />
                                                                            </Form.Item>
                                                                        )}
                                                                    </Card>
                                                                ),
                                                            }))}
                                                    />
                                                </Card>
                                            </Col>
                                        )}
                                        {/* Submit Buttons */}
                                        <Col span={24} className="mt-3 d-flex justify-content-end">
                                            <Form.Item>

                                                <Flex alignItems="center" gap={16}>
                                                    {/* make buck button */}
                                                    <Button

                                                        htmlType="submit"
                                                        onClick={() => navigate(-1)}
                                                        loading={loading}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    {Permisson?.includes('Create Campaign') && (
                                                        <Button
                                                            type="primary"
                                                            htmlType="submit"
                                                            onClick={(e) => ParepareRequest(e)}
                                                            loading={loading}
                                                        >
                                                            {isEditing ? 'Update' : 'Save'} Preset
                                                        </Button>
                                                    )}
                                                </Flex>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                        {/* mobile preview  */}
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Card title={
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <span>Campaign Preview</span>
                                    <Flex alignItems='center'>
                                        <span className='h5 m-0 mr-2 p-0'>Auto Scroll</span>
                                        <Switch checked={isLoop} onChange={handleSwitchChange} />
                                    </Flex>
                                </div>
                            }>
                                <CarouselPreview
                                    mainBody={mainBody}
                                    mediaLink={exitingMediaIDs}
                                    requestType={'template'}
                                    cards={cards}
                                    isLoop={isLoop}
                                    thumbnail={thumbnail}
                                    newMesaage={newMesaage}
                                    currentCardIndex={currentCardIndex}
                                    urlButtons={urlButtons}
                                    customMessage={customMessage}
                                />
                            </Card>
                        </Col>
                    </>
                }
            </Row >
        </>
    )
}
const CheckMediaValidity = ({ mediaLink, cardIndex }) => {
    const [isValid, setIsValid] = useState(null); // State to hold validity status
    const { CheckExpiredMedia } = useMyContext()
    useEffect(() => {
        const checkMedia = async () => {
            const valid = await CheckExpiredMedia(mediaLink);
            setIsValid(valid);
        };

        checkMedia();
    }, [mediaLink]);

    return (
        <div>
            {isValid === null ? (
                <p>Checking media validity...</p>
            ) : isValid ? (
                <></>
            ) : (
                // <p style={{ color: 'red' }}>Media is invalid or expired</p>
                <motion.div
                    initial={{ opacity: 0, marginBottom: 0 }}
                    animate={{ opacity: 1 , marginBottom: 20 }}
                >
                    <Alert type="error" showIcon message={'Media is invalid or expired'} />
                </motion.div>
            )}
        </div>
    );
};
export default CarouselCampaign
