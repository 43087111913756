import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Avatar, Button, Col, Divider, Dropdown, Image, Menu, Popover, Row, Space, Spin, Tag, Tooltip, Typography } from 'antd';
import { getInitials } from '../Conversation';
import { MessageOutlined, MailOutlined, PhoneOutlined, CalendarOutlined, MoreOutlined, PlayCircleOutlined, FilePdfOutlined, DownloadOutlined } from '@ant-design/icons';
import { getStatusIcon } from 'components/shared-components/AvatarStatus';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars-2';
import Flex from 'components/shared-components/Flex';
import bg from '../../../../../assets/image/web-chat.png';
import Title from 'antd/es/skeleton/Title';
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';
const { Text } = Typography;
const ChatContentBody = ({ messages, loading, keywords, number }) => {
    const { phone_id, waToken, api, authToken, UserData } = useMyContext()
    const chatBodyRef = useRef();

    const styles = useMemo(() => ({
        chatBackground: {
            backgroundImage: `url(${bg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        chatBubble: {
            borderRadius: '8px',
            padding: '10px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
            position: 'relative',
            // maxWidth:'80%',
            // overflow: 'hidden',
        },
        chatBubbleBefore: {
            content: '""',
            position: 'absolute',
            top: '0',
            left: '-5px',
            width: '0',
            height: '0',
            borderStyle: 'solid',
            borderWidth: '10px 10px 10px 0',
            borderColor: 'transparent white transparent transparent',
            background: '#ffff',
            backgroundColor: '#ffff',
            clipPath: 'polygon(0 0, 0 100%, 100% 100%)',
            transform: 'rotate(180deg)',
        },
        myChatBubbleAfter: {
            content: '""',
            position: 'absolute',
            top: '0',
            right: '-8px',
            width: '0',
            height: '0',
            borderStyle: 'solid',
            borderWidth: '0 0 15px 15px',
            borderColor: 'transparent transparent #d9fdd3 transparent',
            transform: 'rotate(180deg)'
        },
        oppositeBubble: {
            backgroundColor: '#ffff',
            color: '#000',
            marginLeft: '20px',
            minWidth: '13rem',
            maxWidth: '75%',
        },
        ownBubble: {
            // backgroundColor: '#22af4b',
            backgroundColor: '#D9FDD3',
            // color: '#fff',
            marginRight: '20px',
            alignSelf: 'flex-end',
        },
        chatTime: {
            textAlign: 'right',
            fontSize: '11px',
            color: 'gray',
            gap: 5
        },
        dateHeader: {
            textAlign: 'center',
            margin: '10px 0',
            fontSize: '12px',
            color: '#888',
            fontWeight: 'bold',
        },
    }), []);

    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollToBottom();
        }
    }, [messages]);

    const formatDate = useCallback((dateString) => {
        const today = moment().startOf('day');
        const yesterday = moment().subtract(1, 'days').startOf('day');
        const messageDate = moment(dateString).startOf('day');

        if (messageDate.isSame(today, 'day')) return 'Today';
        if (messageDate.isSame(yesterday, 'day')) return 'Yesterday';
        return messageDate.format('MMMM D, YYYY');
    }, []);

    const groupMessagesByDate = (messages) => {
        const groupedMessages = {};
        messages.forEach((message) => {
            const date = formatDate(message.time || message.created_at);
            if (!groupedMessages[date]) {
                groupedMessages[date] = [];
            }
            groupedMessages[date].push(message);
        });
        return groupedMessages;
    };

    const groupedMessages = useMemo(() => groupMessagesByDate(messages), [messages, formatDate]);


    const isChatBotMessage = (message) => {
        if (message) {
            if (keywords?.includes(message)) {
                return true
            } else {
                return false
            }
        }
    }

    const AgentContent = ({ data, activeChatCount }) => {
        const activeDays = data?.working_days?.split(',').map(day => day.trim()) || [];
        return (
            <>
                {data &&
                    <Row>
                        <Col className='d-flex align-items-center mb-3'>
                            <Avatar
                                size={40}
                                style={{
                                    backgroundColor: '#87d068',
                                    fontSize: '15px',
                                }}
                            >
                                {getInitials(data?.name)}
                            </Avatar>
                            <Title level={5} style={{ margin: '0 0 0 0.5rem', textAlign: 'center' }}>
                                {data?.name}
                            </Title>
                        </Col>
                        <Col span={24} className='mb-2'>
                            <Space direction="vertical" size="small" style={{ width: '100%' }}>
                                <Row align="middle">
                                    <Col flex="24px">
                                        <MessageOutlined style={{ color: '#8c8c8c' }} />
                                    </Col>
                                    <Col flex="auto">
                                        <Tag className='text-primary'>Active Chats : {activeChatCount}</Tag>
                                    </Col>
                                </Row>
                                <Row align="middle">
                                    <Col flex="24px">
                                        <MailOutlined style={{ color: '#8c8c8c' }} />
                                    </Col>
                                    <Col flex="auto">
                                        <Text copyable>{data?.email}</Text>
                                    </Col>
                                </Row>
                                <Row align="middle">
                                    <Col flex="24px">
                                        <PhoneOutlined style={{ color: '#8c8c8c' }} />
                                    </Col>
                                    <Col flex="auto">
                                        <Text copyable>{data?.phone}</Text>
                                    </Col>
                                </Row>
                            </Space>
                        </Col>

                        <Col span={24}>
                            <Row className='d-flex gap-3' align="middle" style={{ marginBottom: '8px' }}>
                                <Col className='d-flex' style={{ gap: '0.7rem' }}>
                                    <CalendarOutlined style={{ color: '#8c8c8c' }} />
                                    <Flex style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', maxWidth: '100%' }}>
                                        {activeDays?.map((day) => (
                                            <Tag
                                                key={day}
                                                color="blue"
                                                style={{
                                                    borderRadius: '12px',
                                                    padding: '0 8px',
                                                    margin: 0,
                                                    fontSize: '11px',
                                                    lineHeight: '16px',
                                                }}
                                            >
                                                {day}
                                            </Tag>
                                        ))}
                                    </Flex>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
            </>

        )
    }

    const menu = (
        <Menu>
            <Menu.Item key="1" >Reply</Menu.Item>
        </Menu>
    );
    const [isDownloading, setIsDownloading] = useState(false);
    const handleDownload = async (media_id, id) => {
        setIsDownloading(true);
        try {
            const firstResponse = await axios.get(`https://graph.facebook.com/v21.0/${media_id}?phone_number_id=${phone_id}`, {
                headers: { Authorization: `Bearer ${waToken}` }
            });
            const mediaUrl = firstResponse.data?.url;
            if (mediaUrl) {
                const secondResponse = await axios.post(`${api}retrieve-image-from-meta`, {
                    url: mediaUrl,
                    token: waToken,
                    parent_number: UserData?.whatsapp_number,
                    child_number: number,
                    report_id: id
                }, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    }
                });
                let media_url = secondResponse?.data?.media_url
                updateMediaUrl(id, media_url)
            } else {
                console.error('Media URL not found in the response.');
            }
        } catch (error) {
            console.error('Error downloading image:', error);
        } finally {
            setIsDownloading(false);
        }
    };


    const updateMediaUrl = (id, newMediaUrl) => {
        Object.keys(groupedMessages).forEach(date => {
            groupedMessages[date] = groupedMessages[date].map(message => {
                if (message.id === id) {
                    return {
                        ...message,
                        media_url: newMediaUrl
                    };
                }
                return message;
            });
        });
    };

    const getType = (message) => {
        let from = message?.from
        let type = from === 'opposite' ? message?.msgType : message?.type
        return type
    }

    const renderContent = (message) => {
    let from = message?.from
        let type = getType(message)
        let url = from === 'opposite' ? message?.media_url : message?.text
        switch (type) {
            case 'pending_image':
            return (
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <Image
                        src="/img/others/dummy-header.jpg" // Dummy image
                        alt="loading"
                        style={{
                            height: '200px',
                            objectFit: 'cover',
                            borderRadius: '8px',
                            filter: 'blur(2px)', // Optional: Add blur effect to indicate loading
                        }}
                        preview={false}
                    />
                    <Spin
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                </div>
            );
            case 'image':
                return (
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                        <Image
                            src={url || '/img/others/dummy-header.jpg'}
                            alt="message"
                            style={{
                                // width: '200px',
                                height: '200px',
                                objectFit: 'cover',
                                borderRadius: '8px',
                            }}
                            preview={(from === 'opposite' && url) || from === 'me'}
                        />
                        {(from === 'opposite' && !url) && (
                            <Tooltip title="Download Image">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={isDownloading ? <Spin /> : <DownloadOutlined style={{ fontSize: '24px' }} />}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        width: '60px',
                                        height: '58px',
                                        transform: 'translate(-50%, -50%)',
                                        backgroundColor: 'rgba(0, 0, 0, 0.48)',
                                        border: 'none',
                                    }}
                                    onClick={() => handleDownload(message?.media_id, message?.id)}
                                    disabled={isDownloading}
                                />
                            </Tooltip>
                        )}
                    </div>
                );
            case 'video':
                return (
                    <div className="text-center">
                        <PlayCircleOutlined style={{ fontSize: '5rem' }} /> <br />
                        <strong>Video</strong> <br />
                        <Text code className="h6">Preview Unavailable</Text>
                    </div>
                );
            case 'document':
                return (
                    <div className="text-center">
                        <FilePdfOutlined style={{ fontSize: '5rem' }} /> <br />
                        <strong>Document</strong> <br />
                        <Text code className="h6">Preview Unavailable</Text>
                    </div>
                );
            case 'text':
                return <span>{message?.text}</span>;
            default:
                return <span>{message?.text}</span>;
        }
    };

    return (
        <>

            <div className="chat-content-body" style={styles.chatBackground}>
                {loading ? (
                    <div className="text-center">
                        <Spin size='medium' />
                    </div>
                ) : (
                    <Scrollbars ref={chatBodyRef} autoHide>
                        {Object.keys(groupedMessages).map((date, index) => (
                            <React.Fragment key={index}>
                                {date !== 'Invalid date' && <Divider style={styles.dateHeader}>{date}</Divider>}
                                {groupedMessages[date].map((elm, i) => {
                                    let type = getType(elm)
                                    return (
                                        <>
                                            <div
                                                key={`msg-${elm.id}-${i}`}
                                                className={`msg ${elm.from === 'opposite' ? 'msg-recipient' : elm.from === 'me' ? 'msg-sent' : ''}`}
                                            >
                                                {elm.avatar && (
                                                    <div className="mr-2">
                                                        <Avatar src={elm.avatar} />
                                                    </div>
                                                )}
                                                {elm.msgType !== 'date' && (
                                                    <div className='d-flex flex-column'>
                                                        <div style={{ ...styles.chatBubble, ...(elm.from === 'opposite' ? styles.oppositeBubble : styles.ownBubble) }}>
                                                            <div style={{ ...(elm.from === 'opposite' ? styles.chatBubbleBefore : styles.myChatBubbleAfter) }} />

                                                            {elm.from === 'opposite' && !isChatBotMessage(elm.text) &&
                                                                <div className='float-right pl-2'>
                                                                    <Dropdown overlay={menu} placement="bottomLeft"
                                                                        overlayStyle={{
                                                                            position: 'fixed',
                                                                            left: menu.x,
                                                                            top: menu.y,
                                                                        }}
                                                                        trigger={['hover']}>
                                                                        <MoreOutlined style={{ fontSize: '12px', cursor: 'pointer' }} />
                                                                    </Dropdown>
                                                                </div>
                                                            }
                                                            {renderContent(elm)}
                                                            <div style={styles.chatTime} className={`mt-2 d-flex justify-content-end align-items-center`}>
                                                            {/* ${elm.type !== 'text' && 'mt-2'} */}
                                                                {type === 'image' &&
                                                                    <>
                                                                        <div className='d-flex'>
                                                                            {/* <p className='bg-warning text-right m-0 px-1 h6 rounded '>Media Expires In 2 Days</p> */}
                                                                            <Tag color='orange' bordered={false} className='p-0 py-1 px-1 m-0' style={{lineHeight:'16px', fontSize:'11px'}}>Media Expires In 2 Days</Tag>
                                                                        </div>
                                                                        <span>/</span>
                                                                    </>
                                                                }
                                                                {isChatBotMessage(elm.text) && elm.from === 'opposite' &&
                                                                    <>
                                                                        <div className='d-flex'>
                                                                            <p className='bg-dark text-right m-0 px-1 h6 rounded text-white'>Chatbot Message</p>
                                                                        </div>
                                                                        <span>/</span>
                                                                    </>
                                                                }
                                                                <div className="d-flex align-items-center gap-1">
                                                                    <span >
                                                                        {moment(elm.time || elm.created_at).format('HH:mm')}
                                                                    </span>
                                                                    {elm.from === 'me' &&
                                                                        getStatusIcon(elm.status)
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {elm.from === 'me' && elm?.agent_id &&
                                                    <div className={`msg ${elm.from === 'opposite' ? 'msg-recipient' : elm.from === 'me' ? 'msg-sent' : ''} agent cursor-pointer`}>
                                                        <Popover
                                                            content={<AgentContent data={elm.agent_id} activeChatCount={elm.agent_activeChatCount} />}
                                                            title="Agent Details"
                                                            trigger="hover"
                                                            placement="left"
                                                            overlayStyle={{
                                                                maxWidth: 400,
                                                                whiteSpace: 'nowrap',
                                                                zIndex: 9999,
                                                            }}
                                                        >
                                                            <Avatar style={{ backgroundColor: '#87d068' }}>
                                                                {getInitials(elm.agent_id?.name)}
                                                            </Avatar>
                                                        </Popover>
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    )
                                })}
                            </React.Fragment>
                        ))}
                    </Scrollbars>
                )}
            </div>
        </>
    );
};

export default ChatContentBody
