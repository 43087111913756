import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import store from './store';
import history from './history'
import Layouts from './layouts'
import { THEME_CONFIG } from './configs/AppConfig';
import './lang'
import { MyContextProvider } from 'Context/MyContextProvider';
import MouseTracker from 'views/app-views/whatsapp/Auth/MouseTracker';
import { logout } from 'store/slices/authSlice';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};



function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const handleLogoutOnClose = () => {
      // dispatch(logout());
    };

    window.addEventListener('unload', handleLogoutOnClose);

    return () => {
      window.removeEventListener('unload', handleLogoutOnClose);
    };
  }, [dispatch]);


  return (
      <div className="App">
        <Provider store={store}>
          <BrowserRouter history={history}>
            <ThemeSwitcherProvider
              themeMap={themes}
              defaultTheme={THEME_CONFIG.currentTheme}
              insertionPoint="styles-insertion-point"
            >
              <MouseTracker />
              <MyContextProvider>
                <Layouts />
              </MyContextProvider>
            </ThemeSwitcherProvider>
          </BrowserRouter>
        </Provider>
      </div>
  );
}

export default App;
