import React, { useEffect, useState } from 'react'
import { Card, Table, Input, Button, Modal, Row, Col, message, Tag, Form, Tooltip } from 'antd';
import { PlusCircleOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Flex from 'components/shared-components/Flex'
import { Link } from "react-router-dom";
import utils from 'utils'
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';
import AgentForm from './NewAgent';
import moment from 'moment';
const AgentList = () => {
    const { api, authToken, UserData, modifyNumber } = useMyContext();

    const [groupListData, setGroupData] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([])

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [nickname, setNickname] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [joiningDate, setJoiningDate] = useState(null);
    const [workingDays, setWorkingDays] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [badges, setBadges] = useState([]);
    const [workingStart, setWorkingStart] = useState(null);
    const [workingEnd, setWorkingEnd] = useState(null);
    const [error, setError] = useState();
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false)

    const GetAgents = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}support-agents`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            const data = (response.data.data).reverse();
            setGroupData(data);
            setFilteredUsers(data);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    useEffect(() => {
        GetAgents()
    }, [])

    const resetCodes = () => setGroupData(filteredUsers)
    const resetFields = () => {
        setName('');
        setError('')
        setEmail('');
        setNickname('');
        setPhone('');
        setJoiningDate('');
        setWorkingDays([]);
        setWorkingStart('');
        setWorkingEnd('');
        setStatus('');
    }
    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'name'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'email'),
        },
        {
            title: 'Nickname',
            dataIndex: 'nickname',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'nickname'),
        },
        {
            title: 'Mobile Number',
            dataIndex: 'phone',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'phone'),
        },
        {
            title: 'Active Chats',
            dataIndex: 'active_chat_count',
        },
        {
            title: 'Status',
            dataIndex: 'online',
            render: (online) => (
                <Tag color={online === 1 ? 'green' : 'volcano'}>
                    {online === 1 ? 'Online' : 'Offline'}
                </Tag>
            ),
        },
        {
            title: 'Working Days',
            dataIndex: 'working_days',
            render: (days) => (
                <>
                    {days.split(',').map((day, index) => (
                        <Tag key={index} color="blue">{day.trim().substring(0, 3)}</Tag>
                    ))}
                </>
            ),
        },
        {
            title: 'Last Online',
            dataIndex: 'last_online',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'last_online'),
            render: (text) => {
                if (!text) return <Tag color="gray">Not Available</Tag>;

                const timeAgo = moment(text).fromNow();
                const isRecent = moment().diff(moment(text), 'minutes') < 5;
                const color = isRecent ? 'green' : 'volcano';

                return (
                    <Tooltip title={`Last online: ${moment(text).format('YYYY-MM-DD HH:mm:ss')}`}>
                        <Tag color={color}>{timeAgo}</Tag>
                    </Tooltip>
                );
            }
        },
        {
            title: 'Working Hours',
            dataIndex: null,
            render: (_, item) => (
                <Flex alignItems="center" style={{ gap: '0.2rem' }}>
                    <Flex>
                        {moment(item?.working_start, 'HH:mm').format('hh:mm A')}
                    </Flex>
                    -
                    <Flex>
                        {moment(item?.working_end, 'HH:mm').format('hh:mm A')}
                    </Flex>
                </Flex>
            ),
            sorter: (a, b) => utils.antdTableSorter(a, b, 'working_end'),
        },
        {
            title: 'Active',
            dataIndex: 'status',
            filters: [
                { text: 'Active', value: 'active' },
                { text: 'Inactive', value: 'inactive' },
            ],
            onFilter: (value, record) => record.status.includes(value),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, item) => (
                <Flex alignItems="center" style={{ gap: '1rem' }}>
                    <Link to={'#'} className='d-flex h4' onClick={() => HandleEdit(item)}>
                        <EditOutlined />
                    </Link>
                </Flex>
            ),
        },
    ];

    const [editState, setEditState] = useState();
    const [editData, setEditData] = useState();
    const HandleEdit = (data) => {
        setEditData(data.id);
        setName(data?.user?.name);
        setEmail(data?.email);
        setBadges(JSON.parse(data?.keyword) || []);
        setNickname(data.nickname);
        setPhone(data.phone);
        setJoiningDate(data.joining_date ? moment(data.joining_date) : null); // Use moment for formatting if necessary
        setWorkingDays(data?.working_days.split(',') || []); // Assuming working_days is an array
        setWorkingStart(moment(data.working_start, 'HH:mm:ss'));
        setWorkingEnd(moment(data.working_end, 'HH:mm:ss'));
        setStatus(data.status);
        setEditState(true)
        setOpen(true)

    }

    //model
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const addAgents = async (formData) => {
        try {
            const response = await axios.post(`${api}support-agents`, formData, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            if (response.data.status) {
                //  message.success(response.data.message);
            }
        } catch (error) {
            console.error('Error adding agent:', error);
            throw error;
        }
    };

    const updateAgents = async (agentId, formData) => {
        try {
            const response = await axios.put(`${api}update-agents/${agentId}`, formData, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
        } catch (error) {
            console.error('Error updating agent:', error);
            throw error;
        }
    };

    function extractTime(dateString) {
        const date = new Date(dateString);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    }


    const handleCancel = () => {
        resetFields()
        setEditData("");
        setEditState(false);
        setOpen(false);
    };
    const [searchText, setSearchText] = useState('');

    const handleSearch = (value) => {
        setSearchText(value);
        if (value) {
            const searchLower = value.toLowerCase();
            const filtered = groupListData.filter((item) =>
                tableColumns.some((column) =>
                    item[column.dataIndex]?.toString().toLowerCase().includes(searchLower)
                )
            );
            setGroupData(filtered);
        } else {
            resetCodes()
        }
    };

    useEffect(() => {
        handleSearch(searchText)
    }, [searchText]);

    const [form] = Form.useForm();

    const handleSubmit = async () => {
        setLoading(true);
        const formData = {
            name,
            user_id: UserData?.id,
            email,
            nickname,
            keyword: badges,
            phone: modifyNumber(phone),
            joining_date: joiningDate ? moment(joiningDate).format('YYYY-MM-DD') : null,
            working_days: workingDays?.join(','),
            working_start: extractTime(workingStart),
            working_end: extractTime(workingEnd),
            status,
        };
        if (!editState) {
            formData.password = password;
        }
        try {
            if (editState) {
                await updateAgents(editData, formData);
            } else {
                await addAgents(formData);
            }
            message.success(editState ? 'Agent Updated Successfully' : 'Agent Added Successfully');
            GetAgents();
            handleCancel();
        } catch (error) {
            setError(error.response.data.message);
        } finally {
            setLoading(false);
            setConfirmLoading(false);
        }
    };




    return (
        <Card>
            <Modal
                width={1000}
                title={editState ? "Edit Agent" : "Create New Agent"}
                footer={null}
                open={open}
                destroyOnClose
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <AgentForm
                    name={name}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    badges={badges}
                    setBadges={setBadges}
                    loading={loading}
                    error={error}
                    setPassword={setPassword}
                    form={form}
                    editState={editState}
                    email={email}
                    nickname={nickname}
                    phone={phone}
                    joiningDate={joiningDate}
                    workingDays={workingDays}
                    workingStart={workingStart}
                    workingEnd={workingEnd}
                    status={status}
                    setName={setName}
                    setEmail={setEmail}
                    setNickname={setNickname}
                    setPhone={setPhone}
                    setJoiningDate={setJoiningDate}
                    setWorkingDays={setWorkingDays}
                    setWorkingStart={setWorkingStart}
                    setWorkingEnd={setWorkingEnd}
                    setStatus={setStatus}
                    handleSubmit={handleSubmit}
                />
            </Modal>

            <div className="container-fluid mb-2">
                <div className="container-fluid">
                    <Row justify="space-between" align="middle" className="">
                        <Col xs={24} sm={24} md={12} lg={8}>
                            <h2>Support Agents</h2>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={16}>
                            <Row gutter={[16, 16]} justify="end" align="middle">
                                <Col xs={12} sm={12} md={6} lg={4}>
                                </Col>
                                <Col xs={24} sm={12} md={10} lg={6}>
                                    <Input
                                        suffix={<SearchOutlined />}
                                        allowClear
                                        className="p-2 rounded"
                                        size="large"
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4}>
                                    <Link>
                                        <Button onClick={() => setOpen(true)} type="primary" icon={<PlusCircleOutlined />} block>New Agent</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="table-responsive">
                <Table
                    loading={loading}
                    columns={tableColumns}
                    dataSource={groupListData}
                    rowKey='id'
                />
            </div>
        </Card>
    )
}

export default AgentList
