import { Select } from 'antd'
import React from 'react'
const { Option } = Select
export const UserSelectField = (props) => {
    const { users, HandleUserChange } = props
    return (
        <Select
            showSearch
            size="default"
            defaultValue={{ key: "Username" }}
            style={{ minWidth: 180 }}
            onChange={(value) => HandleUserChange(value)}
            optionFilterProp="children"
            filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
            }
        >
            {
                users?.length > 0 ?
                    users.map((item, index) => (
                        <Option value={item.id} key={index}>{item?.company_name}</Option>
                    ))
                    :
                    <Option >No Users Found</Option>
            }
        </Select>
    )
}
