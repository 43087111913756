import React, { useEffect, useState } from 'react'
import { Card, Table, Input, Button, Modal, Form, Row, Col } from 'antd';
import { EyeOutlined, DeleteOutlined, SearchOutlined, PlusCircleOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import Flex from 'components/shared-components/Flex'
import { Link } from "react-router-dom";
import utils from 'utils'
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';
import { ROW_GUTTER } from 'constants/ThemeConstant';



const { confirm } = Modal;
const GroupList = () => {

    const { api, authToken, UserData, navigate, Permisson, HandleExport } = useMyContext();

    useEffect(() => {
        if (!Permisson?.includes('View Group')) {
            navigate('404')
        }
    }, [Permisson]);

    const [list, setList] = useState([])
    const [groupListData, setGroupData] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [loading, setLoading] = useState(false)

    const GroupData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}groups/${UserData.id}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            const data = (response.data.groups).reverse();
            setGroupData(data);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    useEffect(() => {
        GroupData()
    }, [])

    const addProduct = () => {
        showModal()
    }


    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'name')
        },
        {
            title: 'Description',
            dataIndex: 'description',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'description')
        },
        {
            title: 'Total Contacts',
            dataIndex: 'contacts_count',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'contacts_count')
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, item) => (
                <Flex alignItems="center" style={{ gap: '1rem' }}>
                    {Permisson?.includes('Edit Group') &&
                        <Link to={`manage/${item.id}`} className='d-flex h4'>
                            <EyeOutlined />
                        </Link>
                    }
                    {Permisson?.includes('Delete Group') &&
                        <span className='d-flex h4 cursor-pointer' onClick={() => showConfirm(item.id)}>
                            <DeleteOutlined />
                        </span>
                    }
                </Flex>
            )
        }
    ];

    const rowSelection = {
        onChange: (key, rows) => {
            setSelectedRows(rows)
            setSelectedRowKeys(key)
        }
    };



    function showConfirm(id) {
        confirm({
            title: 'Do you Want to delete this Group?',
            onOk() {
                HandleDeleteContact(id)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    const HandleDeleteContact = async (deleteid) => {
        try {
            const response = await axios.delete(`${api}delete-group/${deleteid}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            console.log(response);
            if (response.data.status) {
                GroupData();
            }
        } catch (error) {
            // setConfirmLoading(false);
            // handleCancel()
            console.log(error);
        }
    }




    //model
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showModal = () => {
        setOpen(true);
    };
    const handleOk = async () => {

        setConfirmLoading(true);
        try {
            const response = await axios.post(`${api}create-group`, { user_id: UserData.id, name, description }, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            console.log(response);
            if (response.data.status) {
                GroupData();
                setConfirmLoading(false);
                handleCancel();
            }
        } catch (error) {
            setConfirmLoading(false);
            // handleCancel()
            console.log(error);
        }

    };
    const handleCancel = () => {
        setName("");
        setDescription("");
        setOpen(false);
    };
    return (
        <Card>
            <Modal
                title="Create New Group"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Row>
                    <Col span={24}>
                        <Form
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 10 }}
                            layout="horizontal"
                        >
                            <Form.Item label="Group Name" className=" my-3">
                                <Input type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Group Name"
                                />
                            </Form.Item>
                            <Form.Item label="Group Description" className=" my-3">
                                <Input type="text"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Group Description"
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Modal>
            <div className="container-fluid">
                <Row justify="space-between" align="middle" className="pb-4">
                    <Col xs={24} md={12} lg={8}>
                        <h2>Groups</h2>
                    </Col>
                    <Col xs={24} md={12} lg={16}>
                        <Row gutter={ROW_GUTTER} justify="end" align="middle">
                            <Col xs={24} sm={12} md={8} lg={4}>
                                <Form.Item>
                                    <Button
                                        icon={<CloudDownloadOutlined />}
                                        onClick={() => HandleExport('export-group', 'groups.xlsx')}
                                        block
                                    >
                                        Export Groups
                                    </Button>
                                </Form.Item>
                            </Col>
                            {Permisson?.includes('Create Group') && (
                                <Col xs={24} sm={12} md={8} lg={4}>
                                    <Form.Item>
                                        <Button
                                            onClick={() => navigate('/new-campaign')}
                                            type="primary"
                                            icon={<PlusCircleOutlined />}
                                            block
                                        >
                                            New Campaign
                                        </Button>
                                    </Form.Item>
                                </Col>
                            )}
                            {Permisson?.includes('View Campaign') && (
                                <Col xs={24} sm={12} md={8} lg={4}>
                                    <Form.Item>
                                        <Button
                                            onClick={addProduct}
                                            type="primary"
                                            icon={<PlusCircleOutlined />}
                                            block
                                        >
                                            New Group
                                        </Button>
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </div>


            <div className="table-responsive">
                <Table
                    loading={loading}
                    columns={tableColumns}
                    dataSource={groupListData}
                    rowKey='id'
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        type: 'checkbox',
                        preserveSelectedRowKeys: false,
                        ...rowSelection,
                    }}
                />
            </div>
        </Card>
    )
}

export default GroupList
