import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Button, Row, Col, Popconfirm, Card, message, Drawer } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';
import InputWithEmoji from '../../CustomUtils.js/InputWithKeyword';
import ListItemPreview from './ListItemPreview';

const ListMessagePreset = () => {
  const { api, authToken, UserData } = useMyContext();
  const location = useLocation();
  const navigate = useNavigate();

  const { editState, presetData } = location.state || {};

  const [header, setHeader] = useState('');
  const [body, setBody] = useState('');
  const [footer, setFooter] = useState('');
  const [name, setName] = useState('');
  const [rows, setRows] = useState([{ id: '', label: '' }]);
  const [buttonText, setButtonText] = useState('');
  const containerRef = useRef(null);

  useEffect(() => {
    if (editState && presetData) {
      setName(presetData?.name);
      setHeader(presetData?.header);
      setBody(presetData?.body);
      setFooter(presetData?.footer);
      setButtonText(presetData?.button_text);
      setRows(JSON.parse(presetData?.rows) || [{ id: '', label: '' }]);
    }
  }, [editState, presetData]);

  const addRow = () => {
    if (rows.length < 10) {
      setRows([...rows, { id: '', label: '' }]);
      // Scroll to bottom after adding a row
      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.scrollTo({
            top: containerRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }
      }, 0);
    } else {
      message.error('Maximum of 10 rows allowed.');
    }
  };

  const removeRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const handleHeaderChange = (e) => {
    setHeader(e.target.value);
  };


  const handleFooterChange = (e) => {
    setFooter(e.target.value);
  };

  const handleRowIdChange = (index, e) => {
    const newRows = [...rows];
    newRows[index].id = e.target.value.trim(); // Remove leading/trailing spaces
    setRows(newRows);
  };

  const handleRowLabelChange = (index, e) => {
    const newRows = [...rows];
    newRows[index].label = e.target.value;
    setRows(newRows);
  };

  const handleSubmit = async (values) => {
    console.log(values)
    if (!body?.trim()) {
      message.error('Body is required!');
      return;
    }
    const filteredRows = rows.filter((row) => row.id.trim() !== '' || row.label.trim() !== '');
    if (filteredRows?.length === 0) {
      message.error('At least one row is required.');
      return;
    }
    const object = {
      messaging_product: "whatsapp",
      type: "interactive",
      to: '#number',
      interactive: {
        type: "list",
        header: {
          type: "text",
          text: header,
        },
        body: {
          text: body,
        },
        footer: {
          text: footer,
        },
        action: {
          button: buttonText,
          sections: [
            {
              title: "Select Option",
              rows: filteredRows.map((row, index) => ({
                id: row.id,
                title: row.label,
                //description: `Option ${index + 1}`,
              })),
            },
          ],
        },
      },
    };

    const payload = {
      user_id: UserData?.id,
      name: name,
      header,
      body,
      footer,
      rows: filteredRows,
      button_text: buttonText,
      object,
    }
    try {
      const url = editState
        ? `${api}list-message-preset/${presetData.id}`
        : `${api}list-message-preset`;

      const method = editState ? 'put' : 'post';

      const response = await axios[method](url, payload, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        }
      });

      message.success(
        editState ? 'Preset updated successfully!' : 'Preset added successfully!'
      );
      console.log(`${editState ? 'Update' : 'Store'} Response:`, response.data);

      navigate(-1);
    } catch (error) {
      console.error('Error submitting preset:', error);
      message.error('Failed to save preset. Please try again.');
    }
  };
  return (
    <Row gutter={ROW_GUTTER}>
      <Col xs={24} sm={24} md={24} lg={18}>
        <Card title="List Message Preset">
          <Form
            layout="vertical"
            onFinish={handleSubmit}
          >
            {/* Header and Footer on the same line */}
            <Row gutter={ROW_GUTTER}>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  label="Preset Name"
                  name="Preset_Name"
                  rules={[
                    //{ required: true, message: 'Preset Name is required!' },
                  ]}
                >
                  <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter header text"
                  />
                  <p className='d-none'>{name}</p>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  label="Header"
                  name="header"
                  rules={[
                    //{ required: true, message: 'Header is required!' },
                    { max: 20, message: 'Header must be at most 20 characters.' },
                  ]}
                >
                  <Input
                    value={header}
                    onChange={handleHeaderChange}
                    placeholder="Enter header text"
                  />
                  <p className='d-none'>{header}</p>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  label="Footer"
                  name="footer"
                  rules={[
                    { max: 60, message: 'Footer must be at most 60 characters.' },
                  ]}
                >
                  <Input
                    value={footer}
                    onChange={handleFooterChange}
                    placeholder="Enter footer text"
                  />
                  <p className='d-none'>{footer}</p>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Button Text"
                  rules={[
                    { required: true, message: 'Button text is required!' },
                    { max: 20, message: 'Button text must be at most 20 characters.' },
                  ]}
                >
                  <Input
                    value={buttonText}
                    onChange={(e) => setButtonText(e.target.value)}
                    placeholder="Enter Button Text"
                  />
                  <p className='d-none'>{buttonText}</p>
                </Form.Item>
              </Col>
            </Row>

            {/* Body with TextArea */}
            <Row gutter={ROW_GUTTER}>
              <Col xs={24} sm={24} md={24}>
                <InputWithEmoji
                  value={body}
                  hideVarBTN={true}
                  language={'english'}
                  setValue={setBody}
                />
              </Col>
            </Row>

            {/* Dynamic Rows */}
            <label>Rows:</label>
            <div ref={containerRef} style={{ marginBottom: '20px', maxHeight: 300, overflowX: 'hidden', overflowY: 'auto' }}>
              {rows?.map((row, index) => (
                <Row gutter={ROW_GUTTER} key={index} align="middle" style={{ marginBottom: '10px' }}>
                  <Col xs={24} sm={10} md={11}>
                    <Form.Item
                      label="ID"
                      rules={[
                        { required: true, message: 'ID is required!' },
                        { max: 256, message: 'ID must be at most 256 characters.' },
                        { pattern: /^[^\s]*$/, message: 'ID cannot contain spaces.' },
                      ]}
                    >
                      <Input
                        value={row.id}
                        onChange={(e) => handleRowIdChange(index, e)}
                        placeholder="Enter ID"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={10} md={11}>
                    <Form.Item
                      label="Label"
                      rules={[
                        { required: true, message: 'Label is required!' },
                        { max: 200, message: 'Label must be at most 200 characters.' },
                      ]}
                    >
                      <Input
                        value={row.label}
                        onChange={(e) => handleRowLabelChange(index, e)}
                        placeholder="Enter Label"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={4} md={2} className='d-flex justify-content-center align-items-center'>
                    <Popconfirm
                      title="Are you sure to delete this row?"
                      onConfirm={() => removeRow(index)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <MinusCircleOutlined style={{ fontSize: '20px', color: 'red' }} />
                    </Popconfirm>
                  </Col>
                </Row>
              ))}
            </div>
            <Button
              type="dashed"
              icon={<PlusOutlined />}
              onClick={addRow}
              className='mb-2'
              block
              style={{ marginTop: '10px' }}
            >
              Add Row
            </Button>

            {/* Submit Button */}
            <Row gutter={ROW_GUTTER}>
              <Col xs={24} sm={24} md={24} className='d-flex justify-content-end'>
                <Button type="default" className='mr-2' onClick={() => navigate(-1)}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" className='position-relative float-right'>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={6}>
        <ListItemPreview
          headerType={'text'}
          header={header}
          body={body}
          footer={footer}
          media={null}
          rows={rows}
          buttonText={buttonText}
        />
      </Col>
    </Row>
  );
};

export default ListMessagePreset;

