import React, { useEffect, useState } from 'react'
import { Card, Table, Tooltip, Button, Modal, message, Drawer, Input, Collapse } from 'antd';
import { SendOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import Flex from 'components/shared-components/Flex';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useMyContext } from 'Context/MyContextProvider';
import ListItemPreview from './ListItemPreview';
const { confirm } = Modal;
export const GetPresets = async (setLoading, api, UserData, authToken, setpresets) => {
    setLoading(true)
    try {
        const response = await axios.get(`${api}list-message-preset/${UserData?.id}`, {
            headers: {
                Authorization: "Bearer " + authToken,
            },
        });
        if (response.data.status) {
            setpresets(response.data?.data)
        }
    } catch (error) {

    } finally {
        setLoading(false)
    }
}
const ListMessagePresets = () => {
    const { Permisson, waToken, authToken, api, messagesApi, UserData } = useMyContext();
    const [loading, setLoading] = useState(false);
    const [presets, setpresets] = useState([]);


    useEffect(() => {
        GetPresets(setLoading, api, UserData, authToken, setpresets)
    }, []);

    const tableColumns = [
        {
            title: 'Preset Name',
            dataIndex: 'name',
            sorter: {
                compare: (a, b) => a.name.length - b.name.length,
            },
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            render: (_, item) => (
                <div className="text-right d-flex">
                    <Tooltip title="Edit">
                        <Link
                            to={{ pathname: 'edit', }}
                            state={{
                                presetData: item,
                                editState: true,
                            }}
                            className="mr-2"
                        >
                            <Button
                                className="mr-2"
                                icon={<EditOutlined />}
                                size="small"
                            />
                        </Link>
                    </Tooltip>

                    <Tooltip title="Quick Send">
                        <Button
                            type="primary"
                            className="mr-2"
                            icon={<SendOutlined />}
                            onClick={() => HandleQuickSend(item?.id)}
                            size="small"
                        />
                    </Tooltip>
                    <Tooltip title="Message Preview">
                        <Button
                            color="primary"
                            className="mr-2"
                            variant="filled"
                            icon={<EyeOutlined className='h4'/>}
                            onClick={() => HandlePreview(item)}
                        />
                    </Tooltip>
                    {Permisson?.includes('Delete Carousel Preset') &&
                        <Tooltip title="Delete">
                            <Button
                                danger
                                icon={<DeleteOutlined/>}
                                onClick={() => showConfirm(item.id, item?.name)}
                                size="small"
                            />
                        </Tooltip>
                    }
                </div>
            ),
        },
    ];


    function showConfirm(id, name) {
        confirm({
            title: 'Do you want to delete this preset?',
            content: "By deleting the preset, it will be permanently removed from Meta and can't be restored.",
            onOk() {
                HandleDelete(id, name)
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    }

    const HandleDelete = async (id) => {
        try {
            setLoading(true)
            const response = await axios.delete(`${api}list-message-preset/${id}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            if (response.data.status) {
                GetPresets(setLoading, api, UserData, authToken, setpresets)
                message.success(response.data.message);
            }
        } catch (error) {
            if (error.response) {
                message.error(error.response.data.message);
            }
        } finally {
            setLoading(false)
        }
    }

    //drawer states 
    const [open, setOpen] = useState(false);
    const [object, setObject] = useState();


    const HandleQuickSend = (id) => {
        let object = presets.find(item => item?.id === id);
        setObject(JSON.parse(object?.object))
        setIsModalVisible(true)
    }

    const onClose = () => {
        setOpen(false);
    };

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [number, setNumber] = useState();
    const handleOk = async () => {
        setLoading(true)
        let objectString = JSON.stringify(object);
        let replacedObjectString = objectString.replace('#number', number);
        let updatedObject = JSON.parse(replacedObjectString);
        try {
            await axios.post(messagesApi, updatedObject, {
                headers: { Authorization: `Bearer ${waToken}` },
            });
            setNumber('')
            setIsModalVisible(false)
            message.success('Message sent successfully')
        } catch (error) {
        } finally {
            setLoading(false)
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setNumber('')
    };

    //preview start from here
    const [previewData, setPreviewData] = useState();
    const HandlePreview = (data) => {
        setPreviewData(data)
        setOpen(true)
    }


    useEffect(() => {
        if (!open) {
            setPreviewData()
        }
    }, [open])

    useEffect(() => {
        if (isModalVisible) {
          const inputField = document.querySelector("#numberInput");
          if (inputField) {
            inputField.focus();
          }
        }
      }, [isModalVisible]);
    return (
        <>
            <Modal
                title="Enter Your WhatsApp Number"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Send"
                cancelText="Cancel"
                okButtonProps={{ disabled: loading }}
                cancelButtonProps={{ disabled: loading }}
            >
                <Input value={number} id='numberInput' onChange={(e) => setNumber(e.target.value)} placeholder='enter your number' autoFocus/>
                <div style={{ marginTop: '16px' }}>
                    <Collapse defaultActiveKey={['1']}>
                        <Collapse.Panel header="Important Information" key="1">
                            <ul style={{ paddingLeft: '20px', margin: 0 }}>
                                <li>Send a message to <b>{UserData?.whatsapp_number}</b> first from your WhatsApp application to ensure immediate list message delivery.</li>
                                <li>If there are no messages exchanged with <b>{UserData?.whatsapp_number}</b> in the last 24 hours, the message may not be sent from here.</li>
                                <li>Ensure the recipient has opted in to receive messages from your business account.</li>
                                <li>Message content must comply with WhatsApp Business API guidelines to avoid potential blocks.</li>
                            </ul>
                        </Collapse.Panel>
                    </Collapse>
                </div>
            </Modal>
            <Card bodyStyle={{ padding: '0px' }}>
                <div className="container-fluid">
                    <Flex justifyContent="space-between" alignItems="center" className="py-4">
                        <h2>List Item Presets</h2>
                        <div className='d-flex'>
                            {Permisson?.includes('Create Template') &&
                                <div>
                                    <Link to='add'>
                                        <Button type="primary" className="ml-2" >
                                            <PlusOutlined />
                                            <span>New</span>
                                        </Button>
                                    </Link>
                                </div>
                            }
                        </div>
                    </Flex>
                </div>

                <div className="table-responsive">
                    <Table loading={loading} columns={tableColumns} dataSource={presets} rowKey="id" />
                </div>
            </Card>
            <div className="">
                <Drawer
                    placement="right"
                    className="right-drawer"
                    closable={false}
                    onClose={onClose}
                    open={open}
                    getContainer={false}
                    style={{ position: 'absolute' }}
                >

                    <ListItemPreview
                        headerType={'text'}
                        open={open}
                        header={previewData?.header}
                        body={previewData?.body}
                        footer={previewData?.footer}
                        media={previewData?.null}
                        rows={previewData?.rows ? JSON.parse(previewData.rows) : []}
                        buttonText={previewData?.button_text}
                    />
                </Drawer>
            </div>
        </>
    );
};

export default ListMessagePresets
