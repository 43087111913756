import { Modal } from 'antd'
import React from 'react'
import MediModel from '../MediModel'

const SelectMediaModel = ({openMediaModel,handleCancelMediaModel,HandleUploadImage,requestType,handleCustomMedia}) => {
    return (
        <Modal
            title="Select Media"
            open={openMediaModel}
            onCancel={handleCancelMediaModel}
            footer={null}
            width={1200}
        >
            <MediModel
                HandleUploadImage={HandleUploadImage}
                requestType={requestType}
                handleCustomMedia={handleCustomMedia}
            />
        </Modal>
    )
}

export default SelectMediaModel
