import React from 'react'
import {Table } from 'antd'

const InsideChatbots = ({ chatbots, loading }) => {
    const tableColumns = [
        {
            title: 'No',
            dataIndex: 'sr_no',
            sorter: {
                compare: (a, b) => a.type?.sr_no - b.type?.sr_no,
            },
        },

        {
            title: 'Keywords',
            dataIndex: 'keyword',
            sorter: {
                compare: (a, b) => a.keyword.length - b.keyword.length,
            },
        },
    ];

    return (
        <Table
            expandable={{
                expandedRowRender: (record) => <p className='ml-5  d-flex'><span className='flex-wrap'>{record?.external_url}</span></p>,
            }}
            loading={loading}
            columns={tableColumns}
            dataSource={chatbots}
            rowKey="id" />
    )
}

export default InsideChatbots