// AgentForm.js
import React, { useEffect } from 'react';
import { Form, Input, DatePicker, TimePicker, Select, Button, Row, Col, Alert } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import moment from 'moment';
import { motion } from "framer-motion"
import 'moment/locale/en-gb';
import { KeywordsComp } from '../ChatBot/ChatbotCodeComps/ChatbotFields';
const { Option } = Select;

const AgentForm = ({
    name,
    email,
    nickname,
    phone,
    editState,
    joiningDate,
    workingDays,
    workingStart,
    workingEnd,
    setPassword,
    status,
    setName,
    setEmail,
    setNickname,
    setPhone,
    setJoiningDate,
    setWorkingDays,
    setWorkingStart,
    setWorkingEnd,
    setStatus,
    handleSubmit,
    form,
    error,
    loading,
    inputValue,
    setInputValue,
    badges,
    setBadges,
}) => {
    useEffect(() => {
        form.setFieldsValue({
            name: name,
            user_email: email,
            nickname: nickname,
            phone: phone,
            workingDays: workingDays,
            // working_start: workingStart,
            // working_end: workingEnd,

            joiningDate: joiningDate ? moment(joiningDate) : null,
            status: status
        });
    }, [name, email, nickname, phone, workingDays, workingStart, workingEnd, joiningDate, status, form]);
    return (
        <Form form={form} layout="vertical" onFinish={(e) => handleSubmit(e)}>
            <Row gutter={ROW_GUTTER}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <motion.div
                        initial={{ opacity: 0, marginBottom: 0 }}
                        animate={{
                            opacity: error ? 1 : 0,
                            marginBottom: error ? 20 : 0
                        }}
                    >
                        <Alert type={`error`} showIcon message={error}></Alert>
                    </motion.div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                    <Form.Item
                        className="my-1"
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your name!',
                            },
                        ]}
                    >
                        <Input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12}>
                    <Form.Item
                        className="my-1"
                        label="Email"
                        name="user_email" // Add name for validation
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your email!',
                            },
                            {
                                type: 'email',
                                message: 'Please enter a valid email!',
                            },
                        ]}
                    >
                        <Input
                            name='user_email'
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={!editState ? 8 : 12}>
                    <Form.Item
                        className="my-1"
                        label="Nickname"
                        name="nickname" // Add name for validation
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your nickname!',
                            },
                        ]}
                    >
                        <Input
                            type="text"
                            value={nickname}
                            onChange={(e) => setNickname(e.target.value)}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={!editState ? 8 : 12}>
                    <Form.Item
                        className="my-1"
                        label="Phone"
                        name="phone" // Add name for validation
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your phone number!',
                            },
                        ]}
                    >
                        <Input
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </Form.Item>
                </Col>
                {
                    !editState &&
                    <Col xs={24} sm={12} md={12} lg={8}>
                        <Form.Item
                            label="Password"
                            name="user_pass" // Use the same name as in your existing code
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your new password!',
                                },
                                {
                                    min: 6,
                                    message: 'Password must be at least 6 characters!',
                                },
                            ]}
                        >
                            <Input.Password autoComplete="off" onChange={(e) => setPassword(e.target.value)} />
                        </Form.Item>
                    </Col>
                }

                <Col xs={24} sm={24} md={16} lg={16}>
                    <Form.Item
                        className="my-1"
                        label="Working Days"
                        name="workingDays" // Add name for validation
                        rules={[
                            {
                                required: true,
                                message: 'Please select working days!',
                            },
                        ]}
                    >
                        <Select
                            mode="multiple"
                            value={workingDays}
                            placeholder="Select Working Days"
                            onChange={(value) => setWorkingDays(value)}
                            style={{ flex: 1 }}
                            options={[
                                { value: 'monday', label: 'Monday' },
                                { value: 'tuesday', label: 'Tuesday' },
                                { value: 'wednesday', label: 'Wednesday' },
                                { value: 'thursday', label: 'Thursday' },
                                { value: 'friday', label: 'Friday' },
                                { value: 'saturday', label: 'Saturday' },
                                { value: 'sunday', label: 'Sunday' },
                            ]}
                        />
                    </Form.Item>
                </Col>

                <Col xs={12} sm={8} md={4} lg={4}>
                    <Form.Item
                        name="working_start" // Add name for validation
                        className="my-1"
                        label="Working Start Time"
                        rules={[
                            {
                                required: true,
                                message: 'Please select working start time!',
                            },
                        ]}
                    >
                        <TimePicker
                            showNow={false}
                            format="HH:mm"
                            onChange={(time) => { setWorkingStart(time) }}
                        />
                    </Form.Item>
                </Col>

                <Col xs={12} sm={8} md={4} lg={4}>
                    <Form.Item
                        name="working_end" // Add name for validation
                        className="my-1"
                        label="Working End Time"
                        rules={[
                            {
                                required: true,
                                message: 'Please select working end time!',
                            },
                        ]}
                    >
                        <TimePicker
                            showNow={false}
                            format="HH:mm"
                            onChange={(time) => setWorkingEnd(time)}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24}>
                    <KeywordsComp
                        badges={badges}
                        setBadges={setBadges}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                    <Form.Item
                        className="my-1"
                        label="Joining Date"
                        name="joiningDate"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a joining date!',
                            },
                        ]}
                    >
                        <DatePicker
                            value={joiningDate ? moment(joiningDate) : null}
                            className="w-100"
                            disabled={(editState && joiningDate) && true}
                            // getPopupContainer={(trigger) => trigger.parentElement}
                            onChange={(date) => setJoiningDate(date)}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12}>
                    <Form.Item
                        className="my-1"
                        label="Status"
                        name="status" // Add name for validation
                        rules={[
                            {
                                required: true,
                                message: 'Please select a status!',
                            },
                        ]}
                    >
                        <Select
                            value={status}
                            onChange={(value) => setStatus(value)}
                            placeholder="Select Status"
                        >
                            <Option value="active">Active</Option>
                            <Option value="inactive">Inactive</Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24}>
                    <Form.Item className="my-1">
                        <Button loading={loading} type="primary" htmlType="submit" className='positive-relative float-right'>
                            {editState ? 'Update' : 'Submit'}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>

    );
};

export default AgentForm;
