import { Alert, Button, Card, Col, Form, Input, message, Radio, Row, Select, Switch } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useMyContext } from 'Context/MyContextProvider';
import { motion } from "framer-motion"
import Flex from 'components/shared-components/Flex';
import "react-transliterate/dist/index.css";
import Groups from '../Campaign/Groups';
import CarouselPreview from './carouselPreview';
import { filterNumbers, formatNumber, validateUserAndBalance } from '../Campaign/CampainComps/OtherUtils';
import ModeLindex from '../Campaign/CampainComps/ModeLindex';
import CommonNumberField from '../Campaign/CampainComps/CommonNumberField';
import CardData from './CarouselCampaignComps.js/CardData';
import MotionError from '../Campaign/CampainComps/MotionError';
const CarouselCampaign = () => {

    const { UserData, api, authToken, messagesApi, waToken, templates, Permisson, navigate, buttonStyle, calculateRequredBalance } = useMyContext()


    useEffect(() => {
        if (!Permisson?.includes('View Campaign')) {
            navigate('404')
        }
    }, [Permisson]);

    const [templateList, setTemplateList] = useState([]);
    const [componentSize, setComponentSize] = useState('small');
    const [number, setNumber] = useState('');
    const [customMessage, setCustomMessage] = useState('');
    const [numberError, setNumberError] = useState(false);
    const [requestType, setRequestType] = useState('');
    const [dynamicValues, setDynamicValues] = useState([]);
    const [dynamicButtonUrl, setDynamicUrlButton] = useState([]);
    const [templateName, setTemplateName] = useState('');
    const [newMesaage, setNewMessage] = useState('');
    const [name, setName] = useState('');
    const [templatePreview, setTemplatePreview] = useState([])
    const [mediaLink, setMediaLink] = useState('')
    const [thumbnail, setThumbnail] = useState('')
    const [template, setTemplate] = useState({ body: null });
    const [templateKey, setTemplateKey] = useState(0);
    const [templateInputs, setTemplateInputs] = useState([]);
    const [templateUrlInputs, setTemplateUrlInputs] = useState([]);
    const [urlButtons, setUrlButtons] = useState([]);
    const [numbers, SetNumbers] = useState([]);

    const [singleLineNumber, setSingleLineNumber] = useState([])
    const [userStatus, setUerStatus] = useState(false)
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [templateCategory, setTemplateCategory] = useState();
    const [blockNumbers, setBlockNumbers] = useState([]);
    const [templateLanguage, setTemplateLanguage] = useState('');
    const [openMediaModel, setOpenMediaModel] = useState(false);
    const [loading, setLoading] = useState(false);

    const [initialTemplates, setInitialTemplates] = useState([]);
    const [mainBody, setMainBody] = useState(null);
    const [mainBodyInputs, setMainBodyInputs] = useState([]);

    const [cardData, setCardData] = useState([]);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [cards, setCards] = useState();
    const [existingMediaIDs, setExistingMediaIDs] = useState([]);
    const GetBlockNumber = async () => {
        try {
            const response = await axios.get(`${api}get-block-numbers/${UserData.id}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            const serverBlocked = response.data.serverBlocked
            const userBlocked = response.data.serverBlocked
            const serverNumbers = serverBlocked.map(item => formatNumber(item.numbers)).filter(Boolean);
            const userNumbers = userBlocked.map(item => formatNumber(item.numbers)).filter(Boolean);
            const total = [...serverNumbers, ...userNumbers];
            setBlockNumbers([...new Set(total)]);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        GetBlockNumber()
    }, [])


    useEffect(() => {
        if (templates.length > 0) {
            const carouselTemplates = templates?.filter(template =>
                template.components?.some(comp => comp.type === 'CAROUSEL')
            );
            setInitialTemplates(carouselTemplates)
            setTemplateList(carouselTemplates)
        }
    }, [templates])

    useEffect(() => {
        if (templateCategory) {
            const filteredList = initialTemplates.filter(item => item.category === templateCategory);
            setTemplateList(filteredList);
        } else {
            setTemplateList(initialTemplates);
        }
    }, [templateCategory, initialTemplates]);

    const onFormLayoutChange = ({ size }) => {
        setComponentSize(size);
    };

    useEffect(() => {
        // console.log(number)
        if (number) {
            setNumberError(false)
        }
    }, [number])



    const HandleTemplate = (value) => {
        setMediaLink('');
        setExistingMediaIDs([])
        setTemplateName(value);
        setTemplateKey((prevKey) => prevKey + 1);

        // Find the selected template
        const template = templates?.find((item) => item.name === value);

        // Set template-related state
        setTemplateLanguage(template?.language);
        setTemplatePreview(template);

        // Extract main body and dynamic values
        const { templateCards, mainBodyTemp } = extractTemplateSections(template);
        setMainBody(mainBodyTemp);
        setCards(templateCards)

        const mainBodyDynamicValue = mainBodyTemp?.text?.match(/{{\d+}}/g);
        if (mainBodyDynamicValue) {
            const inputs = generateInputsForDynamicValues(mainBodyDynamicValue, 'mainBody');
            setMainBodyInputs(inputs);
        } else {
            setMainBodyInputs([]);
        }

        // Handle templateCards
        if (templateCards && templateCards.length > 0) {
            const cardData = templateCards.map((card, index) => {
                const cardBodyDynamicValue = card.body?.text?.match(/{{\d+}}/g);
                // Return the card with dynamic inputs and media-related fields
                return {
                    ...card,
                    inputs: cardBodyDynamicValue
                        ? generateInputsForDynamicValues(cardBodyDynamicValue, 'card', index)
                        : [],
                    mediaType: 'Media', // Initialize mediaType
                    mediaLink: '', // Initialize mediaLink
                    mediaName: '', // Initialize mediaName
                    useExternalUrl: false, // Initialize useExternalUrl
                };
            });
            setCardData(cardData); // Set cardData with media-related fields
        } else {
            setCardData([]);
        }
    };




    const extractTemplateSections = (template) => {
        if (template) {
            let mainBodyTemp = null;
            let templateCards = [];

            // Process components to extract main body and carousel cards
            template.components.forEach(section => {
                if (section.type === "BODY") {
                    mainBodyTemp = section;
                } else if (section.type === "CAROUSEL") {
                    templateCards = section.cards.map(card => {
                        let cardHeader = null;
                        let cardBody = null;
                        let cardButtons = null;

                        // Extract header, body, and buttons for each card
                        card.components.forEach(cardSection => {
                            switch (cardSection.type) {
                                case "HEADER":
                                    cardHeader = cardSection;
                                    break;
                                case "BODY":
                                    cardBody = cardSection;
                                    break;
                                case "BUTTONS":
                                    cardButtons = cardSection;
                                    break;
                                default:
                                    break;
                            }
                        });

                        return { header: cardHeader, body: cardBody, buttons: cardButtons };
                    });
                }
            });
            return { mainBodyTemp, templateCards }
            // setMainBody(mainBodyTemp);
            // setCarouselCards(cards);
        }
    }

    const generateInputsForDynamicValues = (dynamicValues, type, cardIndex) => {
        return dynamicValues.map((placeholder, index) => (
            <Input
                key={index}
                type="text"
                placeholder={`Enter value for ${placeholder}`}
                onChange={(e) => handleInputChange(index, e.target.value, type, cardIndex)}
            />
        ));
    }


    const handleInputChange = (index, value, type, cardIndex) => {
        if (type === 'mainBody') {
            setDynamicValues(prevValues => {
                const updatedValues = [...prevValues];
                updatedValues[index] = value;
                return updatedValues;
            });
        } else if (type === 'card') {
            setCardData(prevCards => {
                const updatedCards = [...prevCards];
                updatedCards[cardIndex].inputs[index] = value;
                return updatedCards;
            });
        }
    };


    const HandleDynamicValues = (templatePreview) => {
        if (dynamicValues.length > 0) {
            const text = templatePreview?.components[1]?.text;
            let updatedText = text;
            dynamicValues.forEach((item, index) => {
                updatedText = updatedText?.replace(`{{${index + 1}}}`, item);
            });
            setNewMessage(updatedText); // Log the updated text array
        }
    }

    useEffect(() => {
        HandleDynamicValues(templatePreview)
    }, [templatePreview, dynamicValues])

    useEffect(() => {
        if (requestType === 'template') {
            setCustomMessage('')
        } else {
            setTemplatePreview([])
            setTemplateInputs([]);
            setDynamicValues([]);
            setTemplateUrlInputs([]);
            setDynamicUrlButton([]);
            setTemplate({
                body: null,
            });

        }
    }, [requestType])

    const GetGroupNumber = (value) => {
        const uniqueNumbersSet = [...new Set(value)];
        SetNumbers(uniqueNumbersSet)
    }


    useEffect(() => {
        if (numbers?.length > 0) {
            const numbersWithNewlines = numbers?.join('\n');
            setSingleLineNumber(numbersWithNewlines)
        }
        // console.log(numbers)
    }, [numbers])


    const showModal = async () => {
        setOpen(true);
    };
    const handleCancel = () => {
        setOpen(false);
    };


    // for media model 

    const showMediaModel = (index) => {
        setCurrentCardIndex(index);  // Set the current card index
        setOpenMediaModel(true);
    };
    const handleCancelMediaModel = () => {
        setOpenMediaModel(false);
    };


    const HandleUploadImage = (path, name) => {
        if (path) {
            setExistingMediaIDs(prevMediaIDs => {
                const updatedMediaIDs = [...prevMediaIDs];
                updatedMediaIDs[currentCardIndex] = path; // Replace the media ID at the current index
                return updatedMediaIDs;
            });
            setCardData((prevData) =>
                prevData.map((card, index) =>
                    index === currentCardIndex
                        ? { ...card, mediaLink: path, mediaName: name }
                        : card
                )
            );
            setOpenMediaModel(false);
        }
    };
 
    const generateContent = (type, id) => {
        switch (type) {
            case 'IMAGE':
                return { image: { id: id } };
            case 'VIDEO':
                return { video: { id: id } };
            case 'DOCUMENT':
                return { document: { id: id } };
            default:
                return {};
        }
    };
    const [isSending, setIsSending] = useState(true);
    const [progress, setProgress] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const generatePayload = (item, templateName, templateLanguage, dynamicValues) => {
        return {
            messaging_product: "whatsapp",
            to: item,
            type: "template",
            template: {
                name: templateName,
                language: { code: templateLanguage },
                components: [
                   {
                        type: "CAROUSEL",
                        cards: cardData.map((card, cardIndex) => ({
                            card_index: cardIndex,
                            components: [
                                ...(card.header ? [
                                    {
                                        type: "HEADER",
                                        parameters: [
                                            {
                                                type: (() => {
                                                    switch (card.header?.format) {
                                                        case "IMAGE":
                                                            return "IMAGE";
                                                        case "VIDEO":
                                                            return "VIDEO";
                                                        case "DOCUMENT":
                                                            return "DOCUMENT";
                                                        default:
                                                            return "TEXT";
                                                    }
                                                })(),
                                                ...generateContent(card.header?.format, card.mediaLink),
                                            },
                                        ],
                                    },
                                ] : []),
                                ...(card.buttons && card.buttons.length > 0 ? card.buttons.map((button, buttonIndex) => ({
                                    type: "BUTTON",
                                    sub_type: button.type === "QUICK_REPLY" ? "QUICK_REPLY" : "URL",
                                    index: buttonIndex,
                                    ...(button.type === 'URL' && dynamicButtonUrl?.length ? {
                                        parameters: dynamicButtonUrl.map(url => ({ type: "text", text: url }))
                                    } : {}),
                                    ...(button.type === 'QUICK_REPLY' ? {
                                        parameters: [{ type: "payload", payload: "btntwo" }]
                                    } : {}),
                                })) : []),
                            ],
                        })),
                    }
                ]
            },
        }
    };


    // Function to handle the rate-limited message sending
    const sendMessageWithRateLimit = async (messagePayloads, messagesApi, waToken, campaignData, api, authToken) => {
        const customRateLimit = 20; // messages per second
        const interval = 1000 / customRateLimit; // milliseconds
        const reportData = [];
        const totalMessages = messagePayloads.length;
        let sentMessages = 0;
        const sendBatch = async (batch) => {
            await Promise.all(batch.map(async (payload) => {
                const mobileNumber = payload.to; // Store the mobile number for reporting

                try {
                    const res = await axios.post(messagesApi, payload, {
                        headers: { Authorization: `Bearer ${waToken}` },
                    });

                    if (res.data.messages[0].message_status === 'accepted' || res.data.messages[0]?.id) {
                        reportData.push({
                            campaign_id: campaignData.id,
                            message_id: res.data.messages[0].id,
                            mobile_number: mobileNumber,
                        });
                    }
                } catch (error) {
                }
                sentMessages++;
                const currentProgress = (sentMessages / totalMessages) * 100;
                setProgress(currentProgress);
            }));
        };

        const processMessages = async () => {
            for (let i = 0; i < messagePayloads.length; i += customRateLimit) {
                const batch = messagePayloads.slice(i, i + customRateLimit);
                await sendBatch(batch);
                await new Promise(resolve => setTimeout(resolve, interval));
            }
            setIsSending(false);
            //setIsModalVisible(false);
            await storeCampaignReports(reportData, campaignData, api, authToken);
        };

        setIsSending(true); // Start sending process
        processMessages();
    };



    const storeCampaignReports = async (reportData, campaignData, api, authToken) => {
        try {
            const response = await axios.post(`${api}create-campaign-report`, {
                campaign_id: campaignData.id,
                reports: reportData,
            }, {
                headers: { Authorization: `Bearer ${authToken}` },
            });

            if (response.data.status) {
                console.log('Campaign reports stored successfully:', response.data.message);
            } else {
                console.error('Failed to store campaign reports:', response.data.message);
            }
        } catch (error) {
            console.error('Error storing campaign reports:', error);
        }
    };



    const [showProcessingModal, setShowProcessingModal] = useState(false);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (isSending) {
                e.preventDefault();
                e.returnValue = ''; // For most browsers
                return ''; // For older browsers
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isSending]);

    useEffect(() => {
        const handlePopstate = (e) => {
            if (isSending) {
                e.preventDefault();
                setShowProcessingModal(true);
            }
        };

        window.addEventListener('popstate', handlePopstate);

        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, [isSending]);

    const groupsRef = useRef(null);
    const [groupsKey, setGroupsKey] = useState(0);

    const resetCampaign = () => {
        setProgress()
        setIsModalVisible(false)
        setSingleLineNumber([])
        setNumber('')
        setGroupsKey(prevKey => prevKey + 1);
    }
    const conicColors = {
        '0%': '#16702F',
        '100%': '#34B55A',
    };

    const handleMediaLinkChange = (value, index) => {
        const newMediaLink = value;
        setCardData((prevData) =>
            prevData.map((card, i) =>
                i === index ? { ...card, mediaLink: newMediaLink } : card
            )
        );
    };

    const [showAlert, setShowAlert] = useState(false);
    const [isLoop, setIsLoop] = useState(true);

    useEffect(() => {
        if (showAlert) {
            message.error('Please provide the required media link and media type.');
        }
    }, [showAlert]);
    const handleSwitchChange = (checked) => {
        setIsLoop(checked)
    }



    // Utility function to check for missing media files
    const getMissingCardIndices = (cardData) => {
        return cardData
            .map((card, index) => card.mediaType === 'Media' && !card.mediaLink ? index + 1 : null)
            .filter(index => index !== null);
    };

    // Utility function to create campaign
    const createCampaign = async (userId, name, templateName, customMessage, authToken) => {
        return await axios.post(`${api}create-campaign`, {
            name,
            user_id: userId,
            templateName: templateName || `Custom Message: ${customMessage}`,
        }, {
            headers: { Authorization: `Bearer ${authToken}` },
        }).then(response => response.data.campaign);
    };

    // Utility function to check user status
    const checkUserStatus = async (userId, authToken) => {
        const response = await axios.get(`${api}chek-user/${userId}`, {
            headers: { 'Authorization': `Bearer ${authToken}` },
        });
        return response.data.status;
    };


    // Main handler function
    const HandleSubmit = async () => {
        try {
            const missingCardIndexes = getMissingCardIndices(cardData);
            if (missingCardIndexes.length > 0) {
                const cardNumbers = missingCardIndexes.join(', ');
                message.error(`Cards ${cardNumbers} are missing media files. Please select and upload files for these cards.`);
                return;
            }
            if (templateName === '') {
                setError('You have to select a template first');
                return;
            }
            const filteredNumbers = filterNumbers(number, numbers, blockNumbers);
            
            if (!name || filteredNumbers.length === 0 || (!templateName && !customMessage)) {
                setError('Invalid input data');
                return;
            }
            validateUserAndBalance(filteredNumbers?.length, calculateRequredBalance, templateCategory, showModal)
            if (filteredNumbers?.length > 5000) {
                setError('You can only process up to 5000 numbers.');
                return;
            }
            const userStatus = await checkUserStatus(UserData.id, authToken);
            setUerStatus(userStatus);
            if (userStatus === false) {
                showModal();
                return;
            }
            setLoading(true);
            setIsModalVisible(true);
            const campaignData = await createCampaign(UserData.id, name, templateName, customMessage, authToken);
            const messagePayloads = filteredNumbers.map(item =>
                generatePayload(item, templateName, templateLanguage, dynamicValues)
            );
            await sendMessageWithRateLimit(messagePayloads, messagesApi, waToken, campaignData, api, authToken);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleTabChange = (key) => {
        const newIndex = parseInt(key, 10);
        setCurrentCardIndex(newIndex);
    };

    return (
        <>
            <ModeLindex
                open={open}
                handleCancel={handleCancel}
                UserData={UserData}
                openMediaModel={openMediaModel}
                handleCancelMediaModel={handleCancelMediaModel}
                HandleUploadImage={HandleUploadImage}
                requestType={requestType}
                progress={progress}
                isModalVisible={isModalVisible}
                loading={loading}
                conicColors={conicColors}
                resetCampaign={resetCampaign}
                navigate={navigate}
            />
            <Row gutter={12}>
                {Permisson?.includes('View Campaign') &&
                    <>
                        <Col xs={24} sm={24} md={24} lg={12}>
                            <Card title="Carousel Campaign">
                                {/* Error Alert */}
                                <MotionError error={error}/>
                                {/* Form */}
                                <Form
                                    name="basicInformation"
                                    layout="vertical"
                                    initialValues={{ size: componentSize }}
                                    onValuesChange={onFormLayoutChange}
                                    size={componentSize}
                                >
                                    <Row gutter={[16, 16]}>
                                        {/* Campaign Name */}
                                        <Col span={24}>
                                            <Form.Item
                                                label="Campaign Name"
                                                name="Campaign-Name"
                                                className='m-0'
                                                rules={[{ required: true, message: 'Please enter campaign name!' }]}
                                            >
                                                <Input type="text" onChange={(e) => setName(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <CommonNumberField
                                                numbers={numbers}
                                                number={number}
                                                setNumber={setNumber}
                                                groupsKey={groupsKey}
                                            />
                                        </Col>


                                        {/* Template Category */}
                                        <Col span={24}>
                                            <Form.Item label="Template Category" name="Template_category">
                                                <Radio.Group
                                                    onChange={(e) => setTemplateCategory(e.target.value)}
                                                    value={templateCategory}
                                                >
                                                    <Radio value="MARKETING">Marketing</Radio>
                                                    <Radio value="UTILITY">Utility</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>

                                        {/* Templates Selection */}
                                        {templateCategory && (
                                            <Col span={24}>
                                                <Form.Item label="Templates" name="Templates">
                                                    <Select
                                                        size="default"
                                                        style={{ width: '100%' }}
                                                        onChange={(value) => HandleTemplate(value)}
                                                        showSearch
                                                    >
                                                        <Select.Option value="choose...">Select</Select.Option>
                                                        {templateList?.length > 0 ? (
                                                            templateList.map((item, index) => (
                                                                <Select.Option value={item.name} key={index}>
                                                                    {item.name}
                                                                </Select.Option>
                                                            ))
                                                        ) : (
                                                            <Select.Option value="">
                                                                You Don't Have Any Approved Templates
                                                            </Select.Option>
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        )}

                                        {/* Dynamic Values */}
                                        {mainBodyInputs?.length > 0 && (
                                            <Col span={24}>
                                                <Form.Item label={`${mainBodyInputs.length} dynamic values detected`}>
                                                    {mainBodyInputs.map((input, index) => (
                                                        <div key={index} className="mb-2">
                                                            {input}
                                                        </div>
                                                    ))}
                                                </Form.Item>
                                            </Col>
                                        )}
                                        {/* Carousel Cards */}
                                        <CardData
                                            cardData={cardData}
                                            handleTabChange={handleTabChange}
                                            buttonStyle={buttonStyle}
                                            showMediaModel={showMediaModel}
                                            handleMediaLinkChange={handleMediaLinkChange}
                                        />

                                        {/* Submit Buttons */}
                                        <Col span={24}>
                                            <Flex alignItems="center" gap={16}>
                                                {Permisson?.includes('Create Campaign') && (
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        onClick={(e) => HandleSubmit(e)}
                                                        loading={loading}>
                                                        Submit
                                                    </Button>
                                                )}
                                            </Flex>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                        {/* mobile preview  */}
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Card
                                title={
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span>Campaign Preview</span>
                                        <Flex alignItems='center'>
                                            <span className='h5 m-0 mr-2 p-0'>Auto Scroll</span>
                                            <Switch checked={isLoop} onChange={handleSwitchChange} />
                                        </Flex>
                                    </div>
                                }
                            >
                                <CarouselPreview
                                    mainBody={mainBody}
                                    mediaLink={existingMediaIDs}
                                    requestType={'template'}
                                    cards={cards}
                                    isLoop={isLoop}
                                    thumbnail={thumbnail}
                                    newMesaage={newMesaage}
                                    urlButtons={urlButtons}
                                    currentCardIndex={currentCardIndex}
                                    customMessage={customMessage}
                                />
                            </Card>
                        </Col>
                    </>
                }
                {
                    Permisson?.includes('View Group') &&
                    <Col xs={24} sm={24} md={24} lg={6}>
                        <Groups key={groupsKey} ref={groupsRef} GetGroupNumber={GetGroupNumber} />
                    </Col>
                }
            </Row >
        </>
    )
}

export default CarouselCampaign
