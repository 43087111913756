import React, { useEffect, useState } from 'react'
import { Button, Col, Image, message, Modal, Row, Spin } from 'antd'
import axios from 'axios';
import Flex from 'components/shared-components/Flex';
import { LoadingOutlined, CheckOutlined } from '@ant-design/icons';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useDispatch } from 'react-redux';
import { logout } from 'store/slices/authSlice';
import { useMyContext } from 'Context/MyContextProvider';

/* global fbq, FB */
const Embedded = ({ title, userId }) => {
    const { api, authToken, UserData } = useMyContext();
    const fbButtonStyle = {
        backgroundColor: '#1877f2',
        border: 0,
        borderRadius: '4px',
        color: '#fff',
        cursor: 'pointer',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontSize: '16px',
        fontWeight: 'bold',
        height: '40px',
        padding: '0 14px'
    };


    const [systemAccessToken, setSystemAccessToken] = useState('');
    const [wabaID, setWabaID] = useState('');
    const [phoneNumberId, setPhoneNumberId] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [completed, setComepleted] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState();
    const [loadingState, setLoadingState] = useState({
        step1: false,
        step2: false,
        step3: false,
        step4: false,

    });
    const [disable, setDisable] = useState(true);
    useEffect(() => {
        window.fbAsyncInit = function () {
            FB.init({
                appId: '921956225972226',
                cookie: true,
                xfbml: true,
                version: 'v20.0'
            });

            FB.getLoginStatus(function (response) {
                statusChangeCallback(response);
            });
        };

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }, []);

    const statusChangeCallback = (response) => {
        if (response.status === 'connected') {
            const { accessToken } = response.authResponse;
            console.log('Access Token:', accessToken);
            testAPI(accessToken);
        } else {
            console.log('Please log into this webpage.');
        }
    };

    useEffect(() => {
        if (wabaID && phoneNumberId && phoneNumber && completed && systemAccessToken) {
            HandleSaveData()
        }
    }, [wabaID, phoneNumberId, phoneNumber, completed, systemAccessToken]);

    const testAPI = async (accessToken) => {
        try {
            const response = await axios.get(`https://graph.facebook.com/v20.0/me`, {
                params: {
                    access_token: accessToken,
                },
            });

            const data = response.data;
            if (data.error) {
                console.error('Error fetching user details:', data.error);
            } else {
                setPhoneNumberId(data.id || 'Not available');
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };


    const getWABAId = async (token) => {
        setOpen(true);
        const url = `https://graph.facebook.com/v20.0/debug_token?input_token=${token}`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            });

            const data = response.data;
            const whatsappBusinessManagementScope = data.data.granular_scopes.find(scope => scope.scope === 'whatsapp_business_management');
            const targetIds = whatsappBusinessManagementScope.target_ids;
            const firstTargetId = targetIds[0];

            setWabaID(firstTargetId);
            setLoadingState(prevState => ({ ...prevState, step1: true }));

            // Proceed to get phone ID
            await getPhoneId(firstTargetId, token);

        } catch (error) {
            console.error('Error in getWABAId:', error);
        }
    };

    const getPhoneId = async (firstTargetId, token) => {
        const url = `https://graph.facebook.com/v20.0/${firstTargetId}/phone_numbers?access_token=${token}`;

        try {
            const response = await axios.get(url);
            const data = response.data.data;

            if (Array.isArray(data) && data.length > 0) {
                const latestOnboardedData = data.reduce((latest, current) => {
                    return new Date(current.last_onboarded_time) > new Date(latest.last_onboarded_time) ? current : latest;
                });

                const phone_number = latestOnboardedData?.display_phone_number;
                const phone_number_id = latestOnboardedData?.id;
                const plainPhoneNumber = phone_number.replace(/[^0-9]/g, '');
                setPhoneNumber(plainPhoneNumber);
                setPhoneNumberId(phone_number_id);

                setLoadingState(prevState => ({ ...prevState, step2: true }));

                // Proceed to set WBA Pin
                await setWBAPin(phone_number_id, token, firstTargetId);

            }
        } catch (error) {
            console.error('Error in getPhoneId:', error);
        }
    };

    const setWBAPin = async (phoneID, token, firstTargetId) => {
        const url = `https://graph.facebook.com/v20.0/${phoneID}/register`;

        try {
            const response = await axios.post(url, {
                messaging_product: 'whatsapp',
                pin: '401402'
            }, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            });

            const data = response.data;

            if (data.success) {
                setLoadingState(prevState => ({ ...prevState, step3: true }));

                // Proceed to subscribe to webhook
                await SubWebhook(firstTargetId, token);
            }
        } catch (error) {
            console.error('Error in setWBAPin:', error);
        }
    };

    const SubWebhook = async (wabaID, token) => {
        const url = `https://graph.facebook.com/v20.0/${wabaID}/subscribed_apps`;

        try {
            const response = await axios.post(url, null, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            });

            const data = response.data;

            if (data.success) {
                setComepleted(true)
                // await HandleSaveData();
            }
        } catch (error) {
            console.error('Error in SubWebhook:', error);
        }
    };

    const HandleSaveData = async (token) => {
        try {
            let userid = userId ? userId : UserData?.id;
            const response = await axios.post(`${api}update-credential`, {
                user_id: userid,
                app_id: '921956225972226',
                whatsapp_business_account_id: wabaID,
                business_account_id: null,
                meta_access_token: token ? token : systemAccessToken,
                whatsapp_phone_id: phoneNumberId,
                whatsapp_number: phoneNumber
            }, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            });

            setDisable(false);
            setLoadingState(prevState => ({ ...prevState, step4: true }));
            message.success(response.data.message);
        } catch (error) {
            console.error('Error in HandleSaveData:', error);
        }
    };

    const exchangeCodeForAccessToken = async (code) => {
        const appId = '921956225972226';
        const appSecret = 'f541ef9fbafe5dda15242d15e844ef25';
        const url = `https://graph.facebook.com/v20.0/oauth/access_token?client_id=${appId}&client_secret=${appSecret}&code=${code}`;
        try {
            const response = await axios.get(url);
            const data = response.data;
            if (data.access_token) {
                HandleSaveData(data.access_token)
                setSystemAccessToken(data.access_token)
                getWABAId(data.access_token)
            } else {
                console.error('Error fetching new access token:', data);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleFacebookLogin = () => {
        if (typeof fbq !== 'undefined') {
            fbq('trackCustom', 'WhatsAppOnboardingStart', {
                appID: '921956225972226',
                feature: 'whatsapp_embedded_signup'
            });
        }

        FB.login((response) => {
            if (response.authResponse) {
                const { code } = response.authResponse;
                exchangeCodeForAccessToken(code);
            }
        }, {
            scope: 'public_profile,email',
            config_id: '7762231433831216',
            response_type: 'code',
            override_default_response_type: true
        });
    };

    const dispatch = useDispatch()

    const handleLogout = async () => {
        setLoading(true)
        try {
            let userid = userId ? userId : UserData?.id;
            const response = await axios.get(`${api}change-viewer-role/${userid}`, {
                user_id: userid,
            }, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            });
            if (response.data.status) {
                UpdateBalance()
            }
            // message.success(response.data.message);
        } catch (error) {
            console.error('Error in HandleSaveData:', error);
        }
    };

    const UpdateBalance = async (e) => {
        let userid = userId ? userId : UserData?.id;
        await axios.post(`${api}add-balance`, {
            amount: 10, user_id: userid, newCredit: 10, deduction: false
        }, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then((response) => {
            if (response.data.status) {
                Pricing()
                message.success(response.data.message)
            }
        }).catch((error) => { console.log(error) });
    }

    const Pricing = async (e) => {
        let userid = userId ? userId : UserData?.id;
        await axios.post(`${api}pricing-model`, {
            user_id: userid, priceAlert: 10, marketingPrice: 0.8, utilityPrice: 0.2 ,servicePrice:0.35,authenticationPrice: 0.2
        }, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then((response) => {
            if (response.data.status) {
                message.success('Pricing Model Updated Successfully')
                setLoading(false)
                dispatch(logout())
            }
        }).catch((error) => {
            message.error('Oops! Something Went Wrong')
        });
    }


    return (
        <div>
            <Modal
                title="Connecting With Meta"
                open={open}
                closable={false}
                footer={null}
                destroyOnClose={true}
            >
                <Row className='my-3' gutter={ROW_GUTTER}>
                    <Col xl={12}>
                        {['step1', 'step2', 'step3', 'step4'].map((step, index) => (
                            <Flex key={step} gap='30px' className='my-2'>
                                {loadingState[step] ? (
                                    <span><CheckOutlined className='text-success' /></span>
                                ) : (
                                    <span><Spin indicator={<LoadingOutlined spin />} /></span>
                                )}
                                <span>{`Step ${index + 1} of 4`}</span>
                            </Flex>
                        ))}
                    </Col>
                    <Col xl={12} className='d-flex'>
                        <Flex justifyContent="center" alignItems="center">
                            <Image src={'/img/others/embedded_img.jpeg'} preview={false} />
                        </Flex>
                    </Col>
                    <Col xl={24}>
                        <p className='text-center text-danger mb-2'> <strong><u>Important Note</u></strong> :
                            <li>1. Demo Credits Added Successfully</li>
                            <li>2. Logout required to save the details</li>
                        </p>
                        <Flex justifyContent="center">
                            <Button type="primary" onClick={() => handleLogout()} disabled={disable || loading}>
                                Save & Re-Login
                            </Button>
                        </Flex>
                    </Col>
                </Row>
            </Modal>
            <Button
                type="primary"
                className='mr-2'
                style={fbButtonStyle}
                danger
                onClick={(e) => handleFacebookLogin()}
            >
                {title ? title : 'Login with Facebook'}
            </Button>
        </div>
    )
}

export default Embedded
