import { Button, Form, Input } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';
import UploadDrawer from '../UploadDrawer';
import { SendOutlined } from '@ant-design/icons';

const ChatContentFooter = (props) => {
    const { msgList,onSend, disable, form, setDisable, setMedia, handleValuesChange, isOldChat, number, setLoading } = props
    const GetMedia = (id, name, link) => {
        setMedia({ id, name, link })
    }

    return (
        <div className="chat-content-footer">
            <Form form={form} name="msgInput" onValuesChange={handleValuesChange} onFinish={onSend} className="w-100">
                <Form.Item name="newMsg" className="mb-0">
                    <Input
                        autoComplete="off"
                        placeholder={`${isOldChat ? 'More than 24 hours have passed since the last message from this number. Please ask user to initiate the chat to start replying' : 'Type a message...'}`}
                        disabled={isOldChat}
                        suffix={
                            <div className="d-flex align-items-center">
                                <Link href="#" className="text-dark font-size-lg mr-3" disabled={!disable || isOldChat}>
                                    <UploadDrawer GetMedia={GetMedia}
                                        setDisable={setDisable}
                                        disabled={!disable || isOldChat}
                                        number={number}
                                        setLoading={setLoading}
                                        msgList={msgList}
                                    />
                                </Link>
                                <Button shape="circle" type="primary" size="small" htmlType="submit" disabled={disable || isOldChat}>
                                    <SendOutlined />
                                </Button>
                            </div>
                        }
                    />
                </Form.Item>
            </Form>
        </div>
    );
};

export default ChatContentFooter
