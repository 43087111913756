import { Col, Form, Row, Select } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import React from 'react';
import MotionWrapper from './ChatbotFields';

const ChatbotDataStoreComps = (props) => {
    const { storeKeys, setStoreKeys, chatbotState, setChatbotState, chatbotAction, setChatbotAction } = props;
    const formatLabel = (text) => {
        return text.replace(/_/g, ' ').replace(/\w\S*/g, (word) => {
            return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
        });
    };


    const handleStoreKeysChange = (value) => {
        setStoreKeys(value);
    };

    const handleChatbotStateChange = (value) => {
        setChatbotState(value);
    };

    const handleChatbotActionChange = (value) => {
        setChatbotAction(value);
    };

    return (
        <Col xs={24} sm={24} md={12}>
            <MotionWrapper>
                <Row gutter={ROW_GUTTER}>
                    <Col xs={24} sm={24} md={8}>
                        <Form.Item label="Store Keys">
                            <Select
                                size='medium'
                                mode="multiple"
                                value={storeKeys}
                                onChange={handleStoreKeysChange}
                                placeholder="Select store keys"
                            >

                                <Select.Option value="token">Token</Select.Option>
                                <Select.Option value="username">User  Name</Select.Option>
                                <Select.Option value="storeAsContact">Store as Contact</Select.Option>
                                <Select.Option value="account number">Account Number</Select.Option>
                                <Select.Option value="ClientID">Client ID</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                        <Form.Item label="Chatbot State">
                            <Select
                                size='medium'
                                value={chatbotState}
                                onChange={handleChatbotStateChange}
                                placeholder="Select store keys"
                            >
                                <Select.Option value="">Select</Select.Option>
                                <Select.Option value="WAITING_FOR_OTP">{formatLabel('WAITING_FOR_OTP')}</Select.Option>
                                <Select.Option value="WAITING_FOR_ACCOUNT_NUMBER">{formatLabel('WAITING_FOR_ACCOUNT_NUMBER')}</Select.Option>
                                <Select.Option value="WAITING_FOR_CHECK_NUMBER">{formatLabel('WAITING_FOR_CHECK_NUMBER')}</Select.Option>
                                <Select.Option value="WAITING_FOR_CARD_NUMBER">{formatLabel('WAITING_FOR_CARD_NUMBER')}</Select.Option>
                                <Select.Option value="WAITING_FOR_DATE_RANGE">{formatLabel('WAITING_FOR_DATE_RANGE')}</Select.Option>
                                <Select.Option value="WAITING_FOR_CLIENT_ID">{formatLabel('WAITING_FOR_CLIENT_ID')}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                        <Form.Item label="Chatbot Action">
                            <Select size='medium'
                                value={chatbotAction}
                                onChange={handleChatbotActionChange}
                                placeholder="Select Action">
                                <Select.Option value="">Select</Select.Option>
                                <Select.Option value="VERIFY_OTP">{formatLabel('Verify OTP')}</Select.Option>
                                <Select.Option value="VERIFY_ACCOUNT_NUMBER">{formatLabel('Verify ACCOUNT_NUMBER')}</Select.Option>
                                <Select.Option value="VERIFY_CHECK_NUMBER">{formatLabel('Verify CHECK_NUMBER')}</Select.Option>
                                <Select.Option value="VERIFY_CARD_NUMBER">{formatLabel('Verify CARD_NUMBER')}</Select.Option>
                                <Select.Option value="VERIFY_DATE_RANGE">{formatLabel('VERIFY_DATE_RANGE')}</Select.Option>
                                <Select.Option value="VERIFY_CLIENT_ID">{formatLabel('VERIFY_CLIENT_ID')}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </MotionWrapper>
        </Col>
    );
};

export default ChatbotDataStoreComps;