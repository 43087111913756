import React, { useEffect, useState } from 'react';
import { Modal, Avatar, Typography, Tabs, Image, Row, Col, Spin, Empty } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import TabPane from 'antd/es/tabs/TabPane';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { Link } from 'react-router-dom';
import axios from 'axios';

const { Text } = Typography;

const ProfileModel = (props) => {
    const { name, number, show, toggleProfileModal, parent, api } = props;
    const [media, setMedia] = useState();
    const [links, setLinks] = useState([
        { name: 'Portfolio', url: 'https://example.com/portfolio' },
        { name: 'GitHub', url: 'https://github.com' },
        { name: 'LinkedIn', url: 'https://linkedin.com' },
    ]);

    const [loading, setLoading] = useState(false);
    const fetchMedia = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${api}reports-media/files/${parent}/${number}`);
            if (response.status === 200) {
                setMedia(response?.data?.files);
            } else {
                throw new Error('Failed to fetch media files');
            }
        } catch (error) {
            console.error('Error fetching media files:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (parent && number) {
            fetchMedia();
        }
        return () => {
            setMedia(null);
        }
    }, [number, show]);


    // Function to get initials from the name
    const getInitials = (name) => {
        const names = name?.split(' ');
        return names?.map((n) => n[0])?.join('');
    };

    // Function to generate a color based on the name
    const getColorForName = (name) => {
        const colors = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];
        const index = name?.length % colors?.length;
        return colors[index];
    };

    return (
        <>
            <Modal
                title="Profile"
                open={show}
                onCancel={toggleProfileModal}
                footer={null}
                width={800}
                style={{ borderRadius: 10 }}
            >
                <div style={{ padding: 24 }}>
                    <Link className="profile-name gap-3 d-flex justify-content-start align-items-center">
                        <Avatar
                            size={50}
                            style={{ backgroundColor: getColorForName(name), fontSize: 16 }}
                        >
                            {getInitials(name)}
                        </Avatar>
                        <div className='d-flex flex-column justify-content-start'>
                            <h4 className="mb-0">{name}</h4>
                            <p style={{ fontSize: '0.8rem' }} className="mb-0">{number}</p>
                        </div>
                    </Link>
                    {/* Media Section */}
                    {loading ?
                        <div className="text-center">
                            <Spin size='md' />
                            <Text type="secondary">Loading...</Text>
                        </div>
                        :
                        <Tabs defaultActiveKey="1" centered>
                            <TabPane tab="Media" key="1">
                                <div className="media" style={{ maxHeight: '500px', overflowY: 'auto', overflowX: 'hidden' }}>
                                    <Row gutter={ROW_GUTTER}>
                                        {media?.length ? media?.map((item, index) => (
                                            <Col key={index} xs={12} sm={12} md={12} lg={8} xl={8}>
                                                <div className='pb-2'>
                                                    <Image
                                                        src={item?.file_url}
                                                        alt={`Media ${index + 1}`}
                                                        style={{
                                                            // width: '200px',
                                                            height: '200px',
                                                            objectFit: 'cover',
                                                            borderRadius: '8px',
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        )) :
                                            <Col className='d-flex justify-content-center align-items-center' span={24}>
                                                <Empty/>
                                            </Col>
                                        }
                                    </Row>
                                </div>
                            </TabPane>
                            <TabPane tab="Links" key="2">
                                <div style={{ textAlign: 'left', marginTop: 16 }}>
                                    {links?.map((link, index) => (
                                        <div key={index} style={{ marginBottom: 16 }}>
                                            <LinkOutlined style={{ marginRight: 8, color: '#666' }} />
                                            <a href={link.url} target="_blank" rel="noopener noreferrer" style={{ fontSize: 16, color: '#666' }}>
                                                {link.name}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </TabPane>
                        </Tabs>

                    }
                </div>
            </Modal>
        </>
    );
};

export default ProfileModel;