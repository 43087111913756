import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Badge, Input, Spin, Tag } from 'antd';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';
import moment from 'moment';
// import { FixedSizeList as List } from 'react-window';
import { debounce, toUpper } from 'lodash';
// import InfiniteScroll from 'react-infinite-scroller';
import InfiniteScroll from 'react-infinite-scroll-component';
export const CheckDate = (time) => {
	const currentDate = new Date();
	const diff = currentDate.getTime() - new Date(time).getTime();
	const diffInHours = diff / (1000 * 3600);
	const isOld = diffInHours > 24;
	return isOld
}

const ChatMenu = () => {
	const { api, UserData, authToken, isMobile } = useMyContext()
	const [list, setList] = useState([]);
	const [initialData, setInitialData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [listHeight, setListHeight] = useState();
	const location = useLocation()
	let navigate = useNavigate();
	const getChats = async () => {
		setLoading(true)
		try {
			const response = await axios.get(`${api}chats/${UserData.id}`, {
				headers: {
					Authorization: "Bearer " + authToken,
				},
			});
			const chats = response.data?.data
			setList(chats)
			setInitialData(chats)
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		const calculateHeight = () => {
			const chatContentBody = document.querySelector('.chat-menu');
			if (chatContentBody && chatContentBody.clientHeight > 0) {
				const height = chatContentBody.clientHeight;
				setListHeight(height - 41);
			} else {
				console.error('Chat menu element not found or has zero height');
				setListHeight(500); // Fallback height
			}
		};
		calculateHeight();
		window.addEventListener('resize', calculateHeight);
		return () => window.removeEventListener('resize', calculateHeight);
	}, []);

	useEffect(() => {
		getChats()
	}, [])




	const openChat = id => {
		const data = list?.map(elm => {
			if (elm.number === id) {
				elm.unread = 0
			}
			return elm
		})
		setList(data)
		navigate(`${id}`)
	}

	const debouncedSearch = debounce((query) => {
		const data = initialData.filter(item => item.name.toLowerCase().includes(query));
		setList(data);
	}, 300);
	const searchOnChange = e => {
		const query = e.target.value.toLowerCase();
		debouncedSearch(query);
	};



	const getSubTitle = (message) => {
		// console.log(message)
		let isFromMe = message?.msg[0]?.from === 'me'
		let type = isFromMe ? message?.chat[0]?.type : message?.msg[0]?.status?.messages_type
		let text = isFromMe ? message?.chat[message?.chat?.length - 1]?.message : message?.msg[0]?.status?.text_body

		if (isFromMe) {
			if (type === 'video' || type === 'image') {
				return toUpper(type)
			} else {
				return text
			}
		} else {
			if (type === 'video' || type === 'image') {
				return toUpper(type)
			} else {
				return text
			}
		}
		// message?.chat?.msgType === 'text' ? message?.msg[message?.msg.length - 1].text : message?.msgType
	}
	const id = parseInt(location.pathname.match(/\/([^/]+)\/?$/)[1])
	const parentRef = useRef();

	const itemsPerPage = list.length; // Number of items to load per scroll
	const [renderedRows, setRenderedRows] = useState(list?.slice(0, itemsPerPage));
	const [hasMore, setHasMore] = useState(list?.length > itemsPerPage);

	const loadMore = () => {
		console.log("Loading more items..."); // Log when loadMore is called
		const nextItems = list?.slice(renderedRows.length, renderedRows.length + itemsPerPage);
		setRenderedRows((prev) => [...prev, ...nextItems]);

		if (renderedRows.length + nextItems.length >= list?.length) {
			setHasMore(false);
		}
	};

	useEffect(() => {
		if (list?.length > 0) {
			setRenderedRows(list?.slice(0, itemsPerPage));
			setHasMore(list?.length > itemsPerPage);
		}
	}, [list]);
	return (
		<div className="chat-menu">
			<div className="chat-menu-toolbar">
				<Input
					placeholder="Search"
					onChange={searchOnChange}
					prefix={
						<SearchOutlined className="font-size-lg mr-2" />
					}
				/>
			</div>
			<div className="chat-menu-list" ref={parentRef} style={{ overflow: 'auto' }}>
				{loading ? (
					<div className="text-center">
						<Spin size='medium' />
					</div>
				) : (
					<InfiniteScroll
						dataLength={renderedRows?.length}
						next={loadMore}
						hasMore={hasMore}
						loader={<Spin size='medium' />}
						scrollableTarget="chat-menu-list"
					>
						{renderedRows?.map((item, index) => {
							const itemId = item?.id ?? `fallback-id-${index}`;
							let isOldchat = CheckDate(item.msgType !== 'date' && item?.from !== 'me' && item?.msg[item?.msg.length - 1].time)
							return (
								<div
								key={`chat-item-${itemId}`}
								onClick={() => openChat(item?.number)}
								style={{ backgroundColor: isOldchat && 'rgb(229 229 229)' }}
								className={`d-flex flex-column chat-menu-list-item ${index === (list?.length - 1) ? 'last' : ''} ${item?.id === id ? 'selected' : ''}`}
								>
									<div className="d-flex align-items-center gap-2">
										<div className="w-100 d-flex">
											<AvatarStatus
												text={item?.name}
												name={item?.name}
												hasStatus={item?.msg?.[item.msg.length - 1]?.from === 'me'}
												status={item?.msg?.[item.msg.length - 1]?.status}
												subTitle={getSubTitle(item)}
											/>
											<div className="text-right">
												<div className="chat-menu-list-item-time">
													{moment(item?.msg[item?.msg.length - 1].time).format('MMM DD, YYYY hh:mm:ss A')}
												</div>
												<div className="d-flex align-items-center gap-2 justify-content-end">
													{isOldchat ? (
														<Tag style={{ lineHeight: 1.5 }} className="p-0 px-2 m-0" color="red">
															Expired
														</Tag>
													) : (
														<Tag style={{ lineHeight: 1.5 }} className="p-0 px-2 m-0" color="green">
															Active
														</Tag>
													)}
													{item?.unread > 0 && (
														<div className="d-flex align-items-baseline">
															<Badge color="green" count={item?.unread} />
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							)
						})}
					</InfiniteScroll>
				)}
			</div>
		</div>
	)
}

export default ChatMenu
