import React, { useState, useEffect } from 'react';
import { Button, Tag, Typography } from 'antd';
import { RobotOutlined } from '@ant-design/icons';
import Flex from 'components/shared-components/Flex';
import '../FlowCss/AnimatedChatbotBuilder.css';
import ActionModel from './ActionModel';

const { Title } = Typography;

const ChatbotBuilder = ({ChatbotData,UserData}) => {
    const [showTags, setShowTags] = useState(false);
    const [hoveredTag, setHoveredTag] = useState(null);
    const [ripples, setRipples] = useState([]);

    useEffect(() => {
        const timer = setTimeout(() => setShowTags(true), 800);
        return () => clearTimeout(timer);
    }, []);

    const handleTagHover = (index) => {
        setHoveredTag(index);
    };

    const handleTagLeave = () => {
        setHoveredTag(null);
    };

    const createRipple = (event) => {
        const button = event.currentTarget;
        const rect = button.getBoundingClientRect();
        const ripple = {
            x: event.clientX - rect.left,
            y: event.clientY - rect.top,
            id: Date.now()
        };
        setRipples((prevRipples) => [...prevRipples, ripple]);
        setTimeout(() => {
            setRipples((prevRipples) => prevRipples.filter((r) => r.id !== ripple.id));
        }, 1000);
        setIsModalVisible(true)
    };


    const [isModalVisible, setIsModalVisible] = useState(false);
    const handleModalOk = () => {
        setIsModalVisible(false);
    };
    const handleModalCancel = (type) => {
        if(type !== 'manual') {
            ChatbotData(UserData?.id)
        }
        setIsModalVisible(false);
    };
    return (
        <>
            <ActionModel
                actionType={'add'}
                isModalVisible={isModalVisible}
                handleModalOk={handleModalOk}
                handleModalCancel={handleModalCancel}
                userId={UserData?.id}
            />
            <div className="main-cn">
                <div className="container">
                    <div className="background-animation"></div>
                    <Flex
                        justifyContent="center"
                        alignItems="center"
                        gap={20}
                        flexDirection="column"
                        style={{
                            minHeight: '80vh',
                            padding: '0 40px',
                            position: 'relative',
                            zIndex: 1,
                        }}
                    >
                        <div className="title-animation floating">
                            <Title
                                level={2}
                                style={{
                                    margin: 0,
                                    fontSize: '28px',
                                }}
                            >
                                Build Your Own Chatbot
                            </Title>
                        </div>
                        <Flex
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                            gap={20}
                        >
                            <div className="button-container">
                                <Button
                                    type="primary"
                                    size="large"
                                    icon={<RobotOutlined className="robot-icon" />}
                                    className="button-animation"
                                    onClick={createRipple}
                                    style={{
                                        padding: '0 40px',
                                        height: '50px',
                                        fontSize: '18px',
                                        borderRadius: '25px',
                                        backgroundColor: '#2ECC71',
                                        borderColor: '#2ECC71',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px'
                                    }}
                                >
                                    Create Your First Response
                                </Button>
                                {ripples.map((ripple) => (
                                    <span
                                        key={ripple.id}
                                        className="ripple"
                                        style={{
                                            left: ripple.x,
                                            top: ripple.y,
                                        }}
                                    />
                                ))}
                            </div>
                            <Flex style={{ gap: '32px' }}>
                                {showTags && (
                                    <>
                                        {['Customizable', 'Intelligent', 'Lightning Fast', 'Smart Responses', '24*7 Availability'].map((tag, index) => (
                                            <Tag
                                                key={index}
                                                color={['green', 'blue', 'orange', 'cyan', 'purple'][index]}
                                                className={`tag-animation ${hoveredTag === index ? 'floating' : ''}`}
                                                style={{
                                                    animationDelay: `${index * 0.1}s`,
                                                    transform: hoveredTag === index ? 'scale(1.1)' : 'scale(1)',
                                                    transition: 'transform 0.3s ease',
                                                }}
                                                onMouseEnter={() => handleTagHover(index)}
                                                onMouseLeave={handleTagLeave}
                                            >
                                                {tag}
                                            </Tag>
                                        ))}
                                    </>
                                )}
                            </Flex>
                        </Flex>
                    </Flex>
                </div>
            </div>
        </>
    );
};


export default ChatbotBuilder;

