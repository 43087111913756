import React, { useEffect, useState } from 'react'
import { Card, Table, Button, Row, Select, Form, Col, DatePicker } from 'antd';
import dayjs from 'dayjs';
import axios from 'axios';
import Flex from 'components/shared-components/Flex';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useMyContext } from 'Context/MyContextProvider';
import { ROW_GUTTER } from 'constants/ThemeConstant';


const { RangePicker } = DatePicker;
const { Option } = Select
const UserStates = () => {
    const { api, authToken, UserData, analyticsApi, waToken, Permisson, navigate, HandleExport, isMobile } = useMyContext();
    useEffect(() => {
        if (!Permisson?.includes('View Cost Analysis')) {
            navigate('404')
        }
    }, [Permisson]);

    const [loading, setLoading] = useState(false);

    const [componentSize, setComponentSize] = useState('medium');
    const [analyticsData, setAnaLyticsData] = useState([]);
    const [users, setUsers] = useState([]);
    const [userAnaLytics, setUserAnaLytics] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [endDate, setEndDate] = useState('');

    const onFormLayoutChange = ({ size }) => {
        // console.log(size)
        setComponentSize(size);
    };

    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: {
                compare: (a, b) => a.name.length - b.name.length,
            },
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone_number',
            sorter: {
                compare: (a, b) => a.phone_number.length - b.phone_number.length,
            },
        },
        {
            title: 'Conversation Category',
            dataIndex: 'conversation_category',
            //   render: date => <span>{dayjs.unix(date).format('MM/DD/YYYY')} </span>,
            sorter: {
                compare: (a, b) => a.conversation_category.length - b.conversation_category.length,
            },
        },
        {
            title: 'Conversation Type',
            dataIndex: 'conversation_type',
            sorter: {
                compare: (a, b) => a.conversation_type.length - b.conversation_type.length,
            },
        },
        {
            title: 'Unique Conversations',
            dataIndex: 'conversation',
            //   render: date => <span>{dayjs.unix(date).format('MM/DD/YYYY')} </span>,
            sorter: {
                compare: (a, b) => a.conversation.length - b.conversation.length,
            },
        },
        {
            title: 'Cost',
            dataIndex: 'Cost',
            render: (_, record) => {
                const conversationType = record.conversation_category;
                if (conversationType === 'MARKETING') {
                    return `₹${((UserData?.pricing_model?.marketing_price) * (record.conversation)).toFixed(2)}`;
                } else {
                    return `₹${((UserData?.pricing_model?.utility_price) * (record.conversation)).toFixed(2)}`;
                }
            },
            sorter: {
                compare: (a, b) => a.conversation.length - b.conversation.length,
            },
        },
        {
            title: 'Date',
            dataIndex: 'start',
            render: date => <span>{dayjs.unix(date).format('DD/MM/YYYY')} </span>,
            sorter: (a, b) => a.start - b.start,
        },
    ];

    //analytics
    const GetAnalytics = async () => {
        setLoading(true);
        try {
            const response = await axios.get(analyticsApi,
                {
                    headers: {
                        'Authorization': 'Bearer ' + waToken,
                    }
                });
            const res = response.data.conversation_analytics.data[0].data_points;
            const sortedData = res?.sort((a, b) => b.start - a.start);
            setAnaLyticsData(sortedData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    useEffect(() => {
        GetAnalytics();
    }, [])


    const UserList = () => {
        setLoading(true);
        axios.get(`${api}users`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then(response => {
                setUsers(response.data.user);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.log(error);
            });
    }

    useEffect(() => {
        UserList();
    }, []);



    const combineData = () => {
        const usersMap = {};
        users.forEach(user => {
            usersMap[user.phone_number] = user.name;
        });

        // Combine user name with analytics data
        const combinedData = analyticsData.map(analytics => {
            const userName = usersMap[analytics.phone_number] || 'Unknown';
            return { ...analytics, name: userName };
        });
        setUserAnaLytics(combinedData);
    };

    useEffect(() => {
        if (users && analyticsData) {
            combineData()
        }
    }, [users, analyticsData])


    const HandleUserChange = (value) => {
        // combineData();
        const filter = userAnaLytics.filter((item) => item.conversation_category === value)
        setFilterData(filter);
    }


    const HandleDateRangeChange = (dates) => {

        if (dates && dates.length > 0) {
            // Sort the dates in ascending order
            const sortedDates = dates.slice().sort((a, b) => new Date(a) - new Date(b));

            // Format sorted dates in "dd mm yy" format
            const formattedDates = sortedDates.map(date => {
                const formattedDate = new Date(date);
                const dd = String(formattedDate.getDate()).padStart(2, '0');
                const mm = String(formattedDate.getMonth() + 1).padStart(2, '0'); // January is 0!
                const yy = String(formattedDate.getFullYear()).slice(-2); // Get last 2 digits of the year
                return `${dd} ${mm} ${yy}`;
            });

            // Now you can use formattedDates as the sorted date range in "dd mm yy" format
            setEndDate(formattedDates[0])
            setEndDate(formattedDates[formattedDates.length - 1])
        }





        // const startUnix = dayjs(startDate).unix
        // const endUnix = dayjs(endDate).unix();
        // const filteredData = userAnaLytics.filter(item => {

        //     const itemDateUnix = item.date
        //      return itemDateUnix >= startUnix && itemDateUnix <= endUnix;
        // });
        // console.log(filteredData);
    };

    return (

        <Card bodyStyle={{ padding: '0px' }}>
            <div className="container-fluid">
                <Form
                    name="basicInformation"
                    layout={`${isMobile ? 'vertical' : 'horizontal'} `}
                >
                    <Row gutter={ROW_GUTTER} justify="space-between" align="middle" className="py-4">
                        <Col>
                            <h2>Analytics</h2>
                        </Col>
                        {/* <Col xs={24} sm={24} md={12} lg={16}>
                            <Row justify="end" align="middle">
                                <Col span={24} xxl={8}>
                                    <div className="mr-md-3 mb-3">
                                        <Form.Item label="Date Range" className='mb-0'>
                                            <RangePicker
                                                style={{ width: '100%' }}
                                                allowClear onChange={(dates) => HandleDateRangeChange(dates)}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={10} xxl={4}>
                                    <div className="mb-3 text-center">
                                        <Button icon={<CloudDownloadOutlined />} onClick={() => HandleExport('export-cost-analysis', 'cost-analysis.xlsx')}>Export Analysis Report</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col> */}
                    </Row>
                </Form>
            </div>
            <div className="table-responsive">
                <Table loading={loading} columns={tableColumns} dataSource={filterData?.length > 0 ? filterData : userAnaLytics} rowKey="id" />
            </div>
            {/* <UserView data={selectedUser} visible={userProfileVisible} close={() => closeUserProfile()} /> */}
        </Card >
    );
};

export default UserStates
