import React, { useEffect, useState } from "react";
import PageHeaderAlt from "components/layout-components/PageHeaderAlt";
import {
    Button,
    Row,
    Col,
    Card,
    Checkbox,
    message,
    Modal,
    Input,
    Form,
} from "antd";
import {
    PlusOutlined,
} from "@ant-design/icons";

import Flex from "components/shared-components/Flex";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useMyContext } from "Context/MyContextProvider";
import Search from "antd/es/input/Search";

const VIEW_LIST = "LIST";
const VIEW_GRID = "GRID";

const ItemHeader = ({ name, category }) => (
    <div>
        <h4 className="mb-0">{name}</h4>
        <span className="text-muted">{category}</span>
    </div>
);

const UserPermission = ({ api, authToken }) => {
    const { Permisson } = useMyContext();
    const UserPermission = Permisson;
    const [view, setView] = useState(VIEW_GRID);
    const [permission, setPermission] = useState([]);
    const [existPermission, setExistPermission] = useState([]);
    const [initialPermission, setInitialPermission] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [selectAll, setSelectAll] = useState(false);
    const [unChek, setUnChek] = useState(false);
    const navigate = useNavigate();
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const PermissionData = async () => {
        try {
            const response = await axios.get(`${api}user-permission/${id}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            setPermission(response.data?.AllPermission);
            setInitialPermission(response.data.AllPermission);
            const existPermissionData = response.data?.exist;
            const existPermissionArray = existPermissionData ? Object.values(existPermissionData) : [];
            setExistPermission(existPermissionArray);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };
    useEffect(() => {
        setLoading(true);
        PermissionData();
    }, []);

    const handlePermissionChange = (e) => {
        const permissionId = e.target.value;
        const isChecked = e.target.checked;
        if (isChecked) {
            setExistPermission((prevExistpermission) => [
                ...(prevExistpermission || []),
                parseInt(permissionId),
            ]);
        } else {
            const newPermissions = existPermission?.filter(
                (id) => id !== parseInt(permissionId)
            );
            setExistPermission(newPermissions);
        }
    };




    useEffect(() => {
        if (existPermission?.length === permission?.length) {
            setUnChek(true);
        } else {
            setUnChek(false);
        }
    }, [existPermission, permission]);

    useEffect(() => {
        setSelectAll(existPermission?.length === permission?.length);
    }, [existPermission, permission]);

    const handleMultiSelect = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectAll(true);
            const allPermissionIds = permission.map((p) => p.id);
            setExistPermission(allPermissionIds);
            setUnChek(true);
        } else {
            setSelectAll(false);
            setExistPermission([]);
            setUnChek(false);
        }
    };

    const GivePermission = (e) => {
        setConfirmLoading(true);
        e.preventDefault();
        let ResultData = [...new Set(existPermission)];
        if (ResultData.length > 0) {
            axios
                .post(
                    `${api}user-permission/${id}`, { user_id: id, permission_id: ResultData },
                    {
                        headers: {
                            Authorization: "Bearer " + authToken,
                        },
                    }
                )
                .then((Response) => {
                    message.success("Permission Assign Successfully");
                    // setAlert(true);
                    setConfirmLoading(false);
                    scrollToTop();
                })
                .catch((error) => { });
        } else {
            message.error("You Have To Select At Least 1 Permission");
            setConfirmLoading(false);
        }
    };

    //model
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [name, setName] = useState('');
    const handleOk = async () => {
        setConfirmLoading(true);
        try {
            const response = await axios.post(`${api}create-permission`, { name }, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            PermissionData();
            setConfirmLoading(false);
            handleCancel()
        } catch (error) {
            setConfirmLoading(false);
            handleCancel()
            console.log(error);
        }

    };
    const handleCancel = () => {
        setName("");
        setOpen(false);
    };

    const handleSeach = (e) => {
        const value = e.target.value.toLowerCase();
        if (value) {
            const filteredPermissions = permission.filter(permission =>
                permission.name.toLowerCase().includes(value)
            );
            setPermission(filteredPermissions);
        } else {
            setPermission(initialPermission)
        }
    };

    return (
        <>
            <Modal
                title="Create New Permission"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Form.Item label="Name" className=" my-3">
                    <Input type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="permission name"
                    />
                </Form.Item>
            </Modal>
            <div className={`my-4 ${view === VIEW_LIST ? "container" : "container-fluid"}`}>
                <Row gutter={16}>
                    <Col xs={24} sm={24} lg={8} xl={8} xxl={6}>
                        <Card>
                            <Flex alignItems="center" justifyContent="space-between">
                                <ItemHeader name={unChek ? "Deselect All" : "Select All"} />
                                <Checkbox
                                    onChange={(e) => handleMultiSelect(e)}
                                    checked={unChek}
                                />
                            </Flex>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} lg={8} xl={8} xxl={6} />
                    <Col xs={24} sm={24} lg={8} xl={8} xxl={6} />
                    <Col xs={24} sm={24} lg={8} xl={8} xxl={6} className="d-flex justify-content-end">
                        {/* <Card> */}
                        <Flex alignItems="center" justifyContent="space-between">
                            {/* <ItemHeader name={unChek ? "Deselect All" : "Select All"} /> */}
                            <Search placeholder="input search text" onChange={(value) => handleSeach(value)} />
                        </Flex>
                        {/* </Card> */}
                    </Col>
                </Row>
                <Row gutter={16}>
                    {permission?.map((item, index) => (
                        <Col xs={24} sm={24} lg={8} xl={8} xxl={6} key={item?.index}>
                            <Card>
                                <Flex alignItems="center" justifyContent="space-between">
                                    <ItemHeader name={item.name} />
                                    {UserPermission?.includes('Assign User Permission') &&
                                        <Checkbox
                                            onChange={(e) => handlePermissionChange(e)}
                                            value={item?.id}
                                            checked={existPermission?.includes(item?.id) || selectAll}
                                            id={`checkbox-${index}`}
                                        />
                                    }
                                </Flex>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <Flex justifyContent="center" alignItems="center" className="py-4">
                    <Button type="primary" danger onClick={() => navigate('/roles')}>Cancel</Button>
                    {UserPermission?.includes('Assign User Permission') &&
                        <Button
                            type="primary"
                            className="ml-2"
                            onClick={(e) => GivePermission(e)}
                            disabled={confirmLoading}
                        >
                            {/* <PlusOutlined /> */}
                            <span>{confirmLoading ? 'Submitting..' : 'Submit'}</span>
                        </Button>
                    }

                </Flex>
            </div>
        </>
    );
};

export default UserPermission;
