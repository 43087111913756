import React from 'react'
import { Card, Col, Row } from 'antd'
import { useParams } from 'react-router-dom'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import NewChatBotForm from './Compoenents/NewChatbotForm';
import ChatbotInfo from './ChatbotCodeComps/ChatbotInfo';
const NewChatBot = () => {
  const { id } = useParams();
  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={24} lg={18}>
          <Card title="Add New Response" style={{ maxHeight: 720, overflow: 'auto' }}>
            <NewChatBotForm id={id} showParentChatbot={true} />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} style={{ maxHeight: 720, overflow: 'auto' }}>
          <ChatbotInfo id={id}/>
        </Col>
      </Row>
    </>
  )
}

export default NewChatBot
