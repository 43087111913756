import React, { useState, useEffect, useCallback } from 'react';
import { Form, message } from 'antd';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';
import { toLower } from 'lodash';
import ChatContentFooter from './ConversationComps/ChatContentFooter';
import ChatContentBody from './ConversationComps/ChatContentBody';
import ChatContentHeader from './ConversationComps/ChatContentHeader';
import moment from 'moment';
export const getInitials = (name) => {
    if (!name) return '';
    const nameParts = name?.split(' ');
    return nameParts?.length === 1
        ? nameParts[0]?.charAt(0)?.toUpperCase()
        : nameParts[0]?.charAt(0)?.toUpperCase() + nameParts[1]?.charAt(0)?.toUpperCase();
};
export const getColorForName = (name) => {
    const colors = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae', '#87d068'];
    const index = name?.length % colors.length || 0;
    return colors[index];
};

const Conversation = () => {
    const { api, UserData, authToken, messagesApi, waToken, isSupportAgent, supportAgents } = useMyContext();
    const params = useParams();
    const [info, setInfo] = useState({});
    const [msgList, setMsgList] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const [disable, setDisable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [activeAgent, setActiveAgent] = useState();
    const [lastReportID, setLastReportID] = useState();
    const [media, setMedia] = useState();
    const [isOldChat, setIsOldChat] = useState(false);
    const [messageSentTime, setMessageSentTime] = useState();
    const { id } = params;

    const [form] = Form.useForm();
    const currentDate = new Date();
    const getChats = async (number) => {
        setLoading(true);
        try {
            const response = await axios.get(`${api}message/${number}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            const chats = response.data;
            setActiveAgent(chats?.active_agent)
            const lastItem = chats?.msg?.filter(item => item.from === 'opposite').pop();
            setLastReportID(lastItem && lastItem.id);
            setInfo(chats);

            const filteredChats = chats?.msg.filter(item => item.msgType !== 'date' && item?.from !== 'me');
            const lastEntryDate = filteredChats[filteredChats.length - 1]?.created_at;
            setMessageSentTime(lastEntryDate)
            const diff = currentDate.getTime() - new Date(lastEntryDate).getTime();
            const diffInHours = diff / (1000 * 3600);
            const isOld = diffInHours > 24;
            setIsOldChat(isOld);

            setMsgList(chats?.msg);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };



    const fetchKeywords = async () => {
        const userId = isSupportAgent ? UserData?.reporting_user?.id : UserData?.id
        try {
            const response = await axios.get(`${api}chatbots/keywords/${userId}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            setKeywords(response.data?.keywords);
        } catch (error) {
            console.error("Error fetching keywords:", error);
        }
    };

    useEffect(() => {
        fetchKeywords()
    }, []);

    useEffect(() => {
        getChats(getUserId());
    }, [id]);

    const getUserId = () => {
        return parseInt(id);
    };
    const getMediaType = (fileName) => {
        const mediaTypes = {
            image: ['jpg', 'jpeg', 'png'],
            video: ['mp4', '3gp'],
            document: ['pdf'],
        };

        const extension = fileName?.split('.').pop()?.split(/[?#]/)[0]; // Handle URLs with query parameters

        for (const [type, extensions] of Object.entries(mediaTypes)) {
            if (extensions?.includes(extension?.toLowerCase())) {
                return toLower(type); // Return the media type
            }
        }
        return null; // Return null if not a media file
    };

    const onSend = async (values) => {
        try {
            setDisable(true);
            const payload = {
                messaging_product: "whatsapp",
                to: info?.number,
            };
            const mediaType = getMediaType(media?.name);
            if(mediaType){
                //remove pending_messages from the list
                const filteredMsgList = msgList.filter(item => item.msgType !== 'pending_message');
                setMsgList(filteredMsgList);
            }
            if (mediaType) {
                payload.type = mediaType;
                payload[mediaType] = { id: media.id };
            } else {
                payload.type = 'text';
                payload.text = {
                    preview_url: false,
                    body: values.newMsg,
                };
            }
            const userID = isSupportAgent ? UserData?.reporting_user?.id : UserData?.id

            const res = await axios.post(messagesApi, payload, {
                headers: { Authorization: `Bearer ${waToken}` },
            });
            form.resetFields();
            if (res.data.messages[0].message_status === 'accepted' || res.data.messages[0]?.id) {
                const messagePayload = {
                    type: !disable ? 'text' : getMediaType(media?.name),
                    user_id: userID,
                    report_id: lastReportID,
                    message_id: res.data.messages[0]?.id,
                    message: !disable ? values.newMsg : media?.link,
                }
                if (isSupportAgent) {
                    messagePayload.agent_id = UserData?.id
                }
                createOutReport(messagePayload, userID)
            }
        } catch (error) {
            console.log(error);
        } finally {
            setDisable(true);
            setLoading(false);
        }
    };
    const createOutReport = async (messagePayload, userID) => {
        const reportData = {
            user_id: isSupportAgent ? UserData?.reporting_user?.id : UserData?.id,
            display_phone_number: isSupportAgent ? UserData?.reporting_user?.whatsapp_number : UserData?.whatsapp_number,
            waId: id,
            status: 'sent',
            status_id: messagePayload?.message_id
        }
        try {
            const response = await axios.post(`${api}out-reports/create`, reportData, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            if (response.data.status) {
                saveReport(messagePayload, userID, response.data.response?.id)
            } else {
                message.error('Failed to create report');
            }
        } catch (error) {
            message.error(error.response?.data?.message || 'Error creating report');
        }
    }
    const saveReport = async (messagePayload, userID, outReportID) => {
        if (outReportID) {
            messagePayload.out_report_id = outReportID
        }
        try {
            const response = await axios.post(`${api}new-chat/${userID}`, messagePayload, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            if (response.data.status) {
                setMedia()
                getChats(id)

                form.resetFields();
            }
        } catch (error) {
            message.error(error.response?.data?.message || 'Error saving report');
        }
    }

    const handleValuesChange = useCallback((changedValues) => {
        if (changedValues.newMsg && changedValues.newMsg.trim().length > 0) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }, []);

    useEffect(() => {
        if (media?.id) {
            onSend()
        }
    }, [media]);

    return (
        <div className="chat-content">
            <ChatContentHeader
                isSupportAgent={isSupportAgent}
                messageSentTime={messageSentTime}
                name={info?.name}
                authToken={authToken}
                number={info.number}
                loading={loading}
                displayPhoneNumber={UserData?.whatsapp_number}
                onRefresh={() => getChats(id)}
                supportAgents={supportAgents}
                api={api}
                getChats={getChats}
                activeAgent={activeAgent}
                wa_id={id}
            />
            <ChatContentBody
                messages={msgList}
                loading={loading}
                supportAgents={supportAgents}
                keywords={keywords}
                number={info.number}
            />
            <ChatContentFooter
                form={form}
                number={info.number}
                setDisable={setDisable}
                onSend={onSend}
                isOldChat={isOldChat}
                disable={disable}
                setLoading={setLoading}
                setMedia={setMedia}
                msgList={msgList}
                handleValuesChange={handleValuesChange}
            />
        </div>
    );
};

export default Conversation;
