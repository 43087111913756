import React from 'react';
import PropTypes from 'prop-types'
import { Avatar, Tooltip } from 'antd';
import { getColorForName, getInitials } from 'views/app-views/whatsapp/chat/Conversation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble, faCircleExclamation, faClockFour } from '@fortawesome/free-solid-svg-icons';
import { AlertCircle, Check, CheckCheck, Clock } from 'lucide-react';

export const renderAvatar = (props) => {
    return (
        <Tooltip title={props.text} placement="top">
            <Avatar
                {...props}
                className={`ant-avatar-${props.type}`}
                style={{
                    backgroundColor: props?.color && getColorForName(props.text),
                }}
            >
                {getInitials(props.text)}
            </Avatar>
        </Tooltip>
    );
};
// export const getStatusIcon = (status, errorMessage) => {
// 	let icon;
// 	if (status === 'pending') {
// 		icon = <FontAwesomeIcon icon={faClockFour} style={{ color: '#808080' }} />;
// 	}
// 	else if (status === 'sent') {
// 		icon = <FontAwesomeIcon icon={faCheck} className='text-muted'/>;
// 	} else if (status === 'delivered') {
// 		icon = <FontAwesomeIcon icon={faCheckDouble} className='text-muted' />;
// 	} else if (status === 'read') {
// 		icon = <FontAwesomeIcon icon={faCheckDouble} style={{ color: '#53bdeb' }} />;
// 	} else {
// 		icon = (
// 			<Tooltip title={errorMessage}>
// 				<FontAwesomeIcon icon={faCircleExclamation} className="text-danger" />
// 			</Tooltip>
// 		);
// 	}
// 	return icon;
// };
// used lucid icons 
export const getStatusIcon = (status, errorMessage) => {
	let icon;
    if (status === 'pending') {
        icon = <Clock size={12} color="#808080" />;
    } else if (status === 'sent') {
        icon = <Check size={16} className="text-muted" />;
    } else if (status === 'delivered') {
        icon = <CheckCheck size={16} className="text-muted" />;
    } else if (status === 'read') {
        icon = <CheckCheck size={16} color="#53bdeb" />;
    } else {
        icon = (
            <Tooltip title={errorMessage}>
                <AlertCircle size={16} className="text-danger" />
            </Tooltip>
        );
    }
	return icon;
};
export const AvatarStatus = props => {
	const { name, suffix, subTitle, id, type, src, icon, size, shape, gap, text, onNameClick, hasStatus, status } = props
	return (
		<div className="avatar-status d-flex align-items-center">
			{renderAvatar({ icon, src, type, size, shape, gap, text })}
			<div className="ml-2">
				<div>
					{
						onNameClick ?
							<div onClick={() => onNameClick({ name, subTitle, src, id })} className="avatar-status-name clickable">{name}</div>
							:
							<div className="avatar-status-name">{name}</div>
					}
					<span>{suffix}</span>
				</div>
				<div className="d-flex align-items-center" style={{ gap: '3px' }}>
					{hasStatus && getStatusIcon(status)}
					<div className="text-muted avatar-status-subtitle">{subTitle}</div>
				</div>
			</div>
		</div>
	)
}

AvatarStatus.propTypes = {
	name: PropTypes.string,
	src: PropTypes.string,
	type: PropTypes.string,
	onNameClick: PropTypes.func
}


export default AvatarStatus;
