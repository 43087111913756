import React, { useMemo, useState } from 'react';
import { Col, Form, Select, Button, Input, Image } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { GetPresets } from '../../ListItem/Components/ListMessagePresets';
import { useMyContext } from 'Context/MyContextProvider';

// Constants
const DEFAULT_IMAGE = '/img/others/dummy-header.jpg';
const FORM_TYPES = {
    TEMPLATE: 'template',
    PRESET: 'preset',
    LIST_ITEM: 'list_item'
};

// Separate components for better organization and reusability
const MediaPreview = ({ url, media, getUrlFromMediaId }) => (
    <Image
        src={getUrlFromMediaId(url, media) || DEFAULT_IMAGE}
        className="border rounded cursor-pointer"
        preview={false}
        style={{
            width: '200px',
            height: '150px',
            objectFit: 'contain',
        }}
    />
);

const MediaSelector = ({ url, buttonStyle, showMediaModel, formType }) => (
    <>
        {url ? (
            <>
                <Input
                    type="text"
                    disabled
                    value={url}
                    className="mb-3"
                />
                <Button type="" style={buttonStyle} onClick={() => showMediaModel(formType)}>
                    <CloudUploadOutlined />Choose Another File
                </Button>
            </>
        ) : (
            <Button type="" style={buttonStyle} onClick={() => showMediaModel(formType)}>
                <CloudUploadOutlined />Choose File
            </Button>
        )}
    </>
);

const DynamicForm = ({
    chatBotType,
    templateList = [],
    presetsList = [],
    replyTemplate,
    handleTemplate,
    templatesMediaState,
    templatesMedia,
    showMediaModel,
    buttonStyle,
    toLower,
    getUrlFromMediaId,
    media,
    preset,
    setpreset,
    setReplyTemplate,
    listItem,
    setListItem
}) => {
    // make state for listItemPresets 
    const { UserData, api, authToken } = useMyContext()
    const [listItemPresets, setListItemPresets] = useState([]);
    const [loading, setLoading] = useState(false);
    // Memoize the options to prevent unnecessary re-renders
    const templateOptions = useMemo(() => {
        if (templateList.length === 0) {
            return [<Select.Option key="empty" value="">You Don't Have Any Approved Templates</Select.Option>];
        }
        return templateList.map((item, index) => (
            <Select.Option key={index} value={item.name}>{item.name}</Select.Option>
        ));
    }, [templateList]);

    const presetOptions = useMemo(() => {
        if (!presetsList?.length) {
            return [<Select.Option key="empty" value="">You Don't Have Any Approved Templates</Select.Option>];
        }
        return presetsList.map((item, index) => (
            <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
        ));
    }, [presetsList]);
    const listItemOptions = useMemo(() => {
        if (!listItemPresets?.length) {
            return [<Select.Option key="empty" value="">You Don't Have Any Approved Templates</Select.Option>];
        }
        return listItemPresets && listItemPresets?.map((item, index) => (
            <Select.Option key={index} value={item?.id}>{item?.name}</Select.Option>
        ));
    }, [listItemPresets]);


    useEffect(() => {
        GetPresets(setLoading, api, UserData, authToken, setListItemPresets)
    }, []);
    // Template form rendering
    const renderTemplateForm = () => (
        <>
            <Col xs={24} sm={24} md={12}>
                <Form.Item label="Templates">
                    <Select
                        showSearch
                        size="default"
                        defaultValue={replyTemplate}
                        style={{ width: '100%' }}
                        onChange={(value) => handleTemplate(value, setReplyTemplate, 'replyTemplate')}
                    >
                        {templateOptions}
                    </Select>
                </Form.Item>
            </Col>
            {templatesMediaState?.replyTemplate && (
                <>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item label="Select Media" className="mb-0" name="media-link">
                            <MediaSelector
                                url={templatesMedia?.replyTemplate?.url}
                                buttonStyle={buttonStyle}
                                showMediaModel={showMediaModel}
                                formType="replyTemplate"
                            />
                        </Form.Item>
                        <span className="text-warning">
                            This Template Contains {toLower(templatesMedia?.replyTemplate?.type)} in header
                        </span>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item label="Media Preview" className="mb-0">
                            <MediaPreview
                                url={templatesMedia?.replyTemplate?.url}
                                media={media}
                                getUrlFromMediaId={getUrlFromMediaId}
                            />
                        </Form.Item>
                        <span className="text-warning">Preview available for images</span>
                    </Col>
                </>
            )}
        </>
    );


    // Preset form rendering
    const renderPresetForm = () => (
        <Col xs={24} sm={24} md={12}>
            <Form.Item label="Presets">
                <Select
                    showSearch
                    size="default"
                    defaultValue={preset}
                    style={{ width: '100%' }}
                    onChange={setpreset}
                >
                    {presetOptions}
                </Select>
            </Form.Item>
        </Col>
    );
    const renderListPresetForm = () => (
        <Col xs={24} sm={24} md={12}>
            <Form.Item label="Presets">
                <Select
                    showSearch
                    size="default"
                    defaultValue={listItem}
                    style={{ width: '100%' }}
                    onChange={setListItem}
                >
                    {listItemOptions}
                </Select>
            </Form.Item>
        </Col>
    );

    // Main render function using object literal pattern instead of switch
    const formTypes = {
        [FORM_TYPES.TEMPLATE]: renderTemplateForm,
        [FORM_TYPES.PRESET]: renderPresetForm,
        [FORM_TYPES.LIST_ITEM]: renderListPresetForm,
    };

    const renderForm = () => {
        const render = formTypes[chatBotType];
        return render ? render() : null;
    };

    return <div>{renderForm()}</div>;
};


export default DynamicForm;
