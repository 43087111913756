import { Modal } from 'antd'
import React from 'react'
import NewChatBotForm from '../Compoenents/NewChatbotForm'
import EditChatBotForm from '../Compoenents/EditChatBotForm'

const ActionModel = (props) => {
    const { actionType, isModalVisible, handleModalOk, handleModalCancel, parentID,userId } = props
    return (
        <Modal
            title={actionType === 'add' ? "Add Child ChatBot" : "Edit ChatBot"}
            open={isModalVisible}
            onOk={handleModalOk}
            onCancel={()=>handleModalCancel('manual')}
            width={1000}
            bodyStyle={{ maxHeight: '500px', padding: 0, overflowX: 'hidden', overflowY: 'auto' }}
            footer={null}
            style={{ top: 10 }}
            destroyOnClose
        >
            {actionType === 'add' ? (
                <NewChatBotForm parentID={parentID}
                    id={userId}
                    showParentChatbot={false}
                    isModal={true}
                    CloseModal={handleModalCancel}
                />
            ) : (
                <EditChatBotForm
                    id={parentID}
                    showParentChatbot={false}
                    isModal={true}
                    CloseModal={handleModalCancel}
                />
            )}
        </Modal>
    )
}

export default ActionModel
