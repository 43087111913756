import { Form } from 'antd';
import React, { useState } from 'react'
import { ReactTransliterate } from 'react-transliterate';
import { PlusOutlined, CloseOutlined, SmileOutlined } from '@ant-design/icons';
import Picker from 'emoji-picker-react';
const InputWithEmoji = ({ value, language, setValue, hideVarBTN }) => {
    const [showPicker, setShowPicker] = useState(false);
    const styles = {
        textarea: {
            width: '100%',
            border: '1px solid #ced4da',
            borderRadius: '.5rem',
            outline: 'none',
        },
    };
    const handleAddVariable = () => {
        // Extract existing variable numbers from the body
        const variableRegex = /{{(\d+)}}/g;
        const existingVariables = [];
        let match;

        // Collect existing variables from the body
        while ((match = variableRegex.exec(value)) !== null) {
            existingVariables.push(parseInt(match[1], 10));
        }

        // Determine the next variable number
        const nextNumber = existingVariables.length === 0
            ? 1
            : Math.max(...existingVariables) + 1;

        const newVariable = `{{${nextNumber}}}`;
        setValue(prevBody => {
            const updatedBody = `${prevBody} ${newVariable}`.trim();
            let reorderedIndex = 0;
            const reorderedBody = updatedBody.replace(variableRegex, () => {
                reorderedIndex++;
                return `{{${reorderedIndex}}}`;
            });

            return reorderedBody;
        });
    };

    const onEmojiClick = (emoji) => {
        setValue(prevBody => prevBody + emoji.emoji);
    }

    return (
        <Form.Item
            label="Body"
            name="Body"
            className='mb-0'
        >
            <ReactTransliterate
                renderComponent={(props) =>
                    <textarea
                        {...props}
                        rows={4}
                        className='p-2'
                        style={styles.textarea}
                        required
                        spellCheck
                        maxLength={1024}
                    />
                }
                value={value}
                onChangeText={(text) => {
                    setValue(text);
                }}
                lang={language}
            />
            <div className='d-flex align-items-center justify-content-end mt-1' style={{ gap: '10px' }}>
                {showPicker ? (
                    <CloseOutlined onClick={() => setShowPicker(false)} />
                ) : (
                    <SmileOutlined onClick={() => setShowPicker(true)} />
                )}
                <span>{value?.length}/1024</span>
                {!hideVarBTN &&
                    <div style={{ background: '#eee', cursor: 'pointer' }} className='p-2 rounded' onClick={() => handleAddVariable()}>
                        <PlusOutlined />
                        <span className='ml-2'>Add variable</span>
                    </div>
                }
            </div>
            {showPicker && (
                <div className='position-absolute' style={{ zIndex: 999, right: '10%' }}>
                    <Picker onEmojiClick={onEmojiClick} />
                </div>
            )}
            <span className='d-none'>{value}</span>
        </Form.Item>
    )
}

export default InputWithEmoji
