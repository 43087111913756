import React from 'react';
import { Button, Row, Col, Empty, Dropdown } from 'antd';
import {
  CloseOutlined,
  AppstoreAddOutlined,
  DownOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { motion, AnimatePresence } from 'framer-motion';
import { useMyContext } from 'Context/MyContextProvider';

const BulkSelectButton = ({ bulkSelectMode, onClick }) => (
  <motion.div
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
    transition={{ duration: 0.2 }}
  >
    <Button
      type={bulkSelectMode ? "danger" : "primary"}
      onClick={onClick}
      icon={bulkSelectMode ? <CloseOutlined /> : <AppstoreAddOutlined />}
    >
      {bulkSelectMode ? "Cancel" : "Bulk Select"}
    </Button>
  </motion.div>
);
const AddNewButton = ({ Permisson, setUploadModalOpen, loading, buttonStyle, uploadModalOpen }) => (
  <div>
    {Permisson?.includes('Upload Media') && (

      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        transition={{ duration: 0.2 }}
      >
        <Button
          type="primary"
          style={buttonStyle}
          onClick={() => setUploadModalOpen(true)}
          disabled={loading}
        >
          <PlusOutlined />
          <span>Add New</span>
        </Button>
      </motion.div>
    )}
  </div>
)
const ActionButton = ({ selectedItems, prevCount, bulkActionMenu, isActive = false }) => (
  <Dropdown overlay={() => bulkActionMenu(isActive)} trigger={["click"]}>
    <Button type="primary" icon={<DownOutlined />}>
      Actions{" "}
      <AnimatePresence mode="popLayout">
        <motion.span
          key={selectedItems.length}
          initial={{ opacity: 0, y: selectedItems.length > prevCount ? 10 : -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: selectedItems.length > prevCount ? -10 : 10 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          style={{ marginLeft: 5 }}
        >
          ({selectedItems.length})
        </motion.span>
      </AnimatePresence>
    </Button>
  </Dropdown>
);

export const MediaTabs = ({
  media,
  bulkSelectMode,
  handleBulkSelect,
  selectedItems,
  prevCount,
  bulkActionMenu,
  isScrolled,
  scrollRef,
  GridItem,
  activeMedia,
  expiredMedia,
  setActiveMedia,
  setExpiredMedia,
  getMedia,
  handleSelectItem,
  isActive,
  UpdateStorageData,
  Permisson,
  setUploadModalOpen,
  loading,
  uploadModalOpen
}) => {
  const { buttonStyle } = useMyContext()
  return (
    <>
      <div className="d-flex gap-5 mb-3">
        <AddNewButton uploadModalOpen={uploadModalOpen} buttonStyle={buttonStyle} Permisson={Permisson} setUploadModalOpen={setUploadModalOpen} loading={loading} />
        <BulkSelectButton bulkSelectMode={bulkSelectMode} onClick={handleBulkSelect} />

        <AnimatePresence>
          {bulkSelectMode && selectedItems.length > 0 && (
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
            >
              <ActionButton
                selectedItems={selectedItems}
                prevCount={prevCount}
                bulkActionMenu={bulkActionMenu}
                isActive={isActive}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div
        ref={scrollRef}
        style={{
          maxHeight: 520,
          overflowY: "auto",
          overflowX: "hidden",
          boxShadow: isScrolled ? "inset 0 6px 10px -6px rgba(0, 0, 0, 0.2)" : "none",
          transition: "box-shadow 0.3s ease-in-out",
        }}
      >
        <Row gutter={16}>
          {media?.length > 0 ? (
            <AnimatePresence>
              {media?.map((item, index) => (
                <Col
                  key={item.id || index}
                  xs={24}
                  sm={24}
                  lg={8}
                  xl={8}
                  xxl={4}
                  style={{ marginBottom: 16 }}
                >
                  <motion.div
                    layout
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{
                      opacity: 0,
                      scale: 0.5,
                      transition: { duration: 0.2 },
                    }}
                    transition={{
                      layout: { duration: 0.4, ease: "easeInOut" },
                    }}
                  >
                    <GridItem
                      activeMedia={activeMedia}
                      expiredMedia={expiredMedia}
                      setActiveMedia={setActiveMedia}
                      setExpiredMedia={setExpiredMedia}
                      data={item}
                      UpdateStorageData={UpdateStorageData}
                      getMedia={getMedia}
                      selectedItems={selectedItems}
                      handleSelectItem={handleSelectItem}
                      bulkSelectMode={bulkSelectMode}
                    />
                  </motion.div>
                </Col>
              ))}
            </AnimatePresence>
          ) : (
            <Col xs={24} sm={24} lg={8} xl={24} xxl={24} className="d-flex justify-content-center">
              <Empty />
            </Col>
          )}
        </Row>
      </div>
    </>
  )
};