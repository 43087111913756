import React, { useEffect, useState } from 'react';
import { Card, Drawer, Image, List, message, Typography } from 'antd';
import { CloseOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
const { Text } = Typography;
const styles = {
    chatBackground: {
        backgroundImage: `url(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: '8px',
        padding: '16px',
        // height:'100%'
    },
    chatBubble: {
        background: 'white',
        borderRadius: '8px',
        padding: '16px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        position: 'relative',
        marginLeft: '20px',
        maxWidth: '85%',
        wordWrap: 'break-word',
    },
    buttoneBubble: {
        background: 'white',
        borderRadius: '8px',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,

        // padding: '16px',
        boxShadow: '0 8px 8px -4px rgba(0, 0, 0, 0.15)',
        position: 'relative',
        bottom: '0.5rem',
        marginLeft: '20px',
        maxWidth: '85%',
        wordWrap: 'break-word',
    },
    chatBubbleBefore: {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '-5px',
        width: '0',
        height: '0',
        borderStyle: 'solid',
        borderWidth: '10px 10px 10px 0',
        borderColor: 'transparent white transparent transparent',
        background: '#ffff',
        backgroundColor: '#ffff',
        clipPath: 'polygon(0 0, 0 100%, 100% 100%)',
        transform: 'rotate(180deg)',


    },
    chatTime: {
        textAlign: 'right',
        fontSize: '12px',
        color: 'gray',
        marginTop: '8px',
    },
    footerMessageStyle: {
        fontSize: '12px',
        marginTop: '5px',
        color: '#918f8f'
    },
    buttonStyle: {
        // fontSize: '11px',
        wordWrap: 'break-word',
        fontFamily: 'system-ui',
        display: 'block',
        textAlign: 'justify',
    },
    drawerContainer: {
        position: 'relative',
        height: '100%',
    },
    listItem: {
        padding: "12px 0", // Add vertical padding
        borderBottom: "1px solid #f0f0f0", // Ant Design's default border color
    },
    text: {
        margin: 0, // Reset any default margins
    },
};

const ListItemPreview = ({ headerType, selected, header, body, footer, media, rows, buttonText, open }) => {

    const [currentTime, setCurrentTime] = useState('');
    const [preview, setPreview] = useState(null);
    const [showOption, setShowOptions] = useState(false);

    useEffect(() => {
        const updateCurrentTime = () => {
            const now = new Date();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            setCurrentTime(`${hours}:${minutes}`);
        };

        updateCurrentTime();
        const interval = setInterval(updateCurrentTime, 60000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (media) {
            const reader = new FileReader();
            if (reader) {
                reader.onloadend = () => {
                    setPreview(reader?.result);
                };
                reader?.readAsDataURL(media[0]);
            }
        } else {
            setPreview(null);
        }
    }, [media]);

    const HandleDrawer = () => {
        const filteredRows = rows?.filter((row) => row?.id.trim() !== '' || row?.label?.trim() !== '');
        if (filteredRows?.length > 0 && body) {
            setShowOptions(true);
        } else {
            message.error('Atleast One Option Is Required To Show Drawer And Body Text Is Required');
        }
    }

    const location = useLocation(); 
    useEffect(() => {
        const drawerElements = document.getElementsByClassName('ant-drawer-content-wrapper');
        Array.from(drawerElements).forEach(element => {
            element.style.boxShadow = 'none';
        });
        // Style the header elements
        const style = document.createElement('style');
        style.textContent = ` 
         .ant-drawer-content-wrapper {
                box-shadow: none !important;
                transform: translateX(0) !important;
            }
          .ant-drawer-header {
            border-bottom: 1px solid #f0f0f0 !important;
            padding: 16px 24px !important;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            position: relative !important;
          }
          
          .ant-drawer-title {
            flex: 1 !important;
            text-align: center !important;
            font-weight: bold !important;
            margin: 0 !important;
            padding: 0 !important;
          }
          
          .ant-drawer-close {
            position: absolute !important;
            right: 16px !important;
            top: 50% !important;
            transform: translateY(-50%) !important;
            margin: 0 !important;
            padding: 0 !important;
            line-height: 1 !important;
          }
    
          .ant-drawer-header-title {
            flex: 1 !important;
            justify-content: center !important;
          }

            /* Custom animation for drawer */
            .card-drawer .ant-drawer-content-wrapper {
                transform: translateY(100%) !important;
                transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1) !important;
            }

            .card-drawer.ant-drawer-open .ant-drawer-content-wrapper {
                transform: translateY(0) !important;
            }

        `;
        document.head.appendChild(style);

        // Cleanup
        return () => {
            Array.from(drawerElements).forEach(element => {
                element.style.boxShadow = '';
            });
            document.head.removeChild(style);
        };
    }, [location.pathname]);
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-200">
            <Card title="List Message Preview" className='overflow-hidden' bordered={false}>
                <div className="chat-container overflow-hidden" style={styles.chatBackground}>
                    <div style={styles.chatBubble}>
                        <div style={styles.chatBubbleBefore}></div>
                        {
                            headerType === 'Media' ? (
                                selected === 1 ? (
                                    <Image src={'/img/others/dummy-header.jpg'} preview={false} />
                                ) : selected === 2 ? (
                                    <Image src={'/img/dummyvideo.png'} preview={false} />
                                ) : selected === 3 ? (
                                    <div className='border rounded d-flex justify-content-center align-items-center mb-3' style={{ background: '#ccd0d5' }}>
                                        <img
                                            src={'/img/pdfBackground.png'}
                                            className='my-3'
                                            width={70}
                                            alt='pdf'
                                        />
                                    </div>
                                ) : null
                            ) : (
                                <div className='header'>
                                    <h5>{header}</h5>
                                </div>
                            )
                        }
                        {body && (
                            <>
                                <div>
                                    <div>
                                        {body.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line.split(/\*([^*]+)\*/g).map((part, index) => {
                                                    if (index % 2 === 1) {
                                                        return <Text key={index} style={{ fontWeight: 'bold' }}>{part}</Text>;
                                                    } else {
                                                        return <Text key={index}>{part}</Text>;
                                                    }
                                                })}
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                                <br />
                            </>
                        )}
                        <span style={styles.footerMessageStyle}>{footer}</span>
                        <div style={styles.chatTime}>{currentTime}</div>
                    </div>
                    <div style={styles.buttoneBubble}>
                        <span style={styles.buttonStyle} className="border-top py-2 cursor-pointer">
                            <button
                                className="border-0 bg-transparent fw-bold w-100 cursor-pointer"
                                style={{ color: '#34B7F1' }}
                                onClick={() => HandleDrawer()}
                            >
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                                    <UnorderedListOutlined />
                                    {buttonText}
                                </div>
                            </button>
                        </span>
                    </div>
                </div>
                <div style={styles.drawerContainer}>
                    <Drawer
                        title={buttonText}
                        placement="bottom"
                        closable={true}
                        onClose={() => setShowOptions(false)}
                        open={showOption}
                        height={220}
                        rootStyle={{ position: 'absolute' }}
                        style={{ position: 'absolute' }}
                        getContainer={false}
                        closeIcon={<CloseOutlined />}
                    >
                        <List>
                            {rows?.map((item, index) => (
                                <List.Item
                                    key={index}
                                    style={styles.listItem}
                                    className="py-3"
                                >
                                    <Typography.Text className="h5 text-black" style={styles.text}>
                                        {item?.label}
                                    </Typography.Text>
                                </List.Item>
                            ))}
                        </List>
                    </Drawer>
                </div>
            </Card >
        </div>
    );
};

export default ListItemPreview;
