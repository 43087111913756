import React, { useCallback, useEffect, useState } from 'react'
import { Card, Table, Tag, Tooltip, Button, Select, Form, DatePicker, message, Input, Row, Col, Drawer } from 'antd';
import { CloudDownloadOutlined, SearchOutlined, EyeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';
import { debounce } from 'lodash';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import NewMobilePreview from '../Campaign/newMobilePreview';
import { UserSelectField } from '../CustomUtils.js/CommonFieldUtils';
import { getStatusIcon } from 'components/shared-components/AvatarStatus';

const { RangePicker } = DatePicker;
const { Option } = Select
const OutReports = () => {
    const { api, authToken, UserData, Permisson, navigate, HandleExport, UserList, userRole, isMobile, templates } = useMyContext();
    useEffect(() => {
        if (!Permisson?.includes('View Outgoing Reports')) {
            navigate('404')
        }
    }, [Permisson]);
    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [users, setUsers] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [userId, setUserId] = useState(UserData?.id);
    const [searchValue, setSearchValue] = useState('');
    const [category, setCategory] = useState('');

    const getStatusColor = status => {
        if (status === 'sent') {
            return 'blue'
        }
        if (status === 'delivered') {
            return 'cyan'
        }
        if (status === 'read') {
            return 'green'
        }
        if (status === 'failed') {
            return 'red'
        }
        return ''
    }

    const fetchReports = useCallback(async (id, value) => {
        if (!id) return;
        setLoading(true);
        const params = {};
        if (startDate) {
            params.startDate = dayjs(startDate, 'DD/MM/YY').format('DD/MM/YY');
        }
        if (endDate) {
            params.endDate = dayjs(endDate, 'DD/MM/YY').format('DD/MM/YY');
        }
        if (value) {
            params.search = value.toLowerCase();
        }
        if (category) {
            params.category = category.toLowerCase();
        }
        try {
            const response = await axios.get(`${api}out-reports/${id}`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                },
                params
            });
            const outReports = response.data.out_reports;
            const reversedData = outReports.reverse();
            setReports(reversedData);
        } catch (error) {
            message.error('Error fetching reports');
        } finally {
            setLoading(false);
        }
    }, [api, authToken, startDate, endDate, category]);


    useEffect(() => {
        ErrorCodes()
    }, []);

    useEffect(() => {
        if (userId) {
            fetchReports(userId);
        }
    }, [userId, fetchReports]);

    useEffect(() => {
        setUsers(UserList);
    }, [UserList]);

    const HandleUserChange = (value) => {
        setUserId(value);
    };

    const HandleDateRangeChange = (dates) => {
        if (dates && dates.length > 0) {
            const sortedDates = dates.slice().sort((a, b) => new Date(a) - new Date(b));
            const formattedDates = sortedDates.map(date => dayjs(date).format('DD/MM/YY'));
            setStartDate(formattedDates[0]);
            setEndDate(formattedDates[formattedDates.length - 1]);
        } else {
            setStartDate(null);
            setEndDate(null);
        }
    };

    // Debounce the fetchReports function
    const debouncedFetchReports = useCallback(
        debounce((id, value) => fetchReports(id, value), 300),
        [fetchReports]
    );

    useEffect(() => {
        if (searchValue) {
            debouncedFetchReports(userId, searchValue);
        } else {
            fetchReports(userId)
        }
    }, [searchValue, userId, debouncedFetchReports]);

    const handleInputChange = (e) => {
        setSearchValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            fetchReports(userId, searchValue);
        }
    };

    const [errorData, setErrorData] = useState();
    const ErrorCodes = async () => {
        try {
            const response = await axios.get(`${api}error-codes`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                }
            });
            const data = response.data.codes;
            setErrorData(data);
        } catch (error) {
        }
    };
    const PasreErrorMessage = (code) => {
        if (code) {
            const message = errorData?.find(item => item.code === parseInt(code));
            return message?.description;
        }
    };

    const tableColumns = [
        {
            title: 'Message Category',
            dataIndex: 'category',
            render: status => (
                <div className="text-capitalize" >
                    {status}
                </div>
            ),
            sorter: {
                compare: (a, b) => a.text_body.length - b.text_body.length,
            },
        },
        {
            title: 'To',
            dataIndex: 'recipient_id',
            sorter: {
                compare: (a, b) => a.recipient_id.length - b.recipient_id.length,
            },
        },
        {
            title: 'Sent Time',
            dataIndex: 'timestamp',
            render: timestamp => {
                const date = dayjs.unix(timestamp);
                const formattedDate = date.format('MM-DD-YYYY');
                const formattedTime = date.format('hh:mm:ss A');
                return (
                    <span>
                        {formattedDate} | {formattedTime}
                    </span>
                );
            },
            sorter: {
                compare: (a, b) => a.timestamp.length - b.timestamp.length,
            },
        },
        {
            title: 'Delivered Time',
            dataIndex: 'delivered_time',
            render: (delivered_time) => {
                return delivered_time ? delivered_time : 'N/A'
            },
            sorter: {
                compare: (a, b) => a.status.length - b.status.length,
            },
        },
        {
            title: 'Read Time',
            dataIndex: 'read_time',
            render: (read_time) => {

                return read_time ? read_time : 'N/A';
            },
            sorter: {
                compare: (a, b) => a.status.length - b.status.length,
            },
        },
        {
            title: 'status',
            dataIndex: 'status',
            render: (_, record) => {
                const errorMessage = PasreErrorMessage(record?.error_code);
                const tagContent = (
                    // <Tag color={getStatusColor(record.status)} style={{ textTransform: 'capitalize', letterSpacing: '1px' }}>
                    //     {record.status}
                    // </Tag>
                    getStatusIcon(record.status,errorMessage)
                );
                return record.status === 'failed' ? (
                    <Tooltip title={errorMessage || 'Unknown error'}>{tagContent}</Tooltip>
                ) : (
                    tagContent
                );
            },
            sorter: {
                compare: (a, b) => a.status.length - b.status.length,
            },
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            render: (_, record) => {
                return (
                    <div className="text-right d-flex">
                        <Tooltip title="Message PreView">
                            <Button
                                type="primary"
                                className="mr-2"
                                icon={<EyeOutlined />}
                                onClick={() => { showDrawer(record) }}
                                size="small"
                            />
                        </Tooltip>
                    </div>
                )
            }
        },
        


    ];

    const [open, setOpen] = useState(false);
    const [template, setTemplate] = useState([]);
    const [mediaID, setMediaID] = useState(null);
    const showDrawer = (item) => {
        if (item?.api_report) {
            let templateName = item?.api_report?.template_name;
            let media_id = item?.api_report?.media_id;
            setMediaID(media_id)
            let buttonValues = JSON.parse(item?.api_report?.button_values);
            let bodyValues = JSON.parse(item?.api_report?.body_values);
            let template = templates?.find(t => t.name === templateName);
            const { header, body, footer, buttons } = extractTemplateSections(template);
            if (bodyValues?.length > 0) {
                body.text = bodyValues?.reduce((text, value, index) => {
                    const placeholder = `{{${index + 1}}}`;
                    return text?.replace(placeholder, value);
                }, body?.text);
            }
            if (buttons) {
                buttons?.buttons?.forEach((btn, index) => {
                    if (btn?.url?.endsWith("\"")) {
                        btn.url = btn?.url?.slice(0, -1);
                    }
                    if (btn?.example && btn?.example.length > 0) {
                        const exampleUrl = btn.example[0];
                        const staticPart = btn.url;

                        if (exampleUrl?.startsWith(staticPart)) {
                            btn.url = staticPart + buttonValues[index];
                        }
                    }
                });
            }
            setTemplate({ header, body, footer, buttons });
            setOpen(true);
        }
    };

    const extractTemplateSections = (template) => {
        let header = null, body = null, footer = null, buttons = null;
        template?.components?.forEach(section => {
            switch (section.type) {
                case "HEADER":
                    header = section;
                    break;
                case "BODY":
                    body = section;
                    break;
                case "FOOTER":
                    footer = section;
                    break;
                case "BUTTONS":
                    buttons = section;
                    break;
                default:
                    // Handle any other section types if needed
                    break;
            }
        });
        return { header, body, footer, buttons };
    }
    const onClose = () => {
        setOpen(false);
        setTemplate(null); // Reset template
        setMediaID(null); // Reset 
    };
    return (
        <>
            <Card bodyStyle={{ padding: '0px' }}>
                <div className="container-fluid">
                    <Form
                        name="basicInformation"
                        layout={`${isMobile ? 'vertical' : 'horizontal'} `}
                    >
                        <Row gutter={ROW_GUTTER} justify="space-between" align="middle" className="py-4">
                            <Col>
                                <h2>Outgoing Reports</h2>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={16}>
                                <Row gutter={ROW_GUTTER} justify="end" align="middle">
                                    {(userRole === 'Admin' || userRole === 'Reseller') &&
                                        <Col span={24} xxl={4}>
                                            <div className="mr-md-3 mb-3">
                                                <Form.Item className='mb-0'>
                                                   <UserSelectField users={users} HandleUserChange={HandleUserChange} />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    }
                                    <Col span={24} xxl={6}>
                                        <div className="ml-md-3 mb-3">
                                            <Form.Item label="Dates" className='mb-0'>
                                                <RangePicker
                                                    style={{ width: '100%' }}
                                                    allowClear onChange={(dates) => HandleDateRangeChange(dates)}
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col span={24} xxl={5}>
                                        <div className="md-3 mb-3">
                                            <Form.Item label="" className='mb-0'>
                                                <Select
                                                    showSearch
                                                    defaultValue={{ key: "choose..." }}
                                                    style={{ minWidth: 180 }}
                                                    onChange={(value) => setCategory(value)}
                                                >
                                                    <Option value="service">Service</Option>
                                                    <Option value="utility">Utility</Option>
                                                    <Option value="marketing">Marketing</Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col span={14} xxl={5}>
                                        <div className="md-3 mb-3">
                                            <Form.Item label="" className='mb-0'>
                                                <Input placeholder="Search" prefix={<SearchOutlined />}
                                                    value={searchValue}
                                                    onChange={handleInputChange}
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col>

                                    <Col span={10} xxl={4}>
                                        <div className="mb-3 text-center">
                                            <Button icon={<CloudDownloadOutlined />} onClick={() => HandleExport('export-reports', 'incoming-report.xlsx')}>Export Report</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </div>


                <div className="table-responsive">
                    <Table
                        loading={loading}
                        columns={tableColumns}
                        dataSource={reports}
                        rowKey="id"
                        expandable={{
                            expandedRowRender: (record) => <p className='ml-5'>{record.status_id}</p>,
                            // rowExpandable: (record) => record.name !== 'Not Expandable',
                        }}

                    />
                </div>
                {/* <UserView data={selectedUser} visible={userProfileVisible} close={() => closeUserProfile()} /> */}
            </Card>
            <div className="">
                <Drawer
                    title="Message Preview"
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    open={open}
                    getContainer={false}
                    style={{
                        position: 'absolute',
                    }}
                >

                    <NewMobilePreview
                        mediaLink={mediaID}
                        requestType={'template'}
                        template={template}
                    />
                </Drawer>
            </div>
        </>

    );
};

export default OutReports
