import React from 'react';
import { Col, Form, Input, Button, Image } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';

const CustomMediaMessage = ({
  chatBotType,
  customMessageType,
  customMediaName,
  buttonStyle,
  showMediaModel,
  getUrlFromMediaId,
  mediaID,
  media
}) => {
  if (chatBotType !== 'Custom' || customMessageType === 'text') {
    return null;
  }

  return (
    <>
      <Col xs={24} sm={24} md={12}>
        <Form.Item
          name={['content', customMessageType]}
          label={`Select ${customMessageType}`}
        >
          {customMediaName && <Input type="text" disabled value={customMediaName} className='mb-3' />}
          <Button
            type=""
            style={buttonStyle}
            onClick={showMediaModel}
          >
            <CloudUploadOutlined />
            Choose File
          </Button>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={12}>
        <Form.Item
          label="Media Preview"
          className='mb-0'
        >
          <Image
            src={getUrlFromMediaId(mediaID, media) || '/img/others/dummy-header.jpg'}
            className="border rounded cursor-pointer"
            preview={false}
            style={{
              width: '200px',
              height: '150px',
              objectFit: 'contain',
            }}
          />
        </Form.Item>
        <span className='text-warning'>Preview available for images</span>
      </Col>
    </>
  );
};

export default CustomMediaMessage;
