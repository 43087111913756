import React, { useEffect, useState } from 'react'
import { Card, Table, Button, Tooltip } from 'antd';
import axios from 'axios';
import Flex from 'components/shared-components/Flex';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useMyContext } from 'Context/MyContextProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
const LiveStatus = () => {
    const { api, authToken, DownloadExcelFile, Permisson, navigate } = useMyContext();
    useEffect(() => {
        if (!Permisson?.includes('View Live Status')) {
            navigate('404')
        }
    }, [Permisson]);
    const [loading, setLoading] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [liveStatus, setLiveStatus] = useState([]);

    //analytics
    const GetSettingData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}live-status`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
            const data = response.data;
            setLiveStatus((data?.LiveStatus).reverse());
            setLoading(false)
        } catch (error) {
            // console.log(error);
        }
    }
    useEffect(() => {
        setLoading(true);
        GetSettingData();
    }, [])



    const tableColumns = [
        {
            title: 'Company Name',
            dataIndex: 'company_name',
            sorter: {
                compare: (a, b) => a.company_name.length - b.company_name.length,
            },
        },
        {
            title: 'Available Credits',
            dataIndex: 'balance',
            sorter: {
                compare: (a, b) => a.name.length - b.name.length,
            },
        },
        {
            title: 'Incoming Conversation',
            dataIndex: 'total_incomingreports_count',
            sorter: {
                compare: (a, b) => a.total_incomingreports_count.length - b.total_incomingreports_count.length,
            },
        },
        {
            title: 'Campaign Conversation',
            dataIndex: 'total_campaignreports_count',
            sorter: {
                compare: (a, b) => a.total_campaignreports_count.length - b.total_campaignreports_count.length,
            },
        },
        {
            title: 'Outgoing Conversation',
            dataIndex: 'total_outreports_count',
            sorter: {
                compare: (a, b) => a.total_outreports_count.length - b.total_outreports_count.length,
            },
        },
        {
            title: 'Marketing',
            dataIndex: 'outreports_category_marketing_count',
            sorter: {
                compare: (a, b) => a.outreports_category_marketing_count.length - b.outreports_category_marketing_count.length,
            },
        },
        {
            title: 'Utility',
            dataIndex: 'outreports_category_utility_count',
            sorter: {
                compare: (a, b) => a.outreports_category_utility_count.length - b.outreports_category_utility_count.length,
            },
        },
        {
            title: 'Service',
            dataIndex: 'outreports_category_service_count',
            sorter: {
                compare: (a, b) => a.outreports_category_service_count.length - b.outreports_category_service_count.length,
            },
        },
        // {
        //     title: 'Totals',
        //     key: 'totals',
        //     fixed: 'right', // Makes the column sticky
        //     render: (_, __, index) => {
        //       if (index === 0) {
        //         const data = filterData?.length > 0 ? filterData : liveStatus;

        //         const totalCampaignReports = data.reduce(
        //           (sum, record) => sum + (Number(record.total_campaignreports_count) || 0),
        //           0
        //         );

        //         const totalOutReports = data.reduce(
        //           (sum, record) => sum + (Number(record.total_outreports_count) || 0),
        //           0
        //         );

        //         return (
        //           <div style={{ fontWeight: 'bold' }}>
        //             <div>Total Campaign: {totalCampaignReports}</div>
        //             <div>Total Outgoing: {totalOutReports}</div>
        //           </div>
        //         );
        //       }
        //       return null; // For other rows, leave the column blank
        //     },
        //   },
    ];


    return (

        <Card bodyStyle={{ padding: '0px' }}>
            <div className="container-fluid">
                <Flex justifyContent="space-between" alignItems="center" className="py-3">
                    <h2>Live Status</h2>
                    <div className="mb-3 text-right">
                        <Button icon={<CloudDownloadOutlined />} onClick={() => DownloadExcelFile(liveStatus, 'live-status.xlsx')}>Export Live Status</Button>
                    </div>
                </Flex>
            </div>
            <div className="table-responsive">
                <Table
                    columns={tableColumns}
                    expandable={{
                        expandedRowRender: (item) => (
                            <>
                                <Flex justifyContent="center">
                                    <Tooltip title="Sent messages count">
                                        <Flex alignItems="center" className='mr-5'>
                                            <FontAwesomeIcon icon={faCheck} />
                                            <p className="m-0 pl-2">{item?.sentCount}</p>
                                        </Flex>
                                    </Tooltip>
                                    <Tooltip title="Delivered messages count">
                                        <Flex alignItems="center" className='mr-5'>
                                            <FontAwesomeIcon icon={faCheckDouble} />
                                            <p className="m-0 pl-2">{item?.deliveredCount}</p>
                                        </Flex>
                                    </Tooltip>
                                    <Tooltip title="Read messages count">
                                        <Flex alignItems="center" className='mr-5'>
                                            <FontAwesomeIcon icon={faCheckDouble} style={{ color: '#22af4b' }} />
                                            <p className="m-0 pl-2">{item?.readCount}</p>
                                        </Flex>
                                    </Tooltip>
                                    <Tooltip title="Failed messages count">
                                        <Flex alignItems="center" className='mr-5'>
                                            <FontAwesomeIcon icon={faCircleExclamation} className='text-danger' />
                                            <p className="m-0 pl-2">{item?.failCount}</p>
                                        </Flex>
                                    </Tooltip>
                                </Flex>
                            </>
                        ),
                    }}
                    loading={loading}
                    dataSource={filterData?.length > 0 ? filterData : liveStatus}
                    rowKey="id"
                    summary={() => {
                        const data = filterData?.length > 0 ? filterData : liveStatus;

                        const totalIncomingReports = data.reduce(
                            (sum, record) => sum + (Number(record.total_incomingreports_count) || 0),
                            0
                        );
                        const totalCampaignReports = data.reduce(
                            (sum, record) => sum + (Number(record.total_campaignreports_count) || 0),
                            0
                        );
                        const totalOutReports = data.reduce(
                            (sum, record) => sum + (Number(record.total_outreports_count) || 0),
                            0
                        );
                        const totalMarketing = data.reduce(
                            (sum, record) => sum + (Number(record.outreports_category_marketing_count) || 0),
                            0
                        );
                        const totalUtility = data.reduce(
                            (sum, record) => sum + (Number(record.outreports_category_utility_count) || 0),
                            0
                        );
                        const totalService = data.reduce(
                            (sum, record) => sum + (Number(record.outreports_category_service_count) || 0),
                            0
                        );
                        return (
                            <Table.Summary.Row style={{ backgroundColor: '#f0f0f0' }}>
                                <Table.Summary.Cell index={0} colSpan={1}>
                                    <strong>Totals:</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} style={{ fontWeight: 'bold' }} />
                                <Table.Summary.Cell index={2} style={{ fontWeight: 'bold' }} />
                                <Table.Summary.Cell index={3} style={{ fontWeight: 'bold' }}>
                                <strong>{totalIncomingReports}</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={4} style={{ fontWeight: 'bold' }}>
                                <strong>{totalCampaignReports}</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={5} style={{ fontWeight: 'bold' }}>
                                <strong>{totalOutReports}</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={6} style={{ fontWeight: 'bold' }}>
                                <strong>{totalMarketing}</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={7} style={{ fontWeight: 'bold' }}>
                                <strong> {totalUtility}</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={8} style={{ fontWeight: 'bold' }}>
                                <strong> {totalService}</strong>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        );
                    }}

                />
            </div>
        </Card >
    );
};

export default LiveStatus
