import axios from "axios";

export const Chatbots = async (setLoading,api,id,authToken,setChatbots) => {
    setLoading(true)
    try {
        const response = await axios.get(`${api}chatbot/${id}`,
            {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            });
        const data = response.data.chatbot;
        setChatbots(data);
        setLoading(false)
    } catch (error) {
        setLoading(false)
        console.log(error);
    }
}