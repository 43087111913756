import React from 'react';
import { Form, Select, Radio, Input, Button } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import SelectTemplate from './CampainComps/SelectTemplate';



const TemplateComponent = ({
  templateName,
  templateCategory,
  setTemplateCategory,
  templateList,
  HandleTemplate,
  template,
  mediaType,
  showMediaModel,
  mediaLink,
  buttonStyle,
  mediaName,
  setMediaLink,
  templateInputs,
  templateUrlInputs, // New prop for handling URL inputs
}) => {
  return (
    <>
      <Form.Item label="Template Category" name="Template_category">
        <Radio.Group
          onChange={(e) => setTemplateCategory(e.target.value)}
          value={templateCategory}
        >
          <Radio value="MARKETING">Marketing</Radio>
          <Radio value="UTILITY">Utility</Radio>
        </Radio.Group>
        <span className='d-none'>{templateCategory}</span>
      </Form.Item>
      {templateCategory &&
        <>
          <Form.Item label="Templates" name="Templates">
            <SelectTemplate
              templateName={templateName}
              HandleTemplate={HandleTemplate}
              templateList={templateList}
            />
            <span className='d-none'>{templateName}</span>
          </Form.Item>

          {template?.header && template?.header?.format !== 'TEXT' && (
            <>
              {mediaType === 'Media' ? (
                <Form.Item label="Select media" name="media-link">
                  {!mediaLink ? (
                    <Button
                      type=""
                      style={buttonStyle}
                      onClick={showMediaModel}
                    >
                      <CloudUploadOutlined />
                      Choose File
                    </Button>
                  ) : (
                    <>
                      <Input
                        type="text"
                        disabled
                        value="Media Selected"
                        className="mb-3"
                      />
                      <Button
                        type=""
                        style={buttonStyle}
                        onClick={showMediaModel}
                      >
                        <CloudUploadOutlined />
                        Choose Another File
                      </Button>
                      <p className="d-none">{mediaName}</p>
                    </>
                  )}
                </Form.Item>
              ) : (
                <Form.Item
                  label="Your media file link"
                  name="media-link"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter campaign name!',
                    },
                  ]}
                >
                  <Input
                    type="text"
                    onChange={(e) => setMediaLink(e.target.value)}
                    className="mb-3"
                  />
                </Form.Item>
              )}
            </>
          )}

          <div>
            {templateInputs.length > 0 && (
              <Form.Item
                label={`${templateInputs.length} dynamic values detected`}
              >
                {templateInputs.map((input, index) => (
                  <div key={index} className="mb-2">
                    {input}
                  </div>
                ))}
              </Form.Item>
            )}
          </div>

          {/* Handling templateUrlInputs */}
          <div>
            {templateUrlInputs.length > 0 && (
              <Form.Item label={`${templateUrlInputs.length} Url Button detected`}>
                {templateUrlInputs.map((input, index) => (
                  <div key={index} className="mb-2">
                    {input}
                  </div>
                ))}
              </Form.Item>
            )}
          </div>
        </>
      }
    </>
  );
};

export default TemplateComponent;
