const { Input } = require("antd");
const { Link } = require("react-router-dom");


export const HandleDynamicValues = (templatePreview, dynamicValues, setNewMessage) => {
    if (dynamicValues?.length > 0) {
        const text = templatePreview?.components[1]?.text;
        let updatedText = text;
        dynamicValues.forEach((item, index) => {
            updatedText = updatedText?.replace(`{{${index + 1}}}`, item);
        });
        setNewMessage(updatedText);
    }
}


export const HandleTemplateChange = (value, setMediaLink, setTemplateName, setTemplateKey, templates, setTemplateCategory, setTemplateLanguage,
    setTemplatePreview, setTemplate, setTemplateInputs, setTemplateUrlInputs, setDynamicValues, setDynamicUrlButton) => {
    setMediaLink('');
    setTemplateName(value);
    setTemplateKey(prevKey => prevKey + 1);
    const template = templates?.find((item) => item.name === value);

    setTemplateCategory(template?.category)
    setTemplateLanguage(template?.language)
    setTemplatePreview(template);
    const { header, body, footer, buttons } = extractTemplateSections(template);
    setTemplate({ header, body, footer, buttons });

    const templateBodyDynamicValue = body?.text?.match(/{{\d+}}/g);
    if (templateBodyDynamicValue) {
        const inputs = generateInputsForDynamicValues(templateBodyDynamicValue, setDynamicValues);
        setTemplateInputs(inputs);
    } else {
        setTemplateInputs([]);
    }

    if (buttons?.buttons) {
        const urlInputs = generateUrlInputs(buttons?.buttons, setDynamicUrlButton);
        setTemplateUrlInputs(urlInputs);
    } else {
        setTemplateUrlInputs([]);
    }
}


export const extractTemplateSections = (template) => {
    let header = null, body = null, footer = null, buttons = null;
    template?.components?.forEach(section => {
        switch (section.type) {
            case "HEADER":
                header = section;
                break;
            case "BODY":
                body = section;
                break;
            case "FOOTER":
                footer = section;
                break;
            case "BUTTONS":
                buttons = section;
                break;
            default:
                break;
        }
    });
    return { header, body, footer, buttons };
}

const generateInputsForDynamicValues = (dynamicValues, setDynamicValues) => {
    return dynamicValues.map((placeholder, index) => (
        <Input
            key={index}
            type="text"
            placeholder={`Enter value for ${placeholder}`}
            onChange={(e) => handleInputChange(index, e.target.value, setDynamicValues)}
        />
    ));
}

const generateUrlInputs = (urlButtons, setDynamicUrlButton) => {
    return urlButtons.map((item, index) => {
        const isDisabled = !item.hasOwnProperty('example');
        if (!isDisabled) {
            return (
                <div key={index}>
                    <Link>{item.url}</Link>
                    <Input
                        type="text"
                        placeholder={`Your "${item.text}" Button Value`}
                        onChange={(e) => handleUrlButton(index, e.target.value, setDynamicUrlButton)}
                        disabled={isDisabled}
                    />
                </div>
            );
        }
        return null;
    });
};


const handleInputChange = (index, value, setDynamicValues) => {
    setDynamicValues(prevValues => {
        const updatedValues = [...prevValues];
        updatedValues[index] = value;
        return updatedValues;
    });

};

const handleUrlButton = (index, value, setDynamicUrlButton) => {
    setDynamicUrlButton(prevValues => {
        const updatedValues = [...prevValues];
        updatedValues[index] = value;
        return updatedValues;
    });
};