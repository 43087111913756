import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactFlow, MiniMap, Background, useNodesState, useEdgesState, MarkerType, ConnectionLineType, Handle, Position } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import MenuActions from './MenuActions';

// Increased spacing for better layout
const NODE_WIDTH = 200;
const NODE_HEIGHT = 60;
const HORIZONTAL_SPACING = 100;
const VERTICAL_SPACING = 150;

// Enhanced edge styles
const edgeStyles = {
  stroke: '#22af4b',
  strokeWidth: 2,
  transition: 'all 0.3s ease',
};


const ChatbotFlowmap = ({ data, ChatbotData }) => {
  const CustomNode = ({ data }) => {
    return (
      <div
        style={{
          padding: '15px 25px',
          border: '2px solid #e6f7ff',
          borderRadius: '10px',
          background: 'white',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          position: 'relative',
          fontSize: '14px',
          textAlign: 'center',
          minWidth: NODE_WIDTH,
          minHeight: NODE_HEIGHT,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'all 0.3s ease',
          '&:hover': {
            boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
            borderColor: '#22af4b',
          }
        }}
      >
        <Handle
          type="target"
          position={Position.Left}
          id="target"
          style={{
            background: '#22af4b',
            width: '10px',
            height: '10px',
            border: '2px solid white',
            borderRadius: '50%',
            left: '-6px',
          }}
        />
        <div style={{
          fontWeight: '500',
          color: '#333',
          maxWidth: '160px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}>
          {data.label}
        </div>
        <MoreOutlined
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            fontSize: '16px',
            color: '#22af4b',
            cursor: 'pointer',
            padding: '4px',
            borderRadius: '4px',
            transition: 'all 0.3s ease',
            '&:hover': {
              background: '#e6f7ff',
            }
          }}
          onClick={(e) => {
            e.stopPropagation();
            onNodeClick(e, data);
          }}
        />
        <Handle
          type="source"
          position={Position.Right}
          id="source"
          style={{
            background: '#22af4b',
            width: '10px',
            height: '10px',
            border: '2px solid white',
            borderRadius: '50%',
            right: '-6px',
          }}
        />
      </div>
    );
  };
  const processNodes = useMemo(() => {
    const process = (node, position = { x: 0, y: 0 }, level = 0, parentWidth = 0) => {
      let allElements = [];
      try {
        const keywords = JSON.parse(node.keyword);
        const label = Array.isArray(keywords) ? keywords[0] : keywords;

        // Calculate proper x position based on level and parent width
        const xPosition = position.x + (level === 0 ? 0 : HORIZONTAL_SPACING + parentWidth);

        const newNode = {
          id: node.id.toString(),
          type: 'custom',
          position: { x: xPosition, y: position.y },
          data: {
            label,
            id: node.id,
            data: node,
            onEdit: () => console.log(`Editing node ${node.id}`)
          }
        };
        allElements.push(newNode);

        if (node.children && node.children.length > 0) {
          const childCount = node.children.length;
          const totalHeight = (childCount - 1) * VERTICAL_SPACING;
          const startY = position.y - (totalHeight / 2);

          node.children.forEach((child, index) => {
            const childPosition = {
              x: xPosition,
              y: startY + (index * VERTICAL_SPACING)
            };

            const childElements = process(child, childPosition, level + 1, NODE_WIDTH);
            allElements.push(...childElements);

            const edge = {
              id: `e${node.id}-${child.id}`,
              source: node.id.toString(),
              target: child.id.toString(),
              sourceHandle: 'source',
              targetHandle: 'target',
              type: 'smoothstep',
              animated: true,
              style: {
                ...edgeStyles,
                animation: 'flowEdge 3s infinite linear',
                strokeDasharray: '5 5',
              },
              markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 15,
                height: 15,
                color: '#22af4b',
              },
            };
            allElements.push(edge);
          });
        }
      } catch (e) {
        console.warn('Failed to process node:', e);
      }
      return allElements;
    };
    return process;
  }, []);

  const { nodes: initialNodes, edges: initialEdges } = useMemo(() => {
    if (!data) return { nodes: [], edges: [] };
    const elements = processNodes(data);
    return {
      nodes: elements.filter(el => !el.source),
      edges: elements.filter(el => el.source)
    };
  }, [data, processNodes]);

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [selectedNode, setSelectedNode] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @keyframes flowEdge {
        0% {
          stroke-dashoffset: 20;
        }
        100% {
          stroke-dashoffset: 0;
        }
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  const onInit = useCallback((reactFlowInstance) => {
    reactFlowInstance.fitView({ padding: 0.2, includeHiddenNodes: false });
  }, []);

  const onNodeClick = useCallback(async (event, node) => {
    event.preventDefault();
    const { pageX, pageY } = event;
    setDropdownPosition({ x: pageX - 150, y: pageY - 100 });
    setSelectedNode(node);
    setDropdownVisible((prevVisible) => !prevVisible);
  }, []);

  const nodeTypes = useMemo(() => ({
    custom: CustomNode
  }), []);

  const handleNodeDrag = useCallback((changes) => {
    changes.forEach((change) => {
      if (change?.type === 'position' && change.dragging) {
        resetNodeData()
      }});
  }, [setNodes]);

  const resetNodeData = () =>{
    setDropdownVisible(false);
  }

  const handleCanvasMove = useCallback((event, viewport) => {
    console.log('kk')
    resetNodeData()
  }, []);
  return (
    <div 
    style={{ width: '100%', height: '800px' }} 
    onClick={() => resetNodeData()}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={(changes) => {
          onNodesChange(changes);
          handleNodeDrag(changes);
        }}
        onEdgesChange={onEdgesChange}
        onPaneMove={handleCanvasMove}
        onInit={onInit}
        //onNodeClick={onNodeClick}
        nodeTypes={nodeTypes}
        fitView
        attributionPosition="bottom-left"
        minZoom={0.1}
        maxZoom={4}
        defaultZoom={0.5}
        nodesDraggable={true}
        nodesConnectable={false}
        elementsSelectable={true}
        zoomOnScroll={false}
        panOnScroll={true}
        panOnScrollMode="free"
        connectionLineType={ConnectionLineType.SmoothStep}
      >
        {/* <Controls /> */}
        <MiniMap
          style={{
            backgroundColor: '#f0f2f5',
            border: '1px solid #22af4b',
            borderRadius: '4px',
          }}
          nodeColor="#22af4b"
        />
        <Background color="#aaa" gap={16} />
      </ReactFlow>
      <Dropdown
        overlay={
          <MenuActions
            ChatbotData={ChatbotData}
            selectedNode={selectedNode}
            setDropdownVisible={setDropdownVisible}
          />}
        trigger={['click']}
        open={dropdownVisible}
        placement='bottomRight'>
        <div
          style={{
            position: 'absolute',
            top: dropdownPosition.y,
            left: dropdownPosition.x,
            width: '0px',
            height: '0px',
          }}
        >

        </div>
      </Dropdown>
    </div>
  );
};

export default ChatbotFlowmap;